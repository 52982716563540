:root {
  --bitflex-blue: #cf8900;
}

html {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  -webkit-tap-highlight-color: transparent !important;
  -webkit-tap-highlight-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #1D2127 inset !important;
  box-shadow: 0 0 0 30px #1D2127 inset !important;
  -webkit-text-fill-color: white !important;
  background-clip: content-box !important;
  background-color: transparent !important;
}

.row-selected-sell {
  border-top: 1px solid transparent;
  border-bottom: 1px solid #433c3c4D;
  display: inline-flex;
  width: 100%;
}

.row-selected-sell .onmouseover {
  border-top: 1px solid transparent;
}

.row-selected-bg-sell {
  background-color: #433c3c3F;
}

/* .row-selected-bg-sell~div {
  background-color: #433c3c3F;
} */

.row-selected-bg-buy {
  background-color: #433c3c3F;
}

.row-selected-bg-buy~div {
  background-color: red;
}

.orderbook-container {
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.orderbook-container.sell {
  display: flex;
  flex: 1;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  /* flex-direction: column; */
  flex-direction: column-reverse;
  /* overflow-x: hidden; */
  transition: all 0.6s;
}

.orderbook-container.buy {
  display: flex;
  flex: 1;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  /* flex-direction: column; */
  /* flex-direction: column-reverse; */
  transition: all 0.6s;
}

/* .orderbook-container.hidden {
  flex: 0;
  transition: all 1s;
} */

.orderbook-container-spread {
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-content: center;
  background-color: #433c3c3F;
  align-items: center;
  align-content: center;
}

.orderbook-container-header {
  height: 36px;
  padding-right: 4px;
  margin-right: -4px;
  display: flex;
  flex-direction: row;
}

.bf-confirmation-overlay {
  position: absolute;
  width: 100%;
  height: 0;
  left: 0;
  right: 0;
  background: rgba(16, 22, 26, 1);
  z-index: 1001;
  transition: all 0.333s, transform 0.333s;
  opacity: 0;
  margin-top: 100%;
}

.bf-confirmation-overlay.show {
  opacity: 1;
  margin-top: -12px;
  height: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.table>thead>tr>th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.table>caption+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>th,
.table>thead:first-child>tr:first-child>th,
.table>caption+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>td,
.table>thead:first-child>tr:first-child>td {
  border-top: 0;
}

.table>tbody+tbody {
  border-top: 2px solid #ddd;
}

.table .table {
  background-color: #fff;
}

.table-condensed>thead>tr>th,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>tbody>tr>td,
.table-condensed>tfoot>tr>td {
  padding: 5px;
}

.table-bordered {
  border: 1px solid #ddd;
}

.table-bordered>thead>tr>th,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>tbody>tr>td,
.table-bordered>tfoot>tr>td {
  border: 1px solid #ddd;
}

.table-bordered>thead>tr>th,
.table-bordered>thead>tr>td {
  border-bottom-width: 2px;
}

/* .table-striped>tbody>tr:nth-of-type(odd) {
  background-color: #f9f9f9;
} */

.table-hover>tbody>tr:hover {
  background-color: #f5f5f5;
}

table col[class*="col-"] {
  position: static;
  float: none;
  display: table-column;
}

table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  float: none;
  display: table-cell;
}

.table>thead>tr>td.active,
.table>tbody>tr>td.active,
.table>tfoot>tr>td.active,
.table>thead>tr>th.active,
.table>tbody>tr>th.active,
.table>tfoot>tr>th.active,
.table>thead>tr.active>td,
.table>tbody>tr.active>td,
.table>tfoot>tr.active>td,
.table>thead>tr.active>th,
.table>tbody>tr.active>th,
.table>tfoot>tr.active>th {
  background-color: #f5f5f5;
}

.table-hover>tbody>tr>td.active:hover,
.table-hover>tbody>tr>th.active:hover,
.table-hover>tbody>tr.active:hover>td,
.table-hover>tbody>tr:hover>.active,
.table-hover>tbody>tr.active:hover>th {
  background-color: #e8e8e8;
}

.table>thead>tr>td.success,
.table>tbody>tr>td.success,
.table>tfoot>tr>td.success,
.table>thead>tr>th.success,
.table>tbody>tr>th.success,
.table>tfoot>tr>th.success,
.table>thead>tr.success>td,
.table>tbody>tr.success>td,
.table>tfoot>tr.success>td,
.table>thead>tr.success>th,
.table>tbody>tr.success>th,
.table>tfoot>tr.success>th {
  background-color: #dff0d8;
}

.table-hover>tbody>tr>td.success:hover,
.table-hover>tbody>tr>th.success:hover,
.table-hover>tbody>tr.success:hover>td,
.table-hover>tbody>tr:hover>.success,
.table-hover>tbody>tr.success:hover>th {
  background-color: #d0e9c6;
}

.table>thead>tr>td.info,
.table>tbody>tr>td.info,
.table>tfoot>tr>td.info,
.table>thead>tr>th.info,
.table>tbody>tr>th.info,
.table>tfoot>tr>th.info,
.table>thead>tr.info>td,
.table>tbody>tr.info>td,
.table>tfoot>tr.info>td,
.table>thead>tr.info>th,
.table>tbody>tr.info>th,
.table>tfoot>tr.info>th {
  background-color: #d9edf7;
}

.table-hover>tbody>tr>td.info:hover,
.table-hover>tbody>tr>th.info:hover,
.table-hover>tbody>tr.info:hover>td,
.table-hover>tbody>tr:hover>.info,
.table-hover>tbody>tr.info:hover>th {
  background-color: #c4e3f3;
}

.table>thead>tr>td.warning,
.table>tbody>tr>td.warning,
.table>tfoot>tr>td.warning,
.table>thead>tr>th.warning,
.table>tbody>tr>th.warning,
.table>tfoot>tr>th.warning,
.table>thead>tr.warning>td,
.table>tbody>tr.warning>td,
.table>tfoot>tr.warning>td,
.table>thead>tr.warning>th,
.table>tbody>tr.warning>th,
.table>tfoot>tr.warning>th {
  background-color: #fcf8e3;
}

.table-hover>tbody>tr>td.warning:hover,
.table-hover>tbody>tr>th.warning:hover,
.table-hover>tbody>tr.warning:hover>td,
.table-hover>tbody>tr:hover>.warning,
.table-hover>tbody>tr.warning:hover>th {
  background-color: #faf2cc;
}

.table>thead>tr>td.danger,
.table>tbody>tr>td.danger,
.table>tfoot>tr>td.danger,
.table>thead>tr>th.danger,
.table>tbody>tr>th.danger,
.table>tfoot>tr>th.danger,
.table>thead>tr.danger>td,
.table>tbody>tr.danger>td,
.table>tfoot>tr.danger>td,
.table>thead>tr.danger>th,
.table>tbody>tr.danger>th,
.table>tfoot>tr.danger>th {
  background-color: #f2dede;
}

.table-hover>tbody>tr>td.danger:hover,
.table-hover>tbody>tr>th.danger:hover,
.table-hover>tbody>tr.danger:hover>td,
.table-hover>tbody>tr:hover>.danger,
.table-hover>tbody>tr.danger:hover>th {
  background-color: #ebcccc;
}

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%;
}

@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }

  .table-responsive>.table {
    margin-bottom: 0;
  }

  .table-responsive>.table>thead>tr>th,
  .table-responsive>.table>tbody>tr>th,
  .table-responsive>.table>tfoot>tr>th,
  .table-responsive>.table>thead>tr>td,
  .table-responsive>.table>tbody>tr>td,
  .table-responsive>.table>tfoot>tr>td {
    white-space: nowrap;
  }

  .table-responsive>.table-bordered {
    border: 0;
  }

  .table-responsive>.table-bordered>thead>tr>th:first-child,
  .table-responsive>.table-bordered>tbody>tr>th:first-child,
  .table-responsive>.table-bordered>tfoot>tr>th:first-child,
  .table-responsive>.table-bordered>thead>tr>td:first-child,
  .table-responsive>.table-bordered>tbody>tr>td:first-child,
  .table-responsive>.table-bordered>tfoot>tr>td:first-child {
    border-left: 0;
  }

  .table-responsive>.table-bordered>thead>tr>th:last-child,
  .table-responsive>.table-bordered>tbody>tr>th:last-child,
  .table-responsive>.table-bordered>tfoot>tr>th:last-child,
  .table-responsive>.table-bordered>thead>tr>td:last-child,
  .table-responsive>.table-bordered>tbody>tr>td:last-child,
  .table-responsive>.table-bordered>tfoot>tr>td:last-child {
    border-right: 0;
  }

  .table-responsive>.table-bordered>tbody>tr:last-child>th,
  .table-responsive>.table-bordered>tfoot>tr:last-child>th,
  .table-responsive>.table-bordered>tbody>tr:last-child>td,
  .table-responsive>.table-bordered>tfoot>tr:last-child>td {
    border-bottom: 0;
  }
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}

input[type="file"] {
  display: none;
}

input[type="range"] {
  display: block;
  width: 100%;
}

select[multiple],
select[size] {
  height: auto;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #999;
}

.form-control::-webkit-input-placeholder {
  color: #999;
}

.form-control::-ms-expand {
  border: 0;
  background-color: transparent;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eeeeee;
  opacity: 1;
}

.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}

textarea.form-control {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {

  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 34px;
  }

  input[type="date"].input-sm,
  input[type="time"].input-sm,
  input[type="datetime-local"].input-sm,
  input[type="month"].input-sm,
  .input-group-sm input[type="date"],
  .input-group-sm input[type="time"],
  .input-group-sm input[type="datetime-local"],
  .input-group-sm input[type="month"] {
    line-height: 30px;
  }

  input[type="date"].input-lg,
  input[type="time"].input-lg,
  input[type="datetime-local"].input-lg,
  input[type="month"].input-lg,
  .input-group-lg input[type="date"],
  .input-group-lg input[type="time"],
  .input-group-lg input[type="datetime-local"],
  .input-group-lg input[type="month"] {
    line-height: 46px;
  }
}

.form-group {
  margin-bottom: 15px;
}

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.radio label,
.checkbox label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9;
}

.radio+.radio,
.checkbox+.checkbox {
  margin-top: -5px;
}

.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
}

.radio-inline+.radio-inline,
.checkbox-inline+.checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}

input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="checkbox"].disabled,
fieldset[disabled] input[type="radio"],
fieldset[disabled] input[type="checkbox"] {
  cursor: not-allowed;
}

.radio-inline.disabled,
.checkbox-inline.disabled,
fieldset[disabled] .radio-inline,
fieldset[disabled] .checkbox-inline {
  cursor: not-allowed;
}

.radio.disabled label,
.checkbox.disabled label,
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
  cursor: not-allowed;
}

.form-control-static {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
  min-height: 34px;
}

.form-control-static.input-lg,
.form-control-static.input-sm {
  padding-left: 0;
  padding-right: 0;
}

.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

select.input-sm {
  height: 30px;
  line-height: 30px;
}

textarea.input-sm,
select[multiple].input-sm {
  height: auto;
}

.form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px;
}

.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto;
}

.form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

select.input-lg {
  height: 46px;
  line-height: 46px;
}

textarea.input-lg,
select[multiple].input-lg {
  height: auto;
}

.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px;
}

.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto;
}

.form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}

.has-feedback {
  position: relative;
}

.has-feedback .form-control {
  padding-right: 42.5px;
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.input-lg+.form-control-feedback,
.input-group-lg+.form-control-feedback,
.form-group-lg .form-control+.form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px;
}

.input-sm+.form-control-feedback,
.input-group-sm+.form-control-feedback,
.form-group-sm .form-control+.form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

/* .table-striped>tbody>tr:nth-of-type(odd) {
  background-color: #f9f9f9;
} */

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.btn:hover,
.btn:focus,
.btn.focus {
  color: #333;
  text-decoration: none;
}

.btn:active,
.btn.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.margin-percent-selector-container {
  width: 100%;
}

.tableFixHead {
  overflow-y: auto !important;
  height: 100px !important;
}

.tableFixHead thead th {
  position: sticky !important;
  top: 0 !important;
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

.margin-percent-selector-box {
  background-color: #cf89000D;
  border-radius: 4;
  border: 1px solid #433c3c85;
  border-radius: 5px;
  margin-left: 12px;
  display: flex;
  margin-right: 12px;
}

.margin-div-selector {
  width: 20%;
  height: 18px;
  text-align: center;
  background-color: rgba(16, 22, 26, 0.4);
  border-left: 1px solid rgba(255, 255, 255, 0.05);
  font-size: 13px;
  top: 50%;
  padding-top: 2px;
}

.has-feedback {
  position: relative;
}

.has-feedback .form-control {
  padding-right: 42.5px;
}

.form-horizontal .has-feedback .form-control-feedback {
  right: 15px;
}

.has-feedback label~.form-control-feedback {
  top: 25px;
}

.has-feedback label.sr-only~.form-control-feedback {
  top: 0;
}

.margin-div-selector:hover {
  background-color: rgba(16, 22, 26, 0.6);
  color: white;
}

.margin-div-selector.first {
  border-left: 0;
}

.margin-div-selector.selected {
  background-color: var(--bitflex-blue);
  color: white;
  -webkit-box-shadow: 0px 0px 3px 1px var(--bitflex-blue);
  -moz-box-shadow: 0px 0px 3px 1px var(--bitflex-blue);
  box-shadow: 0px 0px 3px 1px var(--bitflex-blue);
}

.margin-div-selector.last {
  border-right: 0;
}

.bf-h2 {
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
  /* identical to box height */
  color: #FFFFFF;
}

.bf-br {
  margin-left: 3%;
  margin-right: 3%;
  background: #8E9091;
  opacity: 0.35;
}

.bf-login-button {
  width: 120px;
  height: 41px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 3px !important;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: white;
  margin-right: 15px;
  line-height: 20px;
  text-align: center;
  background: transparent;
}

.shadow-red {
  border: 1px solid red;
  color: red;
}

.bf-orange-gradient-button {
  height: 44px;
  background: linear-gradient(90deg, #cf8900 0%, #ffd700 100%);
  border-radius: 3px !important;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: white;
  /* margin-right: 19px; */
  line-height: 20px;
  text-align: center;
  border: 0;
  box-shadow: 3px 3px 3px #1b2329 !important;
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 0.07em;
  font-size: 20px;
}

.bf-register-button {
  /* width: 120px; */
  height: 41px;
  background: linear-gradient(90deg, #cf8900 0%, #ffd700 100%);
  border-radius: 3px !important;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: white;
  margin-right: 19px;
  line-height: 20px;
  text-align: center;
}

.bf-greengradient-button {
  width: 120px;
  height: 41px;
  background: linear-gradient(90deg, #04D73F 0%, #49F885 100%);
  border-radius: 3px !important;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: white;
  margin-right: 19px;
  line-height: 20px;
  text-align: center;
}

.content2faFooter {
  /* margin-top: 10px;
  /* position: absolute; */
  /* bottom: 20px;
  right: 20px;
  text-align: right;
  flex: auto;
  flex-direction: row;
  display: flex;  */
}

.content2faFooterFinalStep {
  margin-top: 10px;
  position: absolute;
  bottom: 20px;
  flex: auto;
  flex-direction: row;
  display: flex;
  padding-right: 40px;
  width: 100%;
}

.Absolute-Center {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.bf-green-button {
  width: 120px;
  height: 41px;
  background: linear-gradient(90deg, #04D73F 0%, #49F885 100%);
  border-radius: 3px !important;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: white;
  margin-right: 19px;
  line-height: 20px;
  text-align: center;
}

.font-roboto {
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 0.07em;
}

.glowing::before {
  content: '';
  position: absolute;
  left: -1px;
  top: -1px;
  background: linear-gradient(45deg, #cf890033, #ffd70033, #433C3C33);
  background-size: 400%;
  width: calc(100% + 3px);
  height: calc(100% + 3px);
  z-index: -1;
  animation: glower 20s linear infinite;
}

@keyframes glower {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.font-roboto-condensed {
  /* font-family: 'Roboto Condensed';
   */
  font-family: 'Roboto Mono', monospace;
  font-style: normal;
  font-weight: normal;
}

*:focus {
  outline: none;
}

.navbar-stat-header {
  font-size: 15px;
  line-height: 14px;
  color: rgb(255, 255, 255);
  font-family: 'Roboto Condensed' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 13px;
  line-height: 14px;
}

.marketTab-price-number {
  color: rgba(200, 203, 208, 0.99);
}

.marketTab-price-decimal {
  color: rgba(200, 203, 208, 0.32);
}

.footerelem {
  font-size: 16px;
  color: white;
  margin-right: 20px;
}

.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.bf-header {
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 64px;
}

.bf-header div:last-child {
  margin-left: auto;
}

.tab-margin-box {
  box-sizing: border-box;
  min-width: 0px;
  padding-left: 2px;
  padding-right: 2px;
  height: 14px;
  font-size: 13px;
  line-height: 12px;
  font-weight: 200;
  color: #f3b71f;
  display: inline-block;
  margin: 0px 0px 0px 4px;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-color: #a67a09;
}

/* для элемента label, связанного с .custom-checkbox */

.custom-checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none !important;
  margin: 8px;
}

/* создание в label псевдоэлемента before со следующими стилями */

.custom-checkbox+label::before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #ffd700;
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  margin-top: 1px;
}

/* стили при наведении курсора на checkbox */

.custom-checkbox:not(:disabled):not(:checked)+label:hover::before {
  border-color: var(--bitflex-blue);
}

/* стили для активного чекбокса (при нажатии на него) */

.custom-checkbox:not(:disabled):active+label::before {
  background-color: var(--bitflex-blue);
  border-color: var(--bitflex-blue);
}

/* стили для чекбокса, находящегося в фокусе */

.custom-checkbox:focus+label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */

.custom-checkbox:focus:not(:checked)+label::before {
  border-color: var(--bitflex-blue);
}

/* стили для чекбокса, находящегося в состоянии checked */

.custom-checkbox:checked+label::before {
  border-color: var(--bitflex-blue);
  background-color: var(--bitflex-blue);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

/* стили для чекбокса, находящегося в состоянии disabled */

.custom-checkbox:disabled+label::before {
  background-color: #e9ecef;
}

body {
  font-family: "Roboto", normal;
  outline: none !important;
  -webkit-appearance: none !important;
  overflow-x: hidden;
  /* position: fixed; */
}

.navmenu-logo {
  padding-left: 10px;
}

.menu-market-type-selector {
  /* border: 1px solid red; */
  /* display: inline-block; */
  font-size: 14px;
  text-align: center;
  color: white;
  cursor: pointer;
  display: inline-flex;
}

.menu-market-type-selector-div {
  margin-left: 25px;
}

.selected-navmenu-spot {
  background: linear-gradient(90deg, #cf8900 0%, #ffd700 100%);
  color: white !important;
}

.selected-navmenu-margin {
  background: linear-gradient(90deg, #ffd700 100%, #cf8900 0%);
  color: white !important;
}

.menu-market-type-selector-spot {
  width: 80px;
  height: 28px;
  border: 1px solid var(--bitflex-blue);
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
  display: inline-block;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  color: #bdbdbd;
  border-right-width: 0 !important;
  text-align: center;
  vertical-align: middle;
  line-height: 28px;
}

.menu-market-type-selector-margin {
  width: 80px;
  height: 28px;
  border: 1px solid var(--bitflex-blue);
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  display: inline-block;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  color: #bdbdbd;
  border-left-width: 0 !important;
  text-align: center;
  vertical-align: middle;
  line-height: 28px;
}

/* .menu-market-type-selector-spot {
  display: inline-block;
  width: 81px;
  height: 28px;
  background: linear-gradient(90deg, #cf8900 0%, #ffd700 100%);
  border-width: 1px;
  border-color:  var(--bitflex-blue);
  border-radius: 3px 0px 0px 3px !important;
}

.menu-market-type-selector-margin {
  display: inline-block;
  width: 81px;
  height: 28px;
  background: linear-gradient(90deg, #ffd700 100%, #cf8900 0%);
  border-width: 1px;
  border-color:  var(--bitflex-blue);
  border-radius: 0px 3px 3px 0px !important;
} */

.navmenu-stat-box {
  white-space: nowrap;
  display: inline-block;
  margin: 9px;
  margin-left: 25px;
}

@media (max-width: 1120px) {
  .navmenu-stat-box {
    margin-left: 20px;
  }

  .small-laptop-hidden {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .navmenu-stat-box {
    display: none !important;
  }
}

.bf-price-in-header.reduce::after {
  content: "";
  background: rgb(248, 73, 96);
  position: absolute;
  top: 17px;
  bottom: 0;
  height: 1px;
  width: 100%;
  margin-left: calc(-100%/2);
  left: 50%;
  box-shadow: 0px 0px 2px 1px rgb(248, 73, 96);
  transition: all 0.150s ease-in-out;
}

.bf-price-in-header.reduce.show::after {
  width: 0;
  margin-left: 0;
}

.bf-price-in-header.grow::after {
  content: "";
  background: rgb(30, 233, 149);
  position: absolute;
  top: 17px;
  bottom: 0;
  height: 1px;
  width: 0;
  margin-left: 0;
  left: 50%;
  box-shadow: 0px 0px 2px 1px rgb(30, 233, 149);
  transition: all 0.150s ease-in-out;
}

.bf-price-in-header.grow.show::after {
  width: 100%;
  margin-left: calc(-100%/2);
}

.bf-footer {
  height: 100%;
  width: 100%;
  padding: 12px;
  /* margin-top: 10px; */
  margin-right: 1px;
  margin-left: 1px;
  display: inline-flex;
  align-items: center;
  padding-left: 40px;
  flex-flow: row wrap;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.page-sidebar {
  margin: 0 !important;
}

@media (max-width: 767px) {
  .bf-footer {
    display: none !important;
  }

  .bf-dashboard-sidemenu-container {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .bf-dashboard-content-container {
    width: 100% !important;
  }
}

@media (max-width: 425px) {
  .bf-header-content-hide {
    display: none !important;
  }
}

@media (max-width: 425px) {
  .bf-header-content-hide {
    display: none !important;
  }
}

.navmenu-stat-box-item {
  display: inline-block;
}

.order-market-dropdown {
  width: 75px;
  background: rgba(16, 22, 26, 0.55) !important;
  border: 0 !important;
  margin: 2px;
  height: 22px;
  padding: 1px !important;
  color: white !important;
  margin-top: 7px;
  padding-left: 5px;
  margin-left: 6px;
  border-radius: 2px !important;
}

.order-market-dropdown-placeholder {
  margin-left: 8px;
}

.order-market-dropdown-arrow {
  top: 9px !important;
  right: 7px !important;
}

.order-market-dropdown-menu {
  background-color: rgba(16, 22, 26, 0.9) !important;
}

.Dropdown-option {
  color: rgba(245, 248, 250, 0.8) !important;
}

.Dropdown-menu {
  border: 1px solid rgba(16, 22, 26, 0.7) !important;
}

.Dropdown-option.is-selected {
  background-color: black !important;
}

.Dropdown-option:hover {
  background-color: #181D26 !important;
  color: white !important;
}

.order-market-dropdown-2 {
  /* width: 100%; */
  background: #1D2127 !important;
  border: 1px solid rgba(0, 0, 0, .4) !important;
  height: 40px;
  padding: 10px;
  color: #999 !important;
  border-radius: 4px !important;
}

.p2p-dropdown-control {
  /* width: 100%; */
  background: #1D2127 !important;
  border: 1px solid rgba(0, 0, 0, .4) !important;
  /* height: 50px; */
  /* padding: 10px; */
  color: #cf8900 !important;
  border-radius: 4px !important;
  font-size: 28px;
  width: 110px;
}

.p2p-dropdown-arrow {
  top: 21px !important;
  background-color: #14181f;
  border-radius: 4px;
  color: #cf8900;
  /* right: 7px !important; */
}

.order-market-dropdown-menu {
  background-color: rgba(16, 22, 26, 0.9) !important;
}

.order-market-dropdown-arrow-2 {
  top: 17px !important;
  background-color: #14181f;
  border-radius: 4px;
  /* right: 7px !important; */
}

.half-div-listing {
  width: 45%;
  padding: 10px;
  float: left;
}

.listing-form-divhalf {
  width: 50%;
  display: inline-block;
  padding: 5px;
}

.listing-form-input {
  width: 100% !important;
  margin-bottom: 12px;
}

fieldset.scheduler-border {
  border: 1px groove #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 6px 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border {
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
  color: white;

  font-family: 'Roboto Mono', monospace;
}

.menuitem {
  float: left;
  font-size: 17px;
  color: rgba(245, 248, 250, 0.9);
  padding: 0 -5em;
  margin: 20px;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.menuitem.selected {
  color: white;
}

.menuitem:before {
  content: "";
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 1.2px;
  left: -23px;
  border-radius: 5px;
}

.menuitem:hover:after {
  content: "";
  background: var(--bitflex-blue);
  position: absolute;
  top: 30px;
  bottom: 0;
  height: 1.5px;
  width: 60%;
  left: 50%;
  -webkit-box-shadow: 0px 0px 3px 1px var(--bitflex-blue);
  -moz-box-shadow: 0px 0px 3px 1px var(--bitflex-blue);
  box-shadow: 0px 0px 3px 1px var(--bitflex-blue);
  margin-left: calc(-60%/2);
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.chart-watermark {
  top: 50%;
  /* position the top  edge of the element at the middle of the parent */
  left: 50%;
  /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  font-size: 40px;
  letter-spacing: 2px;
  color: whitesmoke;
  opacity: 0.07;
  /* z-index: 999; */
  position: absolute;
  text-align: center;
}

@media (max-width: 1024px) {
  .chart-watermark {
    font-size: 22px;
  }
}

@media (max-width: 768px) {
  .chart-watermark {
    font-size: 20px;
  }
}

.menuitem.selected:after {
  content: "";
  background: var(--bitflex-blue);
  position: absolute;
  top: 27px;
  bottom: 0;
  height: 1.5px;
  width: 100%;
  left: 50%;
  -webkit-box-shadow: 0px 0px 2px 1px var(--bitflex-blue);
  -moz-box-shadow: 0px 0px 2px 1px var(--bitflex-blue);
  box-shadow: 0px 0px 2px 1px var(--bitflex-blue);
  margin-left: calc(-100%/2);
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

/* .menuitem.selected:hover:after {
  width: 100% !important;
  left: calc(75%/2);
} */

.listing-type-div {
  width: calc(33% - 20px);
  margin: 10px;
  display: inline-block;
  min-width: 180px;
  text-align: center;
}

.form-element-listing {
  width: calc(50% - 10px);
  display: inline-block;
  min-width: 250px;
}

.listing-type-namecell {
  background-color: rgba(67, 60, 60, 0.2);
  text-align: center;
  padding: 6px;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.listing-type-pricecell {
  border: 1px solid rgba(67, 60, 60, 0.2);
  border-top: 0;
  text-align: center;
  padding: 10px;
}

.leverage-label {
  display: inline-flex;
  align-items: center;
  margin: 8px;
  float: right;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  width: 750px;
  max-width: 70%;
  height: 1000px;
  max-height: 75%;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: whitesmoke;
  outline: none;
  border-radius: 3px !important;
  background-color: #13171F;
  border: 1px solid #181D26;
  outline: 1px solid #1b2329;
  box-shadow: 0 0 3px #1b2329;
  z-index: 6;
  overflow: hidden;
}

.height45 {
  height: 45px !important;
}

.otpContainer {
  display: flex;
}

.otpInput {
  background-color: #14181e;
  width: calc(100% / 6);
  margin: 4px;
  line-height: 200%;
  padding-top: 10%;
  padding-bottom: 10%;
  color: white;
  border-radius: 4px;
  background: #14181e;
  border-width: 1px;
  border-style: dashed;
  font-size: 32px;
  text-align: center;
  border-color: #433C3C;
}

.Modal-inPanel {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  width: 750px !important;
  max-width: 70%;
  max-height: 98%;
  /* bottom: auto; */
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: whitesmoke;
  outline: none;
  border-radius: 4px !important;
  background-color: #13171F;
  border: 1px solid #433C3C;
  box-shadow: 3px 5px 5px 0px rgb(37 36 36 / 25%);
  z-index: 6;
  overflow: hidden;
  overflow-y: auto;
  /* padding-bottom: 20px; */
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.Modal-inPanel-small {
  position: absolute;
  top: 34%;
  left: 50%;
  right: 50%;
  width: 400px !important;
  max-width: 50%;
  max-height: 50%;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: whitesmoke;
  outline: none;
  border-radius: 3px !important;
  background-color: #13171F;
  border: 1px solid #181D26;
  outline: 1px solid #1b2329;
  box-shadow: 0 0 3px #1b2329;
  z-index: 6;
  overflow: hidden;
}

@media (max-width: 768px) {
  .Modal-inPanel {
    max-width: 98%;
    max-height: 98%;
    top: 50%;
  }
}

@media (max-width: 768px) {
  .Modal-inPanel-small {
    max-width: 98%;
    max-height: 98%;
    top: 50%;
  }
}

.Modal-Center-Content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
  .vert-cent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 768px) {
  .listing-form-divhalf {
    width: 100%;
    display: inline-block;
    padding: 5px;
  }

  .listing-type-div {
    width: 100%;
    margin: 0px;
    margin-bottom: 15px;
    text-align: center;
  }

  .Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    width: 1000px;
    max-width: 90%;
    height: 100%;
    /* max-height: 0%; */
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: whitesmoke;
    outline: none;
    border-radius: 3px !important;
    background-color: #13171F;
    border: 1px solid #181D26;
    outline: 1px solid #1b2329;
    box-shadow: 0 0 3px #1b2329;
    z-index: 6;
    overflow: hidden;
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 1000;
}

.Overlay-verif {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.90);
  z-index: 5;
  /* filter: blur(3px); */
}

.hoverbutton:hover {
  /* background-color: rgba(49, 143, 99, 0.67); */
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.createorder_container {
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
}

/* 
.dropdown-fix{
  margin-left: 4px;
  margin-right: 3px;
} */

.coininformation-div {
  display: flex;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 5%;
  overflow: hidden;
  height: 50px;
  transition: all 0.3s;
}

.transition {
  transition: all 0.3s;
}

.coininformation-image {
  width: 20%;
}

.coininformation-name {
  width: 80%;
  padding-top: 4px;
}

.coininformation-title {
  font-size: 16px !important;
}

.coininformation-value {
  font-size: 17px;
  color: #c8cbd0;
}

.input-div-inline {
  display: flex;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
}

.input-inline-form {
  background: #1D2127 !important;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 4px !important;
  /* width: 100%; */
  color: white;
  padding: 9px;
  padding-left: 12px;
  box-sizing: none !important;
  font-family: 'Roboto Condensed';
  height: 20px;
}

.input-inline-form-2 {
  background: #1D2127 !important;
  border: 1px solid black;
  border-radius: 4px !important;
  width: 100%;
  color: white;
  padding: 9px;
  padding-left: 12px;
  box-sizing: none !important;
  font-family: 'Roboto Condensed';
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-color: #1D2127 !important;
  background-image: none !important;
  color: white;
}

.input-inline-form.maxwidth {
  width: 100%;
}

.input-inline-form.rightborder {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.rightBorders {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.input-form {
  border: 1px solid rgba(0, 0, 0, .4);
  /* background: rgba(16, 22, 26, 0.55) !important; */
  background: #1D2127;
  border-radius: 3px !important;
  padding: 9px;
  padding-left: 12px;
  width: 100%;
  font-family: 'Roboto Condensed';
}

input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 41px !important;
}

.order-market-dropdown-placeholder-2 {
  font-family: 'Roboto Condensed' !important;
  text-align: left;
}

.image-createorder-form {
  height: 50px;
}

.order-icon-market {
  color: white;
  text-align: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  /* -webkit-box-shadow: 0 0 0px 1000px #000 inset; */
  transition: background-color 5000s ease-in-out 0s;
}

.icon-order-currency {
  top: 8px;
  padding: 4px;
  padding-top: 6px;
  padding-left: 6px;
  padding-right: 6px;
  right: 8px;
  text-align: center !important;
  align-self: center !important;
  position: absolute;
  color: #ffd700;
  background-color: #14181f;
  border-radius: 4px;
}

.icon-blockie {
  top: 0px;
  padding: 5px;
  padding-top: 6px;
  overflow: hidden;
  right: 8px;
  text-align: center !important;
  align-self: center !important;
  position: absolute;
  color: #ffd700;
  /* background-color: #14181f; */
  border-radius: 4px;
}

.icon-right {
  position: absolute;
  top: 0;
  right: 0;
  /* z-index: 2; */
  display: block;
  padding: 4px;
  background: #14181f;
  width: 8%;
  border-radius: 3px !important;
  top: 8px;
  color: white;
  text-align: center;
}

.buybutton {
  border: 0;
  color: white;
  background-color: #35CB3B;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  border-radius: 2px !important;
  width: 100%;
  font-weight: 500;
}

.buydiv {
  display: inline;
  justify-content: center;
  text-align: center;
  background: rgba(53, 203, 59, 0.35);
  border-radius: 4px !important;
  width: 49%;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.buydiv.signal {
  -webkit-box-shadow: 0 0 5px rgba(53, 203, 59, 0.65);
  box-shadow: 0 0 5px rgba(53, 203, 59, 0.65);
}

.selldiv {
  display: inline;
  justify-content: center;
  text-align: center;
  background: rgba(224, 60, 45, 0.35);
  border-radius: 4px !important;
  width: 49%;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.selldiv.signal {
  -webkit-box-shadow: 0 0 5px rgba(224, 60, 45, 0.65);
  box-shadow: 0 0 5px rgba(224, 60, 45, 0.65);
}

.span_between {
  display: inline;
  width: 2%;
}

.available_div {
  padding: 11px;
  color: white;
}

.sellbutton {
  border: 0;
  color: white;
  background-color: #E03C2D;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  border-radius: 2px !important;
  width: 100%;
  font-weight: 500;
}

.info-well {
  justify-content: center;
  text-align: center;
  background: #181D26;
  margin: 12px;
  border-radius: 4px !important;
  min-height: 38px;
  padding: 7px;
  color: white;
  position: relative;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  transition: all 0.2s;
}

.info-well.left {
  justify-content: left;
  text-align: left;
  padding-left: 25px;
}

.success-well {
  background: #35CB3B;
  margin: 10px;
  border-radius: 4px !important;
  padding: 7px;
  color: white;
}

.success-well.left {
  justify-content: left;
  text-align: left;
  padding-left: 25px;
}

.info-well.left>h3 {
  color: wheat;
}

.info-well.warning {
  border-left: 10px solid #ff7f42;
}

.info-well.success-well {
  border: 1px solid #35CB3B;
  background-color: rgba(53, 203, 59, 0.2);
  color: white;
  min-height: 0px;
  transition: all 0.2s;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
}

.info-well.warning-well {
  border: 1px solid #cf8900;
  background-color: rgba(255, 170, 4, 0.2);
  color: white;
  /* min-height: 0px; */
  transition: all 0.2s;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
}

.total-css {
  display: inline-flex;
  color: #c8cbd0;
  font-size: 20px;
  line-height: 20px;
  margin-left: 8px;
}

.total-css-fee {
  margin-left: 10px;
}

.total-css-fee-span {
  color: #c8cbd0;
  font-size: 13px !important;
  line-height: 16px;
}

.tabbable-custom>.nav-tabs>li {
  margin-right: 0 !important;
  border-top: 0px;
  border-bottom: 3px solid #181D26;
  color: whitesmoke !important;
}

.tabbable-custom>.nav-tabs>li.active {
  border-top: 0px;
  border-bottom: 3px solid var(--bitflex-blue);
}

.ob-status {
  /* padding: 7px 0; */
  /* text-align: right; */
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  align-content: center;
}

.ob-status:active:focus {
  outline: none !important;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none !important;
}

.ob-status:focus {
  outline: none !important;
}

.btn:hover,
.btn:focus,
.btn:active {
  color: #fff;
}

.bf-dashboard-container {
  max-width: 1350px;
  /* padding-right: 8px;
  padding-left: 8px; */
  margin: 0 auto;
}

@media (max-width: 768px) {
  .bf-dashboard-container {
    /* margin: 7px; */
  }
}

.bf-shadow-box {
  box-shadow: 0 0 3px #1b2329;
  border-radius: 2px !important;
}

.blurred {
  filter: blur(5px);
}

.invisible {
  display: none;
}

a:hover {
  color: white !important;
}

.bf-dashboard-content {
  box-shadow: 0 0 px #433C3C;
  border-radius: 4px !important;
  background: #13171F !important;
  /* float: 'left'; */
  display: flex;
  border: 1px solid #433C3C;
  box-shadow: 0 0 px #433C3C;
  overflow: hidden;
  height: 100%;
}

@media (min-width: 768px) {
  .bf-dashboard-content {
    min-height: calc(85vh - 64px);
  }
}

.bf-well-orange {
  background: rgba(255, 170, 4, 0.1);
  border: 1px solid #cf8900;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  /* or 27px */
  color: white;
}

.bf-textSubtitle {
  font-size: 16px;
  line-height: 150%;
  color: #8E9091;
  /* opacity: 0.8; */
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.bf-page-content {
  box-shadow: 0 0 10px #1b2329;
  border-radius: 4px !important;
  height: 1000px;
  float: 'left';
}

.btn.bf-blue {
  background: linear-gradient(90deg, #cf8900 0%, #ffd700 100%);
  color: white;
  border-radius: 2px !important;
  margin-left: 19px;
  margin-right: 19px;
  border: 1px solid #181D26 !important;
  /* outline: 1px solid #1b2329 !important; */
  box-shadow: 3px 3px 3px #1b2329 !important;
}

.btn.bf-green {
  background: linear-gradient(90deg, #04D73F 0%, #49F885 100%);
  color: white;
  border-radius: 2px !important;
  margin-left: 19px;
  margin-right: 19px;
  border: 1px solid #181D26 !important;
  /* outline: 1px solid #1b2329 !important; */
  box-shadow: 3px 3px 3px #1b2329 !important;
}

.backupTextArea {
  font-size: 24px;
  /* margin-top: 30px; */
  background: rgba(67, 60, 60, 0.3);
  border: 0;
  border-radius: 4px !important;
  color: #fff;
  /* padding: 9px 9px 9px 12px; */
  width: 100%;
  /* margin: 5px; */
  padding: 15px;
  box-sizing: border-box;
}

.btn.bf-red {
  background-color: #C3073F;
  color: white;
  border-radius: 2px !important;
  margin-bottom: 17px;
  margin-left: 19px;
  margin-right: 19px;
  margin-top: 21px;
  border: 0px solid #181D26 !important;
  /* outline: 1px solid #1b2329 !important; */
  box-shadow: 3px 3px 3px #1b2329 !important;
}

.btn.bf-blue-sm {
  background: linear-gradient(90deg, #cf8900 0%, #ffd700 100%);
  color: white;
  border-radius: 2px !important;
  border: 1px solid #181D26 !important;
  /* outline: 1px solid #1b2329 !important; */
  box-shadow: 2px 2px 2px #1b2329 !important;
}

.btn.bf-blue-big {
  background: linear-gradient(90deg, #cf8900 0%, #ffd700 100%);
  color: white;
  padding: 10px;
  font-size: 20px;
  border-radius: 7px !important;
  width: 270px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  border: 1px solid #181D26 !important;
  /* outline: 1px solid #1b2329 !important; */
  box-shadow: 2px 2px 2px #1b2329 !important;
}

.marginTop20 {
  margin-top: 20px;
}

.marginTop30 {
  margin-top: 30px;
}

.marginTop40 {
  margin-top: 40px;
}

.bf-btn-whiteshadow {
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid #FFFFFF;
  white-space: nowrap;
  font-size: 17px;
  border-radius: 4px;
  -webkit-user-select: none;
  user-select: none;
  background: transparent;
  border-radius: 4px !important;
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 500;
  color: white;
  text-align: center;
  min-width: 120px;
  height: 42px;
  display: inline-block;
  line-height: 42px;
  text-align: center;
}

.bf-btn-gradient {
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  font-size: 17px;
  border-radius: 4px;
  -webkit-user-select: none;
  user-select: none;
  background: linear-gradient(90deg, #cf8900 0%, #ffd700 100%);
  border-radius: 4px !important;
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 500;
  color: white;
  text-align: center;
  min-width: 120px;
  height: 42px;
  display: inline-block;
  line-height: 42px;
  text-align: center;
}

.bf-btn-gradient-destructive {
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  font-size: 17px;
  border-radius: 4px;
  -webkit-user-select: none;
  user-select: none;
  background: linear-gradient(90deg, #E03C2D 0%, #C3073F 100%);
  border-radius: 4px !important;
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 500;
  color: white;
  text-align: center;
  min-width: 120px;
  height: 42px;
  display: inline-block;
  line-height: 42px;
  text-align: center;
}

.bf-btn-gradient-green {
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  font-size: 17px;
  border-radius: 4px;
  -webkit-user-select: none;
  user-select: none;
  background: linear-gradient(90deg, #04D73F 0%, #49F885 100%);
  border-radius: 4px !important;
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 500;
  color: white;
  text-align: center;
  min-width: 120px;
  height: 42px;
  display: inline-block;
  line-height: 42px;
  text-align: center;
}

.bf-btn-golden-borger {
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(90deg, #cf8900 0%, #ffd700 100%);
  white-space: nowrap;
  font-size: 17px;
  -webkit-user-select: none;
  user-select: none;
  background: rgba(207, 137, 0, 0.1);
  border-radius: 4px !important;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 500;
  color: white;
  text-align: center;
  min-width: 100px;
  height: 42px;
  display: inline-block;
  line-height: 38px;
  text-align: center;
}

.bf-btn-telegram {
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(90deg, #229ED9 0%, #2AABEE 100%);
  white-space: nowrap;
  font-size: 17px;
  -webkit-user-select: none;
  user-select: none;
  background: rgba(34, 158, 217, 0.1);
  border-radius: 4px !important;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 500;
  color: white;
  text-align: center;
  min-width: 100px;
  height: 42px;
  display: inline-block;
  line-height: 38px;
  text-align: center;
}

.bf-btn-green-borger {
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(90deg, #04D73F 0%, #49F885 100%);
  white-space: nowrap;
  font-size: 17px;
  -webkit-user-select: none;
  user-select: none;
  background: rgba(4, 215, 63, 0.1);
  border-radius: 4px !important;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 500;
  color: white;
  text-align: center;
  min-width: 100px;
  height: 42px;
  display: inline-block;
  line-height: 38px;
  text-align: center;
}

.bf-btn-form-save-square {
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(90deg, #cf8900 0%, #ffd700 100%);
  white-space: nowrap;
  /* font-size: 17px; */
  -webkit-user-select: none;
  user-select: none;
  background: transparent;
  border-radius: 4px !important;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 500;
  color: white;
  text-align: center;
  width: 38px;
  height: 38px;
  display: inline-block;
  line-height: 38px;
  text-align: center;
}

.bf-btn-small-destructive {
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(90deg, #E03C2D 0%, #C3073F 100%);
  white-space: nowrap;
  font-size: 14px;
  -webkit-user-select: none;
  user-select: none;
  background: transparent;
  border-radius: 4px !important;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 500;
  color: white;
  text-align: center;
  /* width: 38px; */
  height: 24px;
  display: inline-block;
  /* line-height: 36px; */
  text-align: center;
}

.bf-btn-small {
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(90deg, #cf8900 0%, #ffd700 100%);
  white-space: nowrap;
  font-size: 14px;
  -webkit-user-select: none;
  user-select: none;
  background: transparent;
  border-radius: 4px !important;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 500;
  color: white;
  text-align: center;
  /* width: 38px; */
  height: 24px;
  display: inline-block;
  line-height: 22px;
  /* line-height: 36px; */
  text-align: center;
}

.bf-btn-gradient-green:hover,
.bf-btn-gradient-green:focus,
.bf-btn-gradient-green:active {
  color: #fff;
}

.bf-btn-whiteshadow:hover,
.bf-btn-whiteshadow:focus,
.bf-btn-whiteshadow:active {
  color: #fff;
}

.bf-btn-gradient-destructive:hover,
.bf-btn-gradient-destructive:focus,
.bf-btn-gradient-destructive:active {
  color: #fff;
}

.bf-btn-gradient:hover,
.bf-btn-gradient:focus,
.bf-btn-gradient:active {
  color: #fff;
}

.btn.bf-red-sm {
  background-color: #C3073F;
  color: white;
  border-radius: 2px !important;
  border: 1px solid #181D26 !important;
  /* outline: 1px solid #1b2329 !important; */
  box-shadow: 2px 2px 2px #1b2329 !important;
}

.btn-lg,
.btn-group-lg>.btn {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.bf-dashboard-title {
  text-align: center;
  margin: 25px;
  color: #FFFFFF;
  font-weight: 500;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 47px;
}

@media (max-width: 768px) {
  .bf-dashboard-title {
    font-size: 28px;
    line-height: 40px;
  }
}

.bf-dashboard-title.left {
  text-align: left;
  margin: 25px;
  color: white;
}

.sigma-button {
  background-color: transparent;
  border: 0;
  /* border-radius: 2px !important; */
  /* padding-top: 2px;
  padding-right: 0px;
  padding-bottom: 1px;
  padding-left: 0px; */
  color: #fff;
}

.sigma-button .active-sigma {
  background-color: rgba(74, 89, 105, 0.75) !important;
}

.chart-page {
  background: #13171F !important;
}

.overlayModal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  width: 750px;
  max-width: 70%;
  height: 1000px;
  max-height: 70%;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: whitesmoke;
  outline: none;
  border-radius: 3px !important;
  background-color: #13171F;
  border: 1px solid #181D26;
  outline: 1px solid #1b2329;
  box-shadow: 0 0 3px #1b2329;
  z-index: 6;
  overflow: hidden;
}

.OrderBook {
  /* display: flex; */
  /* flex-direction: column;
  border: 1px solid red; */
  height: 100%;
}

.OrderBookcontainer {
  position: relative;
  overflow: hidden;
}

.red-actionbutton {
  background: linear-gradient(90deg, #E03C2D 0%, #C3073F 100%);
  border: 0;
  color: white;
  cursor: pointer;
}

.OrderBook table {
  table-layout: fixed;
  width: 100%;
}

.fill-bid {
  font-size: 14px;
  padding: 8px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-image: linear-gradient(to left, rgba(53, 203, 59, 0.13) 100%, white 0);
  transition: all 0.5s ease;
  /* overflow: hidden; */
}

.my-node-enter {
  opacity: 0;
}

.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.my-node-exit {
  opacity: 1;
}

.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.fill-ask {
  font-size: 14px;
  padding: 8px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-image: linear-gradient(to left, rgba(224, 60, 45, 0.13) 100%, white 0);
  transition: all 0.5s ease;
  /* overflow: hidden; */
}

.removingOrder {
  opacity: 0 !important;
  padding: 0 !important;
  padding-left: 8px !important;
  height: 0 !important;
}

.removingOrder.buy {
  background: rgba(53, 203, 59, 0.33)
}

.removingOrder.buy {
  background: rgba(224, 60, 45, 0.33)
}

.Absolute-Center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.TVChartContainer {
  height: 100%;
}

/* .table-striped>tbody>tr:nth-of-type(odd) {
  background-color: #2b343e;
} */

.tdFix {
  text-align: center;
  border-bottom: 0 !important;
  border-top: 0 !important;
  vertical-align: middle !important;
  color: rgba(255, 255, 255, 0.9);
  /* display: flex;
  align-items: center; */
  white-space: nowrap;
}

.tdFix-left {
  text-align: left !important;
  padding-left: 50px !important;
}

.Modal-inPanel {
  width: 100%;
}

@media (max-width: 768px) {
  .tdFix-left {
    text-align: left !important;
    padding-left: 5px !important;
  }

  .tdFix {
    text-align: center;
    border-bottom: 0 !important;
    border-top: 0 !important;
    vertical-align: middle !important;
    padding: 5px !important;
    font-size: 13px !important;
  }
}

.thFix {
  text-align: center;
  font-weight: 500 !important;
  border-bottom: 0 !important;
  white-space: nowrap;
}

.thFix.noborder {
  border-bottom: 0 !important;
  border-top: 0 !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

hr {
  border-top: 1px solid #313942;
}

.bf-row {
  margin: 20px;
  padding-top: 15px;
}

@media (max-width: 768px) {
  .bf-row {
    margin: 5px;
    /* padding-top: 10px; */
  }
}

.dash-text-content {
  font-size: 20px;
}

.bf-dash-header {
  padding-top: 4px;
  text-align: center;
  background-color: rgba(67, 60, 60, 0.2) !important;
  padding-bottom: 4px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  font-size: 19px;
  color: white;
  border-bottom-width: 1px;
  border-bottom-color: #433c3c85;
  border-bottom-style: solid;
  align-content: center;
  align-items: center;
}

.react-tabs__tab {
  width: 50%;
  text-align: center;
  border: 0 !important;
  border-bottom: 3px solid #181D26 !important;
  padding: 9px;
  border: 0;
  bottom: 0;
  padding: 0 !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  background: rgba(16, 22, 26, 0.50);
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 0.07em;
  position: relative;
}

.react-tabs__tab_p2p {
  width: 50%;
  text-align: center;
  border: 0 !important;
  border-bottom: 3px solid #181D26 !important;
  padding: 9px;
  border: 0;
  bottom: 0;
  padding: 0 !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  background: rgba(16, 22, 26, 0.50);
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 0.07em;
  position: relative;
}

.react-tabs__tab--selected {
  color: rgb(255, 255, 255);
  padding: 9px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.04);
  border: 0px;
}

.react-tabs__tab-list {
  border-bottom: 0 !important;
  margin-bottom: 0 !important;
  margin-top: -1px !important;
}

.bf-dashboard-tab {
  width: 25% !important;
  font-size: 26px;
  border-bottom: 0px !important;
  font-weight: 300;
  background: #13171F;
  margin-bottom: 0px;
}

.bf-dashboard-li {
  float: left;
  text-align: center;
  padding: 10px;
  color: white;
}

.bf-dashboard-li.wallet {
  width: 33.33%;
}

.bf-dashboard-sidebar {
  height: 100%;
  margin: 0;
  padding: 0px;
  background: #13171F !important;
  width: 100%;
  overflow-y: auto;
}

.bf-dashboard-con {
  margin: 0;
  padding: 0px;
  background: #13171F;
  width: 100%;
}

.bf-dashboard-sidemenu {
  padding: 0;
  height: 100%;
  border: 0 !important;
  /* box-shadow: 4px 0px 5px rgba(0, 0, 0, 0.2); */
}

.neon {
  color: #fff;
  text-shadow: 0 0 8px #cf89006E, 0 0 8px #cf89006E, 0 0 10px #cf89006E, 0 0 10px #ffd7006E, 0 0 10px #ffd7006E, 0 0 10px #ffd7006E, 0 0 8px #ffd7006E, 0 0 8px #ffd7006E;
  text-align: center;
}

.bf-dashboard-sidemenu-container {
  background: #13171F !important;
  height: 100%;
  /* float: left; */
  width: 240px;
  box-shadow: 2px 3px 3px 0px rgb(27 35 41 / 45%);
  overflow-y: auto;
  /* z-index: 99; */

}

.bf-dashboard-content-container {
  /* border: 1px solid #181D26 !important; */
  border-left: 1px solid #181D26 !important;
  box-shadow: 0 0 2px #1b2329 !important;
  height: 100%;
  float: left;
  background: #13171F;
  width: 80%;
}

.bf-static-header {
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 26px;
  color: #FFFFFF !important;
}

.page-sidebar .page-sidebar-menu li>a {
  color: #8a9ba8;
}

.page-sidebar .page-sidebar-menu li>a:hover {
  color: white;
}

.page-sidebar .page-sidebar-menu li.active>a {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}

.active-tab {
  border-top: 0px;
  border-bottom: 3px solid #cf8900 !important;
  color: rgb(255, 255, 255);
  padding: 9px;
  cursor: pointer;
  background: #433c3c85;
  font-weight: 600;
  transition: 0.25s;
}

.three-elem-tab {
  width: calc(100% / 3);
}

.active-tab-dashboard {
  border-top: 0px;
  color: rgb(255, 255, 255);
  padding: 9px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.04);
  font-weight: 300;
  transition: 0.25s;
}

.star-active {
  color: yellow;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: orange;
}

.star-inactive {
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: wheat;
}

.search-bf::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8E9091;
  opacity: 0.9;
  font-size: 12;
  /* Firefox */
}

.scrollable-wrapper {
  width: calc(100% - 4px);
}

.scrollable-bf {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
  /* padding-right: 4px; */
  margin-right: -4px !important;
}

/* .scrollable-bf:hover {
  /* overflow-y: scroll; */

/* padding-right: 0px; */

/* } */

/* 
.scrollable-bf::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  background-color: #181D26;
}

.scrollable-bf::-webkit-scrollbar {
  width: 4px;
  background-color: #181D26;
}

.scrollable-bf::-webkit-scrollbar-thumb {
  background-color: rgba(16, 22, 26, 0.8);
  border-radius: 6px;
} */

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  /* background: yellow; */
  border: 2px solid black;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0px;
  box-shadow: inset 0px 0px 0px 0px transparent;
}

.scrollable-table {
  overflow-y: auto;
  height: 100%;
  display: block;
  width: 100%;
}

.scrollable-table thead th {
  position: sticky;
  top: 0;
}

.getApp {
  /* background: #242522;  */
  position: absolute;
  /* height: 290px; */
  /* padding: 10px; */
  top: 50px;
  border-radius: 5px;
  cursor: default;
  /* border-radius: 5px; */
  /* overflow: hidden; */
}

.thereisno2step {
  border: 1px dashed var(--bitflex-blue);
  padding: 7px;
  /* margin-right: 30px; */
}

.BFInputFieldError:after {
  content: " ";
  position: absolute;
  left: 30px;
  top: 25px;
  border-top: 10px solid #E03C2D;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: none
}

.getApp:after {
  content: " ";
  position: absolute;
  right: 95px;
  top: -20px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #242522;
}

.orderbook-table-noti:after {
  content: " ";
  position: absolute;
  right: 207px;
  top: 4px;
  border-style: solid;
  border-width: 10px 0 10px 12px;
  border-color: transparent transparent transparent rgba(67, 60, 60, 0.95);
  transform: rotate(180deg);
}

.stickyHeader {
  position: sticky;
  top: 0;
  background: rgb(47, 57, 68);
  background: #181D26;
  font-weight: 500 !important;
}

.table-striped>tbody>tr:nth-of-type(odd) {
  background-color: rgba(74, 89, 105, 0.1);
}

.marketTab-active {
  border-left: 2px solid var(--bitflex-blue);
  background-color: #fed12e12 !important;
  border-right: 2px solid var(--bitflex-blue);
}

.toTheBottom {
  margin-top: auto;
  /* background: red; */
  align-self: flex-start;
  margin-left: auto;
  margin-right: auto;
}

.marketTab-inactive {
  border-left: 4px solid transparent;
}

th {
  color: white;
  text-align: center;
  font-weight: 400 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

a.dot {
  text-decoration: none;
  /* Убираем подчеркивание */
  border-bottom: 0.5px dashed white;
  /* Добавляем свою линию */
  color: white;
  font-size: 13px;
}

a.dot:hover {
  color: whitesmoke;
  /* Цвет ссылки при наведении на нее курсора */
}

#stay-in-place {
  height: 100%;
  width: 100%;
  position: absolute;
}

.app-hover-disabled {
  position: absolute;
  bottom: -100%;
  height: 100%;
  width: 100%;
  background: #13171F;
  opacity: 0;
}

.affiliate-level {
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 0.07em;
  color: white;
}

.affiliate-descr {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.affiliate-descr span {
  color: #B0B0B0;
}

.affiliate-descr strong {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #FFFFFF;
}

.affiliate-background {
  background: linear-gradient(180deg, #2E4A68 0%, #2A3643 100%);
  border-radius: 10px;
}

.bf-notification-note {
  /* padding: 10px; */
  /* background-color: rgba(0, 0, 0, 0.54); */
  color: #fff;
  display: inline-block;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  /* transform: translateY(-200px); */
  transition: all .25s ease-in-out;
  position: absolute;
  z-index: 1001;
  width: 100%;
  max-height: 200px;
  top: 0;
  left: 0;
  overflow: hidden;
  margin-left: 0px;
  margin-right: 0px;
}

.bf-notification-note.warning {
  background: linear-gradient(90deg, #cf8900 0%, #ffd700 100%);
}

.bf-notification-note.error {
  background: linear-gradient(90deg, #E03C2D 0%, #C3073F 100%);
}

.bf-notification-note.success {
  background: linear-gradient(90deg, #04D73F 0%, #49F885 100%);
}

.bf-notification-note.information {
  background: linear-gradient(90deg, #2a2a72 0%, #009ffd 100%);
}

.bf-notification-note.show {
  transform: translateY(0px);
}

.bf-notification-note.hide {
  transform: translateY(-200px);
}

.static-text-container {
  padding: 20px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}

.static-text-container p {
  color: rgba(255, 255, 255, 0.65);
}

.static-text-container h1 {
  font-size: 22px;
  color: rgba(255, 255, 255, 0.9);
}

.static-text-container h2 {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.9);
}

.box {
  position: relative;
  height: 468px;
  overflow: hidden;
}

.box-login {
  position: relative;
  min-height: 450px;
  /* overflow: hidden; */
}

.box-mobile-login {
  position: relative;
  height: 400px;
  overflow: hidden;
}

.box-signup {
  position: relative;
  min-height: 450px;
  /* overflow: hidden; */
}

@media (max-width: 768px) {
  .box-signup {
    position: relative;
    min-height: 450px;
    /* overflow: hidden; */
  }
}

input {
  color: white;
}

.box-mobile-signup {
  position: relative;
  height: 555px;
  overflow: hidden;
}

.app-hover-active {
  bottom: 0;
  -webkit-transition: all 0.333s, -webkit-transform 0.333s;
  transition: all 0.333s, transform 0.333s;
  width: 100%;
  height: 100%;
  background: #13171F;
  opacity: 1 !important;
}

#x {
  position: absolute;
  font-size: 24px;
  color: red;
  top: 0px;
  right: 0px;
}

.tr-highlight {
  background-color: rgba(245, 248, 250, 0.2);
  -webkit-transition: background-color 0.2s ease-in;
  -moz-transition: background-color 0.2s ease-in;
  -o-transition: background-color 0.2s ease-in;
  -ms-transition: background-color 0.2s ease-in;
  transition: background-color 0.2s ease-in;
}

.tr-highlight-red {
  background-color: rgba(152, 45, 47, 0.44) !important;
  -webkit-transition: background-color 0.2s ease-out;
  -moz-transition: background-color 0.2s ease-out;
  -o-transition: background-color 0.2s ease-out;
  -ms-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;
}

.tr-highlight-green {
  background-color: rgba(17, 105, 71, 0.44) !important;
  -webkit-transition: background-color 0.2s ease-out;
  -moz-transition: background-color 0.2s ease-out;
  -o-transition: background-color 0.2s ease-out;
  -ms-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;
}

.tr-fadeout {
  -webkit-transition: height 1s ease-in-out;
  -moz-transition: height 1s ease-in-out;
  -ms-transition: height 1s ease-in-out;
  -o-transition: height 1s ease-in-out;
  transition: height 1s ease-in-out;
}

.input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}

.input-group-btn-vertical>.btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  padding: 8px;
  margin-left: -1px;
  position: relative;
  border-radius: 0;
}

.input-group-btn-vertical>.btn:first-child {
  border-top-right-radius: 4px;
}

.input-group-btn-vertical>.btn:last-child {
  margin-top: -2px;
  border-bottom-right-radius: 4px;
}

.input-group-btn-vertical i {
  position: absolute;
  top: 0;
  left: 4px;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.number-input {
  display: inline-flex;
}

.number-input,
.number-input * {
  box-sizing: border-box;
}

.number-input button {
  outline: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  width: 3rem;
  cursor: pointer;
  margin: 0;
  position: relative;
}

.number-input button:before,
.number-input button:after {
  display: inline-block;
  position: absolute;
  content: '';
  width: 1rem;
  height: 2px;
  background-color: #C3073F;
  transform: translate(-50%, -50%);
}

.number-input button.plus:after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.number-input input[type=number] {
  max-width: 5rem;
  padding: .5rem;
  border-width: 0 2px;
  font-weight: bold;
  text-align: center;
}

.blocklogin-overlay {
  height: 100%;
  width: 100%;
  opacity: 1;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  transition: opacity .5s;
  z-index: 4;
}

.blocklogin-content {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  /* padding: 10px;
  background-color: rgba(16, 22, 26, 0.7);
  border-radius: 4px; */
}

.bf-red-button {
  /* outline: 1px solid rgb(126, 10, 45) !important; */
  box-shadow: 0 0 8px #C3073F !important;
  border-radius: 2px !important;
}

.bf-blue-button {
  /* outline: 1px solid #3095d4 !important; */
  box-shadow: 0 0 8px #cf8900 !important;
  border-radius: 2px !important;
  background-color: #cf8900 !important;
  border-color: #3095d4 !important;
}

.blocklogin-item {
  opacity: 0.18;
  filter: blur(4px);
  -webkit-filter: blur(4px);
}

@keyframes scaleAnimation {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes drawCircle {
  0% {
    stroke-dashoffset: 151px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawCheck {
  0% {
    stroke-dashoffset: 36px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#successAnimationCircle {
  stroke-dasharray: 151px 151px;
  stroke: #35CB3B;
}

#successAnimationCheck {
  stroke-dasharray: 36px 36px;
  stroke: #35CB3B;
}

#successAnimationResult {
  fill: #35CB3B;
  opacity: 0;
}

#successAnimation.animated {
  animation: 1s ease-out 0s 1 both scaleAnimation;
}

#successAnimation.animated #successAnimationCircle {
  animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both fadeOut;
}

#successAnimation.animated #successAnimationCheck {
  animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both fadeOut;
}

#successAnimation.animated #successAnimationResult {
  animation: 0.3s linear 0.9s both fadeIn;
}

#replay {
  background: rgba(255, 255, 255, 0.2);
  border: 0;
  border-radius: 3px;
  bottom: 100px;
  color: #fff;
  left: 50%;
  outline: 0;
  padding: 10px 30px;
  position: absolute;
  transform: translateX(-50%);
}

#replay:active {
  background: rgba(255, 255, 255, 0.1);
}

.shakeForm input {
  outline: 0 none;
  transition: box-shadow 0.5s;
}

.shakeForm input.error {
  animation: shakeForm 0.2s ease-in-out 0s 2;
  box-shadow: 0 0 0.5em red;
}

@keyframes shakeForm {
  0% {
    margin-left: 0rem;
  }

  25% {
    margin-left: 0.5rem;
  }

  75% {
    margin-left: -0.5rem;
  }

  100% {
    margin-left: 0rem;
  }
}

.enableTfaTitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  padding-top: 5px;
  color: white;
}

.enableTfaTextRow {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #FFFFFF;
  opacity: 0.8;
}

.enableTfaTextRowValue {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #FFFFFF;
  padding-left: 20px;
}

/*********************************
 METRONIC SQUARE STYLE COMPONENTS 
*********************************/

/***
Rounded corners reset
***/

/* Reset rounded corners for all elements */

/* div, svg, input, select, textarea, span, img, table, label, td, th, p, a, button, ul, code, pre, li {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
} */

/* except below bootstrap elements */

.img-circle {
  border-radius: 50% !important;
}

.img-rounded {
  border-radius: 6px !important;
}

/* Cubic Bezier Transition */

/***
General reset
***/

/* Set default body */

body {
  color: rgba(255, 255, 255, 0.9);
  font-family: "Roboto", normal;
  padding: 0 !important;
  margin: 0px !important;
  direction: "ltr";
  font-size: 13px;
}

/*
 Internet Explorer 10 doesn't differentiate device width from viewport width, and thus doesn't 
 properly apply the media queries in Bootstrap's CSS. To address this, 
 you can optionally include the following CSS and JavaScript to work around this problem until Microsoft issues a fix.
*/

@-webkit-viewport {
  width: device-width;
}

@-moz-viewport {
  width: device-width;
}

@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

/*
 Internet Explorer 10 doesn't differentiate device width from viewport width, 
 and thus doesn't properly apply the media queries in Bootstrap's CSS. To address this, following CSS code applied 
*/

@-ms-viewport {
  width: auto !important;
}

.md-shadow-z-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.18);
}

.md-shadow-z-1-i {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.18) !important;
}

.md-shadow-z-1-hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14), 0 1px 2px rgba(0, 0, 0, 0.22);
}

.md-shadow-z-2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.22);
}

.md-shadow-z-2-i {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.22) !important;
}

.md-shadow-z-2-hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.26);
}

.md-shadow-z-3 {
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.18), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.md-shadow-z-3-i {
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.18), 0 6px 6px rgba(0, 0, 0, 0.24) !important;
}

.md-shadow-z-3-hover {
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.22), 0 6px 6px rgba(0, 0, 0, 0.26);
}

.md-shadow-z-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.26), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.md-shadow-z-4-i {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.26), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
}

.md-shadow-z-5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.28), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.md-shadow-z-5-i {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.28), 0 15px 12px rgba(0, 0, 0, 0.22) !important;
}

.md-shadow-none {
  box-shadow: none !important;
}

.md-click-circle {
  display: block;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);
}

.md-click-circle.md-click-animate {
  -webkit-animation: mdClickEffect 0.65s linear;
  -moz-animation: mdClickEffect 0.65s linear;
  animation: mdClickEffect 0.65s linear;
}

@-webkit-keyframes mdClickEffect {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
  }

  100% {
    opacity: 0 !important;
    -webkit-transform: scale(2.5);
  }
}

@-moz-keyframes mdClickEffect {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
  }

  100% {
    opacity: 0 !important;
    -moz-transform: scale(2.5);
  }
}

@keyframes mdClickEffect {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
  }

  100% {
    opacity: 0 !important;
    transform: scale(2.5);
  }
}

.form-group.form-md-line-input {
  position: relative;
  margin: 0 0 35px 0;
  padding-top: 20px;
}

.form-horizontal .form-group.form-md-line-input {
  padding-top: 10px;
  margin-bottom: 20px;
  margin: 0 -15px 20px -15px;
}

.form-horizontal .form-group.form-md-line-input>label {
  padding-top: 5px;
  font-size: 14px;
  color: #888888;
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-group.form-md-line-input .form-control {
  background: none;
  border: 0;
  border-bottom: 1px solid #c2cad8;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  color: #555555;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
}

.form-group.form-md-line-input .form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-group.form-md-line-input .form-control:-ms-input-placeholder {
  color: #999;
}

.form-group.form-md-line-input .form-control::-webkit-input-placeholder {
  color: #999;
}

.form-group.form-md-line-input .form-control.form-control-static {
  border-bottom: 0;
}

.form-group.form-md-line-input .form-control.input-sm {
  font-size: 14px;
  padding: 6px 0;
}

.form-group.form-md-line-input .form-control.input-lg {
  font-size: 20px;
  padding: 14px 0;
}

.form-group.form-md-line-input .form-control~label,
.form-group.form-md-line-input .form-control~.form-control-focus {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  pointer-events: none;
}

.form-horizontal .form-group.form-md-line-input .form-control~label,
.form-horizontal .form-group.form-md-line-input .form-control~.form-control-focus {
  width: auto;
  left: 15px;
  right: 15px;
}

.form-group.form-md-line-input .form-control~label:after,
.form-group.form-md-line-input .form-control~.form-control-focus:after {
  content: '';
  position: absolute;
  z-index: 5;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  width: 0;
  visibility: hidden;
  transition: 0.2s ease all;
}

.form-group.form-md-line-input .form-control~label {
  top: 0;
  margin-bottom: 0;
  font-size: 14px;
  color: #888888;
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-group.form-md-line-input .form-control~.help-block-error {
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-group.form-md-line-input .form-control.edited:not([readonly])~label,
.form-group.form-md-line-input .form-control.edited:not([readonly])~.form-control-focus,
.form-group.form-md-line-input .form-control:focus:not([readonly])~label,
.form-group.form-md-line-input .form-control:focus:not([readonly])~.form-control-focus {
  color: #888888;
}

.form-group.form-md-line-input .form-control.edited:not([readonly])~label:after,
.form-group.form-md-line-input .form-control.edited:not([readonly])~.form-control-focus:after,
.form-group.form-md-line-input .form-control:focus:not([readonly])~label:after,
.form-group.form-md-line-input .form-control:focus:not([readonly])~.form-control-focus:after {
  visibility: visible;
  left: 0;
  width: 100%;
  background: #36c6d3;
}

.form-group.form-md-line-input .form-control.edited:not([readonly])~label,
.form-group.form-md-line-input .form-control:focus:not([readonly])~label {
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-group.form-md-line-input .form-control.edited:not([readonly])~.help-block,
.form-group.form-md-line-input .form-control:focus:not([readonly])~.help-block {
  color: #36c6d3;
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-group.form-md-line-input .form-control.edited:not([readonly])~.help-block-error,
.form-group.form-md-line-input .form-control:focus:not([readonly])~.help-block-error {
  opacity: 0;
  filter: alpha(opacity=0);
}

.form-group.form-md-line-input .form-control.edited:not([readonly]):not(:focus)~.help-block {
  opacity: 0;
  filter: alpha(opacity=0);
}

.form-group.form-md-line-input .form-control.edited:not([readonly]):not(:focus)~.help-block-error {
  opacity: 0;
  filter: alpha(opacity=0);
}

.form-group.form-md-line-input .form-control[readonly],
.form-group.form-md-line-input .form-control[disabled],
fieldset[disabled] .form-group.form-md-line-input .form-control {
  background: none;
  cursor: not-allowed;
  border-bottom: 1px dashed #c2cad8;
}

.form-group.form-md-line-input.form-md-floating-label .form-control~label {
  font-size: 16px;
  top: 25px;
  transition: 0.2s ease all;
  color: #999;
}

.form-group.form-md-line-input.form-md-floating-label .form-control[readonly]~label,
.form-group.form-md-line-input.form-md-floating-label .form-control.edited~label,
.form-group.form-md-line-input.form-md-floating-label .form-control.form-control-static~label,
.form-group.form-md-line-input.form-md-floating-label .form-control:focus:not([readonly])~label,
.form-group.form-md-line-input.form-md-floating-label .form-control.focus:not([readonly])~label {
  top: 0;
  font-size: 13px;
}

.form-group.form-md-line-input.form-md-floating-label .form-control.input-sm~label {
  font-size: 14px;
  top: 24px;
}

.form-group.form-md-line-input.form-md-floating-label .form-control.input-sm[readonly]~label,
.form-group.form-md-line-input.form-md-floating-label .form-control.input-sm.form-control-static~label,
.form-group.form-md-line-input.form-md-floating-label .form-control.input-sm.edited~label,
.form-group.form-md-line-input.form-md-floating-label .form-control.input-sm:focus:not([readonly])~label,
.form-group.form-md-line-input.form-md-floating-label .form-control.input-sm.focus:not([readonly])~label {
  top: 0;
  font-size: 13px;
}

.form-group.form-md-line-input.form-md-floating-label .form-control.input-lg~label {
  font-size: 20px;
  top: 30px;
}

.form-group.form-md-line-input.form-md-floating-label .form-control.input-lg[readonly]~label,
.form-group.form-md-line-input.form-md-floating-label .form-control.input-lg.edited~label,
.form-group.form-md-line-input.form-md-floating-label .form-control.input-lg.form-control-static~label,
.form-group.form-md-line-input.form-md-floating-label .form-control.input-lg:focus:not([readonly])~label,
.form-group.form-md-line-input.form-md-floating-label .form-control.input-lg.focus:not([readonly])~label {
  top: 0;
  font-size: 13px;
}

.form-group.form-md-line-input.form-md-floating-label .input-icon>label {
  padding-left: 34px;
}

.form-group.form-md-line-input.form-md-floating-label .input-icon.right>label {
  padding-left: 0;
  padding-right: 34px;
}

.form-group.form-md-line-input+.input-icon {
  padding-top: 0;
}

.form-group.form-md-line-input .help-block {
  position: absolute;
  margin: 2px 0 0 0;
  opacity: 0;
  filter: alpha(opacity=0);
  font-size: 13px;
}

.form-group.form-md-line-input>.input-icon>i {
  left: 0;
  bottom: 0;
  margin: 9px 2px 10px 10px;
  color: #888888;
}

.form-group.form-md-line-input>.input-icon.input-icon-lg>i {
  top: 6px;
}

.form-group.form-md-line-input>.input-icon.input-icon-sm>i {
  top: -1px;
}

.form-group.form-md-line-input>.input-icon .form-control {
  padding-left: 34px;
}

.form-group.form-md-line-input>.input-icon>label {
  margin-top: -20px;
}

.form-group.form-md-line-input>.input-icon.right .form-control {
  padding-left: 0;
  padding-right: 34px;
}

.form-group.form-md-line-input>.input-icon.right>i {
  left: auto;
  right: 8px;
  margin: 11px 2px 10px 10px;
}

.form-group.form-md-line-input+.input-group {
  padding-top: 0;
}

.form-group.form-md-line-input .input-group {
  padding-top: 0;
}

.form-group.form-md-line-input .input-group>label {
  margin-top: -20px;
}

.form-group.form-md-line-input .input-group+.input-group-control {
  padding-top: 0;
}

.form-group.form-md-line-input .input-group .input-group-control {
  padding-top: 0;
  position: relative;
  display: table-cell;
  vertical-align: bottom;
}

.form-group.form-md-line-input .input-group .input-group-control>label {
  margin-top: -20px;
}

.form-group.form-md-line-input .input-group .input-group-btn .btn {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.form-group.form-md-line-input .input-group .input-group-btn.btn-left .btn {
  margin-right: 10px;
}

.form-group.form-md-line-input .input-group .input-group-btn.btn-right .btn {
  margin-left: 10px;
}

.form-group.form-md-line-input .input-group .help-block {
  margin-top: 35px;
}

.form-group.form-md-line-input .input-group.input-group-sm .help-block {
  margin-top: 30px;
}

.form-group.form-md-line-input .input-group.input-group-lg .help-block {
  margin-top: 47px;
}

.form-group.form-md-line-input.has-success .form-control {
  border-bottom: 1px solid #27a4b0;
}

.form-group.form-md-line-input.has-success .form-control.edited:not([readonly])~label:after,
.form-group.form-md-line-input.has-success .form-control.edited:not([readonly])~.form-control-focus:after,
.form-group.form-md-line-input.has-success .form-control.form-control-static~label:after,
.form-group.form-md-line-input.has-success .form-control.form-control-static~.form-control-focus:after,
.form-group.form-md-line-input.has-success .form-control:focus:not([readonly])~label:after,
.form-group.form-md-line-input.has-success .form-control:focus:not([readonly])~.form-control-focus:after,
.form-group.form-md-line-input.has-success .form-control.focus:not([readonly])~label:after,
.form-group.form-md-line-input.has-success .form-control.focus:not([readonly])~.form-control-focus:after {
  background: #27a4b0;
}

.form-group.form-md-line-input.has-success .form-control.edited:not([readonly])~label,
.form-group.form-md-line-input.has-success .form-control.form-control-static~label,
.form-group.form-md-line-input.has-success .form-control:focus:not([readonly])~label,
.form-group.form-md-line-input.has-success .form-control.focus:not([readonly])~label {
  color: #27a4b0;
}

.form-group.form-md-line-input.has-success .form-control.edited:not([readonly])~i,
.form-group.form-md-line-input.has-success .form-control.form-control-static~i,
.form-group.form-md-line-input.has-success .form-control:focus:not([readonly])~i,
.form-group.form-md-line-input.has-success .form-control.focus:not([readonly])~i {
  color: #27a4b0;
}

.form-group.form-md-line-input.has-success .form-control.edited:not([readonly])~.help-block,
.form-group.form-md-line-input.has-success .form-control.form-control-static~.help-block,
.form-group.form-md-line-input.has-success .form-control:focus:not([readonly])~.help-block,
.form-group.form-md-line-input.has-success .form-control.focus:not([readonly])~.help-block {
  color: #27a4b0;
}

.form-group.form-md-line-input.has-success label {
  color: #27a4b0;
}

.form-group.form-md-line-input.has-warning .form-control {
  border-bottom: 1px solid #c29d0b;
}

.form-group.form-md-line-input.has-warning .form-control.edited:not([readonly])~label:after,
.form-group.form-md-line-input.has-warning .form-control.edited:not([readonly])~.form-control-focus:after,
.form-group.form-md-line-input.has-warning .form-control.form-control-static~label:after,
.form-group.form-md-line-input.has-warning .form-control.form-control-static~.form-control-focus:after,
.form-group.form-md-line-input.has-warning .form-control:focus:not([readonly])~label:after,
.form-group.form-md-line-input.has-warning .form-control:focus:not([readonly])~.form-control-focus:after,
.form-group.form-md-line-input.has-warning .form-control.focus:not([readonly])~label:after,
.form-group.form-md-line-input.has-warning .form-control.focus:not([readonly])~.form-control-focus:after {
  background: #c29d0b;
}

.form-group.form-md-line-input.has-warning .form-control.edited:not([readonly])~label,
.form-group.form-md-line-input.has-warning .form-control.form-control-static~label,
.form-group.form-md-line-input.has-warning .form-control:focus:not([readonly])~label,
.form-group.form-md-line-input.has-warning .form-control.focus:not([readonly])~label {
  color: #c29d0b;
}

.form-group.form-md-line-input.has-warning .form-control.edited:not([readonly])~i,
.form-group.form-md-line-input.has-warning .form-control.form-control-static~i,
.form-group.form-md-line-input.has-warning .form-control:focus:not([readonly])~i,
.form-group.form-md-line-input.has-warning .form-control.focus:not([readonly])~i {
  color: #c29d0b;
}

.form-group.form-md-line-input.has-warning .form-control.edited:not([readonly])~.help-block,
.form-group.form-md-line-input.has-warning .form-control.form-control-static~.help-block,
.form-group.form-md-line-input.has-warning .form-control:focus:not([readonly])~.help-block,
.form-group.form-md-line-input.has-warning .form-control.focus:not([readonly])~.help-block {
  color: #c29d0b;
}

.form-group.form-md-line-input.has-warning label {
  color: #c29d0b;
}

.form-group.form-md-line-input.has-error .form-control {
  border-bottom: 1px solid #e73d4a;
}

.form-group.form-md-line-input.has-error .form-control.edited:not([readonly])~label:after,
.form-group.form-md-line-input.has-error .form-control.edited:not([readonly])~.form-control-focus:after,
.form-group.form-md-line-input.has-error .form-control.form-control-static~label:after,
.form-group.form-md-line-input.has-error .form-control.form-control-static~.form-control-focus:after,
.form-group.form-md-line-input.has-error .form-control:focus:not([readonly])~label:after,
.form-group.form-md-line-input.has-error .form-control:focus:not([readonly])~.form-control-focus:after,
.form-group.form-md-line-input.has-error .form-control.focus:not([readonly])~label:after,
.form-group.form-md-line-input.has-error .form-control.focus:not([readonly])~.form-control-focus:after {
  background: #e73d4a;
}

.form-group.form-md-line-input.has-error .form-control.edited:not([readonly])~label,
.form-group.form-md-line-input.has-error .form-control.form-control-static~label,
.form-group.form-md-line-input.has-error .form-control:focus:not([readonly])~label,
.form-group.form-md-line-input.has-error .form-control.focus:not([readonly])~label {
  color: #e73d4a;
}

.form-group.form-md-line-input.has-error .form-control.edited:not([readonly])~i,
.form-group.form-md-line-input.has-error .form-control.form-control-static~i,
.form-group.form-md-line-input.has-error .form-control:focus:not([readonly])~i,
.form-group.form-md-line-input.has-error .form-control.focus:not([readonly])~i {
  color: #e73d4a;
}

.form-group.form-md-line-input.has-error .form-control.edited:not([readonly])~.help-block,
.form-group.form-md-line-input.has-error .form-control.form-control-static~.help-block,
.form-group.form-md-line-input.has-error .form-control:focus:not([readonly])~.help-block,
.form-group.form-md-line-input.has-error .form-control.focus:not([readonly])~.help-block {
  color: #e73d4a;
}

.form-group.form-md-line-input.has-error label {
  color: #e73d4a;
}

.form-group.form-md-line-input.has-info .form-control {
  border-bottom: 1px solid #327ad5;
}

.form-group.form-md-line-input.has-info .form-control.edited:not([readonly])~label:after,
.form-group.form-md-line-input.has-info .form-control.edited:not([readonly])~.form-control-focus:after,
.form-group.form-md-line-input.has-info .form-control.form-control-static~label:after,
.form-group.form-md-line-input.has-info .form-control.form-control-static~.form-control-focus:after,
.form-group.form-md-line-input.has-info .form-control:focus:not([readonly])~label:after,
.form-group.form-md-line-input.has-info .form-control:focus:not([readonly])~.form-control-focus:after,
.form-group.form-md-line-input.has-info .form-control.focus:not([readonly])~label:after,
.form-group.form-md-line-input.has-info .form-control.focus:not([readonly])~.form-control-focus:after {
  background: #327ad5;
}

.form-group.form-md-line-input.has-info .form-control.edited:not([readonly])~label,
.form-group.form-md-line-input.has-info .form-control.form-control-static~label,
.form-group.form-md-line-input.has-info .form-control:focus:not([readonly])~label,
.form-group.form-md-line-input.has-info .form-control.focus:not([readonly])~label {
  color: #327ad5;
}

.form-group.form-md-line-input.has-info .form-control.edited:not([readonly])~i,
.form-group.form-md-line-input.has-info .form-control.form-control-static~i,
.form-group.form-md-line-input.has-info .form-control:focus:not([readonly])~i,
.form-group.form-md-line-input.has-info .form-control.focus:not([readonly])~i {
  color: #327ad5;
}

.form-group.form-md-line-input.has-info .form-control.edited:not([readonly])~.help-block,
.form-group.form-md-line-input.has-info .form-control.form-control-static~.help-block,
.form-group.form-md-line-input.has-info .form-control:focus:not([readonly])~.help-block,
.form-group.form-md-line-input.has-info .form-control.focus:not([readonly])~.help-block {
  color: #327ad5;
}

.form-group.form-md-line-input.has-info label {
  color: #327ad5;
}

.form-inline .form-md-line-input {
  margin: 0;
  padding-top: 0;
  margin-right: 20px;
}

.form-inline .form-md-line-input>.input-icon {
  padding: 0;
}

.form-horizontal .form-group.form-md-line-input .input-icon .form-control {
  padding-left: 33px;
}

.form-horizontal .form-group.form-md-line-input .input-icon>i {
  top: 0;
}

.form-horizontal .form-group.form-md-line-input .input-icon.right .form-control {
  padding-left: 0;
  padding-right: 33px;
}

.form-horizontal .form-group.form-md-line-input .input-icon>.form-control-focus {
  left: 0 !important;
  right: 0 !important;
}

.form-horizontal .form-group.form-md-line-input .input-group {
  padding-top: 0;
}

.form-horizontal .form-group.form-md-line-input .input-group>.input-group-btn .btn {
  margin-bottom: 0 !important;
}

.form-horizontal .form-group.form-md-line-input .input-group>.input-group-control {
  padding-top: 0;
}

.form-horizontal .form-group.form-md-line-input .input-group>.input-group-control>.form-control-focus {
  left: 0 !important;
  right: 0 !important;
}

.form-horizontal .form-group.form-md-line-input .input-group>.form-control-focus {
  left: 0 !important;
  right: 0 !important;
}

.md-checkbox {
  position: relative;
  /* handling click events */
  /* when checkbox is checked */
}

.md-checkbox.md-checkbox-inline {
  display: inline-block;
}

.form-inline .md-checkbox.md-checkbox-inline {
  margin-right: 20px;
  top: 3px;
}

.md-checkbox input[type=checkbox] {
  visibility: hidden;
  position: absolute;
}

.md-checkbox label {
  cursor: pointer;
  padding-left: 30px;
}

.md-checkbox label>span {
  display: block;
  position: absolute;
  left: 0;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.md-checkbox label>span.inc {
  background: #fff;
  left: -20px;
  top: -20px;
  height: 60px;
  width: 60px;
  opacity: 0;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
}

.md-checkbox label>.box {
  top: 0px;
  border: 2px solid #666;
  height: 20px;
  width: 20px;
  z-index: 5;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.md-checkbox label>.check {
  top: -4px;
  left: 6px;
  width: 10px;
  height: 20px;
  border: 2px solid #36c6d3;
  border-top: none;
  border-left: none;
  opacity: 0;
  z-index: 5;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition-delay: 0.3s;
  -moz-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.md-checkbox label>span.inc {
  -webkit-animation: growCircle 0.3s ease;
  -moz-animation: growCircle 0.3s ease;
  animation: growCircle 0.3s ease;
}

.md-checkbox input[type=checkbox]:checked~label>.box {
  opacity: 0;
  -webkit-transform: scale(0) rotate(-180deg);
  -moz-transform: scale(0) rotate(-180deg);
  transform: scale(0) rotate(-180deg);
}

.md-checkbox input[type=checkbox]:checked~label>.check {
  opacity: 1;
  -webkit-transform: scale(1) rotate(45deg);
  -moz-transform: scale(1) rotate(45deg);
  transform: scale(1) rotate(45deg);
}

.md-checkbox input[type=checkbox]:disabled~label,
.md-checkbox input[type=checkbox][disabled]~label {
  cursor: not-allowed;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.md-checkbox input[type=checkbox]:disabled~label>.box,
.md-checkbox input[type=checkbox][disabled]~label>.box {
  cursor: not-allowed;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.md-checkbox input[type=checkbox]:disabled:checked~label>.check,
.md-checkbox input[type=checkbox][disabled]:checked~label>.check {
  cursor: not-allowed;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.has-error .md-checkbox label,
.has-error.md-checkbox label {
  color: #e73d4a;
}

.has-error .md-checkbox label>.box,
.has-error.md-checkbox label>.box {
  border-color: #e73d4a;
}

.has-error .md-checkbox label>.check,
.has-error.md-checkbox label>.check {
  border-color: #e73d4a;
}

.has-success .md-checkbox label,
.has-success.md-checkbox label {
  color: #27a4b0;
}

.has-success .md-checkbox label>.box,
.has-success.md-checkbox label>.box {
  border-color: #27a4b0;
}

.has-success .md-checkbox label>.check,
.has-success.md-checkbox label>.check {
  border-color: #27a4b0;
}

.has-warning .md-checkbox label,
.has-warning.md-checkbox label {
  color: #c29d0b;
}

.has-warning .md-checkbox label>.box,
.has-warning.md-checkbox label>.box {
  border-color: #c29d0b;
}

.has-warning .md-checkbox label>.check,
.has-warning.md-checkbox label>.check {
  border-color: #c29d0b;
}

.has-info .md-checkbox label,
.has-info.md-checkbox label {
  color: #327ad5;
}

.has-info .md-checkbox label>.box,
.has-info.md-checkbox label>.box {
  border-color: #327ad5;
}

.has-info .md-checkbox label>.check,
.has-info.md-checkbox label>.check {
  border-color: #327ad5;
}

.form-md-checkboxes {
  padding-top: 5px;
}

.form-md-checkboxes>label {
  font-size: 14px;
  color: #888888;
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-md-checkboxes.has-error label {
  color: #ed6b75;
}

.form-md-checkboxes.has-info label {
  color: #659be0;
}

.form-md-checkboxes.has-success label {
  color: #36c6d3;
}

.form-md-checkboxes.has-warning label {
  color: #F1C40F;
}

.md-checkbox-list {
  margin: 5px 0 5px 0;
}

.form-horizontal .md-checkbox-list {
  margin-top: 5px;
}

.md-checkbox-list .md-checkbox {
  display: block;
  margin-bottom: 10px;
}

.md-checkbox-list .md-checkbox:last-child {
  margin-bottom: 0;
}

.md-checkbox-inline {
  margin: 5px 0 5px 0;
}

.form-horizontal .md-checkbox-inline {
  margin-top: 7px;
}

.md-checkbox-inline .md-checkbox {
  display: inline-block;
  margin-right: 20px;
}

.md-checkbox-inline .md-checkbox:last-child {
  margin-right: 0;
}

/* bubble animation */

@-webkit-keyframes growCircle {

  0%,
  100% {
    -webkit-transform: scale(0);
    opacity: 1;
  }

  70% {
    background: #eee;
    -webkit-transform: scale(1.25);
  }
}

@-moz-keyframes growCircle {

  0%,
  100% {
    -moz-transform: scale(0);
    opacity: 1;
  }

  70% {
    background: #eee;
    -moz-transform: scale(1.25);
  }
}

@keyframes growCircle {

  0%,
  100% {
    transform: scale(0);
    opacity: 1;
  }

  70% {
    background: #eee;
    transform: scale(1.25);
  }
}

.md-radio {
  position: relative;
  /* handling click events */
  /* when radio is checked */
}

.md-radio input[type=radio] {
  visibility: hidden;
  position: absolute;
}

.md-radio label {
  cursor: pointer;
  padding-left: 30px;
}

.md-radio label>span {
  display: block;
  position: absolute;
  left: 0;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.md-radio label>span.inc {
  background: #fff;
  left: -20px;
  top: -20px;
  height: 60px;
  width: 60px;
  opacity: 0;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
}

.md-radio label>.box {
  top: 0px;
  border: 2px solid #666;
  height: 20px;
  width: 20px;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  z-index: 5;
}

.md-radio label>.check {
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  background: #36c6d3;
  opacity: 0;
  z-index: 6;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);
}

.md-radio label>span.inc {
  -webkit-animation: growCircleRadio 0.3s ease;
  -moz-animation: growCircleRadio 0.3s ease;
  animation: growCircleRadio 0.3s ease;
}

.md-radio input[type=radio]:checked~label>.check {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.md-radio input[type=radio]:disabled~label,
.md-radio input[type=radio][disabled]~label {
  cursor: not-allowed;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.md-radio input[type=radio]:disabled~label>.box,
.md-radio input[type=radio][disabled]~label>.box {
  cursor: not-allowed;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.md-radio input[type=radio]:disabled:checked~label>.check,
.md-radio input[type=radio][disabled]:checked~label>.check {
  cursor: not-allowed;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.has-error .md-radio label,
.has-error.md-radio label {
  color: #e73d4a;
}

.has-error .md-radio label>.box,
.has-error.md-radio label>.box {
  border-color: #e73d4a;
}

.has-error .md-radio label>.check,
.has-error.md-radio label>.check {
  background: #e73d4a;
}

.has-success .md-radio label,
.has-success.md-radio label {
  color: #27a4b0;
}

.has-success .md-radio label>.box,
.has-success.md-radio label>.box {
  border-color: #27a4b0;
}

.has-success .md-radio label>.check,
.has-success.md-radio label>.check {
  background: #27a4b0;
}

.has-warning .md-radio label,
.has-warning.md-radio label {
  color: #c29d0b;
}

.has-warning .md-radio label>.box,
.has-warning.md-radio label>.box {
  border-color: #c29d0b;
}

.has-warning .md-radio label>.check,
.has-warning.md-radio label>.check {
  background: #c29d0b;
}

.has-info .md-radio label,
.has-info.md-radio label {
  color: #327ad5;
}

.has-info .md-radio label>.box,
.has-info.md-radio label>.box {
  border-color: #327ad5;
}

.has-info .md-radio label>.check,
.has-info.md-radio label>.check {
  background: #327ad5;
}

.md-radio-list {
  margin: 5px 0 5px 0;
}

.form-horizontal .md-radio-list {
  margin-top: 5px;
}

.md-radio-list .md-radio {
  display: block;
  margin-bottom: 10px;
}

.md-radio-inline {
  margin: 5px 0 5px 0;
}

.form-horizontal .md-radio-inline {
  margin-top: 7px;
}

.md-radio-inline .md-radio {
  display: inline-block;
  margin-right: 20px;
}

.md-radio-inline .md-radio:last-child {
  margin-right: 0;
}

/* bubble animation */

@-webkit-keyframes growCircleRadio {

  0%,
  100% {
    -webkit-transform: scale(0);
    opacity: 1;
  }

  70% {
    background: #eee;
    -webkit-transform: scale(1.25);
  }
}

@-moz-keyframes growCircleRadio {

  0%,
  100% {
    -moz-transform: scale(0);
    opacity: 1;
  }

  70% {
    background: #eee;
    -moz-transform: scale(1.25);
  }
}

@keyframes growCircleRadio {

  0%,
  100% {
    transform: scale(0);
    opacity: 1;
  }

  70% {
    background: #eee;
    transform: scale(1.25);
  }
}

/***
General typography
***/

/* Links */

a:hover {
  cursor: pointer;
}

/* Primary Link */

.primary-link {
  color: #65A0D0;
  font-weight: 600;
}

.primary-link:hover {
  color: #5194ca;
}

p {
  margin: 20px 0;
}

label {
  font-weight: normal;
}

/* Headings */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto Mono', monospace;
  color: wheat !important;
  font-weight: 300;
}

h1 {
  font-size: 36px;
  color: wheat !important;
  font-family: 'Roboto Mono', monospace;
}

h2 {
  font-size: 30px;
  color: wheat !important;
  font-family: 'Roboto Mono', monospace;
}

.eVykKS table tr {
  background: transparent !important;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
  color: white !important;
}

.gshOOv>ul>li.react-tabs__tab--selected {
  background: green !important;
}

h6 {
  font-size: 12px;
}

/* Headings helper text */

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  color: #444444;
}

/* Block headings */

h1.block,
h2.block,
h3.block,
h4.block,
h5.block,
h6.block {
  padding-top: 10px;
  padding-bottom: 10px;
}

/* Links */

a {
  text-shadow: none;
  color: #337ab7;
}

a:hover {
  color: #23527c;
}

a:focus,
a:hover,
a:active {
  outline: 0;
}

/* Horizontal break */

hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #eee;
  border-bottom: 0;
}

/* Unstyled List */

/* Code */

code {
  border: 1px solid #e1e1e1;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

/* Disabled Navigation Link */

.disabled-link.disable-target,
.disabled-link .disable-target {
  opacity: 0.6 !important;
  filter: alpha(opacity=60) !important;
}

.disabled-link.disable-target:hover,
.disabled-link .disable-target:hover {
  cursor: not-allowed !important;
}

.disabled-link:hover {
  cursor: not-allowed !important;
}

/***
Utilities
***/

/* Rounded Element */

.rounded-2 {
  border-radius: 2px !important;
}

.rounded-3 {
  border-radius: 3px !important;
}

.rounded-4 {
  border-radius: 4px !important;
}

/* Circle Element */

.circle {
  border-radius: 25px !important;
}

.circle-right {
  border-radius: 0 25px 25px 0 !important;
}

.circle-left {
  border-radius: 25px 0 0 25px !important;
}

.circle-bottom {
  border-radius: 0 0 25px 25px !important;
}

.circle-top {
  border-radius: 25px 25px 0 0 !important;
}

.rounded {
  border-radius: 50% !important;
}

/* General utilities */

.display-none,
.display-hide {
  display: none;
}

.hidden {
  display: none !important;
}

.primary-font {
  font-family: "Open Sans", sans-serif !important;
}

.bold {
  font-weight: 700 !important;
}

.bold.btn {
  letter-spacing: 0;
}

.thin {
  font-weight: 300 !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.fix-margin {
  margin-left: 0px !important;
}

.border {
  border: 1px solid red;
}

.font-hg {
  font-size: 23px;
}

.font-lg {
  font-size: 18px;
}

.font-md {
  font-size: 14px;
}

.font-sm {
  font-size: 13px;
}

.font-xs {
  font-size: 11px;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.text-align-reverse {
  text-align: right;
}

/* Margin and padding utilities */

.no-space {
  margin: 0px !important;
  padding: 0px !important;
}

.no-margin {
  margin: 0;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

/* IE8 & IE9 mode utilities */

.visible-ie8 {
  display: none;
}

.ie8 .visible-ie8 {
  display: inherit !important;
}

.visible-ie9 {
  display: none;
}

.ie9 .visible-ie9 {
  display: inherit !important;
}

.hidden-ie8 {
  display: inherit;
}

.ie8 .hidden-ie8 {
  display: none !important;
}

.hidden-ie9 {
  display: inherit;
}

.ie9 .hidden-ie9 {
  display: none !important;
}

/***
Responsive Utils
***/

@media (max-width: 1024px) {
  .hidden-1024 {
    display: none;
  }
}

@media (max-width: 480px) {
  .hidden-480 {
    display: none;
  }
}

@media (max-width: 320px) {
  .hidden-320 {
    display: none;
  }
}

/***
Demo Utils
***/

.util-btn-margin-bottom-5 .btn {
  margin-bottom: 5px !important;
}

.util-btn-group-margin-bottom-5 .btn-group {
  margin-bottom: 5px !important;
}

.padding-tb-10 {
  padding: 10px 0;
}

.padding-tb-15 {
  padding: 10px 0;
}

.padding-tb-20 {
  padding: 20px 0;
}

/***
2088s
***/

.user-info {
  margin-bottom: 10px !important;
}

.user-info img {
  float: left;
  margin-right: 5px;
}

.user-info .details {
  display: inline-block;
}

.user-info .label {
  font-weight: 300;
  font-size: 11px;
}

/***
Custom vertical inline menu
***/

.ver-inline-menu {
  padding: 0;
  margin: 0;
  list-style: none;
}

.ver-inline-menu li {
  position: relative;
  margin-bottom: 1px;
}

.ver-inline-menu li i {
  width: 37px;
  height: 37px;
  display: inline-block;
  color: #b9cbd5;
  font-size: 15px;
  padding: 12px 10px 10px 8px;
  margin: 0 8px 0 0;
  text-align: center;
  background: #e0eaf0 !important;
}

.ver-inline-menu li a {
  font-size: 14px;
  font-weight: 300;
  color: #557386;
  display: block;
  background: #f0f6fa;
  border-left: solid 2px #c4d5df;
}

.ver-inline-menu li:hover a {
  background: #e0eaf0;
  text-decoration: none;
}

.ver-inline-menu li:hover i {
  color: #fff;
  background: #c4d5df !important;
}

.ver-inline-menu li.active a {
  border-left: solid 2px #0c91e5;
}

.ver-inline-menu li.active i {
  background: #0c91e5 !important;
}

.ver-inline-menu li.active a,
.ver-inline-menu li.active i {
  color: #fff;
  background: #169ef4;
  text-decoration: none;
}

.ver-inline-menu li.active a,
.ver-inline-menu li:hover a {
  font-size: 14px;
}

.ver-inline-menu li.active:after {
  content: '';
  display: inline-block;
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  border-left: 6px solid #169ef4;
  position: absolute;
  top: 12px;
  right: -5px;
}

@media (max-width: 767px) {
  .ver-inline-menu>li.active:after {
    display: none;
  }
}

/***
Separated List
***/

.list-separated {
  margin-top: 10px;
  margin-bottom: 15px;
}

.list-separated>div:last-child {
  border-right: 0;
}

@media (max-width: 767px) {
  .list-separated {
    /* 767px */
  }

  .list-separated>div {
    margin-bottom: 20px;
  }
}

/***
Number & Chart Stats
***/

.number-stats {
  margin: 10px 0;
}

.number-stats .stat-number {
  display: inline-block;
  margin: 0 5px;
}

.number-stats .stat-number .title {
  font-size: 13px;
  margin-bottom: 3px;
  color: #B8C3C7;
}

.number-stats .stat-number .number {
  font-size: 27px;
  line-height: 27px;
  color: #7D8C9D;
}

.number-stats .stat-chart {
  display: inline-block;
  margin: 0 5px;
}

.number-stats>div {
  border-right: 1px solid #f5f5f5;
}

.number-stats>div:last-child {
  border-right: 0;
}

.number-stats .stat-left {
  float: right;
}

.number-stats .stat-left .stat-number {
  float: right;
  text-align: right;
}

.number-stats .stat-left .stat-chart {
  float: right;
}

.number-stats .stat-right {
  float: left !important;
}

.number-stats .stat-right .stat-number {
  float: left;
  text-align: left;
}

.number-stats .stat-right .stat-chart {
  float: left;
}

.number-stats .stat-number {
  float: left;
  text-align: left;
}

.number-stats .stat-chart {
  float: left;
}

/***
General User Record Listing
***/

.general-item-list>.item {
  padding: 10px 0;
  border-bottom: 1px solid #F1F4F7;
}

.general-item-list>.item:last-child {
  border-bottom: 0;
}

.general-item-list>.item>.item-head {
  margin-bottom: 5px;
}

.general-item-list>.item>.item-head:before,
.general-item-list>.item>.item-head:after {
  content: " ";
  display: table;
}

.general-item-list>.item>.item-head:after {
  clear: both;
}

.general-item-list>.item>.item-head>.item-details {
  display: inline-block;
  float: left;
}

.general-item-list>.item>.item-head>.item-details>.item-name {
  display: inline-block;
  margin-right: 10px;
}

.general-item-list>.item>.item-head>.item-details>.item-label {
  color: #C0C9CC;
}

.general-item-list>.item>.item-head>.item-status {
  color: #C0C9CC;
  top: 10px;
  position: relative;
  display: inline-block;
  float: right;
}

.general-item-list>.item>.item-head>.item-status>.badge {
  margin-top: -2px;
}

.general-item-list>.item>.item-body {
  color: #96a5aa;
}

/***
File dropzone
***/

.file-drop-zone {
  border: 2px dashed #ddd;
  padding: 30px;
  text-align: center;
}

.file-drop-zone.file-drop-zone-over {
  border-color: #aaa;
}

/***
Fontawesome Icons
***/

[class^="fa-"]:not(.fa-stack),
[class^="glyphicon-"],
[class^="icon-"],
[class*=" fa-"]:not(.fa-stack),
[class*=" glyphicon-"],
[class*=" icon-"] {
  display: inline-block;
  *margin-right: .3em;
  line-height: 14px;
  -webkit-font-smoothing: antialiased;
}

/* Make font awesome icons fixed width */

li [class^="fa-"],
li [class^="glyphicon-"],
li [class^="icon-"],
li [class*=" fa-"],
li [class*=" glyphicon-"],
li [class*=" icon-"] {
  display: inline-block;
  width: 1.25em;
  text-align: center;
}

li [class^="glyphicon-"],
li [class*=" glyphicon-"] {
  top: 2px;
}

li [class^="icon-"],
li [class*=" icon-"] {
  top: 1px;
  position: relative;
}

li [class^="fa-"].icon-large,
li [class^="glyphicon-"].icon-large,
li [class^="icon-"].icon-large,
li [class*=" fa-"].icon-large,
li [class*=" glyphicon-"].icon-large,
li [class*=" icon-"].icon-large {
  /* increased font size for icon-large */
  width: 1.5625em;
}

/* Icon states */

.icon-state-default {
  color: #bac3d0;
}

.icon-state-success {
  color: #36c6d3;
}

.icon-state-info {
  color: #659be0;
}

.icon-state-warning {
  color: #F1C40F;
}

.icon-state-danger {
  color: #ed6b75;
}

/***
Font Awesome 4.x Demo
***/

.fa-item {
  font-size: 14px;
  padding: 10px 10px 10px 20px;
}

.fa-item i {
  font-size: 16px;
  display: inline-block;
  width: 20px;
  color: #333;
}

.fa-item:hover {
  cursor: pointer;
  background: #eee;
}

/***
Simple Line Icons Demo
***/

/*** 
Glyphicons Demo
***/

/***
Customized Bootstrap Alerts
***/

.alert {
  border-width: 1px;
}

.alert>p {
  margin: 0;
}

.alert.alert-borderless {
  border: 0;
}

/***
Custom Bootstrap Badges
***/

.badge {
  font-size: 11px !important;
  font-weight: 300;
  text-align: center;
  height: 18px;
  color: #fff;
  padding: 3px 6px 3px 6px;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  border-radius: 12px !important;
  text-shadow: none !important;
  text-align: center;
  vertical-align: middle;
}

.badge.badge-empty {
  display: inline-block;
  padding: 0;
  min-width: 8px;
  height: 8px;
  width: 8px;
}

/* Badge variants */

.badge-default {
  background-color: #bac3d0;
  background-image: none;
}

.badge-primary {
  background-color: #337ab7;
  background-image: none;
}

.badge-info {
  background-color: #659be0;
  background-image: none;
}

.badge-success {
  background-color: #36c6d3;
  background-image: none;
}

.badge-danger {
  background-color: #ed6b75;
  background-image: none;
}

.badge-warning {
  background-color: #F1C40F;
  background-image: none;
}

/* Fix badge position for navs */

.nav.nav-stacked>li>a>.badge {
  margin-top: -2px;
}

/***
Dropdown Menu Badges
***/

.dropdown-menu>li>a>.badge {
  position: absolute;
  margin-top: 1px;
  right: 3px;
  display: inline;
}

/***
Custom buttons based on bootstrap SASS
***/

.btn {
  outline: none !important;
  box-shadow: none !important;
}

.btn:hover {
  transition: all 0.3s;
}

.btn:not(.btn-sm):not(.btn-lg) {
  line-height: 1.44;
}

/***
Custom color buttons 
***/

.btn.white:not(.btn-outline) {
  color: #666;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn.white:not(.btn-outline):focus,
.btn.white:not(.btn-outline).focus {
  color: #666;
  background-color: #e6e6e6;
  border-color: #bfbfbf;
}

.btn.white:not(.btn-outline):hover {
  color: #666;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
}

.btn.white:not(.btn-outline):active,
.btn.white:not(.btn-outline).active,
.open>.btn.white:not(.btn-outline).dropdown-toggle {
  color: #666;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
}

.btn.white:not(.btn-outline):active:hover,
.btn.white:not(.btn-outline):active:focus,
.btn.white:not(.btn-outline):active.focus,
.btn.white:not(.btn-outline).active:hover,
.btn.white:not(.btn-outline).active:focus,
.btn.white:not(.btn-outline).active.focus,
.open>.btn.white:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.white:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.white:not(.btn-outline).dropdown-toggle.focus {
  color: #666;
  background-color: #d4d4d4;
  border-color: #bfbfbf;
}

.btn.white:not(.btn-outline):active,
.btn.white:not(.btn-outline).active,
.open>.btn.white:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.white:not(.btn-outline).disabled:hover,
.btn.white:not(.btn-outline).disabled:focus,
.btn.white:not(.btn-outline).disabled.focus,
.btn.white:not(.btn-outline)[disabled]:hover,
.btn.white:not(.btn-outline)[disabled]:focus,
.btn.white:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.white:not(.btn-outline):hover,
fieldset[disabled] .btn.white:not(.btn-outline):focus,
fieldset[disabled] .btn.white:not(.btn-outline).focus {
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn.white:not(.btn-outline) .badge {
  color: #ffffff;
  background-color: #666;
}

.btn.btn-outline.white {
  border-color: #ffffff;
  color: #ffffff;
  background: none;
}

.btn.btn-outline.white:hover,
.btn.btn-outline.white:active,
.btn.btn-outline.white:active:hover,
.btn.btn-outline.white:active:focus,
.btn.btn-outline.white:focus,
.btn.btn-outline.white.active {
  border-color: #ffffff;
  color: #666;
  background-color: #ffffff;
}

.btn.white.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.default:not(.btn-outline) {
  color: #666;
  background-color: #e1e5ec;
  border-color: #e1e5ec;
}

.btn.default:not(.btn-outline):focus,
.btn.default:not(.btn-outline).focus {
  color: #666;
  background-color: #c2cad8;
  border-color: #93a1bb;
}

.btn.default:not(.btn-outline):hover {
  color: #666;
  background-color: #c2cad8;
  border-color: #bcc5d4;
}

.btn.default:not(.btn-outline):active,
.btn.default:not(.btn-outline).active,
.open>.btn.default:not(.btn-outline).dropdown-toggle {
  color: #666;
  background-color: #c2cad8;
  border-color: #bcc5d4;
}

.btn.default:not(.btn-outline):active:hover,
.btn.default:not(.btn-outline):active:focus,
.btn.default:not(.btn-outline):active.focus,
.btn.default:not(.btn-outline).active:hover,
.btn.default:not(.btn-outline).active:focus,
.btn.default:not(.btn-outline).active.focus,
.open>.btn.default:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.default:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.default:not(.btn-outline).dropdown-toggle.focus {
  color: #666;
  background-color: #acb7ca;
  border-color: #93a1bb;
}

.btn.default:not(.btn-outline):active,
.btn.default:not(.btn-outline).active,
.open>.btn.default:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.default:not(.btn-outline).disabled:hover,
.btn.default:not(.btn-outline).disabled:focus,
.btn.default:not(.btn-outline).disabled.focus,
.btn.default:not(.btn-outline)[disabled]:hover,
.btn.default:not(.btn-outline)[disabled]:focus,
.btn.default:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.default:not(.btn-outline):hover,
fieldset[disabled] .btn.default:not(.btn-outline):focus,
fieldset[disabled] .btn.default:not(.btn-outline).focus {
  background-color: #e1e5ec;
  border-color: #e1e5ec;
}

.btn.default:not(.btn-outline) .badge {
  color: #e1e5ec;
  background-color: #666;
}

.btn.btn-outline.default {
  border-color: #e1e5ec;
  color: #e1e5ec;
  background: none;
}

.btn.btn-outline.default:hover,
.btn.btn-outline.default:active,
.btn.btn-outline.default:active:hover,
.btn.btn-outline.default:active:focus,
.btn.btn-outline.default:focus,
.btn.btn-outline.default.active {
  border-color: #e1e5ec;
  color: #666;
  background-color: #e1e5ec;
}

.btn.default.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.dark:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #2f353b;
  border-color: #2f353b;
}

.btn.dark:not(.btn-outline):focus,
.btn.dark:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #181c1f;
  border-color: black;
}

.btn.dark:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #181c1f;
  border-color: #141619;
}

.btn.dark:not(.btn-outline):active,
.btn.dark:not(.btn-outline).active,
.open>.btn.dark:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #181c1f;
  border-color: #141619;
}

.btn.dark:not(.btn-outline):active:hover,
.btn.dark:not(.btn-outline):active:focus,
.btn.dark:not(.btn-outline):active.focus,
.btn.dark:not(.btn-outline).active:hover,
.btn.dark:not(.btn-outline).active:focus,
.btn.dark:not(.btn-outline).active.focus,
.open>.btn.dark:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.dark:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.dark:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #090a0b;
  border-color: black;
}

.btn.dark:not(.btn-outline):active,
.btn.dark:not(.btn-outline).active,
.open>.btn.dark:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.dark:not(.btn-outline).disabled:hover,
.btn.dark:not(.btn-outline).disabled:focus,
.btn.dark:not(.btn-outline).disabled.focus,
.btn.dark:not(.btn-outline)[disabled]:hover,
.btn.dark:not(.btn-outline)[disabled]:focus,
.btn.dark:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.dark:not(.btn-outline):hover,
fieldset[disabled] .btn.dark:not(.btn-outline):focus,
fieldset[disabled] .btn.dark:not(.btn-outline).focus {
  background-color: #2f353b;
  border-color: #2f353b;
}

.btn.dark:not(.btn-outline) .badge {
  color: #2f353b;
  background-color: #FFFFFF;
}

.btn.btn-outline.dark {
  border-color: #2f353b;
  color: #2f353b;
  background: none;
}

.btn.btn-outline.dark:hover,
.btn.btn-outline.dark:active,
.btn.btn-outline.dark:active:hover,
.btn.btn-outline.dark:active:focus,
.btn.btn-outline.dark:focus,
.btn.btn-outline.dark.active {
  border-color: #2f353b;
  color: #FFFFFF;
  background-color: #2f353b;
}

.btn.dark.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.blue:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #3598dc;
  border-color: #3598dc;
}

.btn.blue:not(.btn-outline):focus,
.btn.blue:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #217ebd;
  border-color: #15527c;
}

.btn.blue:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #217ebd;
  border-color: #1f78b5;
}

.btn.blue:not(.btn-outline):active,
.btn.blue:not(.btn-outline).active,
.open>.btn.blue:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #217ebd;
  border-color: #1f78b5;
}

.btn.blue:not(.btn-outline):active:hover,
.btn.blue:not(.btn-outline):active:focus,
.btn.blue:not(.btn-outline):active.focus,
.btn.blue:not(.btn-outline).active:hover,
.btn.blue:not(.btn-outline).active:focus,
.btn.blue:not(.btn-outline).active.focus,
.open>.btn.blue:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.blue:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.blue:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #1c699f;
  border-color: #15527c;
}

.btn.blue:not(.btn-outline):active,
.btn.blue:not(.btn-outline).active,
.open>.btn.blue:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.blue:not(.btn-outline).disabled:hover,
.btn.blue:not(.btn-outline).disabled:focus,
.btn.blue:not(.btn-outline).disabled.focus,
.btn.blue:not(.btn-outline)[disabled]:hover,
.btn.blue:not(.btn-outline)[disabled]:focus,
.btn.blue:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.blue:not(.btn-outline):hover,
fieldset[disabled] .btn.blue:not(.btn-outline):focus,
fieldset[disabled] .btn.blue:not(.btn-outline).focus {
  background-color: #3598dc;
  border-color: #3598dc;
}

.btn.blue:not(.btn-outline) .badge {
  color: #3598dc;
  background-color: #FFFFFF;
}

.btn.btn-outline.blue {
  border-color: #3598dc;
  color: #3598dc;
  background: none;
}

.btn.btn-outline.blue:hover,
.btn.btn-outline.blue:active,
.btn.btn-outline.blue:active:hover,
.btn.btn-outline.blue:active:focus,
.btn.btn-outline.blue:focus,
.btn.btn-outline.blue.active {
  border-color: #3598dc;
  color: #FFFFFF;
  background-color: #3598dc;
}

.btn.blue.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.blue-steel:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #4B77BE;
  border-color: #4B77BE;
}

.btn.blue-steel:not(.btn-outline):focus,
.btn.blue-steel:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #395f9d;
  border-color: #243d65;
}

.btn.blue-steel:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #395f9d;
  border-color: #365b96;
}

.btn.blue-steel:not(.btn-outline):active,
.btn.blue-steel:not(.btn-outline).active,
.open>.btn.blue-steel:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #395f9d;
  border-color: #365b96;
}

.btn.blue-steel:not(.btn-outline):active:hover,
.btn.blue-steel:not(.btn-outline):active:focus,
.btn.blue-steel:not(.btn-outline):active.focus,
.btn.blue-steel:not(.btn-outline).active:hover,
.btn.blue-steel:not(.btn-outline).active:focus,
.btn.blue-steel:not(.btn-outline).active.focus,
.open>.btn.blue-steel:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.blue-steel:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.blue-steel:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #2f4f83;
  border-color: #243d65;
}

.btn.blue-steel:not(.btn-outline):active,
.btn.blue-steel:not(.btn-outline).active,
.open>.btn.blue-steel:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.blue-steel:not(.btn-outline).disabled:hover,
.btn.blue-steel:not(.btn-outline).disabled:focus,
.btn.blue-steel:not(.btn-outline).disabled.focus,
.btn.blue-steel:not(.btn-outline)[disabled]:hover,
.btn.blue-steel:not(.btn-outline)[disabled]:focus,
.btn.blue-steel:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.blue-steel:not(.btn-outline):hover,
fieldset[disabled] .btn.blue-steel:not(.btn-outline):focus,
fieldset[disabled] .btn.blue-steel:not(.btn-outline).focus {
  background-color: #4B77BE;
  border-color: #4B77BE;
}

.btn.blue-steel:not(.btn-outline) .badge {
  color: #4B77BE;
  background-color: #FFFFFF;
}

.btn.btn-outline.blue-steel {
  border-color: #4B77BE;
  color: #4B77BE;
  background: none;
}

.btn.btn-outline.blue-steel:hover,
.btn.btn-outline.blue-steel:active,
.btn.btn-outline.blue-steel:active:hover,
.btn.btn-outline.blue-steel:active:focus,
.btn.btn-outline.blue-steel:focus,
.btn.btn-outline.blue-steel.active {
  border-color: #4B77BE;
  color: #FFFFFF;
  background-color: #4B77BE;
}

.btn.blue-steel.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.blue-soft:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #4c87b9;
  border-color: #4c87b9;
}

.btn.blue-soft:not(.btn-outline):focus,
.btn.blue-soft:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #3b6d97;
  border-color: #264560;
}

.btn.blue-soft:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #3b6d97;
  border-color: #386890;
}

.btn.blue-soft:not(.btn-outline):active,
.btn.blue-soft:not(.btn-outline).active,
.open>.btn.blue-soft:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #3b6d97;
  border-color: #386890;
}

.btn.blue-soft:not(.btn-outline):active:hover,
.btn.blue-soft:not(.btn-outline):active:focus,
.btn.blue-soft:not(.btn-outline):active.focus,
.btn.blue-soft:not(.btn-outline).active:hover,
.btn.blue-soft:not(.btn-outline).active:focus,
.btn.blue-soft:not(.btn-outline).active.focus,
.open>.btn.blue-soft:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.blue-soft:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.blue-soft:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #315a7d;
  border-color: #264560;
}

.btn.blue-soft:not(.btn-outline):active,
.btn.blue-soft:not(.btn-outline).active,
.open>.btn.blue-soft:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.blue-soft:not(.btn-outline).disabled:hover,
.btn.blue-soft:not(.btn-outline).disabled:focus,
.btn.blue-soft:not(.btn-outline).disabled.focus,
.btn.blue-soft:not(.btn-outline)[disabled]:hover,
.btn.blue-soft:not(.btn-outline)[disabled]:focus,
.btn.blue-soft:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.blue-soft:not(.btn-outline):hover,
fieldset[disabled] .btn.blue-soft:not(.btn-outline):focus,
fieldset[disabled] .btn.blue-soft:not(.btn-outline).focus {
  background-color: #4c87b9;
  border-color: #4c87b9;
}

.btn.blue-soft:not(.btn-outline) .badge {
  color: #4c87b9;
  background-color: #FFFFFF;
}

.btn.btn-outline.blue-soft {
  border-color: #4c87b9;
  color: #4c87b9;
  background: none;
}

.btn.btn-outline.blue-soft:hover,
.btn.btn-outline.blue-soft:active,
.btn.btn-outline.blue-soft:active:hover,
.btn.btn-outline.blue-soft:active:focus,
.btn.btn-outline.blue-soft:focus,
.btn.btn-outline.blue-soft.active {
  border-color: #4c87b9;
  color: #FFFFFF;
  background-color: #4c87b9;
}

.btn.blue-soft.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.blue-dark:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #5e738b;
  border-color: #5e738b;
}

.btn.blue-dark:not(.btn-outline):focus,
.btn.blue-dark:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #495a6d;
  border-color: #2b343f;
}

.btn.blue-dark:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #495a6d;
  border-color: #455566;
}

.btn.blue-dark:not(.btn-outline):active,
.btn.blue-dark:not(.btn-outline).active,
.open>.btn.blue-dark:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #495a6d;
  border-color: #455566;
}

.btn.blue-dark:not(.btn-outline):active:hover,
.btn.blue-dark:not(.btn-outline):active:focus,
.btn.blue-dark:not(.btn-outline):active.focus,
.btn.blue-dark:not(.btn-outline).active:hover,
.btn.blue-dark:not(.btn-outline).active:focus,
.btn.blue-dark:not(.btn-outline).active.focus,
.open>.btn.blue-dark:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.blue-dark:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.blue-dark:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #3b4857;
  border-color: #2b343f;
}

.btn.blue-dark:not(.btn-outline):active,
.btn.blue-dark:not(.btn-outline).active,
.open>.btn.blue-dark:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.blue-dark:not(.btn-outline).disabled:hover,
.btn.blue-dark:not(.btn-outline).disabled:focus,
.btn.blue-dark:not(.btn-outline).disabled.focus,
.btn.blue-dark:not(.btn-outline)[disabled]:hover,
.btn.blue-dark:not(.btn-outline)[disabled]:focus,
.btn.blue-dark:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.blue-dark:not(.btn-outline):hover,
fieldset[disabled] .btn.blue-dark:not(.btn-outline):focus,
fieldset[disabled] .btn.blue-dark:not(.btn-outline).focus {
  background-color: #5e738b;
  border-color: #5e738b;
}

.btn.blue-dark:not(.btn-outline) .badge {
  color: #5e738b;
  background-color: #FFFFFF;
}

.btn.btn-outline.blue-dark {
  border-color: #5e738b;
  color: #5e738b;
  background: none;
}

.btn.btn-outline.blue-dark:hover,
.btn.btn-outline.blue-dark:active,
.btn.btn-outline.blue-dark:active:hover,
.btn.btn-outline.blue-dark:active:focus,
.btn.btn-outline.blue-dark:focus,
.btn.btn-outline.blue-dark.active {
  border-color: #5e738b;
  color: #FFFFFF;
  background-color: #5e738b;
}

.btn.blue-dark.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.blue-sharp:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #5C9BD1;
  border-color: #5C9BD1;
}

.btn.blue-sharp:not(.btn-outline):focus,
.btn.blue-sharp:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #3782c3;
  border-color: #265a87;
}

.btn.blue-sharp:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #3782c3;
  border-color: #357dbb;
}

.btn.blue-sharp:not(.btn-outline):active,
.btn.blue-sharp:not(.btn-outline).active,
.open>.btn.blue-sharp:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #3782c3;
  border-color: #357dbb;
}

.btn.blue-sharp:not(.btn-outline):active:hover,
.btn.blue-sharp:not(.btn-outline):active:focus,
.btn.blue-sharp:not(.btn-outline):active.focus,
.btn.blue-sharp:not(.btn-outline).active:hover,
.btn.blue-sharp:not(.btn-outline).active:focus,
.btn.blue-sharp:not(.btn-outline).active.focus,
.open>.btn.blue-sharp:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.blue-sharp:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.blue-sharp:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #2f70a7;
  border-color: #265a87;
}

.btn.blue-sharp:not(.btn-outline):active,
.btn.blue-sharp:not(.btn-outline).active,
.open>.btn.blue-sharp:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.blue-sharp:not(.btn-outline).disabled:hover,
.btn.blue-sharp:not(.btn-outline).disabled:focus,
.btn.blue-sharp:not(.btn-outline).disabled.focus,
.btn.blue-sharp:not(.btn-outline)[disabled]:hover,
.btn.blue-sharp:not(.btn-outline)[disabled]:focus,
.btn.blue-sharp:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.blue-sharp:not(.btn-outline):hover,
fieldset[disabled] .btn.blue-sharp:not(.btn-outline):focus,
fieldset[disabled] .btn.blue-sharp:not(.btn-outline).focus {
  background-color: #5C9BD1;
  border-color: #5C9BD1;
}

.btn.blue-sharp:not(.btn-outline) .badge {
  color: #5C9BD1;
  background-color: #FFFFFF;
}

.btn.btn-outline.blue-sharp {
  border-color: #5C9BD1;
  color: #5C9BD1;
  background: none;
}

.btn.btn-outline.blue-sharp:hover,
.btn.btn-outline.blue-sharp:active,
.btn.btn-outline.blue-sharp:active:hover,
.btn.btn-outline.blue-sharp:active:focus,
.btn.btn-outline.blue-sharp:focus,
.btn.btn-outline.blue-sharp.active {
  border-color: #5C9BD1;
  color: #FFFFFF;
  background-color: #5C9BD1;
}

.btn.blue-sharp.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.green:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #C3073F;
  border-color: #C3073F;
}

.btn.green:not(.btn-outline):focus,
.btn.green:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #b01a47;
  border-color: #dc143c;
}

.btn.green:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #b01a47;
  border-color: #dc143c;
}

.btn.green:not(.btn-outline):active,
.btn.green:not(.btn-outline).active,
.open>.btn.green:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #26a1ab;
  border-color: #2499a3;
}

.btn.green:not(.btn-outline):active:hover,
.btn.green:not(.btn-outline):active:focus,
.btn.green:not(.btn-outline):active.focus,
.btn.green:not(.btn-outline).active:hover,
.btn.green:not(.btn-outline).active:focus,
.btn.green:not(.btn-outline).active.focus,
.open>.btn.green:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.green:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.green:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #1f858e;
  border-color: #18666d;
}

.btn.green:not(.btn-outline):active,
.btn.green:not(.btn-outline).active,
.open>.btn.green:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.green:not(.btn-outline).disabled:hover,
.btn.green:not(.btn-outline).disabled:focus,
.btn.green:not(.btn-outline).disabled.focus,
.btn.green:not(.btn-outline)[disabled]:hover,
.btn.green:not(.btn-outline)[disabled]:focus,
.btn.green:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.green:not(.btn-outline):hover,
fieldset[disabled] .btn.green:not(.btn-outline):focus,
fieldset[disabled] .btn.green:not(.btn-outline).focus {
  background-color: #C3073F;
  border-color: #C3073F;
}

.btn.green:not(.btn-outline) .badge {
  color: #C3073F;
  background-color: #FFFFFF;
}

.btn.btn-outline.green {
  border-color: #cf8900;
  color: #cf8900;
  background: none;
}

.btn.btn-outline.green:hover,
.btn.btn-outline.green:active,
.btn.btn-outline.green:active:hover,
.btn.btn-outline.green:active:focus,
.btn.btn-outline.green:focus,
.btn.btn-outline.green.active {
  border-color: #cf8900;
  color: #FFFFFF;
  background-color: #cf8900;
}

.btn.green.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.green-seagreen:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #1BA39C;
  border-color: #1BA39C;
}

.btn.green-seagreen:not(.btn-outline):focus,
.btn.green-seagreen:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #147772;
  border-color: #093633;
}

.btn.green-seagreen:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #147772;
  border-color: #126e6a;
}

.btn.green-seagreen:not(.btn-outline):active,
.btn.green-seagreen:not(.btn-outline).active,
.open>.btn.green-seagreen:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #147772;
  border-color: #126e6a;
}

.btn.green-seagreen:not(.btn-outline):active:hover,
.btn.green-seagreen:not(.btn-outline):active:focus,
.btn.green-seagreen:not(.btn-outline):active.focus,
.btn.green-seagreen:not(.btn-outline).active:hover,
.btn.green-seagreen:not(.btn-outline).active:focus,
.btn.green-seagreen:not(.btn-outline).active.focus,
.open>.btn.green-seagreen:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.green-seagreen:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.green-seagreen:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #0f5955;
  border-color: #093633;
}

.btn.green-seagreen:not(.btn-outline):active,
.btn.green-seagreen:not(.btn-outline).active,
.open>.btn.green-seagreen:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.green-seagreen:not(.btn-outline).disabled:hover,
.btn.green-seagreen:not(.btn-outline).disabled:focus,
.btn.green-seagreen:not(.btn-outline).disabled.focus,
.btn.green-seagreen:not(.btn-outline)[disabled]:hover,
.btn.green-seagreen:not(.btn-outline)[disabled]:focus,
.btn.green-seagreen:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.green-seagreen:not(.btn-outline):hover,
fieldset[disabled] .btn.green-seagreen:not(.btn-outline):focus,
fieldset[disabled] .btn.green-seagreen:not(.btn-outline).focus {
  background-color: #1BA39C;
  border-color: #1BA39C;
}

.btn.green-seagreen:not(.btn-outline) .badge {
  color: #1BA39C;
  background-color: #FFFFFF;
}

.btn.btn-outline.green-seagreen {
  border-color: #1BA39C;
  color: #1BA39C;
  background: none;
}

.btn.btn-outline.green-seagreen:hover,
.btn.btn-outline.green-seagreen:active,
.btn.btn-outline.green-seagreen:active:hover,
.btn.btn-outline.green-seagreen:active:focus,
.btn.btn-outline.green-seagreen:focus,
.btn.btn-outline.green-seagreen.active {
  border-color: #1BA39C;
  color: #FFFFFF;
  background-color: #1BA39C;
}

.btn.green-seagreen.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.green-turquoise:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #36D7B7;
  border-color: #36D7B7;
}

.btn.green-turquoise:not(.btn-outline):focus,
.btn.green-turquoise:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #24b699;
  border-color: #177663;
}

.btn.green-turquoise:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #24b699;
  border-color: #22ad92;
}

.btn.green-turquoise:not(.btn-outline):active,
.btn.green-turquoise:not(.btn-outline).active,
.open>.btn.green-turquoise:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #24b699;
  border-color: #22ad92;
}

.btn.green-turquoise:not(.btn-outline):active:hover,
.btn.green-turquoise:not(.btn-outline):active:focus,
.btn.green-turquoise:not(.btn-outline):active.focus,
.btn.green-turquoise:not(.btn-outline).active:hover,
.btn.green-turquoise:not(.btn-outline).active:focus,
.btn.green-turquoise:not(.btn-outline).active.focus,
.open>.btn.green-turquoise:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.green-turquoise:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.green-turquoise:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #1e9880;
  border-color: #177663;
}

.btn.green-turquoise:not(.btn-outline):active,
.btn.green-turquoise:not(.btn-outline).active,
.open>.btn.green-turquoise:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.green-turquoise:not(.btn-outline).disabled:hover,
.btn.green-turquoise:not(.btn-outline).disabled:focus,
.btn.green-turquoise:not(.btn-outline).disabled.focus,
.btn.green-turquoise:not(.btn-outline)[disabled]:hover,
.btn.green-turquoise:not(.btn-outline)[disabled]:focus,
.btn.green-turquoise:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.green-turquoise:not(.btn-outline):hover,
fieldset[disabled] .btn.green-turquoise:not(.btn-outline):focus,
fieldset[disabled] .btn.green-turquoise:not(.btn-outline).focus {
  background-color: #36D7B7;
  border-color: #36D7B7;
}

.btn.green-turquoise:not(.btn-outline) .badge {
  color: #36D7B7;
  background-color: #FFFFFF;
}

.btn.btn-outline.green-turquoise {
  border-color: #36D7B7;
  color: #36D7B7;
  background: none;
}

.btn.btn-outline.green-turquoise:hover,
.btn.btn-outline.green-turquoise:active,
.btn.btn-outline.green-turquoise:active:hover,
.btn.btn-outline.green-turquoise:active:focus,
.btn.btn-outline.green-turquoise:focus,
.btn.btn-outline.green-turquoise.active {
  border-color: #36D7B7;
  color: #FFFFFF;
  background-color: #36D7B7;
}

.btn.green-turquoise.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.green-haze:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #44b6ae;
  border-color: #44b6ae;
}

.btn.green-haze:not(.btn-outline):focus,
.btn.green-haze:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #36918b;
  border-color: #215955;
}

.btn.green-haze:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #36918b;
  border-color: #338983;
}

.btn.green-haze:not(.btn-outline):active,
.btn.green-haze:not(.btn-outline).active,
.open>.btn.green-haze:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #36918b;
  border-color: #338983;
}

.btn.green-haze:not(.btn-outline):active:hover,
.btn.green-haze:not(.btn-outline):active:focus,
.btn.green-haze:not(.btn-outline):active.focus,
.btn.green-haze:not(.btn-outline).active:hover,
.btn.green-haze:not(.btn-outline).active:focus,
.btn.green-haze:not(.btn-outline).active.focus,
.open>.btn.green-haze:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.green-haze:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.green-haze:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #2c7772;
  border-color: #215955;
}

.btn.green-haze:not(.btn-outline):active,
.btn.green-haze:not(.btn-outline).active,
.open>.btn.green-haze:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.green-haze:not(.btn-outline).disabled:hover,
.btn.green-haze:not(.btn-outline).disabled:focus,
.btn.green-haze:not(.btn-outline).disabled.focus,
.btn.green-haze:not(.btn-outline)[disabled]:hover,
.btn.green-haze:not(.btn-outline)[disabled]:focus,
.btn.green-haze:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.green-haze:not(.btn-outline):hover,
fieldset[disabled] .btn.green-haze:not(.btn-outline):focus,
fieldset[disabled] .btn.green-haze:not(.btn-outline).focus {
  background-color: #44b6ae;
  border-color: #44b6ae;
}

.btn.green-haze:not(.btn-outline) .badge {
  color: #44b6ae;
  background-color: #FFFFFF;
}

.btn.btn-outline.green-haze {
  border-color: #44b6ae;
  color: #44b6ae;
  background: none;
}

.btn.btn-outline.green-haze:hover,
.btn.btn-outline.green-haze:active,
.btn.btn-outline.green-haze:active:hover,
.btn.btn-outline.green-haze:active:focus,
.btn.btn-outline.green-haze:focus,
.btn.btn-outline.green-haze.active {
  border-color: #44b6ae;
  color: #FFFFFF;
  background-color: #44b6ae;
}

.btn.green-haze.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.green-jungle:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #26C281;
  border-color: #26C281;
}

.btn.green-jungle:not(.btn-outline):focus,
.btn.green-jungle:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #1e9765;
  border-color: #11573a;
}

.btn.green-jungle:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #1e9765;
  border-color: #1c8f5f;
}

.btn.green-jungle:not(.btn-outline):active,
.btn.green-jungle:not(.btn-outline).active,
.open>.btn.green-jungle:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #1e9765;
  border-color: #1c8f5f;
}

.btn.green-jungle:not(.btn-outline):active:hover,
.btn.green-jungle:not(.btn-outline):active:focus,
.btn.green-jungle:not(.btn-outline):active.focus,
.btn.green-jungle:not(.btn-outline).active:hover,
.btn.green-jungle:not(.btn-outline).active:focus,
.btn.green-jungle:not(.btn-outline).active.focus,
.open>.btn.green-jungle:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.green-jungle:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.green-jungle:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #187a51;
  border-color: #11573a;
}

.btn.green-jungle:not(.btn-outline):active,
.btn.green-jungle:not(.btn-outline).active,
.open>.btn.green-jungle:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.green-jungle:not(.btn-outline).disabled:hover,
.btn.green-jungle:not(.btn-outline).disabled:focus,
.btn.green-jungle:not(.btn-outline).disabled.focus,
.btn.green-jungle:not(.btn-outline)[disabled]:hover,
.btn.green-jungle:not(.btn-outline)[disabled]:focus,
.btn.green-jungle:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.green-jungle:not(.btn-outline):hover,
fieldset[disabled] .btn.green-jungle:not(.btn-outline):focus,
fieldset[disabled] .btn.green-jungle:not(.btn-outline).focus {
  background-color: #26C281;
  border-color: #26C281;
}

.btn.green-jungle:not(.btn-outline) .badge {
  color: #26C281;
  background-color: #FFFFFF;
}

.btn.btn-outline.green-jungle {
  border-color: #26C281;
  color: #26C281;
  background: none;
}

.btn.btn-outline.green-jungle:hover,
.btn.btn-outline.green-jungle:active,
.btn.btn-outline.green-jungle:active:hover,
.btn.btn-outline.green-jungle:active:focus,
.btn.btn-outline.green-jungle:focus,
.btn.btn-outline.green-jungle.active {
  border-color: #26C281;
  color: #FFFFFF;
  background-color: #26C281;
}

.btn.green-jungle.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.green-soft:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #3faba4;
  border-color: #3faba4;
}

.btn.green-soft:not(.btn-outline):focus,
.btn.green-soft:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #318680;
  border-color: #1d4e4b;
}

.btn.green-soft:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #318680;
  border-color: #2f7e79;
}

.btn.green-soft:not(.btn-outline):active,
.btn.green-soft:not(.btn-outline).active,
.open>.btn.green-soft:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #318680;
  border-color: #2f7e79;
}

.btn.green-soft:not(.btn-outline):active:hover,
.btn.green-soft:not(.btn-outline):active:focus,
.btn.green-soft:not(.btn-outline):active.focus,
.btn.green-soft:not(.btn-outline).active:hover,
.btn.green-soft:not(.btn-outline).active:focus,
.btn.green-soft:not(.btn-outline).active.focus,
.open>.btn.green-soft:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.green-soft:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.green-soft:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #286c67;
  border-color: #1d4e4b;
}

.btn.green-soft:not(.btn-outline):active,
.btn.green-soft:not(.btn-outline).active,
.open>.btn.green-soft:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.green-soft:not(.btn-outline).disabled:hover,
.btn.green-soft:not(.btn-outline).disabled:focus,
.btn.green-soft:not(.btn-outline).disabled.focus,
.btn.green-soft:not(.btn-outline)[disabled]:hover,
.btn.green-soft:not(.btn-outline)[disabled]:focus,
.btn.green-soft:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.green-soft:not(.btn-outline):hover,
fieldset[disabled] .btn.green-soft:not(.btn-outline):focus,
fieldset[disabled] .btn.green-soft:not(.btn-outline).focus {
  background-color: #3faba4;
  border-color: #3faba4;
}

.btn.green-soft:not(.btn-outline) .badge {
  color: #3faba4;
  background-color: #FFFFFF;
}

.btn.btn-outline.green-soft {
  border-color: #3faba4;
  color: #3faba4;
  background: none;
}

.btn.btn-outline.green-soft:hover,
.btn.btn-outline.green-soft:active,
.btn.btn-outline.green-soft:active:hover,
.btn.btn-outline.green-soft:active:focus,
.btn.btn-outline.green-soft:focus,
.btn.btn-outline.green-soft.active {
  border-color: #3faba4;
  color: #FFFFFF;
  background-color: #3faba4;
}

.btn.green-soft.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.green-dark:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #4DB3A2;
  border-color: #4DB3A2;
}

.btn.green-dark:not(.btn-outline):focus,
.btn.green-dark:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #3d9082;
  border-color: #265a51;
}

.btn.green-dark:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #3d9082;
  border-color: #3a897b;
}

.btn.green-dark:not(.btn-outline):active,
.btn.green-dark:not(.btn-outline).active,
.open>.btn.green-dark:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #3d9082;
  border-color: #3a897b;
}

.btn.green-dark:not(.btn-outline):active:hover,
.btn.green-dark:not(.btn-outline):active:focus,
.btn.green-dark:not(.btn-outline):active.focus,
.btn.green-dark:not(.btn-outline).active:hover,
.btn.green-dark:not(.btn-outline).active:focus,
.btn.green-dark:not(.btn-outline).active.focus,
.open>.btn.green-dark:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.green-dark:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.green-dark:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #33776b;
  border-color: #265a51;
}

.btn.green-dark:not(.btn-outline):active,
.btn.green-dark:not(.btn-outline).active,
.open>.btn.green-dark:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.green-dark:not(.btn-outline).disabled:hover,
.btn.green-dark:not(.btn-outline).disabled:focus,
.btn.green-dark:not(.btn-outline).disabled.focus,
.btn.green-dark:not(.btn-outline)[disabled]:hover,
.btn.green-dark:not(.btn-outline)[disabled]:focus,
.btn.green-dark:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.green-dark:not(.btn-outline):hover,
fieldset[disabled] .btn.green-dark:not(.btn-outline):focus,
fieldset[disabled] .btn.green-dark:not(.btn-outline).focus {
  background-color: #4DB3A2;
  border-color: #4DB3A2;
}

.btn.green-dark:not(.btn-outline) .badge {
  color: #4DB3A2;
  background-color: #FFFFFF;
}

.btn.btn-outline.green-dark {
  border-color: #4DB3A2;
  color: #4DB3A2;
  background: none;
}

.btn.btn-outline.green-dark:hover,
.btn.btn-outline.green-dark:active,
.btn.btn-outline.green-dark:active:hover,
.btn.btn-outline.green-dark:active:focus,
.btn.btn-outline.green-dark:focus,
.btn.btn-outline.green-dark.active {
  border-color: #4DB3A2;
  color: #FFFFFF;
  background-color: #4DB3A2;
}

.btn.green-dark.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.green-sharp:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #3562d9;
  border-color: #3562d9;
}

.btn.green-sharp:not(.btn-outline):focus,
.btn.green-sharp:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #218d96;
  border-color: #135257;
}

.btn.green-sharp:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #218d96;
  border-color: #1f858e;
}

.btn.green-sharp:not(.btn-outline):active,
.btn.green-sharp:not(.btn-outline).active,
.open>.btn.green-sharp:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #218d96;
  border-color: #1f858e;
}

.btn.green-sharp:not(.btn-outline):active:hover,
.btn.green-sharp:not(.btn-outline):active:focus,
.btn.green-sharp:not(.btn-outline):active.focus,
.btn.green-sharp:not(.btn-outline).active:hover,
.btn.green-sharp:not(.btn-outline).active:focus,
.btn.green-sharp:not(.btn-outline).active.focus,
.open>.btn.green-sharp:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.green-sharp:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.green-sharp:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #1a7179;
  border-color: #135257;
}

.btn.green-sharp:not(.btn-outline):active,
.btn.green-sharp:not(.btn-outline).active,
.open>.btn.green-sharp:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.green-sharp:not(.btn-outline).disabled:hover,
.btn.green-sharp:not(.btn-outline).disabled:focus,
.btn.green-sharp:not(.btn-outline).disabled.focus,
.btn.green-sharp:not(.btn-outline)[disabled]:hover,
.btn.green-sharp:not(.btn-outline)[disabled]:focus,
.btn.green-sharp:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.green-sharp:not(.btn-outline):hover,
fieldset[disabled] .btn.green-sharp:not(.btn-outline):focus,
fieldset[disabled] .btn.green-sharp:not(.btn-outline).focus {
  background-color: #3562d9;
  border-color: #3562d9;
}

.btn.green-sharp:not(.btn-outline) .badge {
  color: #3562d9;
  background-color: #FFFFFF;
}

.btn.btn-outline.green-sharp {
  border-color: #3562d9;
  color: #3562d9;
  background: none;
}

.btn.btn-outline.green-sharp:hover,
.btn.btn-outline.green-sharp:active,
.btn.btn-outline.green-sharp:active:hover,
.btn.btn-outline.green-sharp:active:focus,
.btn.btn-outline.green-sharp:focus,
.btn.btn-outline.green-sharp.active {
  border-color: #3562d9;
  color: #FFFFFF;
  background-color: #3562d9;
}

.btn.green-sharp.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.green-steel:not(.btn-outline) {
  color: #FFFFFF;
  background-color: #cf8900;
  border-color: #cf8900;
}

.btn.green-steel:not(.btn-outline):focus,
.btn.green-steel:not(.btn-outline).focus {
  color: #FFFFFF;
  background-color: #208b8c;
  border-color: #124d4e;
}

.btn.green-steel:not(.btn-outline):hover {
  color: #FFFFFF;
  background-color: #208b8c;
  border-color: #1e8384;
}

.btn.green-steel:not(.btn-outline):active,
.btn.green-steel:not(.btn-outline).active,
.open>.btn.green-steel:not(.btn-outline).dropdown-toggle {
  color: #FFFFFF;
  background-color: #208b8c;
  border-color: #1e8384;
}

.btn.green-steel:not(.btn-outline):active:hover,
.btn.green-steel:not(.btn-outline):active:focus,
.btn.green-steel:not(.btn-outline):active.focus,
.btn.green-steel:not(.btn-outline).active:hover,
.btn.green-steel:not(.btn-outline).active:focus,
.btn.green-steel:not(.btn-outline).active.focus,
.open>.btn.green-steel:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.green-steel:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.green-steel:not(.btn-outline).dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #196e6f;
  border-color: #124d4e;
}

.btn.green-steel:not(.btn-outline):active,
.btn.green-steel:not(.btn-outline).active,
.open>.btn.green-steel:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.green-steel:not(.btn-outline).disabled:hover,
.btn.green-steel:not(.btn-outline).disabled:focus,
.btn.green-steel:not(.btn-outline).disabled.focus,
.btn.green-steel:not(.btn-outline)[disabled]:hover,
.btn.green-steel:not(.btn-outline)[disabled]:focus,
.btn.green-steel:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.green-steel:not(.btn-outline):hover,
fieldset[disabled] .btn.green-steel:not(.btn-outline):focus,
fieldset[disabled] .btn.green-steel:not(.btn-outline).focus {
  background-color: #cf8900;
  border-color: #cf8900;
}

.btn.green-steel:not(.btn-outline) .badge {
  color: #cf8900;
  background-color: #FFFFFF;
}

.btn.btn-outline.green-steel {
  border-color: #cf8900;
  color: #cf8900;
  background: none;
}

.btn.btn-outline.green-steel:hover,
.btn.btn-outline.green-steel:active,
.btn.btn-outline.green-steel:active:hover,
.btn.btn-outline.green-steel:active:focus,
.btn.btn-outline.green-steel:focus,
.btn.btn-outline.green-steel.active {
  border-color: #cf8900;
  color: #FFFFFF;
  background-color: #cf8900;
}

.btn.green-steel.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.btn-outline.red:hover,
.btn.btn-outline.red:active,
.btn.btn-outline.red:active:hover,
.btn.btn-outline.red:active:focus,
.btn.btn-outline.red:focus,
.btn.btn-outline.red.active {
  border-color: #e7505a;
  color: #ffffff;
  background-color: #e7505a;
}

.btn.red.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.red-pink:not(.btn-outline) {
  color: #ffffff;
  background-color: #E08283;
  border-color: #E08283;
}

.btn.red-pink:not(.btn-outline):focus,
.btn.red-pink:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #d6595a;
  border-color: #b52d2e;
}

.btn.red-pink:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #d6595a;
  border-color: #d45152;
}

.btn.red-pink:not(.btn-outline):active,
.btn.red-pink:not(.btn-outline).active,
.open>.btn.red-pink:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #d6595a;
  border-color: #d45152;
}

.btn.red-pink:not(.btn-outline):active:hover,
.btn.red-pink:not(.btn-outline):active:focus,
.btn.red-pink:not(.btn-outline):active.focus,
.btn.red-pink:not(.btn-outline).active:hover,
.btn.red-pink:not(.btn-outline).active:focus,
.btn.red-pink:not(.btn-outline).active.focus,
.open>.btn.red-pink:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.red-pink:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.red-pink:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #cf3d3e;
  border-color: #b52d2e;
}

.btn.red-pink:not(.btn-outline):active,
.btn.red-pink:not(.btn-outline).active,
.open>.btn.red-pink:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.red-pink:not(.btn-outline).disabled:hover,
.btn.red-pink:not(.btn-outline).disabled:focus,
.btn.red-pink:not(.btn-outline).disabled.focus,
.btn.red-pink:not(.btn-outline)[disabled]:hover,
.btn.red-pink:not(.btn-outline)[disabled]:focus,
.btn.red-pink:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.red-pink:not(.btn-outline):hover,
fieldset[disabled] .btn.red-pink:not(.btn-outline):focus,
fieldset[disabled] .btn.red-pink:not(.btn-outline).focus {
  background-color: #E08283;
  border-color: #E08283;
}

.btn.red-pink:not(.btn-outline) .badge {
  color: #E08283;
  background-color: #ffffff;
}

.btn.btn-outline.red-pink {
  border-color: #E08283;
  color: #E08283;
  background: none;
}

.btn.btn-outline.red-pink:hover,
.btn.btn-outline.red-pink:active,
.btn.btn-outline.red-pink:active:hover,
.btn.btn-outline.red-pink:active:focus,
.btn.btn-outline.red-pink:focus,
.btn.btn-outline.red-pink.active {
  border-color: #E08283;
  color: #ffffff;
  background-color: #E08283;
}

.btn.red-pink.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.red-soft:not(.btn-outline) {
  color: #ffffff;
  background-color: #d05454;
  border-color: #d05454;
}

.btn.red-soft:not(.btn-outline):focus,
.btn.red-soft:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #bd3434;
  border-color: #812323;
}

.btn.red-soft:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #bd3434;
  border-color: #b53232;
}

.btn.red-soft:not(.btn-outline):active,
.btn.red-soft:not(.btn-outline).active,
.open>.btn.red-soft:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #bd3434;
  border-color: #b53232;
}

.btn.red-soft:not(.btn-outline):active:hover,
.btn.red-soft:not(.btn-outline):active:focus,
.btn.red-soft:not(.btn-outline):active.focus,
.btn.red-soft:not(.btn-outline).active:hover,
.btn.red-soft:not(.btn-outline).active:focus,
.btn.red-soft:not(.btn-outline).active.focus,
.open>.btn.red-soft:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.red-soft:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.red-soft:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #a12c2c;
  border-color: #812323;
}

.btn.red-soft:not(.btn-outline):active,
.btn.red-soft:not(.btn-outline).active,
.open>.btn.red-soft:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.red-soft:not(.btn-outline).disabled:hover,
.btn.red-soft:not(.btn-outline).disabled:focus,
.btn.red-soft:not(.btn-outline).disabled.focus,
.btn.red-soft:not(.btn-outline)[disabled]:hover,
.btn.red-soft:not(.btn-outline)[disabled]:focus,
.btn.red-soft:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.red-soft:not(.btn-outline):hover,
fieldset[disabled] .btn.red-soft:not(.btn-outline):focus,
fieldset[disabled] .btn.red-soft:not(.btn-outline).focus {
  background-color: #d05454;
  border-color: #d05454;
}

.btn.red-soft:not(.btn-outline) .badge {
  color: #d05454;
  background-color: #ffffff;
}

.btn.btn-outline.red-soft {
  border-color: #d05454;
  color: #d05454;
  background: none;
}

.btn.btn-outline.red-soft:hover,
.btn.btn-outline.red-soft:active,
.btn.btn-outline.red-soft:active:hover,
.btn.btn-outline.red-soft:active:focus,
.btn.btn-outline.red-soft:focus,
.btn.btn-outline.red-soft.active {
  border-color: #d05454;
  color: #ffffff;
  background-color: #d05454;
}

.btn.red-soft.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.red-haze:not(.btn-outline) {
  color: #ffffff;
  background-color: #f36a5a;
  border-color: #f36a5a;
}

.btn.red-haze:not(.btn-outline):focus,
.btn.red-haze:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #f03f2a;
  border-color: #c0210e;
}

.btn.red-haze:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #f03f2a;
  border-color: #ef3621;
}

.btn.red-haze:not(.btn-outline):active,
.btn.red-haze:not(.btn-outline).active,
.open>.btn.red-haze:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #f03f2a;
  border-color: #ef3621;
}

.btn.red-haze:not(.btn-outline):active:hover,
.btn.red-haze:not(.btn-outline):active:focus,
.btn.red-haze:not(.btn-outline):active.focus,
.btn.red-haze:not(.btn-outline).active:hover,
.btn.red-haze:not(.btn-outline).active:focus,
.btn.red-haze:not(.btn-outline).active.focus,
.open>.btn.red-haze:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.red-haze:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.red-haze:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #e62711;
  border-color: #c0210e;
}

.btn.red-haze:not(.btn-outline):active,
.btn.red-haze:not(.btn-outline).active,
.open>.btn.red-haze:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.red-haze:not(.btn-outline).disabled:hover,
.btn.red-haze:not(.btn-outline).disabled:focus,
.btn.red-haze:not(.btn-outline).disabled.focus,
.btn.red-haze:not(.btn-outline)[disabled]:hover,
.btn.red-haze:not(.btn-outline)[disabled]:focus,
.btn.red-haze:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.red-haze:not(.btn-outline):hover,
fieldset[disabled] .btn.red-haze:not(.btn-outline):focus,
fieldset[disabled] .btn.red-haze:not(.btn-outline).focus {
  background-color: #f36a5a;
  border-color: #f36a5a;
}

.btn.red-haze:not(.btn-outline) .badge {
  color: #f36a5a;
  background-color: #ffffff;
}

.btn.btn-outline.red-haze {
  border-color: #f36a5a;
  color: #f36a5a;
  background: none;
}

.btn.btn-outline.red-haze:hover,
.btn.btn-outline.red-haze:active,
.btn.btn-outline.red-haze:active:hover,
.btn.btn-outline.red-haze:active:focus,
.btn.btn-outline.red-haze:focus,
.btn.btn-outline.red-haze.active {
  border-color: #f36a5a;
  color: #ffffff;
  background-color: #f36a5a;
}

.btn.red-haze.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.yellow:not(.btn-outline) {
  color: #ffffff;
  background-color: #c49f47;
  border-color: #c49f47;
}

.btn.yellow:not(.btn-outline):focus,
.btn.yellow:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #a48334;
  border-color: #6a5422;
}

.btn.yellow:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #a48334;
  border-color: #9c7c32;
}

.btn.yellow:not(.btn-outline):active,
.btn.yellow:not(.btn-outline).active,
.open>.btn.yellow:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #a48334;
  border-color: #9c7c32;
}

.btn.yellow:not(.btn-outline):active:hover,
.btn.yellow:not(.btn-outline):active:focus,
.btn.yellow:not(.btn-outline):active.focus,
.btn.yellow:not(.btn-outline).active:hover,
.btn.yellow:not(.btn-outline).active:focus,
.btn.yellow:not(.btn-outline).active.focus,
.open>.btn.yellow:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.yellow:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.yellow:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #896d2c;
  border-color: #6a5422;
}

.btn.yellow:not(.btn-outline):active,
.btn.yellow:not(.btn-outline).active,
.open>.btn.yellow:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.yellow:not(.btn-outline).disabled:hover,
.btn.yellow:not(.btn-outline).disabled:focus,
.btn.yellow:not(.btn-outline).disabled.focus,
.btn.yellow:not(.btn-outline)[disabled]:hover,
.btn.yellow:not(.btn-outline)[disabled]:focus,
.btn.yellow:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.yellow:not(.btn-outline):hover,
fieldset[disabled] .btn.yellow:not(.btn-outline):focus,
fieldset[disabled] .btn.yellow:not(.btn-outline).focus {
  background-color: #c49f47;
  border-color: #c49f47;
}

.btn.yellow:not(.btn-outline) .badge {
  color: #c49f47;
  background-color: #ffffff;
}

.btn.btn-outline.yellow {
  border-color: #c49f47;
  color: #c49f47;
  background: none;
}

.btn.btn-outline.yellow:hover,
.btn.btn-outline.yellow:active,
.btn.btn-outline.yellow:active:hover,
.btn.btn-outline.yellow:active:focus,
.btn.btn-outline.yellow:focus,
.btn.btn-outline.yellow.active {
  border-color: #c49f47;
  color: #ffffff;
  background-color: #c49f47;
}

.btn.yellow.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.yellow-gold:not(.btn-outline) {
  color: #ffffff;
  background-color: #E87E04;
  border-color: #E87E04;
}

.btn.yellow-gold:not(.btn-outline):focus,
.btn.yellow-gold:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #b66303;
  border-color: #6b3a02;
}

.btn.yellow-gold:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #b66303;
  border-color: #ac5d03;
}

.btn.yellow-gold:not(.btn-outline):active,
.btn.yellow-gold:not(.btn-outline).active,
.open>.btn.yellow-gold:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #b66303;
  border-color: #ac5d03;
}

.btn.yellow-gold:not(.btn-outline):active:hover,
.btn.yellow-gold:not(.btn-outline):active:focus,
.btn.yellow-gold:not(.btn-outline):active.focus,
.btn.yellow-gold:not(.btn-outline).active:hover,
.btn.yellow-gold:not(.btn-outline).active:focus,
.btn.yellow-gold:not(.btn-outline).active.focus,
.open>.btn.yellow-gold:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.yellow-gold:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.yellow-gold:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #935003;
  border-color: #6b3a02;
}

.btn.yellow-gold:not(.btn-outline):active,
.btn.yellow-gold:not(.btn-outline).active,
.open>.btn.yellow-gold:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.yellow-gold:not(.btn-outline).disabled:hover,
.btn.yellow-gold:not(.btn-outline).disabled:focus,
.btn.yellow-gold:not(.btn-outline).disabled.focus,
.btn.yellow-gold:not(.btn-outline)[disabled]:hover,
.btn.yellow-gold:not(.btn-outline)[disabled]:focus,
.btn.yellow-gold:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.yellow-gold:not(.btn-outline):hover,
fieldset[disabled] .btn.yellow-gold:not(.btn-outline):focus,
fieldset[disabled] .btn.yellow-gold:not(.btn-outline).focus {
  background-color: #E87E04;
  border-color: #E87E04;
}

.btn.yellow-gold:not(.btn-outline) .badge {
  color: #E87E04;
  background-color: #ffffff;
}

.btn.btn-outline.yellow-gold {
  border-color: #E87E04;
  color: #E87E04;
  background: none;
}

.btn.btn-outline.yellow-gold:hover,
.btn.btn-outline.yellow-gold:active,
.btn.btn-outline.yellow-gold:active:hover,
.btn.btn-outline.yellow-gold:active:focus,
.btn.btn-outline.yellow-gold:focus,
.btn.btn-outline.yellow-gold.active {
  border-color: #E87E04;
  color: #ffffff;
  background-color: #E87E04;
}

.btn.yellow-gold.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.yellow-soft:not(.btn-outline) {
  color: #ffffff;
  background-color: #c8d046;
  border-color: #c8d046;
}

.btn.yellow-soft:not(.btn-outline):focus,
.btn.yellow-soft:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #adb52e;
  border-color: #73781e;
}

.btn.yellow-soft:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #adb52e;
  border-color: #a5ad2c;
}

.btn.yellow-soft:not(.btn-outline):active,
.btn.yellow-soft:not(.btn-outline).active,
.open>.btn.yellow-soft:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #adb52e;
  border-color: #a5ad2c;
}

.btn.yellow-soft:not(.btn-outline):active:hover,
.btn.yellow-soft:not(.btn-outline):active:focus,
.btn.yellow-soft:not(.btn-outline):active.focus,
.btn.yellow-soft:not(.btn-outline).active:hover,
.btn.yellow-soft:not(.btn-outline).active:focus,
.btn.yellow-soft:not(.btn-outline).active.focus,
.open>.btn.yellow-soft:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.yellow-soft:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.yellow-soft:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #929927;
  border-color: #73781e;
}

.btn.yellow-soft:not(.btn-outline):active,
.btn.yellow-soft:not(.btn-outline).active,
.open>.btn.yellow-soft:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.yellow-soft:not(.btn-outline).disabled:hover,
.btn.yellow-soft:not(.btn-outline).disabled:focus,
.btn.yellow-soft:not(.btn-outline).disabled.focus,
.btn.yellow-soft:not(.btn-outline)[disabled]:hover,
.btn.yellow-soft:not(.btn-outline)[disabled]:focus,
.btn.yellow-soft:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.yellow-soft:not(.btn-outline):hover,
fieldset[disabled] .btn.yellow-soft:not(.btn-outline):focus,
fieldset[disabled] .btn.yellow-soft:not(.btn-outline).focus {
  background-color: #c8d046;
  border-color: #c8d046;
}

.btn.yellow-soft:not(.btn-outline) .badge {
  color: #c8d046;
  background-color: #ffffff;
}

.btn.btn-outline.yellow-soft {
  border-color: #c8d046;
  color: #c8d046;
  background: none;
}

.btn.btn-outline.yellow-soft:hover,
.btn.btn-outline.yellow-soft:active,
.btn.btn-outline.yellow-soft:active:hover,
.btn.btn-outline.yellow-soft:active:focus,
.btn.btn-outline.yellow-soft:focus,
.btn.btn-outline.yellow-soft.active {
  border-color: #c8d046;
  color: #ffffff;
  background-color: #c8d046;
}

.btn.yellow-soft.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.yellow-haze:not(.btn-outline) {
  color: #ffffff;
  background-color: #c5bf66;
  border-color: #c5bf66;
}

.btn.yellow-haze:not(.btn-outline):focus,
.btn.yellow-haze:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #b4ad44;
  border-color: #7c772f;
}

.btn.yellow-haze:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #b4ad44;
  border-color: #aca641;
}

.btn.yellow-haze:not(.btn-outline):active,
.btn.yellow-haze:not(.btn-outline).active,
.open>.btn.yellow-haze:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #b4ad44;
  border-color: #aca641;
}

.btn.yellow-haze:not(.btn-outline):active:hover,
.btn.yellow-haze:not(.btn-outline):active:focus,
.btn.yellow-haze:not(.btn-outline):active.focus,
.btn.yellow-haze:not(.btn-outline).active:hover,
.btn.yellow-haze:not(.btn-outline).active:focus,
.btn.yellow-haze:not(.btn-outline).active.focus,
.open>.btn.yellow-haze:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.yellow-haze:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.yellow-haze:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #9a943a;
  border-color: #7c772f;
}

.btn.yellow-haze:not(.btn-outline):active,
.btn.yellow-haze:not(.btn-outline).active,
.open>.btn.yellow-haze:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.yellow-haze:not(.btn-outline).disabled:hover,
.btn.yellow-haze:not(.btn-outline).disabled:focus,
.btn.yellow-haze:not(.btn-outline).disabled.focus,
.btn.yellow-haze:not(.btn-outline)[disabled]:hover,
.btn.yellow-haze:not(.btn-outline)[disabled]:focus,
.btn.yellow-haze:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.yellow-haze:not(.btn-outline):hover,
fieldset[disabled] .btn.yellow-haze:not(.btn-outline):focus,
fieldset[disabled] .btn.yellow-haze:not(.btn-outline).focus {
  background-color: #c5bf66;
  border-color: #c5bf66;
}

.btn.yellow-haze:not(.btn-outline) .badge {
  color: #c5bf66;
  background-color: #ffffff;
}

.btn.btn-outline.yellow-haze {
  border-color: #c5bf66;
  color: #c5bf66;
  background: none;
}

.btn.btn-outline.yellow-haze:hover,
.btn.btn-outline.yellow-haze:active,
.btn.btn-outline.yellow-haze:active:hover,
.btn.btn-outline.yellow-haze:active:focus,
.btn.btn-outline.yellow-haze:focus,
.btn.btn-outline.yellow-haze.active {
  border-color: #c5bf66;
  color: #ffffff;
  background-color: #c5bf66;
}

.btn.yellow-haze.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.purple:not(.btn-outline) {
  color: #ffffff;
  background-color: #8E44AD;
  border-color: #8E44AD;
}

.btn.purple:not(.btn-outline):focus,
.btn.purple:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #703688;
  border-color: #432051;
}

.btn.purple:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #703688;
  border-color: #6a3381;
}

.btn.purple:not(.btn-outline):active,
.btn.purple:not(.btn-outline).active,
.open>.btn.purple:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #703688;
  border-color: #6a3381;
}

.btn.purple:not(.btn-outline):active:hover,
.btn.purple:not(.btn-outline):active:focus,
.btn.purple:not(.btn-outline):active.focus,
.btn.purple:not(.btn-outline).active:hover,
.btn.purple:not(.btn-outline).active:focus,
.btn.purple:not(.btn-outline).active.focus,
.open>.btn.purple:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.purple:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.purple:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #5b2c6f;
  border-color: #432051;
}

.btn.purple:not(.btn-outline):active,
.btn.purple:not(.btn-outline).active,
.open>.btn.purple:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.purple:not(.btn-outline).disabled:hover,
.btn.purple:not(.btn-outline).disabled:focus,
.btn.purple:not(.btn-outline).disabled.focus,
.btn.purple:not(.btn-outline)[disabled]:hover,
.btn.purple:not(.btn-outline)[disabled]:focus,
.btn.purple:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.purple:not(.btn-outline):hover,
fieldset[disabled] .btn.purple:not(.btn-outline):focus,
fieldset[disabled] .btn.purple:not(.btn-outline).focus {
  background-color: #8E44AD;
  border-color: #8E44AD;
}

.btn.purple:not(.btn-outline) .badge {
  color: #8E44AD;
  background-color: #ffffff;
}

.btn.btn-outline.purple {
  border-color: #8E44AD;
  color: #8E44AD;
  background: none;
}

.btn.btn-outline.purple:hover,
.btn.btn-outline.purple:active,
.btn.btn-outline.purple:active:hover,
.btn.btn-outline.purple:active:focus,
.btn.btn-outline.purple:focus,
.btn.btn-outline.purple.active {
  border-color: #8E44AD;
  color: #ffffff;
  background-color: #8E44AD;
}

.btn.purple.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.purple-plum:not(.btn-outline) {
  color: #ffffff;
  background-color: #8775a7;
  border-color: #8775a7;
}

.btn.purple-plum:not(.btn-outline):focus,
.btn.purple-plum:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #6d5b8e;
  border-color: #493d60;
}

.btn.purple-plum:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #6d5b8e;
  border-color: #685788;
}

.btn.purple-plum:not(.btn-outline):active,
.btn.purple-plum:not(.btn-outline).active,
.open>.btn.purple-plum:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #6d5b8e;
  border-color: #685788;
}

.btn.purple-plum:not(.btn-outline):active:hover,
.btn.purple-plum:not(.btn-outline):active:focus,
.btn.purple-plum:not(.btn-outline):active.focus,
.btn.purple-plum:not(.btn-outline).active:hover,
.btn.purple-plum:not(.btn-outline).active:focus,
.btn.purple-plum:not(.btn-outline).active.focus,
.open>.btn.purple-plum:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.purple-plum:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.purple-plum:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #5d4d78;
  border-color: #493d60;
}

.btn.purple-plum:not(.btn-outline):active,
.btn.purple-plum:not(.btn-outline).active,
.open>.btn.purple-plum:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.purple-plum:not(.btn-outline).disabled:hover,
.btn.purple-plum:not(.btn-outline).disabled:focus,
.btn.purple-plum:not(.btn-outline).disabled.focus,
.btn.purple-plum:not(.btn-outline)[disabled]:hover,
.btn.purple-plum:not(.btn-outline)[disabled]:focus,
.btn.purple-plum:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.purple-plum:not(.btn-outline):hover,
fieldset[disabled] .btn.purple-plum:not(.btn-outline):focus,
fieldset[disabled] .btn.purple-plum:not(.btn-outline).focus {
  background-color: #8775a7;
  border-color: #8775a7;
}

.btn.purple-plum:not(.btn-outline) .badge {
  color: #8775a7;
  background-color: #ffffff;
}

.btn.btn-outline.purple-plum {
  border-color: #8775a7;
  color: #8775a7;
  background: none;
}

.btn.btn-outline.purple-plum:hover,
.btn.btn-outline.purple-plum:active,
.btn.btn-outline.purple-plum:active:hover,
.btn.btn-outline.purple-plum:active:focus,
.btn.btn-outline.purple-plum:focus,
.btn.btn-outline.purple-plum.active {
  border-color: #8775a7;
  color: #ffffff;
  background-color: #8775a7;
}

.btn.purple-plum.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.purple-medium:not(.btn-outline) {
  color: #ffffff;
  background-color: #BF55EC;
  border-color: #BF55EC;
}

.btn.purple-medium:not(.btn-outline):focus,
.btn.purple-medium:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #ae27e7;
  border-color: #8013ae;
}

.btn.purple-medium:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #ae27e7;
  border-color: #aa1ee6;
}

.btn.purple-medium:not(.btn-outline):active,
.btn.purple-medium:not(.btn-outline).active,
.open>.btn.purple-medium:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #ae27e7;
  border-color: #aa1ee6;
}

.btn.purple-medium:not(.btn-outline):active:hover,
.btn.purple-medium:not(.btn-outline):active:focus,
.btn.purple-medium:not(.btn-outline):active.focus,
.btn.purple-medium:not(.btn-outline).active:hover,
.btn.purple-medium:not(.btn-outline).active:focus,
.btn.purple-medium:not(.btn-outline).active.focus,
.open>.btn.purple-medium:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.purple-medium:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.purple-medium:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #9b18d3;
  border-color: #8013ae;
}

.btn.purple-medium:not(.btn-outline):active,
.btn.purple-medium:not(.btn-outline).active,
.open>.btn.purple-medium:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.purple-medium:not(.btn-outline).disabled:hover,
.btn.purple-medium:not(.btn-outline).disabled:focus,
.btn.purple-medium:not(.btn-outline).disabled.focus,
.btn.purple-medium:not(.btn-outline)[disabled]:hover,
.btn.purple-medium:not(.btn-outline)[disabled]:focus,
.btn.purple-medium:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.purple-medium:not(.btn-outline):hover,
fieldset[disabled] .btn.purple-medium:not(.btn-outline):focus,
fieldset[disabled] .btn.purple-medium:not(.btn-outline).focus {
  background-color: #BF55EC;
  border-color: #BF55EC;
}

.btn.purple-medium:not(.btn-outline) .badge {
  color: #BF55EC;
  background-color: #ffffff;
}

.btn.btn-outline.purple-medium {
  border-color: #BF55EC;
  color: #BF55EC;
  background: none;
}

.btn.btn-outline.purple-medium:hover,
.btn.btn-outline.purple-medium:active,
.btn.btn-outline.purple-medium:active:hover,
.btn.btn-outline.purple-medium:active:focus,
.btn.btn-outline.purple-medium:focus,
.btn.btn-outline.purple-medium.active {
  border-color: #BF55EC;
  color: #ffffff;
  background-color: #BF55EC;
}

.btn.purple-medium.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.purple-studio:not(.btn-outline) {
  color: #ffffff;
  background-color: #8E44AD;
  border-color: #8E44AD;
}

.btn.purple-studio:not(.btn-outline):focus,
.btn.purple-studio:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #703688;
  border-color: #432051;
}

.btn.purple-studio:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #703688;
  border-color: #6a3381;
}

.btn.purple-studio:not(.btn-outline):active,
.btn.purple-studio:not(.btn-outline).active,
.open>.btn.purple-studio:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #703688;
  border-color: #6a3381;
}

.btn.purple-studio:not(.btn-outline):active:hover,
.btn.purple-studio:not(.btn-outline):active:focus,
.btn.purple-studio:not(.btn-outline):active.focus,
.btn.purple-studio:not(.btn-outline).active:hover,
.btn.purple-studio:not(.btn-outline).active:focus,
.btn.purple-studio:not(.btn-outline).active.focus,
.open>.btn.purple-studio:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.purple-studio:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.purple-studio:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #5b2c6f;
  border-color: #432051;
}

.btn.purple-studio:not(.btn-outline):active,
.btn.purple-studio:not(.btn-outline).active,
.open>.btn.purple-studio:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.purple-studio:not(.btn-outline).disabled:hover,
.btn.purple-studio:not(.btn-outline).disabled:focus,
.btn.purple-studio:not(.btn-outline).disabled.focus,
.btn.purple-studio:not(.btn-outline)[disabled]:hover,
.btn.purple-studio:not(.btn-outline)[disabled]:focus,
.btn.purple-studio:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.purple-studio:not(.btn-outline):hover,
fieldset[disabled] .btn.purple-studio:not(.btn-outline):focus,
fieldset[disabled] .btn.purple-studio:not(.btn-outline).focus {
  background-color: #8E44AD;
  border-color: #8E44AD;
}

.btn.purple-studio:not(.btn-outline) .badge {
  color: #8E44AD;
  background-color: #ffffff;
}

.btn.btn-outline.purple-studio {
  border-color: #8E44AD;
  color: #8E44AD;
  background: none;
}

.btn.btn-outline.purple-studio:hover,
.btn.btn-outline.purple-studio:active,
.btn.btn-outline.purple-studio:active:hover,
.btn.btn-outline.purple-studio:active:focus,
.btn.btn-outline.purple-studio:focus,
.btn.btn-outline.purple-studio.active {
  border-color: #8E44AD;
  color: #ffffff;
  background-color: #8E44AD;
}

.btn.purple-studio.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.purple-sharp:not(.btn-outline) {
  color: #ffffff;
  background-color: #796799;
  border-color: #796799;
}

.btn.purple-sharp:not(.btn-outline):focus,
.btn.purple-sharp:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #61527b;
  border-color: #3d344d;
}

.btn.purple-sharp:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #61527b;
  border-color: #5c4e75;
}

.btn.purple-sharp:not(.btn-outline):active,
.btn.purple-sharp:not(.btn-outline).active,
.open>.btn.purple-sharp:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #61527b;
  border-color: #5c4e75;
}

.btn.purple-sharp:not(.btn-outline):active:hover,
.btn.purple-sharp:not(.btn-outline):active:focus,
.btn.purple-sharp:not(.btn-outline):active.focus,
.btn.purple-sharp:not(.btn-outline).active:hover,
.btn.purple-sharp:not(.btn-outline).active:focus,
.btn.purple-sharp:not(.btn-outline).active.focus,
.open>.btn.purple-sharp:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.purple-sharp:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.purple-sharp:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #504465;
  border-color: #3d344d;
}

.btn.purple-sharp:not(.btn-outline):active,
.btn.purple-sharp:not(.btn-outline).active,
.open>.btn.purple-sharp:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.purple-sharp:not(.btn-outline).disabled:hover,
.btn.purple-sharp:not(.btn-outline).disabled:focus,
.btn.purple-sharp:not(.btn-outline).disabled.focus,
.btn.purple-sharp:not(.btn-outline)[disabled]:hover,
.btn.purple-sharp:not(.btn-outline)[disabled]:focus,
.btn.purple-sharp:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.purple-sharp:not(.btn-outline):hover,
fieldset[disabled] .btn.purple-sharp:not(.btn-outline):focus,
fieldset[disabled] .btn.purple-sharp:not(.btn-outline).focus {
  background-color: #796799;
  border-color: #796799;
}

.btn.purple-sharp:not(.btn-outline) .badge {
  color: #796799;
  background-color: #ffffff;
}

.btn.btn-outline.purple-sharp {
  border-color: #796799;
  color: #796799;
  background: none;
}

.btn.btn-outline.purple-sharp:hover,
.btn.btn-outline.purple-sharp:active,
.btn.btn-outline.purple-sharp:active:hover,
.btn.btn-outline.purple-sharp:active:focus,
.btn.btn-outline.purple-sharp:focus,
.btn.btn-outline.purple-sharp.active {
  border-color: #796799;
  color: #ffffff;
  background-color: #796799;
}

.btn.purple-sharp.btn-no-border:not(.active) {
  border-color: transparent;
}

.btn.purple-soft:not(.btn-outline) {
  color: #ffffff;
  background-color: #8877a9;
  border-color: #8877a9;
}

.btn.purple-soft:not(.btn-outline):focus,
.btn.purple-soft:not(.btn-outline).focus {
  color: #ffffff;
  background-color: #6e5c91;
  border-color: #4a3e62;
}

.btn.purple-soft:not(.btn-outline):hover {
  color: #ffffff;
  background-color: #6e5c91;
  border-color: #69588b;
}

.btn.purple-soft:not(.btn-outline):active,
.btn.purple-soft:not(.btn-outline).active,
.open>.btn.purple-soft:not(.btn-outline).dropdown-toggle {
  color: #ffffff;
  background-color: #6e5c91;
  border-color: #69588b;
}

.btn.purple-soft:not(.btn-outline):active:hover,
.btn.purple-soft:not(.btn-outline):active:focus,
.btn.purple-soft:not(.btn-outline):active.focus,
.btn.purple-soft:not(.btn-outline).active:hover,
.btn.purple-soft:not(.btn-outline).active:focus,
.btn.purple-soft:not(.btn-outline).active.focus,
.open>.btn.purple-soft:not(.btn-outline).dropdown-toggle:hover,
.open>.btn.purple-soft:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.purple-soft:not(.btn-outline).dropdown-toggle.focus {
  color: #ffffff;
  background-color: #5d4e7b;
  border-color: #4a3e62;
}

.btn.purple-soft:not(.btn-outline):active,
.btn.purple-soft:not(.btn-outline).active,
.open>.btn.purple-soft:not(.btn-outline).dropdown-toggle {
  background-image: none;
}

.btn.purple-soft:not(.btn-outline).disabled:hover,
.btn.purple-soft:not(.btn-outline).disabled:focus,
.btn.purple-soft:not(.btn-outline).disabled.focus,
.btn.purple-soft:not(.btn-outline)[disabled]:hover,
.btn.purple-soft:not(.btn-outline)[disabled]:focus,
.btn.purple-soft:not(.btn-outline)[disabled].focus,
fieldset[disabled] .btn.purple-soft:not(.btn-outline):hover,
fieldset[disabled] .btn.purple-soft:not(.btn-outline):focus,
fieldset[disabled] .btn.purple-soft:not(.btn-outline).focus {
  background-color: #8877a9;
  border-color: #8877a9;
}

.btn.purple-soft:not(.btn-outline) .badge {
  color: #8877a9;
  background-color: #ffffff;
}

.btn.btn-outline.purple-soft {
  border-color: #8877a9;
  color: #8877a9;
  background: none;
}

.btn.btn-outline.purple-soft:hover,
.btn.btn-outline.purple-soft:active,
.btn.btn-outline.purple-soft:active:hover,
.btn.btn-outline.purple-soft:active:focus,
.btn.btn-outline.purple-soft:focus,
.btn.btn-outline.purple-soft.active {
  border-color: #8877a9;
  color: #ffffff;
  background-color: #8877a9;
}

.btn.purple-soft.btn-no-border:not(.active) {
  border-color: transparent;
}

/* Circle Buttons */

.btn-circle {
  border-radius: 25px !important;
  overflow: hidden;
}

.btn-circle-right {
  border-radius: 0 25px 25px 0 !important;
}

.btn-circle-left {
  border-radius: 25px 0 0 25px !important;
}

.btn-circle-bottom {
  border-radius: 0 0 25px 25px !important;
}

.btn-circle-top {
  border-radius: 25px 25px 0 0 !important;
}

.btn-icon-only {
  height: 34px;
  width: 34px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.btn-icon-only>[class^="icon-"],
.btn-icon-only>i {
  text-align: center;
  margin-top: 2px;
}

.btn-group.btn-group-circle>.btn:first-child {
  border-radius: 25px 0 0 25px !important;
}

.btn-group.btn-group-circle>.btn:last-child {
  border-radius: 0 25px 25px 0 !important;
}

.btn-group-vertical.btn-group-vertical-circle>.btn:first-child {
  border-radius: 25px 25px 0 0 !important;
}

.btn-group-vertical.btn-group-vertical-circle>.btn:last-child {
  border-radius: 0 0 25px 25px !important;
}

/***
Image Carousel
***/

/***
Charts and statistics
***/

.chart,
.bars {
  overflow: hidden;
  height: 300px;
  width: 100%;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px;
}

/***
Statistic lists
***/

.item-list.table .percent {
  width: 30px;
  float: right;
  margin-right: 10px;
  margin-top: 3px;
}

/***
Chart tooltips
***/

.chart-tooltip {
  clear: both;
  z-index: 100;
  background-color: #736e6e !important;
  padding: 5px !important;
  color: #fff;
}

.chart-tooltip .label {
  clear: both;
  display: block;
  margin-bottom: 2px;
}

/***
Mini chart containers
***/

.bar-chart,
.line-chart {
  display: none;
}

/***
Chats
***/

.chats {
  margin: 0;
  padding: 0;
  margin-top: -15px;
}

.chats li {
  list-style: none;
  padding: 5px 0;
  margin: 10px auto;
  font-size: 12px;
}

.chats li .body {
  display: block;
}

.chats li .name {
  color: #3590c1;
  font-size: 13px;
  font-weight: 400;
}

.chats li .datetime {
  color: #333;
  font-size: 13px;
  font-weight: 400;
}

.chats li .message {
  display: block;
  padding: 5px;
  position: relative;
}

.chats li.in .message {
  text-align: left;
  border-left: 2px solid #1BBC9B;
  margin-left: 65px;
  background: #fafafa;
}

.chats li.in .message .arrow {
  display: block;
  position: absolute;
  top: 5px;
  left: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #1BBC9B;
}

.chats li.out .message {
  border-right: 2px solid #F3565D;
  margin-right: 65px;
  background: #fafafa;
  text-align: right;
}

.chats li.out .message .arrow {
  display: block;
  position: absolute;
  top: 5px;
  right: -8px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #F3565D;
}

.chats li.out .name,
.chats li.out .datetime {
  text-align: right;
}

.chat-form {
  margin-top: 15px;
  padding: 10px;
  background-color: #e9eff3;
  overflow: hidden;
  clear: both;
}

/***
Customized Bootstrap Close Icon 
***/

.close {
  display: inline-block;
  margin-top: 0;
  margin-right: 0;
  width: 9px;
  height: 9px;
  background-repeat: no-repeat !important;
  text-indent: -10000px;
  outline: none;
}

/***
Customized Bootstrap Dropdowns
***/

/***
Dropdowns
***/

.dropdown.open>.dropdown-toggle {
  border-color: #ddd;
}

/***
Dropdown Menu
***/

/*rtl:ignore*/

.dropdown-menu {
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
}

.dropdown-menu {
  left: 0;
  min-width: 175px;
  position: absolute;
  z-index: 1000;
  display: none;
  float: left;
  list-style: none;
  text-shadow: none;
  padding: 0px;
  background-color: #ffffff;
  margin: 10px 0px 0px 0px;
  border: 1px solid #eee;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.dropdown-menu li.header {
  padding: 8px 14px 2px 14px;
}

.dropdown-menu>li>a {
  padding: 8px 16px;
  color: #6f6f6f;
  text-decoration: none;
  display: block;
  clear: both;
  font-weight: 300;
  line-height: 18px;
  white-space: nowrap;
}

.dropdown-menu>li>a>[class^="fa-"],
.dropdown-menu>li>a>[class*=" fa-"] {
  color: #888;
}

.dropdown-menu>li>a>[class^="icon-"],
.dropdown-menu>li>a>[class*=" icon-"] {
  color: #666;
}

.dropdown-menu>li>a>[class^="glyphicon-"],
.dropdown-menu>li>a>[class*=" glyphicon-"] {
  color: #888;
}

.dropdown-menu>li:hover>a,
.dropdown-menu>li.active>a,
.dropdown-menu>li.active:hover>a {
  text-decoration: none;
  background-image: none;
  background-color: #f6f6f6;
  color: #555;
  filter: none;
}

.dropdown-menu.bottom-up {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}

.dropdown-menu.bottom-up:before,
.dropdown-menu.bottom-up:after {
  display: none !important;
}

.dropdown-menu>li:first-child:hover>a {
  border-radius: 4px 4px 0px 0px;
}

.dropdown-menu>li:last-child:hover>a {
  border-radius: 0px 0px 4px 4px;
}

.dropdown-hover:hover>.dropdown-menu {
  display: block;
}

.dropdown>.dropdown-menu,
.dropdown-toggle>.dropdown-menu,
.btn-group>.dropdown-menu {
  margin-top: 10px;
}

.dropdown>.dropdown-menu:before,
.dropdown-toggle>.dropdown-menu:before,
.btn-group>.dropdown-menu:before {
  position: absolute;
  top: -8px;
  left: 9px;
  right: auto;
  display: inline-block !important;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #e0e0e0;
  border-left: 8px solid transparent;
  content: '';
}

.dropdown>.dropdown-menu:after,
.dropdown-toggle>.dropdown-menu:after,
.btn-group>.dropdown-menu:after {
  position: absolute;
  top: -7px;
  left: 10px;
  right: auto;
  display: inline-block !important;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  content: '';
}

.dropdown>.dropdown-menu.pull-left:before,
.dropdown-toggle>.dropdown-menu.pull-left:before,
.btn-group>.dropdown-menu.pull-left:before {
  left: auto;
  right: 9px;
}

.dropdown>.dropdown-menu.pull-left:after,
.dropdown-toggle>.dropdown-menu.pull-left:after,
.btn-group>.dropdown-menu.pull-left:after {
  left: auto;
  right: 10px;
}

.dropdown>.dropdown-menu.pull-right:before,
.dropdown-toggle>.dropdown-menu.pull-right:before,
.btn-group>.dropdown-menu.pull-right:before {
  left: auto;
  right: 9px;
}

.dropdown>.dropdown-menu.pull-right:after,
.dropdown-toggle>.dropdown-menu.pull-right:after,
.btn-group>.dropdown-menu.pull-right:after {
  left: auto;
  right: 10px;
}

.dropdown-toggle:after {
  display: none;
}

/* Dropdown submenu support for Bootsrap 3 */

.nav.pull-right>li>.dropdown-menu,
.nav>li>.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}

.nav.pull-right>li>.dropdown-menu:before,
.nav>li>.dropdown-menu.pull-right:before {
  right: 12px;
  left: auto;
}

.nav.pull-right>li>.dropdown-menu:after,
.nav>li>.dropdown-menu.pull-right:after {
  right: 13px;
  left: auto;
}

.nav.pull-right>li>.dropdown-menu .dropdown-menu,
.nav>li>.dropdown-menu.pull-right .dropdown-menu {
  right: 100%;
  left: auto;
  margin-right: -1px;
  margin-left: 0;
}

@media (max-width: 767px) {

  /* 767px */
  .navbar-nav .open .dropdown-menu {
    position: absolute;
    float: left;
    width: auto;
    margin-top: 0;
    background-color: #ffffff;
    border: 1px solid #efefef;
    box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  }

  .navbar-nav .open .dropdown-menu>li>a {
    padding: 6px 0 6px 13px;
    color: #333;
  }

  .navbar-nav .open .dropdown-menu>li>a:hover,
  .navbar-nav .open .dropdown-menu>li>a:active {
    background-color: #eee;
  }
}

/***
Dropdown Checkboxes
***/

.dropdown-content {
  padding: 10px;
}

.dropdown-content form {
  margin: 0;
}

.dropdown.inline .dropdown-menu {
  display: inline-block;
  position: relative;
}

.dropdown-checkboxes {
  padding: 5px;
}

.dropdown-checkboxes label {
  display: block;
  font-weight: 300;
  color: #333;
  margin-bottom: 4px;
  margin-top: 4px;
}

.dropdown-checkboxes label .radio {
  margin-right: 3px;
}

/*--------------------------------------------------
    [Dropdown Menu v2]
----------------------------------------------------*/

.dropdown-menu-v2 {
  top: 100%;
  right: 0;
  z-index: 1001;
  display: none;
  float: left;
  min-width: 225px;
  padding: 20px 0;
  margin: 25px 0 0;
  font-size: 13px;
  text-align: left;
  list-style: none;
  position: absolute;
  background: #242b31;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.dropdown-menu-v2:before {
  top: -10px;
  right: 0;
  width: 0;
  height: 0;
  content: ' ';
  position: absolute;
  border-style: solid;
  display: inline-block;
  border-width: 0 0 15px 15px;
  border-color: transparent transparent #242b31 transparent;
}

.dropdown-menu-v2>li>a {
  clear: both;
  color: #606e7a;
  display: block;
  font-weight: 600;
  padding: 8px 20px;
  position: relative;
  white-space: nowrap;
  line-height: 1.42857143;
  text-transform: uppercase;
}

.dropdown-menu-v2>li>a:hover,
.dropdown-menu-v2>li>a:focus {
  background: #21282e;
  text-decoration: none;
  color: #009dc7;
}

.dropdown-menu-v2 .active>a,
.dropdown-menu-v2 .active>a:hover,
.dropdown-menu-v2 .active>a:focus,
.dropdown-menu-v2 .active>a,
.dropdown-menu-v2 .active>a:hover,
.dropdown-menu-v2 .active>a:focus,
.dropdown-menu-v2 .active>a,
.dropdown-menu-v2 .active>a:hover,
.dropdown-menu-v2 .active>a:focus {
  outline: 0;
  text-decoration: none;
  background-color: #21282e;
  color: #009dc7;
}

.dropdown-menu-v2 .disabled>a,
.dropdown-menu-v2 .disabled>a:hover,
.dropdown-menu-v2 .disabled>a:focus,
.dropdown-menu-v2 .disabled>a,
.dropdown-menu-v2 .disabled>a:hover,
.dropdown-menu-v2 .disabled>a:focus,
.dropdown-menu-v2 .disabled>a,
.dropdown-menu-v2 .disabled>a:hover,
.dropdown-menu-v2 .disabled>a:focus {
  color: #009dc7;
}

.dropdown-menu-v2 .disabled>a:hover,
.dropdown-menu-v2 .disabled>a:focus,
.dropdown-menu-v2 .disabled>a:hover,
.dropdown-menu-v2 .disabled>a:focus {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
}

.dropdown-menu-v2 .badge {
  top: 8px;
  right: 10px;
  position: absolute;
}

.dropdown-menu-v2.pull-right {
  right: 0;
  left: auto;
}

.open>.dropdown-menu-v2 {
  display: block;
}

.pull-right>.dropdown-menu-v2 {
  right: 0;
  left: auto;
}

/***
System feeds
***/

/***
Form Layouts
****/

/* Static info */

.static-info {
  margin-bottom: 10px;
}

.static-info .name {
  font-size: 14px;
}

.static-info .value {
  font-size: 14px;
  font-weight: 600;
}

.static-info.align-reverse .name,
.static-info.align-reverse .value {
  text-align: right;
}

/* Help blocks */

.help-block {
  margin-top: 5px;
  margin-bottom: 5px;
}

.help-inline {
  font-size: 13px;
  color: #737373;
  display: inline-block;
  padding: 5px;
}

.form-inline input {
  margin-bottom: 0px !important;
}

/* Control Label */

.control-label {
  margin-top: 1px;
  font-weight: normal;
}

.control-label .required,
.form-group .required {
  color: #e02222;
  font-size: 12px;
  padding-left: 2px;
}

.form {
  padding: 0 !important;
}

.portlet-form .form-body,
.form .form-body {
  padding: 20px;
}

.portlet.light .portlet-form .form-body,
.portlet.light .form .form-body {
  padding-left: 0;
  padding-right: 0;
}

.portlet-form .form-actions,
.form .form-actions {
  padding: 20px 20px;
  margin: 0;
  background-color: #f5f5f5;
  border-top: 1px solid #e7ecf1;
  *zoom: 1;
}

.portlet.light .portlet-form .form-actions,
.portlet.light .form .form-actions {
  background: none;
  padding-left: 0;
  padding-right: 0;
}

.portlet-form .form-actions.noborder,
.form .form-actions.noborder {
  border-top: 0;
}

.portlet .portlet-form .form-actions,
.portlet .form .form-actions {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  -o-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.portlet-form .form-actions:before,
.portlet-form .form-actions:after,
.form .form-actions:before,
.form .form-actions:after {
  content: " ";
  display: table;
}

.portlet-form .form-actions:after,
.form .form-actions:after {
  clear: both;
}

.portlet-form .form-actions.right,
.form .form-actions.right {
  padding-left: 0;
  padding-right: 20px;
  text-align: right;
}

.portlet.light .portlet-form .form-actions.right,
.portlet.light .form .form-actions.right {
  padding-right: 0;
}

.portlet-form .form-actions.left,
.form .form-actions.left {
  padding-left: 20px;
  padding-right: 0;
  text-align: left;
}

.portlet.light .portlet-form .form-actions.left,
.portlet.light .form .form-actions.left {
  padding-left: 0;
}

.portlet-form .form-actions.top,
.form .form-actions.top {
  margin-top: 0;
  margin-bottom: 20px;
  border-top: 0;
  border-bottom: 1px solid #e7ecf1;
}

.portlet.light .portlet-form .form-actions.top,
.portlet.light .form .form-actions.top {
  background: none;
}

.portlet-form .form-actions .btn-set,
.form .form-actions .btn-set {
  display: inline-block;
}

@media (max-width: 767px) {

  .portlet-form .form-actions,
  .form .form-actions {
    /* 767px */
  }

  .portlet-form .form-actions .btn-set,
  .form .form-actions .btn-set {
    margin-bottom: 3px;
    margin-top: 3px;
    float: left !important;
  }
}

.portlet-form .form-section,
.form .form-section {
  margin: 30px 0px 30px 0px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e7ecf1;
}

.form-fit .portlet-form .form-section,
.form-fit .form .form-section {
  margin-left: 20px;
  margin-right: 20px;
}

/* Checkboxes */

.checkbox,
.form-horizontal .checkbox {
  padding: 0;
}

.checkbox>label,
.form-horizontal .checkbox>label {
  padding-left: 0;
}

.checkbox-list>label {
  display: block;
}

.checkbox-list>label.checkbox-inline {
  display: inline-block;
}

.checkbox-list>label.checkbox-inline:first-child {
  padding-left: 0;
}

/* Radio buttons */

.radio-list>label {
  display: block;
}

.radio-list>label.radio-inline {
  display: inline-block;
}

.radio-list>label.radio-inline:first-child {
  padding-left: 0;
}

/* Radio buttons in horizontal forms */

.form-horizontal .radio-list .radio {
  padding-top: 1px;
}

.form-horizontal .radio-list>label {
  margin-bottom: 0;
}

.form-horizontal .radio>span {
  margin-top: 2px;
}

/* Rows seperated form layout */

/* Form bordered */

.form .form-bordered .form-group {
  margin: 0;
  border-bottom: 1px solid #efefef;
}

.form .form-bordered .form-group>div {
  padding: 15px;
  border-left: 1px solid #efefef;
}

@media (max-width: 991px) {
  .form .form-bordered .form-group>div {
    /* 991px */
    border-left: 0;
  }
}

.form .form-bordered .form-group.last {
  border-bottom: 0;
}

.form .form-bordered .form-group .control-label {
  padding-top: 20px;
}

@media (max-width: 991px) {
  .form .form-bordered .form-group .control-label {
    /* 991px */
    padding-top: 10px;
  }
}

.form .form-bordered .form-group .help-block {
  margin-bottom: 0;
}

.form .form-bordered .form-group .form-control {
  margin: 0;
}

.form .form-bordered .form-body {
  margin: 0;
  padding: 0;
}

.form .form-bordered .form-actions {
  margin-top: 0;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

@media (max-width: 991px) {
  .form .form-bordered .form-actions {
    /* 991px */
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

/* Horizontal bordered form */

.form .form-horizontal.form-bordered.form-row-stripped .form-group:nth-child(even) {
  background-color: #fcfcfc;
}

.form .form-horizontal.form-bordered.form-row-stripped .form-control {
  background: #fff !important;
}

.form .form-horizontal.form-bordered.form-label-stripped .form-group:nth-child(even) {
  background-color: #fcfcfc;
}

.form .form-horizontal.form-bordered.form-label-stripped .form-group:nth-child(even)>div {
  background-color: #ffffff;
}

.form .form-horizontal.form-bordered.form-label-stripped .form-group:nth-child(even) {
  background-color: #fcfcfc;
}

/* Horizontal form small input issue */

.form-horizontal .form-group .input-sm {
  margin-top: 3px;
}

.form-horizontal .form-group .form-control-static {
  margin-top: 1px;
}

/***
Form Repeater
****/

/***
Forms
***/

.form-control {
  outline: none !important;
  box-shadow: none !important;
}

.form-control.height-auto {
  height: auto;
}

/***
Custom color buttons 
***/

.form-control.white {
  border-color: #ffffff;
}

.form-control.white:focus {
  border-color: #e6e6e6;
}

.form-control.focus-white:focus {
  border-color: #ffffff;
}

.form-control.default {
  border-color: #e1e5ec;
}

.form-control.default:focus {
  border-color: #c2cad8;
}

.form-control.focus-default:focus {
  border-color: #e1e5ec;
}

.form-control.dark {
  border-color: #2f353b;
}

.form-control.dark:focus {
  border-color: #181c1f;
}

.form-control.focus-dark:focus {
  border-color: #2f353b;
}

.form-control.blue {
  border-color: #3598dc;
}

.form-control.blue:focus {
  border-color: #217ebd;
}

.form-control.focus-blue:focus {
  border-color: #3598dc;
}

.form-control.blue-steel {
  border-color: #4B77BE;
}

.form-control.blue-steel:focus {
  border-color: #395f9d;
}

.form-control.focus-blue-steel:focus {
  border-color: #4B77BE;
}

.form-control.blue-soft {
  border-color: #4c87b9;
}

.form-control.blue-soft:focus {
  border-color: #3b6d97;
}

.form-control.focus-blue-soft:focus {
  border-color: #4c87b9;
}

.form-control.blue-dark {
  border-color: #5e738b;
}

.form-control.blue-dark:focus {
  border-color: #495a6d;
}

.form-control.focus-blue-dark:focus {
  border-color: #5e738b;
}

.form-control.blue-sharp {
  border-color: #5C9BD1;
}

.form-control.blue-sharp:focus {
  border-color: #3782c3;
}

.form-control.focus-blue-sharp:focus {
  border-color: #5C9BD1;
}

.form-control.green {
  border-color: #C3073F;
}

.form-control.green:focus {
  border-color: #26a1ab;
}

.form-control.focus-green:focus {
  border-color: #C3073F;
}

.form-control.green-seagreen {
  border-color: #1BA39C;
}

.form-control.green-seagreen:focus {
  border-color: #147772;
}

.form-control.focus-green-seagreen:focus {
  border-color: #1BA39C;
}

.form-control.green-turquoise {
  border-color: #36D7B7;
}

.form-control.green-turquoise:focus {
  border-color: #24b699;
}

.form-control.focus-green-turquoise:focus {
  border-color: #36D7B7;
}

.form-control.green-haze {
  border-color: #44b6ae;
}

.form-control.green-haze:focus {
  border-color: #36918b;
}

.form-control.focus-green-haze:focus {
  border-color: #44b6ae;
}

.form-control.green-jungle {
  border-color: #26C281;
}

.form-control.green-jungle:focus {
  border-color: #1e9765;
}

.form-control.focus-green-jungle:focus {
  border-color: #26C281;
}

.form-control.green-soft {
  border-color: #3faba4;
}

.form-control.green-soft:focus {
  border-color: #318680;
}

.form-control.focus-green-soft:focus {
  border-color: #3faba4;
}

.form-control.green-dark {
  border-color: #4DB3A2;
}

.form-control.green-dark:focus {
  border-color: #3d9082;
}

.form-control.focus-green-dark:focus {
  border-color: #4DB3A2;
}

.form-control.green-sharp {
  border-color: #3562d9;
}

.form-control.green-sharp:focus {
  border-color: #218d96;
}

.form-control.focus-green-sharp:focus {
  border-color: #3562d9;
}

.form-control.green-steel {
  border-color: #cf8900;
}

.form-control.green-steel:focus {
  border-color: #208b8c;
}

.form-control.focus-green-steel:focus {
  border-color: #cf8900;
}

.form-control.red {
  border-color: #e7505a;
}

.form-control.red:focus {
  border-color: #e12330;
}

.form-control.focus-red:focus {
  border-color: #e7505a;
}

.form-control.red-pink {
  border-color: #E08283;
}

.form-control.red-pink:focus {
  border-color: #d6595a;
}

.form-control.focus-red-pink:focus {
  border-color: #E08283;
}

.form-control.red-soft {
  border-color: #d05454;
}

.form-control.red-soft:focus {
  border-color: #bd3434;
}

.form-control.focus-red-soft:focus {
  border-color: #d05454;
}

.form-control.red-haze {
  border-color: #f36a5a;
}

.form-control.red-haze:focus {
  border-color: #f03f2a;
}

.form-control.focus-red-haze:focus {
  border-color: #f36a5a;
}

.form-control.yellow {
  border-color: #c49f47;
}

.form-control.yellow:focus {
  border-color: #a48334;
}

.form-control.focus-yellow:focus {
  border-color: #c49f47;
}

.form-control.yellow-gold {
  border-color: #E87E04;
}

.form-control.yellow-gold:focus {
  border-color: #b66303;
}

.form-control.focus-yellow-gold:focus {
  border-color: #E87E04;
}

.form-control.yellow-soft {
  border-color: #c8d046;
}

.form-control.yellow-soft:focus {
  border-color: #adb52e;
}

.form-control.focus-yellow-soft:focus {
  border-color: #c8d046;
}

.form-control.yellow-haze {
  border-color: #c5bf66;
}

.form-control.yellow-haze:focus {
  border-color: #b4ad44;
}

.form-control.focus-yellow-haze:focus {
  border-color: #c5bf66;
}

.form-control.purple {
  border-color: #8E44AD;
}

.form-control.purple:focus {
  border-color: #703688;
}

.form-control.focus-purple:focus {
  border-color: #8E44AD;
}

.form-control.purple-plum {
  border-color: #8775a7;
}

.form-control.purple-plum:focus {
  border-color: #6d5b8e;
}

.form-control.focus-purple-plum:focus {
  border-color: #8775a7;
}

.form-control.purple-medium {
  border-color: #BF55EC;
}

.form-control.purple-medium:focus {
  border-color: #ae27e7;
}

.form-control.focus-purple-medium:focus {
  border-color: #BF55EC;
}

.form-control.purple-studio {
  border-color: #8E44AD;
}

.form-control.purple-studio:focus {
  border-color: #703688;
}

.form-control.focus-purple-studio:focus {
  border-color: #8E44AD;
}

.form-control.purple-sharp {
  border-color: #796799;
}

.form-control.purple-sharp:focus {
  border-color: #61527b;
}

.form-control.focus-purple-sharp:focus {
  border-color: #796799;
}

.form-control.purple-soft {
  border-color: #8877a9;
}

.form-control.purple-soft:focus {
  border-color: #6e5c91;
}

.form-control.focus-purple-soft:focus {
  border-color: #8877a9;
}

.input-xxs {
  width: 45px !important;
}

/* Form uneditable input */

.form-control-static {
  display: inline-block;
  margin: 0;
}

/* Form input sizing */

.input-mini {
  width: 45px !important;
}

.input-small {
  width: 145px !important;
}

.input-medium {
  width: 240px !important;
}

.input-large {
  width: 320px !important;
}

@media (max-width: 768px) {

  /* 768px */
  .input-large {
    width: 250px !important;
  }
}

.input-inline {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.input-fixed {
  overflow: hidden;
}

.input-fixed {
  overflow: hidden;
}

.form-group .input-inline {
  margin-right: 5px;
}

@media (max-width: 768px) {

  /* 768px */
  .input-lg {
    width: 180px !important;
  }
}

/* Circle Inputs */

.input-circle {
  border-radius: 25px !important;
}

.input-circle-right {
  border-radius: 0 25px 25px 0 !important;
}

.input-circle-left {
  border-radius: 25px 0 0 25px !important;
}

.input-circle-bottom {
  border-radius: 0 0 25px 25px !important;
}

.input-circle-top {
  border-radius: 25px 25px 0 0 !important;
}

/***
Custom icon buttons
***/

.icon-btn {
  height: 60px;
  min-width: 80px;
  margin: 5px 5px 0 0;
  border: 1px solid #ddd;
  padding: 12px 0px 0px 0px;
  background-color: #fafafa;
  background-image: none;
  filter: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: inline-block;
  color: #646464;
  text-shadow: none;
  text-align: center;
  cursor: pointer;
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.icon-btn:hover {
  text-decoration: none;
  border-color: #999;
  color: #444;
  text-shadow: 0 1px 0px white;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.icon-btn:hover>.badge {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.icon-btn>div {
  margin-top: 5px;
  margin-bottom: 20px;
  color: #3f444a;
  font-size: 12px;
  font-weight: 300;
}

.icon-btn>.badge {
  position: absolute;
  font-size: 11px;
  font-weight: 300;
  top: -5px;
  right: -5px;
  padding: 3px 6px 3px 6px;
  color: white;
  text-shadow: none;
  border-width: 0;
  border-style: solid;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.icon-btn>i {
  font-size: 18px;
}

.ie8 .icon-btn:hover {
  filter: none;
}

/***
Input icons
***/

.input-icon {
  position: relative;
  left: 0;
}

.input-icon>.form-control {
  padding-left: 33px;
}

.input-group .input-icon>.form-control {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -ms-border-radius: 4px 0 0 4px;
  -o-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

.input-icon>i {
  color: #ccc;
  display: block;
  position: absolute;
  margin: 11px 2px 4px 10px;
  z-index: 3;
  width: 16px;
  font-size: 16px;
  text-align: center;
  left: 0;
}

.modal .input-icon>i {
  z-index: 10055;
}

.has-success .input-icon>i {
  color: #36c6d3;
}

.has-warning .input-icon>i {
  color: #F1C40F;
}

.has-info .input-icon>i {
  color: #659be0;
}

.has-error .input-icon>i {
  color: #ed6b75;
}

.input-icon.right {
  left: auto;
  right: 0;
}

.input-icon.right>.form-control {
  padding-right: 33px;
  padding-left: 12px;
}

.input-group .input-icon.right>.form-control {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  -o-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

.input-icon.right>i {
  left: auto;
  right: 8px;
  float: right;
}

.input-icon.input-icon-lg>i {
  margin-top: 16px;
}

.input-icon.input-icon-sm>i {
  margin-top: 8px;
  font-size: 13px;
}

/***
Customized Bootstrap Labels
***/

.label {
  text-shadow: none !important;
  font-size: 14px;
  font-weight: 300;
  padding: 3px 6px 3px 6px;
  color: #fff;
  font-family: "Roboto", normal;
}

.label.label-sm {
  font-size: 13px;
  padding: 2px 5px 2px 5px;
}

h1 .label,
h2 .label,
h3 .label,
h4 .label,
h5 .label,
h6 .label {
  font-size: 75%;
}

/* Labels variants */

.label-default {
  background-color: #bac3d0;
}

.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #9ca8bb;
}

.label-primary {
  background-color: #337ab7;
}

.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #286090;
}

.label-success {
  background-color: #36c6d3;
}

.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #27a4b0;
}

.label-info {
  background-color: #659be0;
}

.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #3a80d7;
}

.label-warning {
  background-color: #F1C40F;
}

.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #c29d0b;
}

.label-danger {
  background-color: #ed6b75;
}

.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #e73d4a;
}

/***
Iconic labels
***/

.label.label-icon {
  padding: 4px 0px 4px 4px;
  margin-right: 2px;
  text-align: center !important;
}

.label.label-icon>i {
  font-size: 12px;
  text-align: center !important;
}

.ie8 .label.label-icon,
.ie9 .label.label-icon {
  padding: 3px 0px 3px 3px;
}

/***
Text states
***/

.text-default {
  color: #bac3d0;
}

.text-primary {
  color: #337ab7;
}

.text-success {
  color: #36c6d3;
}

.text-info {
  color: #659be0;
}

.text-warning {
  color: #F1C40F;
}

.text-danger {
  color: #ed6b75;
}

/***
Customized List Group
***/

/* Contextual variants */

.list-group>li:first-child {
  border-radius-topleft: 4px;
  border-radius-topright: 4px;
}

.list-group>li:last-child {
  border-radius-bottomleft: 4px;
  border-radius-bottomright: 4px;
}

.list-group .list-group-item-success {
  color: #27a4b0;
  background-color: #abe7ed;
}

.list-group a.list-group-item-success,
.list-group button.list-group-item-success {
  color: #27a4b0;
}

.list-group a.list-group-item-success .list-group-item-heading,
.list-group button.list-group-item-success .list-group-item-heading {
  color: inherit;
}

.list-group a.list-group-item-success:hover,
.list-group a.list-group-item-success:focus,
.list-group button.list-group-item-success:hover,
.list-group button.list-group-item-success:focus {
  color: #27a4b0;
  background-color: #96e1e8;
}

.list-group a.list-group-item-success.active,
.list-group a.list-group-item-success.active:hover,
.list-group a.list-group-item-success.active:focus,
.list-group button.list-group-item-success.active,
.list-group button.list-group-item-success.active:hover,
.list-group button.list-group-item-success.active:focus {
  color: #fff;
  background-color: #27a4b0;
  border-color: #27a4b0;
}

.list-group .list-group-item-info {
  color: #327ad5;
  background-color: #e0ebf9;
}

.list-group a.list-group-item-info,
.list-group button.list-group-item-info {
  color: #327ad5;
}

.list-group a.list-group-item-info .list-group-item-heading,
.list-group button.list-group-item-info .list-group-item-heading {
  color: inherit;
}

.list-group a.list-group-item-info:hover,
.list-group a.list-group-item-info:focus,
.list-group button.list-group-item-info:hover,
.list-group button.list-group-item-info:focus {
  color: #327ad5;
  background-color: #caddf4;
}

.list-group a.list-group-item-info.active,
.list-group a.list-group-item-info.active:hover,
.list-group a.list-group-item-info.active:focus,
.list-group button.list-group-item-info.active,
.list-group button.list-group-item-info.active:hover,
.list-group button.list-group-item-info.active:focus {
  color: #fff;
  background-color: #327ad5;
  border-color: #327ad5;
}

.list-group .list-group-item-warning {
  color: #c29d0b;
  background-color: #f9e491;
}

.list-group a.list-group-item-warning,
.list-group button.list-group-item-warning {
  color: #c29d0b;
}

.list-group a.list-group-item-warning .list-group-item-heading,
.list-group button.list-group-item-warning .list-group-item-heading {
  color: inherit;
}

.list-group a.list-group-item-warning:hover,
.list-group a.list-group-item-warning:focus,
.list-group button.list-group-item-warning:hover,
.list-group button.list-group-item-warning:focus {
  color: #c29d0b;
  background-color: #f7de79;
}

.list-group a.list-group-item-warning.active,
.list-group a.list-group-item-warning.active:hover,
.list-group a.list-group-item-warning.active:focus,
.list-group button.list-group-item-warning.active,
.list-group button.list-group-item-warning.active:hover,
.list-group button.list-group-item-warning.active:focus {
  color: #fff;
  background-color: #c29d0b;
  border-color: #c29d0b;
}

.list-group .list-group-item-danger {
  color: #e73d4a;
  background-color: #fbe1e3;
}

.list-group a.list-group-item-danger,
.list-group button.list-group-item-danger {
  color: #e73d4a;
}

.list-group a.list-group-item-danger .list-group-item-heading,
.list-group button.list-group-item-danger .list-group-item-heading {
  color: inherit;
}

.list-group a.list-group-item-danger:hover,
.list-group a.list-group-item-danger:focus,
.list-group button.list-group-item-danger:hover,
.list-group button.list-group-item-danger:focus {
  color: #e73d4a;
  background-color: #f8cace;
}

.list-group a.list-group-item-danger.active,
.list-group a.list-group-item-danger.active:hover,
.list-group a.list-group-item-danger.active:focus,
.list-group button.list-group-item-danger.active,
.list-group button.list-group-item-danger.active:hover,
.list-group button.list-group-item-danger.active:focus {
  color: #fff;
  background-color: #e73d4a;
  border-color: #e73d4a;
}

/***
UI Loading
***/

.loading-message {
  display: inline-block;
  min-width: 125px;
  margin-left: -60px;
  padding: 10px;
  margin: 0 auto;
  color: #000 !important;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
}

.loading-message>span {
  line-height: 20px;
  vertical-align: middle;
}

.page-loading {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 125px;
  margin-left: -60px;
  margin-top: -30px;
  padding: 7px;
  text-align: center;
  color: #333;
  font-size: 13px;
  border: 1px solid #ddd;
  background-color: #eee;
  vertical-align: middle;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.page-loading>span {
  line-height: 20px;
  vertical-align: middle;
}

.page-spinner-bar {
  position: fixed;
  z-index: 10051;
  width: 100px;
  top: 40%;
  left: 50%;
  margin-left: -55px;
  text-align: center;
}

.page-spinner-bar>div {
  margin: 0 5px;
  width: 18px;
  height: 18px;
  background: #eee;
  border-radius: 100% !important;
  display: inline-block;
  -webkit-animation: bounceDelay 1.4s infinite ease-in-out;
  animation: bounceDelay 1.4s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.page-spinner-bar .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.page-spinner-bar .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.block-spinner-bar {
  display: inline-block;
  width: 80px;
  text-align: center;
}

.block-spinner-bar>div {
  margin: 0 2px;
  width: 15px;
  height: 15px;
  background: #eee;
  border-radius: 100% !important;
  display: inline-block;
  -webkit-animation: bounceDelay 1.4s infinite ease-in-out;
  animation: bounceDelay 1.4s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.block-spinner-bar .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.block-spinner-bar .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

/***
Metro icons
***/

[class^="m-icon-"] {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-top: 3px;
  line-height: 14px;
  vertical-align: top;
  background-position: 0 0;
  background-repeat: no-repeat;
}

[class^="m-icon-big-"] {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 6px;
  vertical-align: middle;
  background-position: 0 0px;
  background-repeat: no-repeat;
}

/* large icons */

.btn.m-icon-big {
  padding: 9px 16px 8px 16px;
}

.btn.m-icon-big.m-icon-only {
  padding: 9px 8px 8px 0px;
}

.btn.m-icon-big [class^="m-icon-big-"] {
  margin: 0 0 0 10px;
}

/* default icons */

.btn.m-icon {
  padding: 7px 14px 7px 14px;
}

.btn.m-icon [class^="m-icon-"] {
  margin: 4px 0 0 5px;
}

.btn.m-icon.m-icon-only {
  padding: 7px 10px 7px 6px;
}

/* white icon */

/*  Misc */

/***
Customized Bootstrap Modal 
***/

.modal {
  z-index: 10050;
  outline: none;
  overflow-y: auto !important;
  /* Fix content shifting to the right on modal open due to scrollbar closed */
}

.page-portlet-fullscreen .modal {
  z-index: 10060;
}

.modal .modal-header {
  border-bottom: 1px solid #EFEFEF;
}

.modal .modal-header h3 {
  font-weight: 300;
}

.modal .modal-header .close {
  margin-top: 0px !important;
}

.modal.draggable-modal .modal-header {
  cursor: move;
}

.modal .modal-dialog {
  z-index: 10051;
}

.modal>.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
}

.modal.in .page-loading {
  display: none;
}

.modal-open {
  overflow-y: auto !important;
}

.modal-backdrop {
  border: 0;
  outline: none;
}

.page-portlet-fullscreen .modal-backdrop {
  z-index: 10059;
}

.modal-backdrop {
  background-color: #333 !important;
}

body[ng-controller] .modal-backdrop {
  z-index: 10049;
}

body[ng-controller] .modal {
  z-index: 10050;
}

/* Full width modal */

.modal-full.modal-dialog {
  width: 99%;
}

@media (max-width: 768px) {
  .modal-full.modal-dialog {
    width: auto;
  }
}

/***
Notes
***/

.note {
  margin: 0 0 0 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eee;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  -o-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

.note h1,
.note h2,
.note h3,
.note h4,
.note h5,
.note h6 {
  margin-top: 0;
}

.note h1 .close,
.note h2 .close,
.note h3 .close,
.note h4 .close,
.note h5 .close,
.note h6 .close {
  margin-right: -10px;
}

.note p {
  margin: 0;
  font-size: 13px;
}

.note p:last-child {
  margin-bottom: 0;
}

.note code,
.note .highlight {
  background-color: #fff;
}

.note.note-default {
  background-color: white;
  border-color: #b0c1d2;
  color: black;
}

.note.note-default.note-bordered {
  background-color: #eef1f5;
  border-color: #c0cedb;
}

.note.note-default.note-shadow {
  background-color: #f1f4f7;
  border-color: #d1dbe4;
  box-shadow: 5px 5px rgba(212, 221, 230, 0.2);
}

.note.note-success {
  background-color: #c0edf1;
  border-color: #58d0da;
  color: black;
}

.note.note-success.note-bordered {
  background-color: #a7e6ec;
  border-color: #6dd6df;
}

.note.note-success.note-shadow {
  background-color: #abe7ed;
  border-color: #81dbe3;
  box-shadow: 5px 5px rgba(134, 221, 228, 0.2);
}

.note.note-info {
  background-color: #f5f8fd;
  border-color: #8bb4e7;
  color: #010407;
}

.note.note-info.note-bordered {
  background-color: #dbe8f8;
  border-color: #a0c2ec;
}

.note.note-info.note-shadow {
  background-color: #e0ebf9;
  border-color: #b5cff0;
  box-shadow: 5px 5px rgba(185, 210, 241, 0.2);
}

.note.note-warning {
  background-color: #faeaa9;
  border-color: #f3cc31;
  color: black;
}

.note.note-warning.note-bordered {
  background-color: #f8e38c;
  border-color: #f4d249;
}

.note.note-warning.note-shadow {
  background-color: #f9e491;
  border-color: #f6d861;
  box-shadow: 5px 5px rgba(246, 217, 102, 0.2);
}

.note.note-danger {
  background-color: #181D26;
  border-color: #C3073F;
  color: white;
  margin: 3%;
}

.note.note-danger>p {
  color: whitesmoke;
}

.note.note-danger.note-bordered {
  background-color: #fbdcde;
  border-color: #f39da3;
}

.note.note-danger.note-shadow {
  border-color: #f6b3b8;
  box-shadow: 5px 5px rgba(246, 184, 189, 0.2);
}

/***
Customized Bootstrap Pagination
***/

.pagination {
  margin: 10px 0;
}

.pagination.pagination-circle>li:first-child>a {
  border-radius: 25px 0 0 25px !important;
}

.pagination.pagination-circle>li:last-child>a {
  border-radius: 0 25px 25px 0 !important;
}

/***
Customized Bootstrap Panels
***/

.panel {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.panel-group .panel {
  overflow: visible;
}

.panel .panel-body {
  font-size: 13px;
}

.panel .panel-title>a:hover {
  text-decoration: none;
}

/***
Accordions
***/

.panel-heading {
  background: #eee;
}

.panel-heading a {
  text-decoration: none;
}

.panel-heading a:active,
.panel-heading a:focus,
.panel-heading a:hover {
  text-decoration: none;
}

/***
Customized Bootstrap Popover
***/

/*rtl:ignore*/

/***
Portlets
***/

/* Full Screen portlet mode */

.page-portlet-fullscreen {
  overflow: hidden;
}

/* Basic portlet */

.portlet {
  margin-top: 0px;
  margin-bottom: 25px;
  padding: 0px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.portlet.portlet-fullscreen {
  z-index: 10060;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

.portlet.portlet-fullscreen>.portlet-body {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 10px;
}

.portlet.portlet-fullscreen>.portlet-title {
  padding: 0 10px;
}

.portlet>.portlet-title {
  border-bottom: 1px solid #eee;
  padding: 0;
  min-height: 41px;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  -o-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.portlet>.portlet-title:before,
.portlet>.portlet-title:after {
  content: " ";
  display: table;
}

.portlet>.portlet-title:after {
  clear: both;
}

.portlet>.portlet-title>.caption {
  float: left;
  display: inline-block;
  font-size: 15px;
  line-height: 15px;
  padding: 3px 0;
  font-weight: 400;
}

.portlet>.portlet-title>.caption.bold {
  font-weight: 400;
}

.portlet>.portlet-title>.caption>i {
  float: left;
  margin-top: 4px;
  display: inline-block;
  font-size: 13px;
  margin-right: 5px;
  color: #666;
}

.portlet>.portlet-title>.caption>.caption-helper {
  padding: 0;
  margin: 0;
  line-height: 13px;
  color: #9eacb4;
  font-size: 13px;
  font-weight: 400;
}

.portlet>.portlet-title>.actions {
  float: right;
  display: inline-block;
  padding: 6px 0;
}

.portlet>.portlet-title>.actions>.dropdown-menu i {
  color: #555555;
}

.portlet>.portlet-title>.actions>.btn,
.portlet>.portlet-title>.actions>.btn.btn-sm,
.portlet>.portlet-title>.actions>.btn-group>.btn,
.portlet>.portlet-title>.actions>.btn-group>.btn.btn-sm {
  padding: 4px 10px;
  font-size: 13px;
  line-height: 1.5;
}

.portlet>.portlet-title>.actions>.btn.btn-default,
.portlet>.portlet-title>.actions>.btn.btn-sm.btn-default,
.portlet>.portlet-title>.actions>.btn-group>.btn.btn-default,
.portlet>.portlet-title>.actions>.btn-group>.btn.btn-sm.btn-default {
  padding: 3px 9px;
}

.portlet>.portlet-title>.actions>.btn>i,
.portlet>.portlet-title>.actions>.btn.btn-sm>i,
.portlet>.portlet-title>.actions>.btn-group>.btn>i,
.portlet>.portlet-title>.actions>.btn-group>.btn.btn-sm>i {
  font-size: 13px;
}

.portlet>.portlet-title>.actions .btn-icon-only {
  padding: 5px 7px 3px 7px;
}

.portlet>.portlet-title>.actions .btn-icon-only.btn-default {
  padding: 4px 6px 2px 6px;
}

.portlet>.portlet-title>.actions .btn-icon-only.btn-default>i {
  font-size: 14px;
}

.portlet>.portlet-title>.actions .btn-icon-only.btn-default.fullscreen {
  font-family: FontAwesome;
  color: #a0a0a0;
  padding-top: 3px;
}

.portlet>.portlet-title>.actions .btn-icon-only.btn-default.fullscreen.btn-sm {
  padding: 3px 3px !important;
  height: 27px;
  width: 27px;
}

.portlet>.portlet-title>.actions .btn-icon-only.btn-default.fullscreen:before {
  content: "\f065";
}

.portlet>.portlet-title>.actions .btn-icon-only.btn-default.fullscreen.on:before {
  content: "\f066";
}

.portlet>.portlet-title>.tools {
  float: right;
  display: inline-block;
  padding: 12px 0 8px 0;
}

.portlet>.portlet-title>.tools>a {
  display: inline-block;
  height: 16px;
  margin-left: 5px;
  opacity: 1;
  filter: alpha(opacity=100);
}

.portlet>.portlet-title>.tools>a.remove {
  background-repeat: no-repeat;
  width: 11px;
}

.portlet>.portlet-title>.tools>a.config {
  background-repeat: no-repeat;
  width: 12px;
}

.portlet>.portlet-title>.tools>a.reload {
  width: 13px;
}

.portlet>.portlet-title>.tools>a.expand {
  width: 14px;
  visibility: visible;
}

.portlet>.portlet-title>.tools>a.collapse {
  width: 14px;
  visibility: visible;
}

.portlet>.portlet-title>.tools>a.fullscreen {
  display: inline-block;
  top: -3px;
  position: relative;
  font-size: 13px;
  font-family: FontAwesome;
  color: #ACACAC;
}

.portlet>.portlet-title>.tools>a.fullscreen:before {
  content: "\f065";
}

.portlet>.portlet-title>.tools>a.fullscreen.on:before {
  content: "\f066";
}

.portlet>.portlet-title>.tools>a:hover {
  text-decoration: none;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.portlet>.portlet-title>.pagination {
  float: right;
  display: inline-block;
  margin: 2px 0 0 0;
  border: 0;
  padding: 4px 0;
}

.portlet>.portlet-title>.nav-tabs {
  background: none;
  margin: 1px 0 0 0;
  float: right;
  display: inline-block;
  border: 0;
}

.portlet>.portlet-title>.nav-tabs>li {
  background: none;
  margin: 0;
  border: 0;
}

.portlet>.portlet-title>.nav-tabs>li>a {
  background: none;
  margin: 5px 0 0 1px;
  border: 0;
  padding: 8px 10px;
  color: #fff;
}

.portlet>.portlet-title>.nav-tabs>li.active>a,
.portlet>.portlet-title>.nav-tabs>li:hover>a {
  color: #333;
  background: #fff;
  border: 0;
}

.portlet>.portlet-body {
  clear: both;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  -o-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.portlet>.portlet-body p {
  margin-top: 0;
}

.portlet>.portlet-empty {
  min-height: 125px;
}

.portlet.full-height-content {
  margin-bottom: 0;
}

/* Solid colored portlet */

.portlet.solid {
  padding: 0 10px 10px 10px;
  border: 0px;
}

.portlet.solid>.portlet-title {
  border-bottom: 0;
  margin-bottom: 10px;
}

.portlet.solid>.portlet-title>.caption {
  padding: 16px 0 2px 0;
}

.portlet.solid>.portlet-title>.actions {
  padding: 12px 0 6px 0;
}

.portlet.solid>.portlet-title>.tools {
  padding: 14px 0 6px 0;
}

/* Solid bordered portlet */

.portlet.solid.bordered>.portlet-title {
  margin-bottom: 10px;
}

/* Box portlet */

.portlet.box {
  padding: 0px !important;
}

.portlet.box>.portlet-title {
  border-bottom: 0;
  padding: 0 10px;
  margin-bottom: 0;
  color: #fff;
}

.portlet.box>.portlet-title>.caption {
  padding: 11px 0 9px 0;
}

.portlet.box>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box>.portlet-title>.actions {
  padding: 7px 0 5px 0;
}

.portlet.box>.portlet-body {
  background-color: #fff;
  padding: 15px;
}

/* Light Portlet */

.portlet.light {
  padding: 12px 20px 15px 20px;
  background-color: #13171F;
}

.portlet.light.bordered {
  /* border: 1px solid #272E34; */
  /* outline: 1px solid #1b2329; */
  /* box-shadow: 0 0 3px #1b2329; */
  border-radius: 3px !important;
  /* -webkit-box-shadow: 3px 3px 3px #1b2329!important;
  -moz-box-shadow: 3px 3px 3px #1b2329!important;
  box-shadow: 3px 3px 3px #1b2329!important; */
  border: 1px solid #433c3c85;
  box-sizing: border-box;
  /* box-shadow: -10px 12px 15px rgba(37, 36, 36, 0.05); */
  border-radius: 7px;
}

.portlet.light.bordered>.portlet-title {
  border-bottom: 1px solid #433c3c85;
}

.portlet.light.bg-inverse {
  background: #f1f4f7;
}

.portlet.light>.portlet-title {
  padding: 0;
  min-height: 40px;
}

.portlet.light>.portlet-title>.caption {
  color: rgba(245, 248, 250, 0.9);
  /* padding: 10px 0; */
  display: flex;
  align-items: center;
}

.portlet.light>.portlet-title>.caption>.caption-subject {
  font-size: 14px;
}

.portlet.light>.portlet-title>.caption>i {
  color: #777;
  font-size: 15px;
  margin-top: 3px;
}

.portlet.light>.portlet-title>.caption.caption-md>.caption-subject {
  font-size: 15px;
}

.portlet.light>.portlet-title>.caption.caption-md>i {
  font-size: 14px;
}

.portlet.light>.portlet-title>.actions {
  padding: 6px 0 14px 0;
}

.portlet.light>.portlet-title>.actions .btn-default {
  color: #666;
}

.portlet.light>.portlet-title>.actions .btn-icon-only {
  height: 27px;
  width: 27px;
}

.portlet.light>.portlet-title>.actions .dropdown-menu li>a {
  color: #555;
}

.portlet.light>.portlet-title>.inputs {
  float: right;
  display: inline-block;
  padding: 4px 0;
}

.portlet.light>.portlet-title>.inputs>.portlet-input .input-icon>i {
  font-size: 14px;
  margin-top: 9px;
}

.portlet.light>.portlet-title>.inputs>.portlet-input .input-icon>.form-control {
  height: 30px;
  padding: 2px 26px 3px 10px;
  font-size: 13px;
}

.portlet.light>.portlet-title>.inputs>.portlet-input>.form-control {
  height: 30px;
  padding: 3px 10px;
  font-size: 13px;
}

.portlet.light>.portlet-title>.pagination {
  padding: 2px 0 13px 0;
}

.portlet.light>.portlet-title>.tools {
  padding: 10px 0 13px 0;
  margin-top: 2px;
}

.portlet.light>.portlet-title>.nav-tabs>li {
  margin: 0;
  padding: 0;
}

.portlet.light>.portlet-title>.nav-tabs>li>a {
  margin: 0;
  padding: 12px 13px 13px 13px;
  font-size: 13px;
  color: #666;
}

.portlet.light>.portlet-title>.nav-tabs>li.active>a,
.portlet.light>.portlet-title>.nav-tabs>li:hover>a {
  margin: 0;
  background: none;
  color: #333;
}

.portlet.light.form-fit {
  padding: 0;
}

.portlet.light.form-fit>.portlet-title {
  padding: 17px 20px 10px 20px;
  margin-bottom: 0;
}

.portlet.light.portlet-fullscreen>.portlet-body {
  padding: 8px 0;
}

.portlet.light.portlet-fit {
  padding: 0;
  /* overflow: hidden; */
  position: relative;
  /* background: red !important; */
}

.portlet.light.portlet-fit>.portlet-title {
  padding: 0px 20px 0px 9px;
}

.portlet.light.portlet-fit.portlet-form>.portlet-body {
  padding: 0;
}

.portlet.light.portlet-fit.portlet-form>.portlet-body {
  padding: 0;
}

.portlet.light.portlet-fit.portlet-form>.portlet-body .form-actions {
  background: none;
}

/* Reverse aligned tabs */

.tabs-reversed>li {
  float: right;
  margin-right: 0;
}

.tabs-reversed>li>a {
  margin-right: 0;
}

/* jQuery UI Draggable Portlets */

.portlet-collapsed {
  display: none;
}

@media (max-width: 991px) {

  /* 991px */
  .portlet-collapsed-on-mobile {
    display: none;
  }
}

/***
Custom colored portlets 
***/

.portlet>.portlet-body.white,
.portlet.white {
  background-color: #ffffff;
}

.portlet.solid.white>.portlet-title,
.portlet.solid.white>.portlet-body {
  border: 0;
  color: #666;
}

.portlet.solid.white>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.white>.portlet-title>.caption>i {
  color: #666;
}

.portlet.solid.white>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.white {
  border: 1px solid white;
  border-top: 0;
}

.portlet.box.white>.portlet-title {
  background-color: #ffffff;
}

.portlet.box.white>.portlet-title>.caption {
  color: #666;
}

.portlet.box.white>.portlet-title>.caption>i {
  color: #666;
}

.portlet.box.white>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid white;
  color: white;
}

.portlet.box.white>.portlet-title>.actions .btn-default>i {
  color: white;
}

.portlet.box.white>.portlet-title>.actions .btn-default:hover,
.portlet.box.white>.portlet-title>.actions .btn-default:focus,
.portlet.box.white>.portlet-title>.actions .btn-default:active,
.portlet.box.white>.portlet-title>.actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

.portlet>.portlet-body.default,
.portlet.default {
  background-color: #e1e5ec;
}

.portlet.solid.default>.portlet-title,
.portlet.solid.default>.portlet-body {
  border: 0;
  color: #666;
}

.portlet.solid.default>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.default>.portlet-title>.caption>i {
  color: #666;
}

.portlet.solid.default>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.default {
  border: 1px solid white;
  border-top: 0;
}

.portlet.box.default>.portlet-title {
  background-color: #e1e5ec;
}

.portlet.box.default>.portlet-title>.caption {
  color: #666;
}

.portlet.box.default>.portlet-title>.caption>i {
  color: #666;
}

.portlet.box.default>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid white;
  color: white;
}

.portlet.box.default>.portlet-title>.actions .btn-default>i {
  color: white;
}

.portlet.box.default>.portlet-title>.actions .btn-default:hover,
.portlet.box.default>.portlet-title>.actions .btn-default:focus,
.portlet.box.default>.portlet-title>.actions .btn-default:active,
.portlet.box.default>.portlet-title>.actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

.portlet>.portlet-body.dark,
.portlet.dark {
  background-color: #2f353b;
}

.portlet.solid.dark>.portlet-title,
.portlet.solid.dark>.portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.dark>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.dark>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.solid.dark>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.dark {
  border: 1px solid #464f57;
  border-top: 0;
}

.portlet.box.dark>.portlet-title {
  background-color: #2f353b;
}

.portlet.box.dark>.portlet-title>.caption {
  color: #FFFFFF;
}

.portlet.box.dark>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.box.dark>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #616d79;
  color: #6c7a88;
}

.portlet.box.dark>.portlet-title>.actions .btn-default>i {
  color: #738290;
}

.portlet.box.dark>.portlet-title>.actions .btn-default:hover,
.portlet.box.dark>.portlet-title>.actions .btn-default:focus,
.portlet.box.dark>.portlet-title>.actions .btn-default:active,
.portlet.box.dark>.portlet-title>.actions .btn-default.active {
  border: 1px solid #798794;
  color: #8793a0;
}

.portlet>.portlet-body.blue,
.portlet.blue {
  background-color: #3598dc;
}

.portlet.solid.blue>.portlet-title,
.portlet.solid.blue>.portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.blue>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.blue>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.solid.blue>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue {
  border: 1px solid #60aee4;
  border-top: 0;
}

.portlet.box.blue>.portlet-title {
  background-color: #3598dc;
}

.portlet.box.blue>.portlet-title>.caption {
  color: #FFFFFF;
}

.portlet.box.blue>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.box.blue>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #95c9ed;
  color: #aad4f0;
}

.portlet.box.blue>.portlet-title>.actions .btn-default>i {
  color: #b7daf3;
}

.portlet.box.blue>.portlet-title>.actions .btn-default:hover,
.portlet.box.blue>.portlet-title>.actions .btn-default:focus,
.portlet.box.blue>.portlet-title>.actions .btn-default:active,
.portlet.box.blue>.portlet-title>.actions .btn-default.active {
  border: 1px solid #c0dff4;
  color: #d6eaf8;
}

.portlet>.portlet-body.blue-steel,
.portlet.blue-steel {
  background-color: #4B77BE;
}

.portlet.solid.blue-steel>.portlet-title,
.portlet.solid.blue-steel>.portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.blue-steel>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.blue-steel>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.solid.blue-steel>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue-steel {
  border: 1px solid #7093cc;
  border-top: 0;
}

.portlet.box.blue-steel>.portlet-title {
  background-color: #4B77BE;
}

.portlet.box.blue-steel>.portlet-title>.caption {
  color: #FFFFFF;
}

.portlet.box.blue-steel>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.box.blue-steel>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #9db5dc;
  color: #b0c3e3;
}

.portlet.box.blue-steel>.portlet-title>.actions .btn-default>i {
  color: #bbcce7;
}

.portlet.box.blue-steel>.portlet-title>.actions .btn-default:hover,
.portlet.box.blue-steel>.portlet-title>.actions .btn-default:focus,
.portlet.box.blue-steel>.portlet-title>.actions .btn-default:active,
.portlet.box.blue-steel>.portlet-title>.actions .btn-default.active {
  border: 1px solid #c3d2e9;
  color: #d6e0f0;
}

.portlet>.portlet-body.blue-soft,
.portlet.blue-soft {
  background-color: #4c87b9;
}

.portlet.solid.blue-soft>.portlet-title,
.portlet.solid.blue-soft>.portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.blue-soft>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.blue-soft>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.solid.blue-soft>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue-soft {
  border: 1px solid #71a0c7;
  border-top: 0;
}

.portlet.box.blue-soft>.portlet-title {
  background-color: #4c87b9;
}

.portlet.box.blue-soft>.portlet-title>.caption {
  color: #FFFFFF;
}

.portlet.box.blue-soft>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.box.blue-soft>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #9dbdd9;
  color: #afc9e0;
}

.portlet.box.blue-soft>.portlet-title>.actions .btn-default>i {
  color: #bad1e4;
}

.portlet.box.blue-soft>.portlet-title>.actions .btn-default:hover,
.portlet.box.blue-soft>.portlet-title>.actions .btn-default:focus,
.portlet.box.blue-soft>.portlet-title>.actions .btn-default:active,
.portlet.box.blue-soft>.portlet-title>.actions .btn-default.active {
  border: 1px solid #c1d6e7;
  color: #d4e2ee;
}

.portlet>.portlet-body.blue-dark,
.portlet.blue-dark {
  background-color: #5e738b;
}

.portlet.solid.blue-dark>.portlet-title,
.portlet.solid.blue-dark>.portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.blue-dark>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.blue-dark>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.solid.blue-dark>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue-dark {
  border: 1px solid #788da4;
  border-top: 0;
}

.portlet.box.blue-dark>.portlet-title {
  background-color: #5e738b;
}

.portlet.box.blue-dark>.portlet-title>.caption {
  color: #FFFFFF;
}

.portlet.box.blue-dark>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.box.blue-dark>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #9dacbd;
  color: #acb8c7;
}

.portlet.box.blue-dark>.portlet-title>.actions .btn-default>i {
  color: #b5c0cd;
}

.portlet.box.blue-dark>.portlet-title>.actions .btn-default:hover,
.portlet.box.blue-dark>.portlet-title>.actions .btn-default:focus,
.portlet.box.blue-dark>.portlet-title>.actions .btn-default:active,
.portlet.box.blue-dark>.portlet-title>.actions .btn-default.active {
  border: 1px solid #bbc5d1;
  color: #cad2db;
}

.portlet>.portlet-body.blue-sharp,
.portlet.blue-sharp {
  background-color: #5C9BD1;
}

.portlet.solid.blue-sharp>.portlet-title,
.portlet.solid.blue-sharp>.portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.blue-sharp>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.blue-sharp>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.solid.blue-sharp>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue-sharp {
  border: 1px solid #84b3dc;
  border-top: 0;
}

.portlet.box.blue-sharp>.portlet-title {
  background-color: #5C9BD1;
}

.portlet.box.blue-sharp>.portlet-title>.caption {
  color: #FFFFFF;
}

.portlet.box.blue-sharp>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.box.blue-sharp>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #b4d1ea;
  color: #c7ddef;
}

.portlet.box.blue-sharp>.portlet-title>.actions .btn-default>i {
  color: #d3e4f3;
}

.portlet.box.blue-sharp>.portlet-title>.actions .btn-default:hover,
.portlet.box.blue-sharp>.portlet-title>.actions .btn-default:focus,
.portlet.box.blue-sharp>.portlet-title>.actions .btn-default:active,
.portlet.box.blue-sharp>.portlet-title>.actions .btn-default.active {
  border: 1px solid #dbe9f5;
  color: #eff5fb;
}

.portlet>.portlet-body.green,
.portlet.green {
  background-color: #C3073F;
}

.portlet.solid.green>.portlet-title,
.portlet.solid.green>.portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.green>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.green>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.solid.green>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green {
  border: 1px solid #5cd1db;
  border-top: 0;
}

.portlet.box.green>.portlet-title {
  background-color: #C3073F;
}

.portlet.box.green>.portlet-title>.caption {
  color: #FFFFFF;
}

.portlet.box.green>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.box.green>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #8edfe6;
  color: #a3e5eb;
}

.portlet.box.green>.portlet-title>.actions .btn-default>i {
  color: #afe8ee;
}

.portlet.box.green>.portlet-title>.actions .btn-default:hover,
.portlet.box.green>.portlet-title>.actions .btn-default:focus,
.portlet.box.green>.portlet-title>.actions .btn-default:active,
.portlet.box.green>.portlet-title>.actions .btn-default.active {
  border: 1px solid #b8ebef;
  color: #cdf1f4;
}

.portlet>.portlet-body.green-seagreen,
.portlet.green-seagreen {
  background-color: #1BA39C;
}

.portlet.solid.green-seagreen>.portlet-title,
.portlet.solid.green-seagreen>.portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.green-seagreen>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.green-seagreen>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.solid.green-seagreen>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-seagreen {
  border: 1px solid #22cfc6;
  border-top: 0;
}

.portlet.box.green-seagreen>.portlet-title {
  background-color: #1BA39C;
}

.portlet.box.green-seagreen>.portlet-title>.caption {
  color: #FFFFFF;
}

.portlet.box.green-seagreen>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.box.green-seagreen>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #4de1da;
  color: #63e5de;
}

.portlet.box.green-seagreen>.portlet-title>.actions .btn-default>i {
  color: #70e7e1;
}

.portlet.box.green-seagreen>.portlet-title>.actions .btn-default:hover,
.portlet.box.green-seagreen>.portlet-title>.actions .btn-default:focus,
.portlet.box.green-seagreen>.portlet-title>.actions .btn-default:active,
.portlet.box.green-seagreen>.portlet-title>.actions .btn-default.active {
  border: 1px solid #78e9e3;
  color: #8eece8;
}

.portlet>.portlet-body.green-turquoise,
.portlet.green-turquoise {
  background-color: #36D7B7;
}

.portlet.solid.green-turquoise>.portlet-title,
.portlet.solid.green-turquoise>.portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.green-turquoise>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.green-turquoise>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.solid.green-turquoise>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-turquoise {
  border: 1px solid #61dfc6;
  border-top: 0;
}

.portlet.box.green-turquoise>.portlet-title {
  background-color: #36D7B7;
}

.portlet.box.green-turquoise>.portlet-title>.caption {
  color: #FFFFFF;
}

.portlet.box.green-turquoise>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.box.green-turquoise>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #94ead9;
  color: #a9eee0;
}

.portlet.box.green-turquoise>.portlet-title>.actions .btn-default>i {
  color: #b6f0e5;
}

.portlet.box.green-turquoise>.portlet-title>.actions .btn-default:hover,
.portlet.box.green-turquoise>.portlet-title>.actions .btn-default:focus,
.portlet.box.green-turquoise>.portlet-title>.actions .btn-default:active,
.portlet.box.green-turquoise>.portlet-title>.actions .btn-default.active {
  border: 1px solid #bef2e8;
  color: #d3f6ef;
}

.portlet>.portlet-body.green-haze,
.portlet.green-haze {
  background-color: #44b6ae;
}

.portlet.solid.green-haze>.portlet-title,
.portlet.solid.green-haze>.portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.green-haze>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.green-haze>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.solid.green-haze>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-haze {
  border: 1px solid #67c6bf;
  border-top: 0;
}

.portlet.box.green-haze>.portlet-title {
  background-color: #44b6ae;
}

.portlet.box.green-haze>.portlet-title>.caption {
  color: #FFFFFF;
}

.portlet.box.green-haze>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.box.green-haze>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #93d7d2;
  color: #a6deda;
}

.portlet.box.green-haze>.portlet-title>.actions .btn-default>i {
  color: #b1e2de;
}

.portlet.box.green-haze>.portlet-title>.actions .btn-default:hover,
.portlet.box.green-haze>.portlet-title>.actions .btn-default:focus,
.portlet.box.green-haze>.portlet-title>.actions .btn-default:active,
.portlet.box.green-haze>.portlet-title>.actions .btn-default.active {
  border: 1px solid #b9e5e2;
  color: #cbece9;
}

.portlet>.portlet-body.green-jungle,
.portlet.green-jungle {
  background-color: #26C281;
}

.portlet.solid.green-jungle>.portlet-title,
.portlet.solid.green-jungle>.portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.green-jungle>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.green-jungle>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.solid.green-jungle>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-jungle {
  border: 1px solid #41da9a;
  border-top: 0;
}

.portlet.box.green-jungle>.portlet-title {
  background-color: #26C281;
}

.portlet.box.green-jungle>.portlet-title>.caption {
  color: #FFFFFF;
}

.portlet.box.green-jungle>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.box.green-jungle>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #74e4b5;
  color: #8ae8c1;
}

.portlet.box.green-jungle>.portlet-title>.actions .btn-default>i {
  color: #96ebc8;
}

.portlet.box.green-jungle>.portlet-title>.actions .btn-default:hover,
.portlet.box.green-jungle>.portlet-title>.actions .btn-default:focus,
.portlet.box.green-jungle>.portlet-title>.actions .btn-default:active,
.portlet.box.green-jungle>.portlet-title>.actions .btn-default.active {
  border: 1px solid #9feccc;
  color: #b4f0d7;
}

.portlet>.portlet-body.green-soft,
.portlet.green-soft {
  background-color: #3faba4;
}

.portlet.solid.green-soft>.portlet-title,
.portlet.solid.green-soft>.portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.green-soft>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.green-soft>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.solid.green-soft>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-soft {
  border: 1px solid #5bc2bc;
  border-top: 0;
}

.portlet.box.green-soft>.portlet-title {
  background-color: #3faba4;
}

.portlet.box.green-soft>.portlet-title>.caption {
  color: #FFFFFF;
}

.portlet.box.green-soft>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.box.green-soft>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #87d3ce;
  color: #9adad6;
}

.portlet.box.green-soft>.portlet-title>.actions .btn-default>i {
  color: #a5deda;
}

.portlet.box.green-soft>.portlet-title>.actions .btn-default:hover,
.portlet.box.green-soft>.portlet-title>.actions .btn-default:focus,
.portlet.box.green-soft>.portlet-title>.actions .btn-default:active,
.portlet.box.green-soft>.portlet-title>.actions .btn-default.active {
  border: 1px solid #ade1dd;
  color: #bfe7e5;
}

.portlet>.portlet-body.green-dark,
.portlet.green-dark {
  background-color: #4DB3A2;
}

.portlet.solid.green-dark>.portlet-title,
.portlet.solid.green-dark>.portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.green-dark>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.green-dark>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.solid.green-dark>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-dark {
  border: 1px solid #71c2b5;
  border-top: 0;
}

.portlet.box.green-dark>.portlet-title {
  background-color: #4DB3A2;
}

.portlet.box.green-dark>.portlet-title>.caption {
  color: #FFFFFF;
}

.portlet.box.green-dark>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.box.green-dark>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #9cd5cb;
  color: #addcd4;
}

.portlet.box.green-dark>.portlet-title>.actions .btn-default>i {
  color: #b8e1da;
}

.portlet.box.green-dark>.portlet-title>.actions .btn-default:hover,
.portlet.box.green-dark>.portlet-title>.actions .btn-default:focus,
.portlet.box.green-dark>.portlet-title>.actions .btn-default:active,
.portlet.box.green-dark>.portlet-title>.actions .btn-default.active {
  border: 1px solid #bfe4de;
  color: #d1ebe7;
}

.portlet>.portlet-body.green-sharp,
.portlet.green-sharp {
  background-color: #3562d9;
}

.portlet.solid.green-sharp>.portlet-title,
.portlet.solid.green-sharp>.portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.green-sharp>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.green-sharp>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.solid.green-sharp>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-sharp {
  border: 1px solid #46cbd7;
  border-top: 0;
}

.portlet.box.green-sharp>.portlet-title {
  background-color: #3562d9;
}

.portlet.box.green-sharp>.portlet-title>.caption {
  color: #FFFFFF;
}

.portlet.box.green-sharp>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.box.green-sharp>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #79d9e2;
  color: #8edfe6;
}

.portlet.box.green-sharp>.portlet-title>.actions .btn-default>i {
  color: #9ae3e9;
}

.portlet.box.green-sharp>.portlet-title>.actions .btn-default:hover,
.portlet.box.green-sharp>.portlet-title>.actions .btn-default:focus,
.portlet.box.green-sharp>.portlet-title>.actions .btn-default:active,
.portlet.box.green-sharp>.portlet-title>.actions .btn-default.active {
  border: 1px solid #a2e5eb;
  color: #b7ebef;
}

.portlet>.portlet-body.green-steel,
.portlet.green-steel {
  background-color: #cf8900;
}

.portlet.solid.green-steel>.portlet-title,
.portlet.solid.green-steel>.portlet-body {
  border: 0;
  color: #FFFFFF;
}

.portlet.solid.green-steel>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.green-steel>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.solid.green-steel>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-steel {
  border: 1px solid #3ed1d4;
  border-top: 0;
}

.portlet.box.green-steel>.portlet-title {
  background-color: #cf8900;
}

.portlet.box.green-steel>.portlet-title>.caption {
  color: #FFFFFF;
}

.portlet.box.green-steel>.portlet-title>.caption>i {
  color: #FFFFFF;
}

.portlet.box.green-steel>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #70dddf;
  color: #85e2e4;
}

.portlet.box.green-steel>.portlet-title>.actions .btn-default>i {
  color: #92e5e6;
}

.portlet.box.green-steel>.portlet-title>.actions .btn-default:hover,
.portlet.box.green-steel>.portlet-title>.actions .btn-default:focus,
.portlet.box.green-steel>.portlet-title>.actions .btn-default:active,
.portlet.box.green-steel>.portlet-title>.actions .btn-default.active {
  border: 1px solid #9ae7e8;
  color: #afeced;
}

.portlet>.portlet-body.red,
.portlet.red {
  background-color: #e7505a;
}

.portlet.solid.red>.portlet-title,
.portlet.solid.red>.portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.red>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.red>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.solid.red>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.red {
  border: 1px solid #ed7d84;
  border-top: 0;
}

.portlet.box.red>.portlet-title {
  background-color: #e7505a;
}

.portlet.box.red>.portlet-title>.caption {
  color: #ffffff;
}

.portlet.box.red>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.box.red>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #f5b3b7;
  color: #f8c9cc;
}

.portlet.box.red>.portlet-title>.actions .btn-default>i {
  color: #f9d7d9;
}

.portlet.box.red>.portlet-title>.actions .btn-default:hover,
.portlet.box.red>.portlet-title>.actions .btn-default:focus,
.portlet.box.red>.portlet-title>.actions .btn-default:active,
.portlet.box.red>.portlet-title>.actions .btn-default.active {
  border: 1px solid #fbe0e1;
  color: #fef6f6;
}

.portlet>.portlet-body.red-pink,
.portlet.red-pink {
  background-color: #E08283;
}

.portlet.solid.red-pink>.portlet-title,
.portlet.solid.red-pink>.portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.red-pink>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.red-pink>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.solid.red-pink>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.red-pink {
  border: 1px solid #eaabac;
  border-top: 0;
}

.portlet.box.red-pink>.portlet-title {
  background-color: #E08283;
}

.portlet.box.red-pink>.portlet-title>.caption {
  color: #ffffff;
}

.portlet.box.red-pink>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.box.red-pink>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #f6dcdc;
  color: #fbf0f0;
}

.portlet.box.red-pink>.portlet-title>.actions .btn-default>i {
  color: #fefdfd;
}

.portlet.box.red-pink>.portlet-title>.actions .btn-default:hover,
.portlet.box.red-pink>.portlet-title>.actions .btn-default:focus,
.portlet.box.red-pink>.portlet-title>.actions .btn-default:active,
.portlet.box.red-pink>.portlet-title>.actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

.portlet>.portlet-body.red-soft,
.portlet.red-soft {
  background-color: #d05454;
}

.portlet.solid.red-soft>.portlet-title,
.portlet.solid.red-soft>.portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.red-soft>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.red-soft>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.solid.red-soft>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.red-soft {
  border: 1px solid #db7c7c;
  border-top: 0;
}

.portlet.box.red-soft>.portlet-title {
  background-color: #d05454;
}

.portlet.box.red-soft>.portlet-title>.caption {
  color: #ffffff;
}

.portlet.box.red-soft>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.box.red-soft>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #e8acac;
  color: #eec0c0;
}

.portlet.box.red-soft>.portlet-title>.actions .btn-default>i {
  color: #f1cccc;
}

.portlet.box.red-soft>.portlet-title>.actions .btn-default:hover,
.portlet.box.red-soft>.portlet-title>.actions .btn-default:focus,
.portlet.box.red-soft>.portlet-title>.actions .btn-default:active,
.portlet.box.red-soft>.portlet-title>.actions .btn-default.active {
  border: 1px solid #f3d4d4;
  color: #f9e8e8;
}

.portlet>.portlet-body.red-haze,
.portlet.red-haze {
  background-color: #f36a5a;
}

.portlet.solid.red-haze>.portlet-title,
.portlet.solid.red-haze>.portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.red-haze>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.red-haze>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.solid.red-haze>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.red-haze {
  border: 1px solid #f6958a;
  border-top: 0;
}

.portlet.box.red-haze>.portlet-title {
  background-color: #f36a5a;
}

.portlet.box.red-haze>.portlet-title>.caption {
  color: #ffffff;
}

.portlet.box.red-haze>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.box.red-haze>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #fbc8c3;
  color: #fcdeda;
}

.portlet.box.red-haze>.portlet-title>.actions .btn-default>i {
  color: #fdebe9;
}

.portlet.box.red-haze>.portlet-title>.actions .btn-default:hover,
.portlet.box.red-haze>.portlet-title>.actions .btn-default:focus,
.portlet.box.red-haze>.portlet-title>.actions .btn-default:active,
.portlet.box.red-haze>.portlet-title>.actions .btn-default.active {
  border: 1px solid #fef3f2;
  color: white;
}

.portlet>.portlet-body.yellow,
.portlet.yellow {
  background-color: #c49f47;
}

.portlet.solid.yellow>.portlet-title,
.portlet.solid.yellow>.portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.yellow>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.yellow>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.solid.yellow>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.yellow {
  border: 1px solid #d0b36e;
  border-top: 0;
}

.portlet.box.yellow>.portlet-title {
  background-color: #c49f47;
}

.portlet.box.yellow>.portlet-title>.caption {
  color: #ffffff;
}

.portlet.box.yellow>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.box.yellow>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #dfcb9c;
  color: #e5d5af;
}

.portlet.box.yellow>.portlet-title>.actions .btn-default>i {
  color: #e9dbbb;
}

.portlet.box.yellow>.portlet-title>.actions .btn-default:hover,
.portlet.box.yellow>.portlet-title>.actions .btn-default:focus,
.portlet.box.yellow>.portlet-title>.actions .btn-default:active,
.portlet.box.yellow>.portlet-title>.actions .btn-default.active {
  border: 1px solid #ecdfc3;
  color: #f2ead6;
}

.portlet>.portlet-body.yellow-gold,
.portlet.yellow-gold {
  background-color: #E87E04;
}

.portlet.solid.yellow-gold>.portlet-title,
.portlet.solid.yellow-gold>.portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.yellow-gold>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.yellow-gold>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.solid.yellow-gold>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.yellow-gold {
  border: 1px solid #fb9724;
  border-top: 0;
}

.portlet.box.yellow-gold>.portlet-title {
  background-color: #E87E04;
}

.portlet.box.yellow-gold>.portlet-title>.caption {
  color: #ffffff;
}

.portlet.box.yellow-gold>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.box.yellow-gold>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #fcb460;
  color: #fdbf79;
}

.portlet.box.yellow-gold>.portlet-title>.actions .btn-default>i {
  color: #fdc788;
}

.portlet.box.yellow-gold>.portlet-title>.actions .btn-default:hover,
.portlet.box.yellow-gold>.portlet-title>.actions .btn-default:focus,
.portlet.box.yellow-gold>.portlet-title>.actions .btn-default:active,
.portlet.box.yellow-gold>.portlet-title>.actions .btn-default.active {
  border: 1px solid #fdcb92;
  color: #fed7ab;
}

.portlet>.portlet-body.yellow-soft,
.portlet.yellow-soft {
  background-color: #c8d046;
}

.portlet.solid.yellow-soft>.portlet-title,
.portlet.solid.yellow-soft>.portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.yellow-soft>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.yellow-soft>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.solid.yellow-soft>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.yellow-soft {
  border: 1px solid #d4da6f;
  border-top: 0;
}

.portlet.box.yellow-soft>.portlet-title {
  background-color: #c8d046;
}

.portlet.box.yellow-soft>.portlet-title>.caption {
  color: #ffffff;
}

.portlet.box.yellow-soft>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.box.yellow-soft>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #e3e79f;
  color: #e9ecb4;
}

.portlet.box.yellow-soft>.portlet-title>.actions .btn-default>i {
  color: #ecefc0;
}

.portlet.box.yellow-soft>.portlet-title>.actions .btn-default:hover,
.portlet.box.yellow-soft>.portlet-title>.actions .btn-default:focus,
.portlet.box.yellow-soft>.portlet-title>.actions .btn-default:active,
.portlet.box.yellow-soft>.portlet-title>.actions .btn-default.active {
  border: 1px solid #eff1c8;
  color: #f5f6dc;
}

.portlet>.portlet-body.yellow-haze,
.portlet.yellow-haze {
  background-color: #c5bf66;
}

.portlet.solid.yellow-haze>.portlet-title,
.portlet.solid.yellow-haze>.portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.yellow-haze>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.yellow-haze>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.solid.yellow-haze>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.yellow-haze {
  border: 1px solid #d3ce8b;
  border-top: 0;
}

.portlet.box.yellow-haze>.portlet-title {
  background-color: #c5bf66;
}

.portlet.box.yellow-haze>.portlet-title>.caption {
  color: #ffffff;
}

.portlet.box.yellow-haze>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.box.yellow-haze>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #e4e1b7;
  color: #ebe9ca;
}

.portlet.box.yellow-haze>.portlet-title>.actions .btn-default>i {
  color: #efedd5;
}

.portlet.box.yellow-haze>.portlet-title>.actions .btn-default:hover,
.portlet.box.yellow-haze>.portlet-title>.actions .btn-default:focus,
.portlet.box.yellow-haze>.portlet-title>.actions .btn-default:active,
.portlet.box.yellow-haze>.portlet-title>.actions .btn-default.active {
  border: 1px solid #f2f1dc;
  color: #f9f8ef;
}

.portlet>.portlet-body.purple,
.portlet.purple {
  background-color: #8E44AD;
}

.portlet.solid.purple>.portlet-title,
.portlet.solid.purple>.portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.purple>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.purple>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.solid.purple>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple {
  border: 1px solid #a563c1;
  border-top: 0;
}

.portlet.box.purple>.portlet-title {
  background-color: #8E44AD;
}

.portlet.box.purple>.portlet-title>.caption {
  color: #ffffff;
}

.portlet.box.purple>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.box.purple>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #bf8ed3;
  color: #c9a1da;
}

.portlet.box.purple>.portlet-title>.actions .btn-default>i {
  color: #cfacde;
}

.portlet.box.purple>.portlet-title>.actions .btn-default:hover,
.portlet.box.purple>.portlet-title>.actions .btn-default:focus,
.portlet.box.purple>.portlet-title>.actions .btn-default:active,
.portlet.box.purple>.portlet-title>.actions .btn-default.active {
  border: 1px solid #d4b3e1;
  color: #dec5e8;
}

.portlet>.portlet-body.purple-plum,
.portlet.purple-plum {
  background-color: #8775a7;
}

.portlet.solid.purple-plum>.portlet-title,
.portlet.solid.purple-plum>.portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.purple-plum>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.purple-plum>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.solid.purple-plum>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple-plum {
  border: 1px solid #a294bb;
  border-top: 0;
}

.portlet.box.purple-plum>.portlet-title {
  background-color: #8775a7;
}

.portlet.box.purple-plum>.portlet-title>.caption {
  color: #ffffff;
}

.portlet.box.purple-plum>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.box.purple-plum>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #c3bad3;
  color: #d0c9dd;
}

.portlet.box.purple-plum>.portlet-title>.actions .btn-default>i {
  color: #d8d2e3;
}

.portlet.box.purple-plum>.portlet-title>.actions .btn-default:hover,
.portlet.box.purple-plum>.portlet-title>.actions .btn-default:focus,
.portlet.box.purple-plum>.portlet-title>.actions .btn-default:active,
.portlet.box.purple-plum>.portlet-title>.actions .btn-default.active {
  border: 1px solid #ded9e7;
  color: #ebe8f0;
}

.portlet>.portlet-body.purple-medium,
.portlet.purple-medium {
  background-color: #BF55EC;
}

.portlet.solid.purple-medium>.portlet-title,
.portlet.solid.purple-medium>.portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.purple-medium>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.purple-medium>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.solid.purple-medium>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple-medium {
  border: 1px solid #d083f1;
  border-top: 0;
}

.portlet.box.purple-medium>.portlet-title {
  background-color: #BF55EC;
}

.portlet.box.purple-medium>.portlet-title>.caption {
  color: #ffffff;
}

.portlet.box.purple-medium>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.box.purple-medium>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #e5baf7;
  color: #eed1fa;
}

.portlet.box.purple-medium>.portlet-title>.actions .btn-default>i {
  color: #f3dffb;
}

.portlet.box.purple-medium>.portlet-title>.actions .btn-default:hover,
.portlet.box.purple-medium>.portlet-title>.actions .btn-default:focus,
.portlet.box.purple-medium>.portlet-title>.actions .btn-default:active,
.portlet.box.purple-medium>.portlet-title>.actions .btn-default.active {
  border: 1px solid #f6e8fc;
  color: white;
}

.portlet>.portlet-body.purple-studio,
.portlet.purple-studio {
  background-color: #8E44AD;
}

.portlet.solid.purple-studio>.portlet-title,
.portlet.solid.purple-studio>.portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.purple-studio>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.purple-studio>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.solid.purple-studio>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple-studio {
  border: 1px solid #a563c1;
  border-top: 0;
}

.portlet.box.purple-studio>.portlet-title {
  background-color: #8E44AD;
}

.portlet.box.purple-studio>.portlet-title>.caption {
  color: #ffffff;
}

.portlet.box.purple-studio>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.box.purple-studio>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #bf8ed3;
  color: #c9a1da;
}

.portlet.box.purple-studio>.portlet-title>.actions .btn-default>i {
  color: #cfacde;
}

.portlet.box.purple-studio>.portlet-title>.actions .btn-default:hover,
.portlet.box.purple-studio>.portlet-title>.actions .btn-default:focus,
.portlet.box.purple-studio>.portlet-title>.actions .btn-default:active,
.portlet.box.purple-studio>.portlet-title>.actions .btn-default.active {
  border: 1px solid #d4b3e1;
  color: #dec5e8;
}

.portlet>.portlet-body.purple-sharp,
.portlet.purple-sharp {
  background-color: #796799;
}

.portlet.solid.purple-sharp>.portlet-title,
.portlet.solid.purple-sharp>.portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.purple-sharp>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.purple-sharp>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.solid.purple-sharp>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple-sharp {
  border: 1px solid #9486ad;
  border-top: 0;
}

.portlet.box.purple-sharp>.portlet-title {
  background-color: #796799;
}

.portlet.box.purple-sharp>.portlet-title>.caption {
  color: #ffffff;
}

.portlet.box.purple-sharp>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.box.purple-sharp>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #b4aac6;
  color: #c2b9d0;
}

.portlet.box.purple-sharp>.portlet-title>.actions .btn-default>i {
  color: #cac3d6;
}

.portlet.box.purple-sharp>.portlet-title>.actions .btn-default:hover,
.portlet.box.purple-sharp>.portlet-title>.actions .btn-default:focus,
.portlet.box.purple-sharp>.portlet-title>.actions .btn-default:active,
.portlet.box.purple-sharp>.portlet-title>.actions .btn-default.active {
  border: 1px solid #cfc9db;
  color: #ddd8e5;
}

.portlet>.portlet-body.purple-soft,
.portlet.purple-soft {
  background-color: #8877a9;
}

.portlet.solid.purple-soft>.portlet-title,
.portlet.solid.purple-soft>.portlet-body {
  border: 0;
  color: #ffffff;
}

.portlet.solid.purple-soft>.portlet-title>.caption {
  font-weight: 400;
}

.portlet.solid.purple-soft>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.solid.purple-soft>.portlet-title>.tools>a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple-soft {
  border: 1px solid #a396bd;
  border-top: 0;
}

.portlet.box.purple-soft>.portlet-title {
  background-color: #8877a9;
}

.portlet.box.purple-soft>.portlet-title>.caption {
  color: #ffffff;
}

.portlet.box.purple-soft>.portlet-title>.caption>i {
  color: #ffffff;
}

.portlet.box.purple-soft>.portlet-title>.actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #c4bcd4;
  color: #d2cbde;
}

.portlet.box.purple-soft>.portlet-title>.actions .btn-default>i {
  color: #dad5e4;
}

.portlet.box.purple-soft>.portlet-title>.actions .btn-default:hover,
.portlet.box.purple-soft>.portlet-title>.actions .btn-default:focus,
.portlet.box.purple-soft>.portlet-title>.actions .btn-default:active,
.portlet.box.purple-soft>.portlet-title>.actions .btn-default.active {
  border: 1px solid #dfdbe8;
  color: #edebf2;
}

/***
Customized Progress Bars
***/

.progress {
  border: 0;
  background-image: none;
  filter: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.progress.progress-sm {
  height: 12px;
}

.page-md .progress {
  text-indent: -99999px;
}

/* Custom colors */

.progress-bar.white {
  background: #ffffff !important;
  color: #666 !important;
}

.progress-bar.default {
  background: #e1e5ec !important;
  color: #666 !important;
}

.progress-bar.dark {
  background: #2f353b !important;
  color: #FFFFFF !important;
}

.progress-bar.blue {
  background: #3598dc !important;
  color: #FFFFFF !important;
}

.progress-bar.blue-steel {
  background: #4B77BE !important;
  color: #FFFFFF !important;
}

.progress-bar.blue-soft {
  background: #4c87b9 !important;
  color: #FFFFFF !important;
}

.progress-bar.blue-dark {
  background: #5e738b !important;
  color: #FFFFFF !important;
}

.progress-bar.blue-sharp {
  background: #5C9BD1 !important;
  color: #FFFFFF !important;
}

.progress-bar.green {
  background: #C3073F !important;
  color: #FFFFFF !important;
}

.progress-bar.green-seagreen {
  background: #1BA39C !important;
  color: #FFFFFF !important;
}

.progress-bar.green-turquoise {
  background: #36D7B7 !important;
  color: #FFFFFF !important;
}

.progress-bar.green-haze {
  background: #44b6ae !important;
  color: #FFFFFF !important;
}

.progress-bar.green-jungle {
  background: #26C281 !important;
  color: #FFFFFF !important;
}

.progress-bar.green-soft {
  background: #3faba4 !important;
  color: #FFFFFF !important;
}

.progress-bar.green-dark {
  background: #4DB3A2 !important;
  color: #FFFFFF !important;
}

.progress-bar.green-sharp {
  background: #3562d9 !important;
  color: #FFFFFF !important;
}

.progress-bar.green-steel {
  background: #cf8900 !important;
  color: #FFFFFF !important;
}

.progress-bar.red {
  background: #e7505a !important;
  color: #ffffff !important;
}

.progress-bar.red-pink {
  background: #E08283 !important;
  color: #ffffff !important;
}

.progress-bar.red-soft {
  background: #d05454 !important;
  color: #ffffff !important;
}

.progress-bar.red-haze {
  background: #f36a5a !important;
  color: #ffffff !important;
}

.progress-bar.yellow {
  background: #c49f47 !important;
  color: #ffffff !important;
}

.progress-bar.yellow-gold {
  background: #E87E04 !important;
  color: #ffffff !important;
}

.progress-bar.yellow-soft {
  background: #c8d046 !important;
  color: #ffffff !important;
}

.progress-bar.yellow-haze {
  background: #c5bf66 !important;
  color: #ffffff !important;
}

.progress-bar.purple {
  background: #8E44AD !important;
  color: #ffffff !important;
}

.progress-bar.purple-plum {
  background: #8775a7 !important;
  color: #ffffff !important;
}

.progress-bar.purple-medium {
  background: #BF55EC !important;
  color: #ffffff !important;
}

.progress-bar.purple-studio {
  background: #8E44AD !important;
  color: #ffffff !important;
}

.progress-bar.purple-sharp {
  background: #796799 !important;
  color: #ffffff !important;
}

.progress-bar.purple-soft {
  background: #8877a9 !important;
  color: #ffffff !important;
}

/***
Dashboard Stats
***/

.dashboard-stat {
  display: block;
  margin-bottom: 25px;
  overflow: hidden;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.dashboard-stat:before,
.dashboard-stat:after {
  content: " ";
  display: table;
}

.dashboard-stat:after {
  clear: both;
}

.portlet .dashboard-stat:last-child {
  margin-bottom: 0;
}

.dashboard-stat .visual {
  width: 80px;
  height: 80px;
  display: block;
  float: left;
  padding-top: 10px;
  padding-left: 15px;
  margin-bottom: 15px;
  font-size: 35px;
  line-height: 35px;
}

.dashboard-stat .visual>i {
  margin-left: -35px;
  font-size: 110px;
  line-height: 110px;
}

.dashboard-stat .details {
  position: absolute;
  right: 15px;
  padding-right: 15px;
}

.dashboard-stat .details .number {
  padding-top: 25px;
  text-align: right;
  font-size: 34px;
  line-height: 36px;
  letter-spacing: -1px;
  margin-bottom: 0px;
  font-weight: 300;
}

.dashboard-stat .details .desc {
  text-align: right;
  font-size: 16px;
  letter-spacing: 0px;
  font-weight: 300;
}

.dashboard-stat .more {
  clear: both;
  display: block;
  padding: 6px 10px 6px 10px;
  position: relative;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 11px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.dashboard-stat .more:hover {
  text-decoration: none;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.dashboard-stat .more>i {
  display: inline-block;
  margin-top: 1px;
  float: right;
}

.dashboard-stat.dashboard-stat-v2 .visual {
  padding-top: 35px;
  margin-bottom: 40px;
}

.dashboard-stat.white {
  background-color: #ffffff;
}

.dashboard-stat.white.dashboard-stat-light:hover {
  background-color: whitesmoke;
}

.dashboard-stat.white .visual>i {
  color: #666;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.white .details .number {
  color: #666;
}

.dashboard-stat.white .details .desc {
  color: #666;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.white .more {
  color: #666;
  background-color: whitesmoke;
}

.dashboard-stat.default {
  background-color: #e1e5ec;
}

.dashboard-stat.default.dashboard-stat-light:hover {
  background-color: #d5dae4;
}

.dashboard-stat.default .visual>i {
  color: #666;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.default .details .number {
  color: #666;
}

.dashboard-stat.default .details .desc {
  color: #666;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.default .more {
  color: #666;
  background-color: #d5dae4;
}

.dashboard-stat.dark {
  background-color: #2f353b;
}

.dashboard-stat.dark.dashboard-stat-light:hover {
  background-color: #262b30;
}

.dashboard-stat.dark .visual>i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.dark .details .number {
  color: #FFFFFF;
}

.dashboard-stat.dark .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.dark .more {
  color: #FFFFFF;
  background-color: #262b30;
}

.dashboard-stat.blue {
  background-color: #3598dc;
}

.dashboard-stat.blue.dashboard-stat-light:hover {
  background-color: #258fd7;
}

.dashboard-stat.blue .visual>i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.blue .details .number {
  color: #FFFFFF;
}

.dashboard-stat.blue .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.blue .more {
  color: #FFFFFF;
  background-color: #258fd7;
}

.dashboard-stat.blue-steel {
  background-color: #4B77BE;
}

.dashboard-stat.blue-steel.dashboard-stat-light:hover {
  background-color: #416db4;
}

.dashboard-stat.blue-steel .visual>i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.blue-steel .details .number {
  color: #FFFFFF;
}

.dashboard-stat.blue-steel .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.blue-steel .more {
  color: #FFFFFF;
  background-color: #416db4;
}

.dashboard-stat.blue-soft {
  background-color: #4c87b9;
}

.dashboard-stat.blue-soft.dashboard-stat-light:hover {
  background-color: #447dad;
}

.dashboard-stat.blue-soft .visual>i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.blue-soft .details .number {
  color: #FFFFFF;
}

.dashboard-stat.blue-soft .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.blue-soft .more {
  color: #FFFFFF;
  background-color: #447dad;
}

.dashboard-stat.blue-dark {
  background-color: #5e738b;
}

.dashboard-stat.blue-dark.dashboard-stat-light:hover {
  background-color: #56697f;
}

.dashboard-stat.blue-dark .visual>i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.blue-dark .details .number {
  color: #FFFFFF;
}

.dashboard-stat.blue-dark .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.blue-dark .more {
  color: #FFFFFF;
  background-color: #56697f;
}

.dashboard-stat.blue-sharp {
  background-color: #5C9BD1;
}

.dashboard-stat.blue-sharp.dashboard-stat-light:hover {
  background-color: #4c91cd;
}

.dashboard-stat.blue-sharp .visual>i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.blue-sharp .details .number {
  color: #FFFFFF;
}

.dashboard-stat.blue-sharp .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.blue-sharp .more {
  color: #FFFFFF;
  background-color: #4c91cd;
}

.dashboard-stat.green {
  background-color: #C3073F;
}

.dashboard-stat.green.dashboard-stat-light:hover {
  background-color: #2bb8c4;
}

.dashboard-stat.green .visual>i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.green .details .number {
  color: #FFFFFF;
}

.dashboard-stat.green .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.green .more {
  color: #FFFFFF;
  background-color: #2bb8c4;
}

.dashboard-stat.green-seagreen {
  background-color: #1BA39C;
}

.dashboard-stat.green-seagreen.dashboard-stat-light:hover {
  background-color: #18918b;
}

.dashboard-stat.green-seagreen .visual>i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.green-seagreen .details .number {
  color: #FFFFFF;
}

.dashboard-stat.green-seagreen .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.green-seagreen .more {
  color: #FFFFFF;
  background-color: #18918b;
}

.dashboard-stat.green-turquoise {
  background-color: #36D7B7;
}

.dashboard-stat.green-turquoise.dashboard-stat-light:hover {
  background-color: #29cfae;
}

.dashboard-stat.green-turquoise .visual>i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.green-turquoise .details .number {
  color: #FFFFFF;
}

.dashboard-stat.green-turquoise .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.green-turquoise .more {
  color: #FFFFFF;
  background-color: #29cfae;
}

.dashboard-stat.green-haze {
  background-color: #44b6ae;
}

.dashboard-stat.green-haze.dashboard-stat-light:hover {
  background-color: #3ea7a0;
}

.dashboard-stat.green-haze .visual>i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.green-haze .details .number {
  color: #FFFFFF;
}

.dashboard-stat.green-haze .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.green-haze .more {
  color: #FFFFFF;
  background-color: #3ea7a0;
}

.dashboard-stat.green-jungle {
  background-color: #26C281;
}

.dashboard-stat.green-jungle.dashboard-stat-light:hover {
  background-color: #23b176;
}

.dashboard-stat.green-jungle .visual>i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.green-jungle .details .number {
  color: #FFFFFF;
}

.dashboard-stat.green-jungle .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.green-jungle .more {
  color: #FFFFFF;
  background-color: #23b176;
}

.dashboard-stat.green-soft {
  background-color: #3faba4;
}

.dashboard-stat.green-soft.dashboard-stat-light:hover {
  background-color: #3a9c96;
}

.dashboard-stat.green-soft .visual>i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.green-soft .details .number {
  color: #FFFFFF;
}

.dashboard-stat.green-soft .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.green-soft .more {
  color: #FFFFFF;
  background-color: #3a9c96;
}

.dashboard-stat.green-dark {
  background-color: #4DB3A2;
}

.dashboard-stat.green-dark.dashboard-stat-light:hover {
  background-color: #46a595;
}

.dashboard-stat.green-dark .visual>i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.green-dark .details .number {
  color: #FFFFFF;
}

.dashboard-stat.green-dark .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.green-dark .more {
  color: #FFFFFF;
  background-color: #46a595;
}

.dashboard-stat.green-sharp {
  background-color: #3562d9;
}

.dashboard-stat.green-sharp.dashboard-stat-light:hover {
  background-color: #26a4af;
}

.dashboard-stat.green-sharp .visual>i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.green-sharp .details .number {
  color: #FFFFFF;
}

.dashboard-stat.green-sharp .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.green-sharp .more {
  color: #FFFFFF;
  background-color: #26a4af;
}

.dashboard-stat.green-steel {
  background-color: #cf8900;
}

.dashboard-stat.green-steel.dashboard-stat-light:hover {
  background-color: #25a4a5;
}

.dashboard-stat.green-steel .visual>i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.green-steel .details .number {
  color: #FFFFFF;
}

.dashboard-stat.green-steel .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.green-steel .more {
  color: #FFFFFF;
  background-color: #25a4a5;
}

.dashboard-stat.red {
  background-color: #e7505a;
}

.dashboard-stat.red.dashboard-stat-light:hover {
  background-color: #e53e49;
}

.dashboard-stat.red .visual>i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.red .details .number {
  color: #ffffff;
}

.dashboard-stat.red .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.red .more {
  color: #ffffff;
  background-color: #e53e49;
}

.dashboard-stat.red-pink {
  background-color: #E08283;
}

.dashboard-stat.red-pink.dashboard-stat-light:hover {
  background-color: #dc7273;
}

.dashboard-stat.red-pink .visual>i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.red-pink .details .number {
  color: #ffffff;
}

.dashboard-stat.red-pink .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.red-pink .more {
  color: #ffffff;
  background-color: #dc7273;
}

.dashboard-stat.red-soft {
  background-color: #d05454;
}

.dashboard-stat.red-soft.dashboard-stat-light:hover {
  background-color: #cc4444;
}

.dashboard-stat.red-soft .visual>i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.red-soft .details .number {
  color: #ffffff;
}

.dashboard-stat.red-soft .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.red-soft .more {
  color: #ffffff;
  background-color: #cc4444;
}

.dashboard-stat.red-haze {
  background-color: #f36a5a;
}

.dashboard-stat.red-haze.dashboard-stat-light:hover {
  background-color: #f25947;
}

.dashboard-stat.red-haze .visual>i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.red-haze .details .number {
  color: #ffffff;
}

.dashboard-stat.red-haze .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.red-haze .more {
  color: #ffffff;
  background-color: #f25947;
}

.dashboard-stat.yellow {
  background-color: #c49f47;
}

.dashboard-stat.yellow.dashboard-stat-light:hover {
  background-color: #bb953c;
}

.dashboard-stat.yellow .visual>i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.yellow .details .number {
  color: #ffffff;
}

.dashboard-stat.yellow .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.yellow .more {
  color: #ffffff;
  background-color: #bb953c;
}

.dashboard-stat.yellow-gold {
  background-color: #E87E04;
}

.dashboard-stat.yellow-gold.dashboard-stat-light:hover {
  background-color: #d47304;
}

.dashboard-stat.yellow-gold .visual>i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.yellow-gold .details .number {
  color: #ffffff;
}

.dashboard-stat.yellow-gold .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.yellow-gold .more {
  color: #ffffff;
  background-color: #d47304;
}

.dashboard-stat.yellow-soft {
  background-color: #c8d046;
}

.dashboard-stat.yellow-soft.dashboard-stat-light:hover {
  background-color: #c3cc36;
}

.dashboard-stat.yellow-soft .visual>i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.yellow-soft .details .number {
  color: #ffffff;
}

.dashboard-stat.yellow-soft .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.yellow-soft .more {
  color: #ffffff;
  background-color: #c3cc36;
}

.dashboard-stat.yellow-haze {
  background-color: #c5bf66;
}

.dashboard-stat.yellow-haze.dashboard-stat-light:hover {
  background-color: #bfb957;
}

.dashboard-stat.yellow-haze .visual>i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.yellow-haze .details .number {
  color: #ffffff;
}

.dashboard-stat.yellow-haze .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.yellow-haze .more {
  color: #ffffff;
  background-color: #bfb957;
}

.dashboard-stat.purple {
  background-color: #8E44AD;
}

.dashboard-stat.purple.dashboard-stat-light:hover {
  background-color: #823e9e;
}

.dashboard-stat.purple .visual>i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.purple .details .number {
  color: #ffffff;
}

.dashboard-stat.purple .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.purple .more {
  color: #ffffff;
  background-color: #823e9e;
}

.dashboard-stat.purple-plum {
  background-color: #8775a7;
}

.dashboard-stat.purple-plum.dashboard-stat-light:hover {
  background-color: #7c699f;
}

.dashboard-stat.purple-plum .visual>i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.purple-plum .details .number {
  color: #ffffff;
}

.dashboard-stat.purple-plum .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.purple-plum .more {
  color: #ffffff;
  background-color: #7c699f;
}

.dashboard-stat.purple-medium {
  background-color: #BF55EC;
}

.dashboard-stat.purple-medium.dashboard-stat-light:hover {
  background-color: #b843ea;
}

.dashboard-stat.purple-medium .visual>i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.purple-medium .details .number {
  color: #ffffff;
}

.dashboard-stat.purple-medium .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.purple-medium .more {
  color: #ffffff;
  background-color: #b843ea;
}

.dashboard-stat.purple-studio {
  background-color: #8E44AD;
}

.dashboard-stat.purple-studio.dashboard-stat-light:hover {
  background-color: #823e9e;
}

.dashboard-stat.purple-studio .visual>i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.purple-studio .details .number {
  color: #ffffff;
}

.dashboard-stat.purple-studio .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.purple-studio .more {
  color: #ffffff;
  background-color: #823e9e;
}

.dashboard-stat.purple-sharp {
  background-color: #796799;
}

.dashboard-stat.purple-sharp.dashboard-stat-light:hover {
  background-color: #6f5f8d;
}

.dashboard-stat.purple-sharp .visual>i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.purple-sharp .details .number {
  color: #ffffff;
}

.dashboard-stat.purple-sharp .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.purple-sharp .more {
  color: #ffffff;
  background-color: #6f5f8d;
}

.dashboard-stat.purple-soft {
  background-color: #8877a9;
}

.dashboard-stat.purple-soft.dashboard-stat-light:hover {
  background-color: #7d6ba1;
}

.dashboard-stat.purple-soft .visual>i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.dashboard-stat.purple-soft .details .number {
  color: #ffffff;
}

.dashboard-stat.purple-soft .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dashboard-stat.purple-soft .more {
  color: #ffffff;
  background-color: #7d6ba1;
}

.dashboard-stat-light {
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.dashboard-stat-light .details {
  margin-bottom: 5px;
}

.dashboard-stat-light .details .number {
  font-weight: 300;
  margin-bottom: 0px;
}

/***
Dashboard Stats 2
***/

.dashboard-stat2 {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  background: #fff;
  padding: 15px 15px 30px 15px;
  margin-bottom: 20px;
}

.dashboard-stat2.bordered {
  border: 1px solid #e7ecf1;
}

.dashboard-stat2 .display {
  margin-bottom: 20px;
}

.dashboard-stat2 .display:before,
.dashboard-stat2 .display:after {
  content: " ";
  display: table;
}

.dashboard-stat2 .display:after {
  clear: both;
}

.dashboard-stat2 .display .number {
  float: left;
  display: inline-block;
}

.dashboard-stat2 .display .number h3 {
  margin: 0 0 2px 0;
  padding: 0;
  font-size: 30px;
  font-weight: 400;
}

.dashboard-stat2 .display .number h3>small {
  font-size: 23px;
}

.dashboard-stat2 .display .number small {
  font-size: 14px;
  color: #AAB5BC;
  font-weight: 600;
  text-transform: uppercase;
}

.dashboard-stat2 .display .icon {
  display: inline-block;
  float: right;
  padding: 7px 0 0 0;
}

.dashboard-stat2 .display .icon>i {
  color: #cbd4e0;
  font-size: 26px;
}

.dashboard-stat2 .progress-info {
  clear: both;
}

.dashboard-stat2 .progress-info .progress {
  margin: 0;
  height: 4px;
  clear: both;
  display: block;
}

.dashboard-stat2 .progress-info .status {
  margin-top: 5px;
  font-size: 11px;
  color: #AAB5BC;
  font-weight: 600;
  text-transform: uppercase;
}

.dashboard-stat2 .progress-info .status .status-title {
  float: left;
  display: inline-block;
}

.dashboard-stat2 .progress-info .status .status-number {
  float: right;
  display: inline-block;
}

/***
Text Stats
***/

.text-stat h3 {
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 18px;
}

.text-stat span {
  font-size: 13px !important;
}

@media (max-width: 767px) {

  /* 767px */
  .text-stat {
    margin-top: 20px;
  }
}

/***
Social Icons
***/

.social-icons {
  padding: 0;
  margin: 0;
}

.social-icons:before,
.social-icons:after {
  content: " ";
  display: table;
}

.social-icons:after {
  clear: both;
}

.social-icons li {
  float: left;
  display: inline;
  list-style: none;
  margin-right: 5px;
  margin-bottom: 5px;
  text-indent: -9999px;
}

.social-icons li>a {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  width: 28px;
  height: 28px;
  display: block;
  background-position: 0 0;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.social-icons li:hover>a {
  background-position: 0 -38px;
}

.social-icons.social-icons-color>li>a {
  opacity: 0.7;
  background-position: 0 -38px !important;
}

.social-icons.social-icons-color>li>a:hover {
  opacity: 1;
}

.social-icons.social-icons-circle>li>a {
  border-radius: 25px !important;
}

/***
Inline Social Icons
***/

.social-icon {
  display: inline-block !important;
  width: 28px;
  height: 28px;
  background-position: 0 0;
  background-repeat: no-repeat;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.social-icon.social-icon-circle {
  border-radius: 25px !important;
}

.social-icon:hover {
  background-position: 0 -38px;
}

.social-icon-color {
  opacity: 0.7;
  background-position: 0 -38px !important;
}

.social-icon-color:hover {
  opacity: 1;
}

/***
Scrollable Tables
***/

.table-scrollable {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  border: 1px solid #e7ecf1;
  margin: 10px 0 !important;
}

.DTS .table-scrollable {
  border: 0;
}

.table-scrollable.table-scrollable-borderless {
  border: 0;
}

.table-scrollable>.table {
  width: 100% !important;
  margin: 0 !important;
  margin-bottom: 0;
  background-color: #fff;
}

.table-scrollable>.table>thead>tr>th,
.table-scrollable>.table>tbody>tr>th,
.table-scrollable>.table>tfoot>tr>th,
.table-scrollable>.table>tfoot>tr>th,
.table-scrollable>.table>tfoot>tr>td {
  white-space: nowrap;
}

.table-scrollable>.table-bordered {
  border: 0;
}

.table-scrollable>.table-bordered>thead>tr>th:first-child,
.table-scrollable>.table-bordered>tbody>tr>th:first-child,
.table-scrollable>.table-bordered>tfoot>tr>th:first-child,
.table-scrollable>.table-bordered>thead>tr>td:first-child,
.table-scrollable>.table-bordered>tbody>tr>td:first-child,
.table-scrollable>.table-bordered>tfoot>tr>td:first-child {
  border-left: 0;
}

.table-scrollable>.table-bordered>thead>tr>th:last-child,
.table-scrollable>.table-bordered>tbody>tr>th:last-child,
.table-scrollable>.table-bordered>tfoot>tr>th:last-child,
.table-scrollable>.table-bordered>thead>tr>td:last-child,
.table-scrollable>.table-bordered>tbody>tr>td:last-child,
.table-scrollable>.table-bordered>tfoot>tr>td:last-child {
  border-right: 0;
}

.table-scrollable>.table-bordered>thead>tr:last-child>th,
.table-scrollable>.table-bordered>tbody>tr:last-child>th,
.table-scrollable>.table-bordered>tfoot>tr:last-child>th,
.table-scrollable>.table-bordered>thead>tr:last-child>td,
.table-scrollable>.table-bordered>tbody>tr:last-child>td,
.table-scrollable>.table-bordered>tfoot>tr:last-child>td {
  border-bottom: 0;
}

/***
Customized Bootstrap Tables
***/

.table td,
.table th {
  font-size: 14px;
}

.table.table-bordered thead>tr>th {
  border-bottom: 0;
}

.table td .img-responsive {
  width: 100%;
}

/***
Responsive Flip Scroll Tables
***/

.flip-scroll table {
  width: 100%;
}

@media only screen and (max-width: 768px) {

  /* 768px */
  .flip-scroll .flip-content:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }

  .flip-scroll * html .flip-content {
    zoom: 1;
  }

  .flip-scroll *:first-child+html .flip-content {
    zoom: 1;
  }

  .flip-scroll table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .flip-scroll th,
  .flip-scroll td {
    margin: 0;
    vertical-align: top;
  }

  .flip-scroll th {
    text-align: left;
    border: 0 !important;
    border-bottom: 1px solid #ddd !important;
    border-right: 1px solid #ddd !important;
    font-size: 13px !important;
    padding: 5px;
    width: auto !important;
  }

  .flip-scroll table {
    display: block;
    position: relative;
    width: 100%;
  }

  .flip-scroll thead {
    display: block;
    float: left;
  }

  .flip-scroll tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }

  .flip-scroll .flip-content tbody tr td {
    font-size: 13px;
    line-height: 1.483;
  }

  .flip-scroll .table-bordered.flip-content tbody tr td {
    font-size: 13px;
    line-height: 1.43;
  }

  .flip-scroll thead tr {
    display: block;
  }

  .flip-scroll th {
    display: block;
    text-align: right;
  }

  .flip-scroll tbody tr {
    display: inline-block;
    vertical-align: top;
    margin-left: -5px;
  }

  .flip-scroll td {
    display: block;
    min-height: 1.25em;
    text-align: left;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }

  /* sort out borders */
  .flip-scroll th {
    border-bottom: 0;
    border-left: 0;
  }

  .flip-scroll td {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }

  .flip-scroll tbody tr {
    border-left: 1px solid #ddd;
  }

  .flip-scroll th:last-child,
  .flip-scroll td:last-child {
    border-bottom: 1px solid #ddd;
  }
}

/***
Custom tables
***/

.table-toolbar {
  margin-bottom: 20px;
}

.table-toolbar:before,
.table-toolbar:after {
  content: " ";
  display: table;
}

.table-toolbar:after {
  clear: both;
}

.table.table-full-width {
  width: 100% !important;
}

.table .btn {
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 5px;
}

.table thead tr th {
  font-size: 14px;
  font-weight: 600;
}

.table-advance {
  margin-bottom: 10px !important;
}

.table-advance thead {
  color: #3f444a;
}

.table-advance thead tr th {
  background-color: #f1f4f7;
  font-size: 14px;
  font-weight: 400;
  color: #3f444a;
}

.table-advance div.success,
.table-advance div.info,
.table-advance div.important,
.table-advance div.warning,
.table-advance div.danger {
  position: absolute;
  margin-top: -5px;
  float: left;
  width: 2px;
  height: 30px;
  margin-right: 20px !important;
}

.table-advance tr td {
  border-left-width: 0px;
}

.table-advance tr td:first-child {
  border-left-width: 2px !important;
}

.table-advance tr td.highlight:first-child a {
  margin-left: 15px;
}

.table-advance td.highlight div.primary {
  border-left: 2px solid #337ab7;
}

.table-advance td.highlight div.success {
  border-left: 2px solid #36c6d3;
}

.table-advance td.highlight div.info {
  border-left: 2px solid #659be0;
}

.table-advance td.highlight div.warning {
  border-left: 2px solid #F1C40F;
}

.table-advance td.highlight div.danger {
  border-left: 2px solid #ed6b75;
}

@media (max-width: 767px) {

  /* 767px */
  .table-advance tr>td.highlight:first-child a {
    margin-left: 8px;
  }
}

/***
Light Table
***/

.table.table-light {
  border: 0 !important;
}

.table.table-light>thead>tr:hover>th {
  background: none;
}

.table.table-light>thead>tr.uppercase {
  text-transform: uppercase;
}

.table.table-light>thead>tr>th {
  font-weight: 600;
  font-size: 13px;
  color: #93a2a9;
  font-family: "Roboto", normal;
  border: 0;
  border-bottom: 1px solid #F2F5F8;
}

.table.table-light>tbody>tr:last-child>td {
  border: 0;
}

.table.table-light>tbody>tr>td {
  border: 0;
  border-bottom: 1px solid #F2F5F8;
  color: #8896a0;
  vertical-align: middle;
}

.table.table-light>tbody>tr>td.fit {
  width: 1px;
  padding-right: 3px;
}

.table.table-light.table-hover>tbody>tr>td:hover,
.table.table-light.table-hover>tbody>tr>th:hover,
.table.table-light.table-hover>tbody>tr:hover>td {
  background: #f9fafb !important;
}

.table-hover>tbody>tr:hover,
.table-hover>tbody>tr:hover>td {
  background: #f3f4f6 !important;
}

/***
Customized Bootstrap Tabs 
***/

/* Tabs and pills */

.nav-tabs {
  margin-bottom: 10px;
}

.nav-tabs>li>a {
  font-size: 14px;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  -o-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.nav-tabs>li>a>.badge {
  margin-top: -6px;
}

.nav-tabs>li .dropdown-menu:before,
.nav-tabs>li .dropdown-menu:after {
  display: none;
}

.nav-tabs.nav-tabs-sm>li>a {
  font-size: 13px;
}

.nav-tabs .dropdown.open>.dropdown-toggle {
  background: #eee;
  color: #0d638f;
  border-color: transparent;
}

/* Left and right tabs */

.tabs-right.nav-tabs,
.tabs-left.nav-tabs {
  border-bottom: 0;
}

.tabs-right.nav-tabs>li,
.tabs-left.nav-tabs>li {
  float: none;
}

.tabs-right.nav-tabs>li>a,
.tabs-left.nav-tabs>li>a {
  margin-right: 0;
  margin-bottom: 3px;
}

/* Left tabs */

.tabs-left.nav-tabs {
  border-right: 1px solid #ddd;
}

.tabs-left.nav-tabs>li>a {
  display: block;
  margin-right: -1px;
}

.tabs-left.nav-tabs>li>a:hover,
.tabs-left.nav-tabs>li>a:focus {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -ms-border-radius: 4px 0 0 4px;
  -o-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
  border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}

.tabs-left.nav-tabs>li.active>a,
.tabs-left.nav-tabs>li.active>a:hover>li.active>a:focus {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -ms-border-radius: 4px 0 0 4px;
  -o-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
  border-color: #ddd transparent #ddd #ddd;
  *border-right-color: #ffffff;
}

/* Right tabs */

.tabs-right.nav-tabs {
  border-left: 1px solid #ddd;
}

.tabs-right.nav-tabs>li>a {
  display: block;
  margin-left: -1px;
}

.tabs-right.nav-tabs>li>a:hover,
.tabs-right.nav-tabs>li>a:focus {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  -o-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}

.tabs-right.nav-tabs>li.active>a,
.tabs-right.nav-tabs>li.active>a:hover>li.active>a:focus {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  -o-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  border-color: #ddd #ddd #ddd transparent;
  *border-left-color: #ffffff;
}

/* Below tabs */

.tabs-below>.nav-tabs {
  border-bottom: 0;
  margin-bottom: 0px;
  margin-top: 10px;
}

.tabs-below>.nav-tabs {
  border-top: 1px solid #ddd;
  margin-bottom: 0;
  margin-top: 10px;
}

.tabs-below>.nav-tabs>li>a {
  margin-top: -1px;
  margin-bottom: 0;
}

.tabs-below>.nav-tabs>li>a:hover,
.tabs-below>.nav-tabs>li>a:focus {
  border-top-color: #ddd;
  /* border-bottom-color: transparent; */
}

.tabs-below>.nav-tabs>li>a .dropdown-menu {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.tabs-below>.nav-tabs .active a,
.tabs-below>.nav-tabs .active a:hover .active a:focus {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  -o-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  border-color: transparent #ddd #ddd #ddd !important;
}

/***
Custom tabs
***/

/* In BS3.0.0 tabbable class was removed. We had to added it back */

.tabbable:before,
.tabbable:after {
  content: " ";
  display: table;
}

.tabbable:after {
  clear: both;
}

.width100Perc {
  width: 100%;
}

.tabbable-custom {
  margin-bottom: 15px;
  padding: 0px;
  /* overflow: hidden; */
  /* justified tabs */
  /* boxless tabs */
  /* below justified tabs */
  /* full width tabs */
  /* below tabs */
}

.tabbable-custom>.nav-tabs {
  border: none;
  margin: 0px;
}

.tabbable-custom>.nav-tabs>li {
  margin-right: 2px;
  border-top: 2px solid transparent;
}

.tabbable-custom>.nav-tabs>li>a {
  margin-right: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.tabbable-custom>.nav-tabs>li>a:hover {
  background: none;
  border-color: transparent;
}

.tabbable-custom>.nav-tabs>li.active {
  border-top: 2px solid #cf8900;
  margin-top: 0;
  position: relative;
  background: 'red';
}

.tabbable-custom>.nav-tabs>li.active>a {
  border-top: none !important;
  font-weight: 400;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.tabbable-custom>.nav-tabs>li.active>a:hover {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  border-top: none;
  background: #fff;
  border-color: #d4d4d4 #d4d4d4 transparent;
}

.tabbable-custom>.tab-content {
  /*background-color: #fff;
        border: 1px solid #ddd;
        padding: 10px;
        -webkit-border-radius: 0 0 4px 4px;
        -moz-border-radius: 0 0 4px 4px;
        -ms-border-radius: 0 0 4px 4px;
        -o-border-radius: 0 0 4px 4px;
        border-radius: 0 0 4px 4px;*/
}

.tabbable-custom.nav-justified>.tab-content {
  margin-top: -1px;
}

.tabbable-custom.tabs-below.nav-justified .tab-content {
  margin-top: 0px;
  margin-bottom: -2px;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  -o-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.tabbable-custom.tabbable-full-width>.nav-tabs>li>a {
  color: #424242;
  font-size: 15px;
  padding: 9px 15px;
}

.tabbable-custom.tabbable-full-width>.tab-content {
  padding: 15px 0;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.tabbable-custom.tabs-below .nav-tabs>li>a {
  border-top: none;
  border-bottom: 2px solid transparent;
  margin-top: -1px;
}

.tabbable-custom.tabs-below .nav-tabs>li.active {
  border-top: none;
  border-bottom: 3px solid #d12610;
  margin-bottom: 0;
  position: relative;
}

.tabbable-custom.tabs-below .nav-tabs>li.active>a {
  border-bottom: none;
}

.tabbable-custom.tabs-below .nav-tabs>li.active>a:hover {
  background: #fff;
  border-color: #d4d4d4 #d4d4d4 transparent;
}

.tabbable-custom.tabbable-noborder>.nav-tabs>li>a {
  border: 0;
}

.tabbable-custom.tabbable-noborder .tab-content {
  border: 0;
}

.portlet:not(.light) .tabbable-line {
  padding-top: 15px;
}

.tabbable-line>.nav-tabs {
  border: none;
  margin: 0px;
}

.tabbable-line>.nav-tabs>li {
  margin: 0;
  border-bottom: 4px solid transparent;
}

.tabbable-line>.nav-tabs>li>a {
  background: none !important;
  border: 0;
  margin: 0;
  padding-left: 15px;
  padding-right: 15px;
  color: #737373;
}

.tabbable-line>.nav-tabs>li>a>i {
  color: #a6a6a6;
}

.tabbable-line>.nav-tabs>li.active {
  background: none;
  border-bottom: 4px solid #36c6d3;
  position: relative;
}

.tabbable-line>.nav-tabs>li.active>a {
  border: 0;
  color: #333;
}

.tabbable-line>.nav-tabs>li.active>a>i {
  color: #404040;
}

.tabbable-line>.nav-tabs>li.open,
.tabbable-line>.nav-tabs>li:hover {
  background: none;
  border-bottom: 4px solid #9fe4ea;
}

.tabbable-line>.nav-tabs>li.open>a,
.tabbable-line>.nav-tabs>li:hover>a {
  border: 0;
  background: none !important;
  color: #333;
}

.tabbable-line>.nav-tabs>li.open>a>i,
.tabbable-line>.nav-tabs>li:hover>a>i {
  color: #a6a6a6;
}

.tabbable-line>.nav-tabs>li.open .dropdown-menu,
.tabbable-line>.nav-tabs>li:hover .dropdown-menu {
  margin-top: 0px;
}

.tabbable-line>.tab-content {
  margin-top: 0;
  border: 0;
  border-top: 1px solid #eef1f5;
  padding: 30px 0;
}

.page-container-bg-solid .tabbable-line>.tab-content {
  border-top: 1px solid #dae2ea;
}

.portlet .tabbable-line>.tab-content {
  padding-bottom: 0;
}

.tabbable-line.tabs-below>.nav-tabs>li {
  border-top: 4px solid transparent;
}

.tabbable-line.tabs-below>.nav-tabs>li>a {
  margin-top: 0;
}

.tabbable-line.tabs-below>.nav-tabs>li:hover {
  border-bottom: 0;
  border-top: 4px solid #fbdcde;
}

.tabbable-line.tabs-below>.nav-tabs>li.active {
  margin-bottom: -2px;
  border-bottom: 0;
  border-top: 4px solid #ed6b75;
}

.tabbable-line.tabs-below>.tab-content {
  margin-top: -10px;
  border-top: 0;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

.portlet .tabbable-bordered {
  margin-top: 20px;
}

.tabbable-bordered .nav-tabs {
  margin-bottom: 0;
  border-bottom: 0;
}

.tabbable-bordered .tab-content {
  padding: 30px 20px 20px 20px;
  border: 1px solid #ddd;
  background: #ffffff;
}

/***
Tiles(new in v1.1.1)
***/

.tiles {
  margin-right: -10px;
}

.tiles:before,
.tiles:after {
  display: table;
  content: " ";
}

.tiles:after {
  clear: both;
}

/***
Custimized Bootstrap Wells
***/

.well {
  border: 0;
  padding: 20px;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.well-lg {
  padding: 40px;
}

.well-sm {
  padding: 10px;
}

/*--------------------------------------------------
	[Widgets]
----------------------------------------------------*/

/*** Widget Background Colors ***/

.widget-bg-color-purple {
  background: #9a7caf;
}

.widget-bg-color-purple-dark {
  background: #4b365a;
}

.widget-bg-color-purple-light {
  background: #674d79;
}

.widget-bg-color-green {
  background: #4db3a4;
}

.widget-bg-color-red {
  background: #f36a5a;
}

.widget-bg-color-blue {
  background: #5b9bd1;
}

.widget-bg-color-#bdbdbd {
  background: #323c45;
}

.widget-bg-color-#bdbdbd-dark {
  background: #144f57;
}

.widget-bg-color-white {
  background: #fff;
}

.widget-bg-color-dark {
  background: #3e4f5e;
}

.widget-bg-color-dark-light {
  background: #8e9daa;
}

.widget-bg-color-fb {
  background: #475e98;
}

.widget-bg-color-tw {
  background: #55acee;
}

/*** Widget Title Colors ***/

.widget-title-color-purple {
  color: #9a7caf;
}

.widget-title-color-purple-dark {
  color: #4b365a;
}

.widget-title-color-purple-light {
  color: #674d79;
}

.widget-title-color-green {
  color: #4db3a4;
}

.widget-title-color-red {
  color: #f36a5a;
}

.widget-title-color-blue {
  color: #5b9bd1;
}

.widget-title-color-#bdbdbd {
  color: #323c45;
}

.widget-title-color-#bdbdbd-dark {
  color: #144f57;
}

.widget-title-color-white {
  color: #fff;
}

.widget-title-color-dark {
  color: #3e4f5e;
}

.widget-title-color-dark-light {
  color: #8e9daa;
}

.widget-title-color-fb {
  color: #475e98;
}

.widget-title-color-tw {
  color: #55acee;
}

.overflow-h {
  overflow: hidden;
}

/*** Widget Carousel ***/

/*** Widget Gradient ***/

.widget-gradient {
  position: relative;
  min-height: 350px;
  overflow: hidden;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 4px;
}

.widget-gradient .widget-gradient-body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  padding: 20px;
}

.widget-gradient .widget-gradient-body .widget-gradient-title {
  font-size: 21px;
  font-weight: 600;
  color: #fff;
  margin: 0;
}

.widget-gradient .widget-gradient-body .widget-gradient-body-actions {
  position: absolute;
  right: 20px;
  bottom: 20px;
  padding: 0;
  margin: 0;
}

.widget-gradient .widget-gradient-body .widget-gradient-body-actions li {
  font-size: 14px;
  padding: 0 0 0 8px;
}

.widget-gradient .widget-gradient-body .widget-gradient-body-actions li:first-child {
  padding-left: 0;
}

.widget-gradient .widget-gradient-body .widget-gradient-body-actions li a {
  color: #fff;
}

.widget-gradient .widget-gradient-body .widget-gradient-body-actions li a:hover {
  color: #a1afbb;
  text-decoration: none;
}

/*** Widget Gradient ***/

.widget-wrap-img {
  border-radius: 4px;
  position: relative;
  min-height: 350px;
  padding: 20px;
}

.widget-wrap-img .widget-wrap-img-title {
  font-size: 21px;
  font-weight: 600;
  color: #3e4f5e;
  margin: 0 0 20px;
}

.widget-wrap-img .widget-wrap-img-element {
  position: absolute;
  bottom: 0;
  right: 0;
}

/*** Widget Tab ***/

.widget-tab {
  min-height: 420px;
  border-radius: 4px;
}

.widget-tab .nav-tabs {
  margin: 0;
  border-color: #eff1f3;
}

.widget-tab .nav-tabs>li {
  margin: 0 10px;
}

.widget-tab .nav-tabs>li:first-child {
  margin-left: 20px;
}

.widget-tab .nav-tabs>li>a {
  border: 0;
  font-weight: bold;
  color: #8e9daa;
  text-transform: uppercase;
  padding: 20px 0;
}

.widget-tab .nav-tabs>li>a>i {
  color: #8e9daa;
}

.widget-tab .nav-tabs>li.open,
.widget-tab .nav-tabs>li:hover {
  border-bottom: 1px solid #f36a5a;
}

.widget-tab .nav-tabs>li.open>a,
.widget-tab .nav-tabs>li:hover>a {
  border: 0;
  background: inherit;
  color: #f36a5a;
}

.widget-tab .nav-tabs>li.open>a>i,
.widget-tab .nav-tabs>li:hover>a>i {
  color: #f36a5a;
}

.widget-tab .nav-tabs>li.active {
  border-bottom: 1px solid #f36a5a;
  position: relative;
}

.widget-tab .nav-tabs>li.active>a {
  border: 0;
  color: #f36a5a;
}

.widget-tab .nav-tabs>li.active>a>i {
  color: #f36a5a;
}

.widget-tab .tab-content {
  padding: 20px;
  color: #8e9daa;
}

/*** Widget News ***/

.widget-news {
  overflow: hidden;
  margin-right: 10px;
  border-radius: 4px;
}

.widget-news .widget-news-left-elem {
  float: left;
  width: 100px;
  height: auto;
  margin-right: 15px;
}

.widget-news .widget-news-right-body {
  overflow: hidden;
}

.widget-news .widget-news-right-body .widget-news-right-body-title {
  font-size: 16px;
  font-weight: 600;
  color: #3e4f5e;
  margin: 0 0 5px;
  clear: both;
}

.widget-news .widget-news-right-body .widget-news-right-body-title .label {
  float: right;
  font-weight: 600;
  background: #a1afbb;
  border-radius: 3px !important;
}

.widget-news .widget-news-right-body p {
  font-size: 13px;
}

/*** Widget Thumb ***/

.widget-thumb {
  padding: 20px;
  border-radius: 4px;
}

.widget-thumb.bordered {
  border: 1px solid #e7ecf1;
}

.widget-thumb .widget-thumb-heading {
  font-size: 14px;
  font-weight: bold;
  color: #8e9daa;
  margin: 0 0 20px 0;
}

.widget-thumb .widget-thumb-wrap {
  overflow: hidden;
}

.widget-thumb .widget-thumb-wrap .widget-thumb-icon {
  float: left;
  width: 60px;
  height: 60px;
  display: inline-block;
  font-size: 20px;
  line-height: 41px;
  color: #fff;
  text-align: center;
  padding: 10px;
  margin-right: 15px;
}

.widget-thumb .widget-thumb-body {
  overflow: hidden;
}

.widget-thumb .widget-thumb-body .widget-thumb-body-stat {
  display: block;
  font-size: 26px;
  font-weight: 600;
  color: #171B1E;
}

/*** Widget Socials ***/

.widget-socials {
  border-radius: 4px;
  min-height: 250px;
  padding: 20px;
}

.widget-socials .widget-socials-title {
  font-size: 25px;
  font-weight: 700;
  line-height: 1.4;
  color: #fff;
  margin: 0 0 20px;
}

.widget-socials .widget-socials-paragraph {
  display: block;
  color: #65727d;
}

.widget-socials .widget-social-icon-fb,
.widget-socials .widget-social-icon-tw {
  font-size: 30px;
  margin: 30px 0;
}

.widget-socials .widget-social-icon-fb {
  color: #2b3f72;
}

.widget-socials .widget-social-icon-tw {
  color: #3686c3;
}

/*** Widget Comments ***/

.widget-comments {
  min-height: 420px;
}

/*** Widget Media ***/

.widget-media {
  border-radius: 4px;
  border-bottom: 1px solid #f6f9fc;
  overflow: hidden;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.widget-media .widget-media-elements {
  float: left;
  margin-right: 20px;
}

.widget-media .widget-btn-default {
  display: inline-block;
  font-size: 12px;
  color: #96a2b1;
  border: 1px solid #ebf0f6;
  padding: 3px 10px;
}

.widget-media .widget-btn-default .widget-btn-icon {
  line-height: 1.5;
}

.widget-media .widget-btn-default:hover {
  background: #ebf0f6;
  text-decoration: none;
}

.widget-media .widget-btn-red {
  display: inline-block;
  font-size: 12px;
  color: #f36a5a;
  border: 1px solid #ebf0f6;
  padding: 3px 10px;
}

.widget-media .widget-btn-red .widget-btn-icon {
  line-height: 1.5;
}

.widget-media .widget-btn-red:hover {
  color: #fff;
  background: #f36a5a;
  text-decoration: none;
}

.widget-media .widget-btn-blue {
  display: inline-block;
  font-size: 12px;
  color: #fff;
  border: 1px solid #ebf0f6;
  padding: 3px 10px;
  background: #337ab7;
}

.widget-media .widget-btn-blue .widget-btn-icon {
  line-height: 1.5;
}

.widget-media .widget-btn-blue:hover {
  color: #337ab7;
  background: #fff;
  text-decoration: none;
}

.widget-media .widget-media-body {
  overflow: hidden;
}

.widget-media .widget-media-body .widget-media-body-title {
  font-size: 15px;
  font-weight: 600;
  color: #5b9bd1;
  margin: 0 0 7px;
}

/*** Widget Blog ***/

.widget-blog {
  border-radius: 4px;
  background: #fff;
  padding: 20px;
  background-position: center center;
  background-size: cover;
  padding-top: 30px;
}

.widget-blog .widget-blog-heading {
  position: relative;
  margin-bottom: 30px;
}

.widget-blog .widget-blog-heading:before {
  position: absolute;
  bottom: -15px;
  left: 50%;
  width: 50px;
  height: 1px;
  border-width: 1px;
  background: #8e9daa;
  margin-left: -25px;
  content: " ";
}

.widget-blog .widget-blog-title {
  font-size: 20px;
  font-weight: 400;
  color: #3e4f5e;
  margin: 0 0 15px;
}

.widget-blog .widget-blog-title a {
  color: #3e4f5e;
}

.widget-blog .btn-widget-purple {
  display: inline-block;
  font-size: 13px;
  color: #8e9daa;
  border: 1px solid #8e9daa;
  padding: 7px 17px;
}

.widget-blog .btn-widget-purple:hover {
  color: #fff;
  background: #8e9daa;
  text-decoration: none;
}

/*** Widget Progress ***/

.widget-progress {
  min-height: 420px;
}

.widget-progress .widget-progress-element {
  border-radius: 4px;
  overflow: hidden;
  padding: 30px 10px;
}

.widget-progress .widget-progress-title {
  display: block;
  color: #fff;
  margin-bottom: 5px;
}

.widget-progress .progress {
  height: 3px;
  background: rgba(255, 255, 255, 0.2);
  margin-bottom: 0;
}

/*** Widget Gradient ***/

.widget-map {
  border-radius: 4px;
  min-height: 350px;
  border-radius: 3px;
}

.widget-map .widget-map-body {
  background: #fff;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 20px;
  overflow: hidden;
}

/* Widget Map for max-width 480px */

@media (max-width: 480px) {
  /* 480px */
}

/*** Widget Subscribe ***/

.widget-subscribe {
  border-radius: 4px;
  min-height: 250px;
  overflow: hidden;
  padding: 30px;
}

.widget-subscribe .widget-subscribe-no {
  float: left;
  font-size: 67px;
  font-weight: 600;
  line-height: 1;
  color: #9a7caf;
}

.widget-subscribe .widget-subscribe-title {
  font-size: 25px;
  font-weight: 700;
  line-height: 1.4;
  margin: 0 0 15px 45px;
}

.widget-subscribe.widget-subscribe-quote {
  position: relative;
}

.widget-subscribe.widget-subscribe-quote:before {
  position: absolute;
  top: 2px;
  font-size: 70px;
  color: #fff;
  content: "\201C";
}

/* Widget Subscribe for media queries */

@media (max-width: 767px) {

  /* 767px */
  .widget-subscribe.widget-subscribe-border {
    border-top: 1px solid #f5f8fb;
    border-bottom: 1px solid #f5f8fb;
    border-right: none;
  }
}

@media (min-width: 768px) {

  /* 768px */
  .widget-subscribe.widget-subscribe-border {
    border-left: 1px solid #f5f8fb;
    border-right: 1px solid #f5f8fb;
  }
}

@media (min-width: 767px) and (max-width: 991px) {

  /* 767px & 991px */
  .widget-subscribe.widget-subscribe-border {
    border-left: none;
  }

  .widget-subscribe.widget-subscribe-border-top {
    border-top: 1px solid #f5f8fb;
  }
}

/*--------------------------------------------------
    [Material Design]
----------------------------------------------------*/

.page-md .widget-bg-color-white,
.page-md .widget-map,
.page-md .widget-progress-element,
.page-md .widget-socials,
.page-md .widget-blog {
  box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.03);
}

/***
AngularJS Basic Animations
***/

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(15px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(15px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(15px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes bounceDelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes bounceDelay {

  0%,
  80%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  40% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@keyframes input-focus {
  0% {
    left: 20%;
    width: 20%;
  }

  99% {
    width: 0;
    left: 0;
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.m-heading-1 {
  margin: 0 0 20px 0;
  background: #ffffff;
  padding-left: 15px;
  border-left: 8px solid #88909a;
}

.m-heading-1>h3 {
  font-size: 20px;
  color: #3f444a;
  font-weight: 500;
  margin: 0 0 15px 0;
}

.m-heading-1>h3>i {
  font-size: 18px;
  color: #88909a;
}

.m-heading-1>p {
  color: #5c6873;
  margin: 10px 0 0 0;
}

.m-heading-1>p:first-child {
  margin-top: 0;
}

.m-heading-1.m-bordered {
  border-right: 1px solid #10161c;
  border-top: 1px solid #10161c;
  border-bottom: 1px solid #10161c;
  padding: 15px 15px;
}

.page-container-bg-solid .m-heading-1.m-bordered {
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
}

.m-heading-1.m-title-md>h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.m-heading-1.m-title-md>h3>i {
  font-size: 16px;
  color: #88909a;
}

.m-heading-1.m-title-md>p {
  margin: 15px 0;
}

.m-heading-1.m-title-sm>h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.m-heading-1.m-title-sm>h3>i {
  font-size: 14px;
  color: #88909a;
}

.m-heading-1.m-title-sm>p {
  margin: 10px 0;
}

@media (max-width: 991px) {

  /* 991px */
  .m-heading-1 {
    margin: 0px 0;
  }
}

/***
Timeline 
***/

.timeline {
  margin: 0;
  padding: 0;
  position: relative;
  margin-bottom: 30px;
}

.timeline:before {
  content: '';
  position: absolute;
  display: block;
  width: 4px;
  background: #f5f6fa;
  top: 0px;
  bottom: 0px;
  margin-left: 38px;
}

.timeline .timeline-item {
  margin: 0;
  padding: 0;
}

.timeline .timeline-badge {
  float: left;
  position: relative;
  padding-right: 30px;
  height: 80px;
  width: 80px;
}

.timeline .timeline-icon {
  width: 80px;
  height: 80px;
  background-color: #f5f6fa;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
  padding-top: 30px;
  padding-left: 22px;
}

.timeline .timeline-icon i {
  font-size: 34px;
}

.timeline .timeline-body {
  position: relative;
  padding: 20px;
  margin-top: 20px;
  margin-left: 110px;
  background-color: #f5f6fa;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.timeline .timeline-body:before,
.timeline .timeline-body:after {
  content: " ";
  display: table;
}

.timeline .timeline-body:after {
  clear: both;
}

.timeline .timeline-body-arrow {
  position: absolute;
  top: 30px;
  left: -14px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 14px 14px 14px 0;
  border-color: transparent #f5f6fa transparent transparent;
}

.timeline .timeline-body-head {
  margin-bottom: 10px;
}

.timeline .timeline-body-head-caption {
  float: left;
}

.timeline .timeline-body-title {
  font-size: 16px;
  font-weight: 600;
}

.timeline .timeline-body-time {
  font-size: 14px;
  margin-left: 10px;
}

.timeline .timeline-body-head-actions {
  float: right;
}

.timeline .timeline-body-head-actions .btn-group {
  margin-top: -2px;
}

.timeline .timeline-body-content {
  font-size: 14px;
  margin-top: 35px;
}

.timeline .timeline-body-img {
  width: 100px;
  height: 100px;
  margin: 5px 20px 0 0px;
}

.timeline.white-bg:before {
  background: #fff;
}

.timeline.white-bg .timeline-icon {
  background-color: #fff;
}

.timeline.white-bg .timeline-body {
  background-color: #fff;
}

.timeline.white-bg .timeline-body-arrow {
  border-color: transparent #fff transparent transparent;
}

@media (max-width: 768px) {
  .timeline .timeline-body-head-caption {
    width: 100%;
  }

  .timeline .timeline-body-head-actions {
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .timeline:before {
    margin-left: 28px;
  }

  .timeline .timeline-badge {
    padding-right: 40px;
    width: 60px;
    height: 60px;
  }

  .timeline .timeline-icon {
    width: 60px;
    height: 60px;
    padding-top: 23px;
    padding-left: 18px;
  }

  .timeline .timeline-icon i {
    font-size: 25px;
  }

  .timeline .timeline-body {
    margin-left: 80px;
  }

  .timeline .timeline-body-arrow {
    top: 17px;
  }
}

/***
Timeline 2
***/

@media (max-width: 991px) {
  /* 991px */
}

/***
Horizontal Timeline 
***/

/* PLUGIN CSS */

.cd-horizontal-timeline {
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.cd-horizontal-timeline::before {
  /* never visible - this is used in jQuery to check the current MQ */
  content: 'mobile';
  display: none;
}

.cd-horizontal-timeline.loaded {
  /* show the timeline after events position has been set (using JavaScript) */
  opacity: 1;
}

.cd-horizontal-timeline .timeline {
  position: relative;
  height: 100px;
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
}

.cd-horizontal-timeline .events-wrapper {
  position: relative;
  height: 100%;
  margin: 0 40px;
  overflow: hidden;
}

.cd-horizontal-timeline .events-wrapper::after,
.cd-horizontal-timeline .events-wrapper::before {
  /* these are used to create a shadow effect at the sides of the timeline */
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100%;
  width: 20px;
}

.cd-horizontal-timeline .events-wrapper::before {
  left: 0;
  background-image: -webkit-linear-gradient(left, #f8f8f8, rgba(248, 248, 248, 0));
  background-image: linear-gradient(to right, #f8f8f8, rgba(248, 248, 248, 0));
}

.cd-horizontal-timeline .events-wrapper::after {
  right: 0;
  background-image: -webkit-linear-gradient(right, #f8f8f8, rgba(248, 248, 248, 0));
  background-image: linear-gradient(to left, #f8f8f8, rgba(248, 248, 248, 0));
}

.cd-horizontal-timeline .events {
  /* this is the #bdbdbd line/timeline */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 49px;
  height: 2px;
  /* width will be set using JavaScript */
  background: #dfdfdf;
  -webkit-transition: -webkit-transform 0.4s;
  -moz-transition: -moz-transform 0.4s;
  transition: transform 0.4s;
}

.cd-horizontal-timeline .filling-line {
  /* this is used to create the green line filling the timeline */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #7b9d6f;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}

.cd-horizontal-timeline .events a {
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-align: center;
  font-size: 1.3rem;
  padding-bottom: 15px;
  color: #383838;
  /* fix bug on Safari - text flickering while timeline translates */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

.cd-horizontal-timeline .events a::after {
  /* this is used to create the event spot */
  content: '';
  position: absolute;
  left: 50%;
  right: auto;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -5px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  -moz-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
}

.no-touch .cd-horizontal-timeline .events a:hover::after {
  background-color: #7b9d6f;
  border-color: #7b9d6f;
}

.cd-horizontal-timeline .events a.selected {
  pointer-events: none;
}

@media only screen and (min-width: 1100px) {
  .cd-horizontal-timeline::before {
    /* never visible - this is used in jQuery to check the current MQ */
    content: 'desktop';
  }
}

.cd-timeline-navigation a {
  /* these are the left/right arrows to navigate the timeline */
  position: absolute;
  z-index: 1;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 2px solid #dfdfdf;
  /* replace text with an icon */
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  -webkit-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

.cd-timeline-navigation a.prev {
  left: 0;
}

.cd-timeline-navigation a.next {
  right: 0;
}

.cd-timeline-navigation a.inactive {
  cursor: not-allowed;
}

.cd-timeline-navigation a.inactive::after {
  background-position: 0 -16px;
}

.no-touch .cd-timeline-navigation a.inactive:hover {
  border-color: #dfdfdf;
}

.cd-horizontal-timeline .events-content {
  position: relative;
  width: 100%;
  margin: 10px 0 0 0;
  overflow: hidden;
  -webkit-transition: height 0.4s;
  -moz-transition: height 0.4s;
  transition: height 0.4s;
}

.cd-horizontal-timeline .events-content>ol>li {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  padding: 0;
  opacity: 0;
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.cd-horizontal-timeline .events-content>ol>li.selected {
  /* visible event content */
  position: relative;
  z-index: 2;
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.cd-horizontal-timeline .events-content>ol>li.enter-right,
.cd-horizontal-timeline .events-content>ol>li.leave-right {
  -webkit-animation-name: cd-enter-right;
  -moz-animation-name: cd-enter-right;
  animation-name: cd-enter-right;
}

.cd-horizontal-timeline .events-content>ol>li.enter-left,
.cd-horizontal-timeline .events-content>ol>li.leave-left {
  -webkit-animation-name: cd-enter-left;
  -moz-animation-name: cd-enter-left;
  animation-name: cd-enter-left;
}

.cd-horizontal-timeline .events-content>ol>li.leave-right,
.cd-horizontal-timeline .events-content>ol>li.leave-left {
  -webkit-animation-direction: reverse;
  -moz-animation-direction: reverse;
  animation-direction: reverse;
}

.cd-horizontal-timeline .events-content>ol>li {
  margin: 0 auto;
}

.cd-horizontal-timeline .events-content em {
  display: block;
  font-style: italic;
  margin: 10px auto;
}

.cd-horizontal-timeline .events-content em::before {
  content: '- ';
}

@-webkit-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}

@-moz-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -moz-transform: translateX(100%);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}

@keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@-webkit-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}

@-moz-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}

@keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}

/* METRONIC EXTENDED CSS */

/***
Tasks Widget 
***/

.tasks-widget:after {
  clear: both;
}

.tasks-widget .task-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tasks-widget .task-list>li {
  position: relative;
  padding: 10px 10px;
  border-bottom: 1px solid #F4F6F9;
}

.tasks-widget .task-list>li:hover {
  background: #F4F6F9;
}

.tasks-widget .task-list>li.last-line {
  border-bottom: none;
}

.tasks-widget .task-list>li.task-done {
  background: #f6f6f6;
}

.tasks-widget .task-list>li.task-done:hover {
  background: #f4f4f4;
}

.tasks-widget .task-list>li>.task-bell {
  margin-left: 10px;
}

.tasks-widget .task-list>li>.task-checkbox {
  float: left;
  width: 30px;
}

.tasks-widget .task-list>li>.task-checkbox input[type="checkbox"] {
  cursor: pointer;
}

.tasks-widget .task-list>li>.task-title {
  color: #838FA1;
  margin-right: 10px;
}

.tasks-widget .task-list>li .task-config-btn {
  margin-top: -1px;
}

.tasks-widget .task-list>li>.task-config {
  display: none;
  position: absolute;
  top: 7px;
  right: 10px;
}

.tasks-widget .task-list>li:hover>.task-config {
  display: block;
  margin-bottom: 0 !important;
}

.tasks-widget .task-footer {
  margin-top: 5px;
}

.tasks-widget .task-footer:before,
.tasks-widget .task-footer:after {
  content: " ";
  display: table;
}

.tasks-widget .task-footer:after {
  clear: both;
}

@media only screen and (max-width: 480px) {
  .tasks-widget .task-config-btn {
    float: inherit;
    display: block;
  }

  .tasks-widget .task-list-projects li>.label {
    margin-bottom: 5px;
  }
}

@media (max-width: 767px) {
  /* 767px */
}

body.stop-scrolling {
  height: auto !important;
  overflow: visible !important;
}

.caption-desc {
  font-size: 13px;
  margin-top: 0.5em;
  line-height: 2.3em;
}

@media (max-width: 991px) {
  /* 991px */
}

/***
Custom color buttons 
***/

.bg-white {
  background: #ffffff !important;
}

.bg-white-opacity {
  background: rgba(255, 255, 255, 0.9) !important;
}

.bg-hover-white:hover {
  background: #ffffff !important;
}

.font-white {
  color: #ffffff !important;
}

.bg-font-white {
  color: #666 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.border-top-white {
  border-top-color: #ffffff !important;
}

.border-bottom-white {
  border-bottom-color: #ffffff !important;
}

.border-left-white {
  border-left-color: #ffffff !important;
}

.border-right-white {
  border-right-color: #ffffff !important;
}

.bg-before-white:before,
.bg-after-white:after {
  background: #ffffff !important;
}

.border-before-white:before,
.border-after-white:after {
  border-color: #ffffff !important;
}

.border-top-before-white:before,
.border-top-after-white:after {
  border-top-color: #ffffff !important;
}

.border-bottom-before-white:before,
.border-bottom-after-white:after {
  border-bottom-color: #ffffff !important;
}

.border-left-before-white:before,
.border-left-after-white:after {
  border-left-color: #ffffff !important;
}

.border-right-before-white:before,
.border-right-after-white:after {
  border-right-color: #ffffff !important;
}

.bg-default {
  background: #e1e5ec !important;
}

.bg-default-opacity {
  background: rgba(225, 229, 236, 0.8) !important;
}

.bg-hover-default:hover {
  background: #e1e5ec !important;
}

.font-default {
  color: #e1e5ec !important;
}

.bg-font-default {
  color: #666 !important;
}

.border-default {
  border-color: #e1e5ec !important;
}

.border-top-default {
  border-top-color: #e1e5ec !important;
}

.border-bottom-default {
  border-bottom-color: #e1e5ec !important;
}

.border-left-default {
  border-left-color: #e1e5ec !important;
}

.border-right-default {
  border-right-color: #e1e5ec !important;
}

.bg-before-default:before,
.bg-after-default:after {
  background: #e1e5ec !important;
}

.border-before-default:before,
.border-after-default:after {
  border-color: #e1e5ec !important;
}

.border-top-before-default:before,
.border-top-after-default:after {
  border-top-color: #e1e5ec !important;
}

.border-bottom-before-default:before,
.border-bottom-after-default:after {
  border-bottom-color: #e1e5ec !important;
}

.border-left-before-default:before,
.border-left-after-default:after {
  border-left-color: #e1e5ec !important;
}

.border-right-before-default:before,
.border-right-after-default:after {
  border-right-color: #e1e5ec !important;
}

.bg-dark {
  background: #2f353b !important;
}

.bg-dark-opacity {
  background: rgba(47, 53, 59, 0.8) !important;
}

.bg-hover-dark:hover {
  background: #2f353b !important;
}

.font-dark {
  color: #2f353b !important;
}

.bg-font-dark {
  color: #FFFFFF !important;
}

.border-dark {
  border-color: #2f353b !important;
}

.border-top-dark {
  border-top-color: #2f353b !important;
}

.border-bottom-dark {
  border-bottom-color: #2f353b !important;
}

.border-left-dark {
  border-left-color: #2f353b !important;
}

.border-right-dark {
  border-right-color: #2f353b !important;
}

.bg-before-dark:before,
.bg-after-dark:after {
  background: #2f353b !important;
}

.border-before-dark:before,
.border-after-dark:after {
  border-color: #2f353b !important;
}

.border-top-before-dark:before,
.border-top-after-dark:after {
  border-top-color: #2f353b !important;
}

.border-bottom-before-dark:before,
.border-bottom-after-dark:after {
  border-bottom-color: #2f353b !important;
}

.border-left-before-dark:before,
.border-left-after-dark:after {
  border-left-color: #2f353b !important;
}

.border-right-before-dark:before,
.border-right-after-dark:after {
  border-right-color: #2f353b !important;
}

.bg-blue {
  background: #3598dc !important;
}

.bg-blue-opacity {
  background: rgba(53, 152, 220, 0.8) !important;
}

.bg-hover-blue:hover {
  background: #3598dc !important;
}

.font-blue {
  color: #3598dc !important;
}

.bg-font-blue {
  color: #FFFFFF !important;
}

.border-blue {
  border-color: #3598dc !important;
}

.border-top-blue {
  border-top-color: #3598dc !important;
}

.border-bottom-blue {
  border-bottom-color: #3598dc !important;
}

.border-left-blue {
  border-left-color: #3598dc !important;
}

.border-right-blue {
  border-right-color: #3598dc !important;
}

.bg-before-blue:before,
.bg-after-blue:after {
  background: #3598dc !important;
}

.border-before-blue:before,
.border-after-blue:after {
  border-color: #3598dc !important;
}

.border-top-before-blue:before,
.border-top-after-blue:after {
  border-top-color: #3598dc !important;
}

.border-bottom-before-blue:before,
.border-bottom-after-blue:after {
  border-bottom-color: #3598dc !important;
}

.border-left-before-blue:before,
.border-left-after-blue:after {
  border-left-color: #3598dc !important;
}

.border-right-before-blue:before,
.border-right-after-blue:after {
  border-right-color: #3598dc !important;
}

.bg-blue-steel {
  background: #4B77BE !important;
}

.bg-blue-steel-opacity {
  background: rgba(75, 119, 190, 0.8) !important;
}

.bg-hover-blue-steel:hover {
  background: #4B77BE !important;
}

.font-blue-steel {
  color: #4B77BE !important;
}

.bg-font-blue-steel {
  color: #FFFFFF !important;
}

.border-blue-steel {
  border-color: #4B77BE !important;
}

.border-top-blue-steel {
  border-top-color: #4B77BE !important;
}

.border-bottom-blue-steel {
  border-bottom-color: #4B77BE !important;
}

.border-left-blue-steel {
  border-left-color: #4B77BE !important;
}

.border-right-blue-steel {
  border-right-color: #4B77BE !important;
}

.bg-before-blue-steel:before,
.bg-after-blue-steel:after {
  background: #4B77BE !important;
}

.border-before-blue-steel:before,
.border-after-blue-steel:after {
  border-color: #4B77BE !important;
}

.border-top-before-blue-steel:before,
.border-top-after-blue-steel:after {
  border-top-color: #4B77BE !important;
}

.border-bottom-before-blue-steel:before,
.border-bottom-after-blue-steel:after {
  border-bottom-color: #4B77BE !important;
}

.border-left-before-blue-steel:before,
.border-left-after-blue-steel:after {
  border-left-color: #4B77BE !important;
}

.border-right-before-blue-steel:before,
.border-right-after-blue-steel:after {
  border-right-color: #4B77BE !important;
}

.bg-blue-soft {
  background: #4c87b9 !important;
}

.bg-blue-soft-opacity {
  background: rgba(76, 135, 185, 0.8) !important;
}

.bg-hover-blue-soft:hover {
  background: #4c87b9 !important;
}

.font-blue-soft {
  color: #4c87b9 !important;
}

.bg-font-blue-soft {
  color: #FFFFFF !important;
}

.border-blue-soft {
  border-color: #4c87b9 !important;
}

.border-top-blue-soft {
  border-top-color: #4c87b9 !important;
}

.border-bottom-blue-soft {
  border-bottom-color: #4c87b9 !important;
}

.border-left-blue-soft {
  border-left-color: #4c87b9 !important;
}

.border-right-blue-soft {
  border-right-color: #4c87b9 !important;
}

.bg-before-blue-soft:before,
.bg-after-blue-soft:after {
  background: #4c87b9 !important;
}

.border-before-blue-soft:before,
.border-after-blue-soft:after {
  border-color: #4c87b9 !important;
}

.border-top-before-blue-soft:before,
.border-top-after-blue-soft:after {
  border-top-color: #4c87b9 !important;
}

.border-bottom-before-blue-soft:before,
.border-bottom-after-blue-soft:after {
  border-bottom-color: #4c87b9 !important;
}

.border-left-before-blue-soft:before,
.border-left-after-blue-soft:after {
  border-left-color: #4c87b9 !important;
}

.border-right-before-blue-soft:before,
.border-right-after-blue-soft:after {
  border-right-color: #4c87b9 !important;
}

.bg-blue-dark {
  background: #5e738b !important;
}

.bg-blue-dark-opacity {
  background: rgba(94, 115, 139, 0.8) !important;
}

.bg-hover-blue-dark:hover {
  background: #5e738b !important;
}

.font-blue-dark {
  color: #5e738b !important;
}

.bg-font-blue-dark {
  color: #FFFFFF !important;
}

.border-blue-dark {
  border-color: #5e738b !important;
}

.border-top-blue-dark {
  border-top-color: #5e738b !important;
}

.border-bottom-blue-dark {
  border-bottom-color: #5e738b !important;
}

.border-left-blue-dark {
  border-left-color: #5e738b !important;
}

.border-right-blue-dark {
  border-right-color: #5e738b !important;
}

.bg-before-blue-dark:before,
.bg-after-blue-dark:after {
  background: #5e738b !important;
}

.border-before-blue-dark:before,
.border-after-blue-dark:after {
  border-color: #5e738b !important;
}

.border-top-before-blue-dark:before,
.border-top-after-blue-dark:after {
  border-top-color: #5e738b !important;
}

.border-bottom-before-blue-dark:before,
.border-bottom-after-blue-dark:after {
  border-bottom-color: #5e738b !important;
}

.border-left-before-blue-dark:before,
.border-left-after-blue-dark:after {
  border-left-color: #5e738b !important;
}

.border-right-before-blue-dark:before,
.border-right-after-blue-dark:after {
  border-right-color: #5e738b !important;
}

.bg-blue-sharp {
  background: #5C9BD1 !important;
}

.bg-blue-sharp-opacity {
  background: rgba(92, 155, 209, 0.8) !important;
}

.bg-hover-blue-sharp:hover {
  background: #5C9BD1 !important;
}

.font-blue-sharp {
  color: #5C9BD1 !important;
}

.bg-font-blue-sharp {
  color: #FFFFFF !important;
}

.border-blue-sharp {
  border-color: #5C9BD1 !important;
}

.border-top-blue-sharp {
  border-top-color: #5C9BD1 !important;
}

.border-bottom-blue-sharp {
  border-bottom-color: #5C9BD1 !important;
}

.border-left-blue-sharp {
  border-left-color: #5C9BD1 !important;
}

.border-right-blue-sharp {
  border-right-color: #5C9BD1 !important;
}

.bg-before-blue-sharp:before,
.bg-after-blue-sharp:after {
  background: #5C9BD1 !important;
}

.border-before-blue-sharp:before,
.border-after-blue-sharp:after {
  border-color: #5C9BD1 !important;
}

.border-top-before-blue-sharp:before,
.border-top-after-blue-sharp:after {
  border-top-color: #5C9BD1 !important;
}

.border-bottom-before-blue-sharp:before,
.border-bottom-after-blue-sharp:after {
  border-bottom-color: #5C9BD1 !important;
}

.border-left-before-blue-sharp:before,
.border-left-after-blue-sharp:after {
  border-left-color: #5C9BD1 !important;
}

.border-right-before-blue-sharp:before,
.border-right-after-blue-sharp:after {
  border-right-color: #5C9BD1 !important;
}

.bg-green {
  background: #C3073F !important;
}

.bg-green-opacity {
  background: rgba(50, 197, 210, 0.8) !important;
}

.bg-hover-green:hover {
  background: #C3073F !important;
}

.font-green {
  color: #C3073F !important;
}

.bg-font-green {
  color: #FFFFFF !important;
}

.border-green {
  border-color: #C3073F !important;
}

.border-top-green {
  border-top-color: #C3073F !important;
}

.border-bottom-green {
  border-bottom-color: #C3073F !important;
}

.border-left-green {
  border-left-color: #C3073F !important;
}

.border-right-green {
  border-right-color: #C3073F !important;
}

.bg-before-green:before,
.bg-after-green:after {
  background: #C3073F !important;
}

.border-before-green:before,
.border-after-green:after {
  border-color: #C3073F !important;
}

.border-top-before-green:before,
.border-top-after-green:after {
  border-top-color: #C3073F !important;
}

.border-bottom-before-green:before,
.border-bottom-after-green:after {
  border-bottom-color: #C3073F !important;
}

.border-left-before-green:before,
.border-left-after-green:after {
  border-left-color: #C3073F !important;
}

.border-right-before-green:before,
.border-right-after-green:after {
  border-right-color: #C3073F !important;
}

.bg-green-seagreen {
  background: #1BA39C !important;
}

.bg-green-seagreen-opacity {
  background: rgba(27, 163, 156, 0.8) !important;
}

.bg-hover-green-seagreen:hover {
  background: #1BA39C !important;
}

.font-green-seagreen {
  color: #1BA39C !important;
}

.bg-font-green-seagreen {
  color: #FFFFFF !important;
}

.border-green-seagreen {
  border-color: #1BA39C !important;
}

.border-top-green-seagreen {
  border-top-color: #1BA39C !important;
}

.border-bottom-green-seagreen {
  border-bottom-color: #1BA39C !important;
}

.border-left-green-seagreen {
  border-left-color: #1BA39C !important;
}

.border-right-green-seagreen {
  border-right-color: #1BA39C !important;
}

.bg-before-green-seagreen:before,
.bg-after-green-seagreen:after {
  background: #1BA39C !important;
}

.border-before-green-seagreen:before,
.border-after-green-seagreen:after {
  border-color: #1BA39C !important;
}

.border-top-before-green-seagreen:before,
.border-top-after-green-seagreen:after {
  border-top-color: #1BA39C !important;
}

.border-bottom-before-green-seagreen:before,
.border-bottom-after-green-seagreen:after {
  border-bottom-color: #1BA39C !important;
}

.border-left-before-green-seagreen:before,
.border-left-after-green-seagreen:after {
  border-left-color: #1BA39C !important;
}

.border-right-before-green-seagreen:before,
.border-right-after-green-seagreen:after {
  border-right-color: #1BA39C !important;
}

.bg-green-turquoise {
  background: #36D7B7 !important;
}

.bg-green-turquoise-opacity {
  background: rgba(54, 215, 183, 0.8) !important;
}

.bg-hover-green-turquoise:hover {
  background: #36D7B7 !important;
}

.font-green-turquoise {
  color: #36D7B7 !important;
}

.bg-font-green-turquoise {
  color: #FFFFFF !important;
}

.border-green-turquoise {
  border-color: #36D7B7 !important;
}

.border-top-green-turquoise {
  border-top-color: #36D7B7 !important;
}

.border-bottom-green-turquoise {
  border-bottom-color: #36D7B7 !important;
}

.border-left-green-turquoise {
  border-left-color: #36D7B7 !important;
}

.border-right-green-turquoise {
  border-right-color: #36D7B7 !important;
}

.bg-before-green-turquoise:before,
.bg-after-green-turquoise:after {
  background: #36D7B7 !important;
}

.border-before-green-turquoise:before,
.border-after-green-turquoise:after {
  border-color: #36D7B7 !important;
}

.border-top-before-green-turquoise:before,
.border-top-after-green-turquoise:after {
  border-top-color: #36D7B7 !important;
}

.border-bottom-before-green-turquoise:before,
.border-bottom-after-green-turquoise:after {
  border-bottom-color: #36D7B7 !important;
}

.border-left-before-green-turquoise:before,
.border-left-after-green-turquoise:after {
  border-left-color: #36D7B7 !important;
}

.border-right-before-green-turquoise:before,
.border-right-after-green-turquoise:after {
  border-right-color: #36D7B7 !important;
}

.bg-green-haze {
  background: #44b6ae !important;
}

.bg-green-haze-opacity {
  background: rgba(68, 182, 174, 0.8) !important;
}

.bg-hover-green-haze:hover {
  background: #44b6ae !important;
}

.font-green-haze {
  color: #44b6ae !important;
}

.bg-font-green-haze {
  color: #FFFFFF !important;
}

.border-green-haze {
  border-color: #44b6ae !important;
}

.border-top-green-haze {
  border-top-color: #44b6ae !important;
}

.border-bottom-green-haze {
  border-bottom-color: #44b6ae !important;
}

.border-left-green-haze {
  border-left-color: #44b6ae !important;
}

.border-right-green-haze {
  border-right-color: #44b6ae !important;
}

.bg-before-green-haze:before,
.bg-after-green-haze:after {
  background: #44b6ae !important;
}

.border-before-green-haze:before,
.border-after-green-haze:after {
  border-color: #44b6ae !important;
}

.border-top-before-green-haze:before,
.border-top-after-green-haze:after {
  border-top-color: #44b6ae !important;
}

.border-bottom-before-green-haze:before,
.border-bottom-after-green-haze:after {
  border-bottom-color: #44b6ae !important;
}

.border-left-before-green-haze:before,
.border-left-after-green-haze:after {
  border-left-color: #44b6ae !important;
}

.border-right-before-green-haze:before,
.border-right-after-green-haze:after {
  border-right-color: #44b6ae !important;
}

.bg-green-jungle {
  background: #26C281 !important;
}

.bg-green-jungle-opacity {
  background: rgba(38, 194, 129, 0.8) !important;
}

.bg-hover-green-jungle:hover {
  background: #26C281 !important;
}

.font-green-jungle {
  color: #26C281 !important;
}

.bg-font-green-jungle {
  color: #FFFFFF !important;
}

.border-green-jungle {
  border-color: #26C281 !important;
}

.border-top-green-jungle {
  border-top-color: #26C281 !important;
}

.border-bottom-green-jungle {
  border-bottom-color: #26C281 !important;
}

.border-left-green-jungle {
  border-left-color: #26C281 !important;
}

.border-right-green-jungle {
  border-right-color: #26C281 !important;
}

.bg-before-green-jungle:before,
.bg-after-green-jungle:after {
  background: #26C281 !important;
}

.border-before-green-jungle:before,
.border-after-green-jungle:after {
  border-color: #26C281 !important;
}

.border-top-before-green-jungle:before,
.border-top-after-green-jungle:after {
  border-top-color: #26C281 !important;
}

.border-bottom-before-green-jungle:before,
.border-bottom-after-green-jungle:after {
  border-bottom-color: #26C281 !important;
}

.border-left-before-green-jungle:before,
.border-left-after-green-jungle:after {
  border-left-color: #26C281 !important;
}

.border-right-before-green-jungle:before,
.border-right-after-green-jungle:after {
  border-right-color: #26C281 !important;
}

.bg-green-soft {
  background: #3faba4 !important;
}

.bg-green-soft-opacity {
  background: rgba(63, 171, 164, 0.8) !important;
}

.bg-hover-green-soft:hover {
  background: #3faba4 !important;
}

.font-green-soft {
  color: #3faba4 !important;
}

.bg-font-green-soft {
  color: #FFFFFF !important;
}

.border-green-soft {
  border-color: #3faba4 !important;
}

.border-top-green-soft {
  border-top-color: #3faba4 !important;
}

.border-bottom-green-soft {
  border-bottom-color: #3faba4 !important;
}

.border-left-green-soft {
  border-left-color: #3faba4 !important;
}

.border-right-green-soft {
  border-right-color: #3faba4 !important;
}

.bg-before-green-soft:before,
.bg-after-green-soft:after {
  background: #3faba4 !important;
}

.border-before-green-soft:before,
.border-after-green-soft:after {
  border-color: #3faba4 !important;
}

.border-top-before-green-soft:before,
.border-top-after-green-soft:after {
  border-top-color: #3faba4 !important;
}

.border-bottom-before-green-soft:before,
.border-bottom-after-green-soft:after {
  border-bottom-color: #3faba4 !important;
}

.border-left-before-green-soft:before,
.border-left-after-green-soft:after {
  border-left-color: #3faba4 !important;
}

.border-right-before-green-soft:before,
.border-right-after-green-soft:after {
  border-right-color: #3faba4 !important;
}

.bg-green-dark {
  background: #4DB3A2 !important;
}

.bg-green-dark-opacity {
  background: rgba(77, 179, 162, 0.8) !important;
}

.bg-hover-green-dark:hover {
  background: #4DB3A2 !important;
}

.font-green-dark {
  color: #4DB3A2 !important;
}

.bg-font-green-dark {
  color: #FFFFFF !important;
}

.border-green-dark {
  border-color: #4DB3A2 !important;
}

.border-top-green-dark {
  border-top-color: #4DB3A2 !important;
}

.border-bottom-green-dark {
  border-bottom-color: #4DB3A2 !important;
}

.border-left-green-dark {
  border-left-color: #4DB3A2 !important;
}

.border-right-green-dark {
  border-right-color: #4DB3A2 !important;
}

.bg-before-green-dark:before,
.bg-after-green-dark:after {
  background: #4DB3A2 !important;
}

.border-before-green-dark:before,
.border-after-green-dark:after {
  border-color: #4DB3A2 !important;
}

.border-top-before-green-dark:before,
.border-top-after-green-dark:after {
  border-top-color: #4DB3A2 !important;
}

.border-bottom-before-green-dark:before,
.border-bottom-after-green-dark:after {
  border-bottom-color: #4DB3A2 !important;
}

.border-left-before-green-dark:before,
.border-left-after-green-dark:after {
  border-left-color: #4DB3A2 !important;
}

.border-right-before-green-dark:before,
.border-right-after-green-dark:after {
  border-right-color: #4DB3A2 !important;
}

.bg-green-sharp {
  background: #3562d9 !important;
}

.bg-green-sharp-opacity {
  background: rgba(42, 180, 192, 0.8) !important;
}

.bg-hover-green-sharp:hover {
  background: #3562d9 !important;
}

.font-green-sharp {
  color: #cf8900 !important;
}

.bg-font-green-sharp {
  color: #FFFFFF !important;
}

.border-green-sharp {
  border-color: #3562d9 !important;
}

.border-top-green-sharp {
  border-top-color: #3562d9 !important;
}

.border-bottom-green-sharp {
  border-bottom-color: #3562d9 !important;
}

.border-left-green-sharp {
  border-left-color: #3562d9 !important;
}

.border-right-green-sharp {
  border-right-color: #3562d9 !important;
}

.bg-before-green-sharp:before,
.bg-after-green-sharp:after {
  background: #3562d9 !important;
}

.border-before-green-sharp:before,
.border-after-green-sharp:after {
  border-color: #3562d9 !important;
}

.border-top-before-green-sharp:before,
.border-top-after-green-sharp:after {
  border-top-color: #3562d9 !important;
}

.border-bottom-before-green-sharp:before,
.border-bottom-after-green-sharp:after {
  border-bottom-color: #3562d9 !important;
}

.border-left-before-green-sharp:before,
.border-left-after-green-sharp:after {
  border-left-color: #3562d9 !important;
}

.border-right-before-green-sharp:before,
.border-right-after-green-sharp:after {
  border-right-color: #3562d9 !important;
}

.bg-green-steel {
  background: #cf8900 !important;
}

.bg-green-steel-opacity {
  background: rgba(41, 180, 182, 0.8) !important;
}

.bg-hover-green-steel:hover {
  background: #cf8900 !important;
}

.font-green-steel {
  color: #cf8900 !important;
}

.bg-font-green-steel {
  color: #FFFFFF !important;
}

.border-green-steel {
  border-color: #cf8900 !important;
}

.border-top-green-steel {
  border-top-color: #cf8900 !important;
}

.border-bottom-green-steel {
  border-bottom-color: #cf8900 !important;
}

.border-left-green-steel {
  border-left-color: #cf8900 !important;
}

.border-right-green-steel {
  border-right-color: #cf8900 !important;
}

.bg-before-green-steel:before,
.bg-after-green-steel:after {
  background: #cf8900 !important;
}

.border-before-green-steel:before,
.border-after-green-steel:after {
  border-color: #cf8900 !important;
}

.border-top-before-green-steel:before,
.border-top-after-green-steel:after {
  border-top-color: #cf8900 !important;
}

.border-bottom-before-green-steel:before,
.border-bottom-after-green-steel:after {
  border-bottom-color: #cf8900 !important;
}

.border-left-before-green-steel:before,
.border-left-after-green-steel:after {
  border-left-color: #cf8900 !important;
}

.border-right-before-green-steel:before,
.border-right-after-green-steel:after {
  border-right-color: #cf8900 !important;
}

.bg-#bdbdbd {
  background: #E5E5E5 !important;
}

.bg-#bdbdbd-opacity {
  background: rgba(229, 229, 229, 0.8) !important;
}

.bg-hover-#bdbdbd:hover {
  background: #E5E5E5 !important;
}

.font-#bdbdbd {
  color: #E5E5E5 !important;
}

.bg-font-#bdbdbd {
  color: #333333 !important;
}

.border-#bdbdbd {
  border-color: #E5E5E5 !important;
}

.border-top-#bdbdbd {
  border-top-color: #E5E5E5 !important;
}

.border-bottom-#bdbdbd {
  border-bottom-color: #E5E5E5 !important;
}

.border-left-#bdbdbd {
  border-left-color: #E5E5E5 !important;
}

.border-right-#bdbdbd {
  border-right-color: #E5E5E5 !important;
}

.bg-before-#bdbdbd:before,
.bg-after-#bdbdbd:after {
  background: #E5E5E5 !important;
}

.border-before-#bdbdbd:before,
.border-after-#bdbdbd:after {
  border-color: #E5E5E5 !important;
}

.border-top-before-#bdbdbd:before,
.border-top-after-#bdbdbd:after {
  border-top-color: #E5E5E5 !important;
}

.border-bottom-before-#bdbdbd:before,
.border-bottom-after-#bdbdbd:after {
  border-bottom-color: #E5E5E5 !important;
}

.border-left-before-#bdbdbd:before,
.border-left-after-#bdbdbd:after {
  border-left-color: #E5E5E5 !important;
}

.border-right-before-#bdbdbd:before,
.border-right-after-#bdbdbd:after {
  border-right-color: #E5E5E5 !important;
}

.bg-#bdbdbd-steel {
  background: #e9edef !important;
}

.bg-#bdbdbd-steel-opacity {
  background: rgba(233, 237, 239, 0.8) !important;
}

.bg-hover-#bdbdbd-steel:hover {
  background: #e9edef !important;
}

.font-#bdbdbd-steel {
  color: #e9edef !important;
}

.bg-font-#bdbdbd-steel {
  color: #80898e !important;
}

.border-#bdbdbd-steel {
  border-color: #e9edef !important;
}

.border-top-#bdbdbd-steel {
  border-top-color: #e9edef !important;
}

.border-bottom-#bdbdbd-steel {
  border-bottom-color: #e9edef !important;
}

.border-left-#bdbdbd-steel {
  border-left-color: #e9edef !important;
}

.border-right-#bdbdbd-steel {
  border-right-color: #e9edef !important;
}

.bg-before-#bdbdbd-steel:before,
.bg-after-#bdbdbd-steel:after {
  background: #e9edef !important;
}

.border-before-#bdbdbd-steel:before,
.border-after-#bdbdbd-steel:after {
  border-color: #e9edef !important;
}

.border-top-before-#bdbdbd-steel:before,
.border-top-after-#bdbdbd-steel:after {
  border-top-color: #e9edef !important;
}

.border-bottom-before-#bdbdbd-steel:before,
.border-bottom-after-#bdbdbd-steel:after {
  border-bottom-color: #e9edef !important;
}

.border-left-before-#bdbdbd-steel:before,
.border-left-after-#bdbdbd-steel:after {
  border-left-color: #e9edef !important;
}

.border-right-before-#bdbdbd-steel:before,
.border-right-after-#bdbdbd-steel:after {
  border-right-color: #e9edef !important;
}

.bg-#bdbdbd-silver {
  background: #BFBFBF !important;
}

.bg-#bdbdbd-silver-opacity {
  background: rgba(191, 191, 191, 0.8) !important;
}

.bg-hover-#bdbdbd-silver:hover {
  background: #BFBFBF !important;
}

.font-#bdbdbd-silver {
  color: #BFBFBF !important;
}

.bg-font-#bdbdbd-silver {
  color: #FAFCFB !important;
}

.border-#bdbdbd-silver {
  border-color: #BFBFBF !important;
}

.border-top-#bdbdbd-silver {
  border-top-color: #BFBFBF !important;
}

.border-bottom-#bdbdbd-silver {
  border-bottom-color: #BFBFBF !important;
}

.border-left-#bdbdbd-silver {
  border-left-color: #BFBFBF !important;
}

.border-right-#bdbdbd-silver {
  border-right-color: #BFBFBF !important;
}

.bg-before-#bdbdbd-silver:before,
.bg-after-#bdbdbd-silver:after {
  background: #BFBFBF !important;
}

.border-before-#bdbdbd-silver:before,
.border-after-#bdbdbd-silver:after {
  border-color: #BFBFBF !important;
}

.border-top-before-#bdbdbd-silver:before,
.border-top-after-#bdbdbd-silver:after {
  border-top-color: #BFBFBF !important;
}

.border-bottom-before-#bdbdbd-silver:before,
.border-bottom-after-#bdbdbd-silver:after {
  border-bottom-color: #BFBFBF !important;
}

.border-left-before-#bdbdbd-silver:before,
.border-left-after-#bdbdbd-silver:after {
  border-left-color: #BFBFBF !important;
}

.border-right-before-#bdbdbd-silver:before,
.border-right-after-#bdbdbd-silver:after {
  border-right-color: #BFBFBF !important;
}

.bg-#bdbdbd-salt {
  background: #bfcad1 !important;
}

.bg-#bdbdbd-salt-opacity {
  background: rgba(191, 202, 209, 0.8) !important;
}

.bg-hover-#bdbdbd-salt:hover {
  background: #bfcad1 !important;
}

.font-#bdbdbd-salt {
  color: #bfcad1 !important;
}

.bg-font-#bdbdbd-salt {
  color: #FAFCFB !important;
}

.border-#bdbdbd-salt {
  border-color: #bfcad1 !important;
}

.border-top-#bdbdbd-salt {
  border-top-color: #bfcad1 !important;
}

.border-bottom-#bdbdbd-salt {
  border-bottom-color: #bfcad1 !important;
}

.border-left-#bdbdbd-salt {
  border-left-color: #bfcad1 !important;
}

.border-right-#bdbdbd-salt {
  border-right-color: #bfcad1 !important;
}

.bg-red {
  background: #e7505a !important;
}

.bg-red-opacity {
  background: rgba(231, 80, 90, 0.8) !important;
}

.bg-hover-red:hover {
  background: #e7505a !important;
}

.font-red {
  color: #e7505a !important;
}

.bg-font-red {
  color: #ffffff !important;
}

.border-red {
  border-color: #e7505a !important;
}

.border-top-red {
  border-top-color: #e7505a !important;
}

.border-bottom-red {
  border-bottom-color: #e7505a !important;
}

.border-left-red {
  border-left-color: #e7505a !important;
}

.border-right-red {
  border-right-color: #e7505a !important;
}

.bg-before-red:before,
.bg-after-red:after {
  background: #e7505a !important;
}

.border-before-red:before,
.border-after-red:after {
  border-color: #e7505a !important;
}

.border-top-before-red:before,
.border-top-after-red:after {
  border-top-color: #e7505a !important;
}

.border-bottom-before-red:before,
.border-bottom-after-red:after {
  border-bottom-color: #e7505a !important;
}

.border-left-before-red:before,
.border-left-after-red:after {
  border-left-color: #e7505a !important;
}

.border-right-before-red:before,
.border-right-after-red:after {
  border-right-color: #e7505a !important;
}

.bg-red-pink {
  background: #E08283 !important;
}

.bg-red-pink-opacity {
  background: rgba(224, 130, 131, 0.8) !important;
}

.bg-hover-red-pink:hover {
  background: #E08283 !important;
}

.font-red-pink {
  color: #E08283 !important;
}

.bg-font-red-pink {
  color: #ffffff !important;
}

.border-red-pink {
  border-color: #E08283 !important;
}

.border-top-red-pink {
  border-top-color: #E08283 !important;
}

.border-bottom-red-pink {
  border-bottom-color: #E08283 !important;
}

.border-left-red-pink {
  border-left-color: #E08283 !important;
}

.border-right-red-pink {
  border-right-color: #E08283 !important;
}

.bg-before-red-pink:before,
.bg-after-red-pink:after {
  background: #E08283 !important;
}

.border-before-red-pink:before,
.border-after-red-pink:after {
  border-color: #E08283 !important;
}

.border-top-before-red-pink:before,
.border-top-after-red-pink:after {
  border-top-color: #E08283 !important;
}

.border-bottom-before-red-pink:before,
.border-bottom-after-red-pink:after {
  border-bottom-color: #E08283 !important;
}

.border-left-before-red-pink:before,
.border-left-after-red-pink:after {
  border-left-color: #E08283 !important;
}

.border-right-before-red-pink:before,
.border-right-after-red-pink:after {
  border-right-color: #E08283 !important;
}

.bg-red-soft {
  background: #d05454 !important;
}

.bg-red-soft-opacity {
  background: rgba(208, 84, 84, 0.8) !important;
}

.bg-hover-red-soft:hover {
  background: #d05454 !important;
}

.font-red-soft {
  color: #d05454 !important;
}

.bg-font-red-soft {
  color: #ffffff !important;
}

.border-red-soft {
  border-color: #d05454 !important;
}

.border-top-red-soft {
  border-top-color: #d05454 !important;
}

.border-bottom-red-soft {
  border-bottom-color: #d05454 !important;
}

.border-left-red-soft {
  border-left-color: #d05454 !important;
}

.border-right-red-soft {
  border-right-color: #d05454 !important;
}

.bg-before-red-soft:before,
.bg-after-red-soft:after {
  background: #d05454 !important;
}

.border-before-red-soft:before,
.border-after-red-soft:after {
  border-color: #d05454 !important;
}

.border-top-before-red-soft:before,
.border-top-after-red-soft:after {
  border-top-color: #d05454 !important;
}

.border-bottom-before-red-soft:before,
.border-bottom-after-red-soft:after {
  border-bottom-color: #d05454 !important;
}

.border-left-before-red-soft:before,
.border-left-after-red-soft:after {
  border-left-color: #d05454 !important;
}

.border-right-before-red-soft:before,
.border-right-after-red-soft:after {
  border-right-color: #d05454 !important;
}

.bg-red-haze {
  background: #f36a5a !important;
}

.bg-red-haze-opacity {
  background: rgba(243, 106, 90, 0.8) !important;
}

.bg-hover-red-haze:hover {
  background: #f36a5a !important;
}

.font-red-haze {
  color: #f36a5a !important;
}

.bg-font-red-haze {
  color: #ffffff !important;
}

.border-red-haze {
  border-color: #f36a5a !important;
}

.border-top-red-haze {
  border-top-color: #f36a5a !important;
}

.border-bottom-red-haze {
  border-bottom-color: #f36a5a !important;
}

.border-left-red-haze {
  border-left-color: #f36a5a !important;
}

.border-right-red-haze {
  border-right-color: #f36a5a !important;
}

.bg-before-red-haze:before,
.bg-after-red-haze:after {
  background: #f36a5a !important;
}

.border-before-red-haze:before,
.border-after-red-haze:after {
  border-color: #f36a5a !important;
}

.border-top-before-red-haze:before,
.border-top-after-red-haze:after {
  border-top-color: #f36a5a !important;
}

.border-bottom-before-red-haze:before,
.border-bottom-after-red-haze:after {
  border-bottom-color: #f36a5a !important;
}

.border-left-before-red-haze:before,
.border-left-after-red-haze:after {
  border-left-color: #f36a5a !important;
}

.border-right-before-red-haze:before,
.border-right-after-red-haze:after {
  border-right-color: #f36a5a !important;
}

.bg-yellow {
  background: #c49f47 !important;
}

.bg-yellow-opacity {
  background: rgba(196, 159, 71, 0.8) !important;
}

.bg-hover-yellow:hover {
  background: #c49f47 !important;
}

.font-yellow {
  color: #c49f47 !important;
}

.bg-font-yellow {
  color: #ffffff !important;
}

.border-yellow {
  border-color: #c49f47 !important;
}

.border-top-yellow {
  border-top-color: #c49f47 !important;
}

.border-bottom-yellow {
  border-bottom-color: #c49f47 !important;
}

.border-left-yellow {
  border-left-color: #c49f47 !important;
}

.border-right-yellow {
  border-right-color: #c49f47 !important;
}

.bg-before-yellow:before,
.bg-after-yellow:after {
  background: #c49f47 !important;
}

.border-before-yellow:before,
.border-after-yellow:after {
  border-color: #c49f47 !important;
}

.border-top-before-yellow:before,
.border-top-after-yellow:after {
  border-top-color: #c49f47 !important;
}

.border-bottom-before-yellow:before,
.border-bottom-after-yellow:after {
  border-bottom-color: #c49f47 !important;
}

.border-left-before-yellow:before,
.border-left-after-yellow:after {
  border-left-color: #c49f47 !important;
}

.border-right-before-yellow:before,
.border-right-after-yellow:after {
  border-right-color: #c49f47 !important;
}

.bg-yellow-gold {
  background: #E87E04 !important;
}

.bg-yellow-gold-opacity {
  background: rgba(232, 126, 4, 0.8) !important;
}

.bg-hover-yellow-gold:hover {
  background: #E87E04 !important;
}

.font-yellow-gold {
  color: #E87E04 !important;
}

.bg-font-yellow-gold {
  color: #ffffff !important;
}

.border-yellow-gold {
  border-color: #E87E04 !important;
}

.border-top-yellow-gold {
  border-top-color: #E87E04 !important;
}

.border-bottom-yellow-gold {
  border-bottom-color: #E87E04 !important;
}

.border-left-yellow-gold {
  border-left-color: #E87E04 !important;
}

.border-right-yellow-gold {
  border-right-color: #E87E04 !important;
}

.bg-before-yellow-gold:before,
.bg-after-yellow-gold:after {
  background: #E87E04 !important;
}

.border-before-yellow-gold:before,
.border-after-yellow-gold:after {
  border-color: #E87E04 !important;
}

.border-top-before-yellow-gold:before,
.border-top-after-yellow-gold:after {
  border-top-color: #E87E04 !important;
}

.border-bottom-before-yellow-gold:before,
.border-bottom-after-yellow-gold:after {
  border-bottom-color: #E87E04 !important;
}

.border-left-before-yellow-gold:before,
.border-left-after-yellow-gold:after {
  border-left-color: #E87E04 !important;
}

.border-right-before-yellow-gold:before,
.border-right-after-yellow-gold:after {
  border-right-color: #E87E04 !important;
}

.bg-yellow-soft {
  background: #c8d046 !important;
}

.bg-yellow-soft-opacity {
  background: rgba(200, 208, 70, 0.8) !important;
}

.bg-hover-yellow-soft:hover {
  background: #c8d046 !important;
}

.font-yellow-soft {
  color: #c8d046 !important;
}

.bg-font-yellow-soft {
  color: #ffffff !important;
}

.border-yellow-soft {
  border-color: #c8d046 !important;
}

.border-top-yellow-soft {
  border-top-color: #c8d046 !important;
}

.border-bottom-yellow-soft {
  border-bottom-color: #c8d046 !important;
}

.border-left-yellow-soft {
  border-left-color: #c8d046 !important;
}

.border-right-yellow-soft {
  border-right-color: #c8d046 !important;
}

.bg-before-yellow-soft:before,
.bg-after-yellow-soft:after {
  background: #c8d046 !important;
}

.border-before-yellow-soft:before,
.border-after-yellow-soft:after {
  border-color: #c8d046 !important;
}

.border-top-before-yellow-soft:before,
.border-top-after-yellow-soft:after {
  border-top-color: #c8d046 !important;
}

.border-bottom-before-yellow-soft:before,
.border-bottom-after-yellow-soft:after {
  border-bottom-color: #c8d046 !important;
}

.border-left-before-yellow-soft:before,
.border-left-after-yellow-soft:after {
  border-left-color: #c8d046 !important;
}

.border-right-before-yellow-soft:before,
.border-right-after-yellow-soft:after {
  border-right-color: #c8d046 !important;
}

.bg-yellow-haze {
  background: #c5bf66 !important;
}

.bg-yellow-haze-opacity {
  background: rgba(197, 191, 102, 0.8) !important;
}

.bg-hover-yellow-haze:hover {
  background: #c5bf66 !important;
}

.font-yellow-haze {
  color: #c5bf66 !important;
}

.bg-font-yellow-haze {
  color: #ffffff !important;
}

.border-yellow-haze {
  border-color: #c5bf66 !important;
}

.border-top-yellow-haze {
  border-top-color: #c5bf66 !important;
}

.border-bottom-yellow-haze {
  border-bottom-color: #c5bf66 !important;
}

.border-left-yellow-haze {
  border-left-color: #c5bf66 !important;
}

.border-right-yellow-haze {
  border-right-color: #c5bf66 !important;
}

.bg-before-yellow-haze:before,
.bg-after-yellow-haze:after {
  background: #c5bf66 !important;
}

.border-before-yellow-haze:before,
.border-after-yellow-haze:after {
  border-color: #c5bf66 !important;
}

.border-top-before-yellow-haze:before,
.border-top-after-yellow-haze:after {
  border-top-color: #c5bf66 !important;
}

.border-bottom-before-yellow-haze:before,
.border-bottom-after-yellow-haze:after {
  border-bottom-color: #c5bf66 !important;
}

.border-left-before-yellow-haze:before,
.border-left-after-yellow-haze:after {
  border-left-color: #c5bf66 !important;
}

.border-right-before-yellow-haze:before,
.border-right-after-yellow-haze:after {
  border-right-color: #c5bf66 !important;
}

.bg-purple {
  background: #8E44AD !important;
}

.bg-purple-opacity {
  background: rgba(142, 68, 173, 0.8) !important;
}

.bg-hover-purple:hover {
  background: #8E44AD !important;
}

.font-purple {
  color: #8E44AD !important;
}

.bg-font-purple {
  color: #ffffff !important;
}

.border-purple {
  border-color: #8E44AD !important;
}

.border-top-purple {
  border-top-color: #8E44AD !important;
}

.border-bottom-purple {
  border-bottom-color: #8E44AD !important;
}

.border-left-purple {
  border-left-color: #8E44AD !important;
}

.border-right-purple {
  border-right-color: #8E44AD !important;
}

.bg-before-purple:before,
.bg-after-purple:after {
  background: #8E44AD !important;
}

.border-before-purple:before,
.border-after-purple:after {
  border-color: #8E44AD !important;
}

.border-top-before-purple:before,
.border-top-after-purple:after {
  border-top-color: #8E44AD !important;
}

.border-bottom-before-purple:before,
.border-bottom-after-purple:after {
  border-bottom-color: #8E44AD !important;
}

.border-left-before-purple:before,
.border-left-after-purple:after {
  border-left-color: #8E44AD !important;
}

.border-right-before-purple:before,
.border-right-after-purple:after {
  border-right-color: #8E44AD !important;
}

.bg-purple-plum {
  background: #8775a7 !important;
}

.bg-purple-plum-opacity {
  background: rgba(135, 117, 167, 0.8) !important;
}

.bg-hover-purple-plum:hover {
  background: #8775a7 !important;
}

.font-purple-plum {
  color: #8775a7 !important;
}

.bg-font-purple-plum {
  color: #ffffff !important;
}

.border-purple-plum {
  border-color: #8775a7 !important;
}

.border-top-purple-plum {
  border-top-color: #8775a7 !important;
}

.border-bottom-purple-plum {
  border-bottom-color: #8775a7 !important;
}

.border-left-purple-plum {
  border-left-color: #8775a7 !important;
}

.border-right-purple-plum {
  border-right-color: #8775a7 !important;
}

.bg-before-purple-plum:before,
.bg-after-purple-plum:after {
  background: #8775a7 !important;
}

.border-before-purple-plum:before,
.border-after-purple-plum:after {
  border-color: #8775a7 !important;
}

.border-top-before-purple-plum:before,
.border-top-after-purple-plum:after {
  border-top-color: #8775a7 !important;
}

.border-bottom-before-purple-plum:before,
.border-bottom-after-purple-plum:after {
  border-bottom-color: #8775a7 !important;
}

.border-left-before-purple-plum:before,
.border-left-after-purple-plum:after {
  border-left-color: #8775a7 !important;
}

.border-right-before-purple-plum:before,
.border-right-after-purple-plum:after {
  border-right-color: #8775a7 !important;
}

.bg-purple-medium {
  background: #BF55EC !important;
}

.bg-purple-medium-opacity {
  background: rgba(191, 85, 236, 0.8) !important;
}

.bg-hover-purple-medium:hover {
  background: #BF55EC !important;
}

.font-purple-medium {
  color: #BF55EC !important;
}

.bg-font-purple-medium {
  color: #ffffff !important;
}

.border-purple-medium {
  border-color: #BF55EC !important;
}

.border-top-purple-medium {
  border-top-color: #BF55EC !important;
}

.border-bottom-purple-medium {
  border-bottom-color: #BF55EC !important;
}

.border-left-purple-medium {
  border-left-color: #BF55EC !important;
}

.border-right-purple-medium {
  border-right-color: #BF55EC !important;
}

.bg-before-purple-medium:before,
.bg-after-purple-medium:after {
  background: #BF55EC !important;
}

.border-before-purple-medium:before,
.border-after-purple-medium:after {
  border-color: #BF55EC !important;
}

.border-top-before-purple-medium:before,
.border-top-after-purple-medium:after {
  border-top-color: #BF55EC !important;
}

.border-bottom-before-purple-medium:before,
.border-bottom-after-purple-medium:after {
  border-bottom-color: #BF55EC !important;
}

.border-left-before-purple-medium:before,
.border-left-after-purple-medium:after {
  border-left-color: #BF55EC !important;
}

.border-right-before-purple-medium:before,
.border-right-after-purple-medium:after {
  border-right-color: #BF55EC !important;
}

.bg-purple-studio {
  background: #8E44AD !important;
}

.bg-purple-studio-opacity {
  background: rgba(142, 68, 173, 0.8) !important;
}

.bg-hover-purple-studio:hover {
  background: #8E44AD !important;
}

.font-purple-studio {
  color: #8E44AD !important;
}

.bg-font-purple-studio {
  color: #ffffff !important;
}

.border-purple-studio {
  border-color: #8E44AD !important;
}

.border-top-purple-studio {
  border-top-color: #8E44AD !important;
}

.border-bottom-purple-studio {
  border-bottom-color: #8E44AD !important;
}

.border-left-purple-studio {
  border-left-color: #8E44AD !important;
}

.border-right-purple-studio {
  border-right-color: #8E44AD !important;
}

.bg-before-purple-studio:before,
.bg-after-purple-studio:after {
  background: #8E44AD !important;
}

.border-before-purple-studio:before,
.border-after-purple-studio:after {
  border-color: #8E44AD !important;
}

.border-top-before-purple-studio:before,
.border-top-after-purple-studio:after {
  border-top-color: #8E44AD !important;
}

.border-bottom-before-purple-studio:before,
.border-bottom-after-purple-studio:after {
  border-bottom-color: #8E44AD !important;
}

.border-left-before-purple-studio:before,
.border-left-after-purple-studio:after {
  border-left-color: #8E44AD !important;
}

.border-right-before-purple-studio:before,
.border-right-after-purple-studio:after {
  border-right-color: #8E44AD !important;
}

.bg-purple-sharp {
  background: #796799 !important;
}

.bg-purple-sharp-opacity {
  background: rgba(121, 103, 153, 0.8) !important;
}

.bg-hover-purple-sharp:hover {
  background: #796799 !important;
}

.font-purple-sharp {
  color: #796799 !important;
}

.bg-font-purple-sharp {
  color: #ffffff !important;
}

.border-purple-sharp {
  border-color: #796799 !important;
}

.border-top-purple-sharp {
  border-top-color: #796799 !important;
}

.border-bottom-purple-sharp {
  border-bottom-color: #796799 !important;
}

.border-left-purple-sharp {
  border-left-color: #796799 !important;
}

.border-right-purple-sharp {
  border-right-color: #796799 !important;
}

.bg-before-purple-sharp:before,
.bg-after-purple-sharp:after {
  background: #796799 !important;
}

.border-before-purple-sharp:before,
.border-after-purple-sharp:after {
  border-color: #796799 !important;
}

.border-top-before-purple-sharp:before,
.border-top-after-purple-sharp:after {
  border-top-color: #796799 !important;
}

.border-bottom-before-purple-sharp:before,
.border-bottom-after-purple-sharp:after {
  border-bottom-color: #796799 !important;
}

.border-left-before-purple-sharp:before,
.border-left-after-purple-sharp:after {
  border-left-color: #796799 !important;
}

.border-right-before-purple-sharp:before,
.border-right-after-purple-sharp:after {
  border-right-color: #796799 !important;
}

.bg-purple-soft {
  background: #8877a9 !important;
}

.bg-purple-soft-opacity {
  background: rgba(136, 119, 169, 0.8) !important;
}

.bg-hover-purple-soft:hover {
  background: #8877a9 !important;
}

.font-purple-soft {
  color: #8877a9 !important;
}

.bg-font-purple-soft {
  color: #ffffff !important;
}

.border-purple-soft {
  border-color: #8877a9 !important;
}

.border-top-purple-soft {
  border-top-color: #8877a9 !important;
}

.border-bottom-purple-soft {
  border-bottom-color: #8877a9 !important;
}

.border-left-purple-soft {
  border-left-color: #8877a9 !important;
}

.border-right-purple-soft {
  border-right-color: #8877a9 !important;
}

.bg-before-purple-soft:before,
.bg-after-purple-soft:after {
  background: #8877a9 !important;
}

.border-before-purple-soft:before,
.border-after-purple-soft:after {
  border-color: #8877a9 !important;
}

.border-top-before-purple-soft:before,
.border-top-after-purple-soft:after {
  border-top-color: #8877a9 !important;
}

.border-bottom-before-purple-soft:before,
.border-bottom-after-purple-soft:after {
  border-bottom-color: #8877a9 !important;
}

.border-left-before-purple-soft:before,
.border-left-after-purple-soft:after {
  border-left-color: #8877a9 !important;
}

.border-right-before-purple-soft:before,
.border-right-after-purple-soft:after {
  border-right-color: #8877a9 !important;
}

/***
Color library demo
***/

.color-demo {
  border: 1px solid #eee;
  margin: 0 0 20px 0;
  cursor: pointer;
}

.color-demo .color-view {
  padding: 35px 10px;
  text-align: center;
  font-size: 18px;
}

.color-demo .color-info {
  border-top: 1px solid #eee;
  padding: 10px 10px;
  text-align: center;
}

@media (max-width: 767px) {
  /* 767px */
}

/*--------------------------------------------------
    [PLACEHOLDER]
----------------------------------------------------*/

/*--------------------------------------------------
    [TRANSITION]
----------------------------------------------------*/

/* Cubic Bezier Transition */

/*--------------------------------------------------
    [Dropdown Menu v2]
----------------------------------------------------*/

.dropdown-menu-v2 {
  margin-right: 10px !important;
}

/*--------------------------------------------------
	[Header]
----------------------------------------------------*/

.page-header {
  margin: 0;
  padding: 0;
  background: #171B1E;
  /* Navbar */
  /* Navbar Brand */
  /* Topbar Actions */
}

.page-header .navbar {
  z-index: 0;
  border: none;
  position: static;
  margin-bottom: 0;
  border-radius: 0;
  /* Container Fluid */
}

.page-header .navbar .container-fluid {
  padding-right: 0;
}

.page-header .navbar-brand {
  height: 66px;
  max-height: 72px;
  line-height: 27px;
}

.page-header .navbar-brand img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.page-header .topbar-actions {
  float: right;
  /* padding: 13px; */
  /* Search box */
  /* Notification */
  /* User Activity */
}

.page-header .topbar-actions .search-form {
  float: left;
  position: relative;
  display: inline-block;
  background: #171B1E;
}

.page-header .topbar-actions .search-form .input-group {
  display: inherit;
}

.page-header .topbar-actions .search-form .input-group .form-control {
  box-shadow: none;
  border-radius: 0;
  position: relative;
  padding-right: 40px;
  border-top: none;
  border-bottom: none;
  color: #4e5962;
  background: #171B1E;
  width: 250px;
  height: 60px;
  font-size: 15px;
  border-left-color: #384047;
  border-right-color: #384047;
}

.page-header .topbar-actions .search-form .input-group .form-control:-moz-placeholder {
  color: #4e5962;
}

.page-header .topbar-actions .search-form .input-group .form-control::-moz-placeholder {
  color: #4e5962;
}

.page-header .topbar-actions .search-form .input-group .form-control:-ms-input-placeholder {
  color: #4e5962;
}

.page-header .topbar-actions .search-form .input-group .form-control::-webkit-input-placeholder {
  color: #4e5962;
}

.page-header .topbar-actions .search-form .input-group .input-group-btn {
  width: 0;
  height: 60px;
}

.page-header .topbar-actions .search-form .input-group .input-group-btn .btn.submit {
  z-index: 2;
  color: #4e5962;
  margin-left: -44px;
}

.page-header .topbar-actions .search-form .input-group .input-group-btn .btn.submit>i {
  font-size: 16px;
}

.page-header .topbar-actions .btn-group-notification .btn {
  background: none;
  width: 60px;
  height: 60px;
  box-shadow: none !important;
  position: static !important;
}

.page-header .topbar-actions .btn-group-notification .btn .badge {
  padding: 0;
  height: auto;
  font-size: 20px !important;
  min-width: auto;
  font-weight: 500;
  line-height: 1.4;
  background: none;
  text-align: center;
  color: #009dc7;
  box-shadow: none !important;
}

.page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 {
  padding: 0;
  min-width: 300px;
}

.page-header .topbar-actions .btn-group-notification .dropdown-menu-v2:before {
  border-color: transparent transparent #21282e transparent;
}

.page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .external {
  padding: 15px;
  display: block;
  overflow: hidden;
  background: #21282e;
  letter-spacing: 0.5px;
}

.page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .external h3 {
  margin: 0;
  z-index: 1;
  padding: 0;
  float: left;
  color: #bbb;
  font-size: 13px;
  line-height: 1.4;
  max-width: 200px;
  position: relative;
}

.page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .external a {
  padding: 0;
  clear: none;
  font-size: 13px;
  font-weight: 300;
  text-align: right;
  color: #009dc7;
  text-transform: lowercase;
}

.page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list>li {
  display: block;
  padding: 15px;
  border-top: 1px solid #2e353c;
}

.page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list>li:before,
.page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list>li:after {
  display: table;
  content: " ";
}

.page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list>li:after {
  clear: both;
}

.page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list>li:first-child {
  border-top: none;
}

.page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list>li:hover {
  background: #21282e;
}

.page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list>li>a {
  color: #606e7a;
  overflow: hidden;
}

.page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list>li>a .details {
  float: left;
  max-width: 215px;
}

.page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list>li>a .details .label {
  float: left;
  padding: 5px;
  margin-right: 10px;
}

.page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list>li>a .time {
  float: right;
  font-size: 12px;
  padding: 4px 8px;
  background: #171B1E;
}

.page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list>li>a:hover {
  text-decoration: none;
}

.page-header .topbar-actions .btn-group-img .btn {
  padding: 0;
  border-radius: 0;
  line-height: normal;
  background: #333c43;
  height: 60px;
  border-left: 1px solid #384047;
}

.page-header .topbar-actions .btn-group-img .btn>span {
  padding: 9px;
  line-height: 1.4;
  color: #009dc7;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
}

.page-header .topbar-actions .btn-group-img .btn>img {
  text-align: center;
  vertical-align: middle;
  height: 60px;
}

/* Page Header Fixed */

.page-header-fixed .page-content {
  margin-top: 80px;
}

/* Stylesheet design for under max-width: 479px */

@media (max-width: 479px) {

  /* 479px */
  .page-header {
    /* Navbar */
    /* Navbar Brand */
    /* Topbar Actions */
  }

  .page-header .navbar-brand {
    float: none;
    display: block;
  }

  .page-header .navbar-brand img {
    margin: 0 auto;
    display: block;
    text-align: right;
  }

  .page-header .topbar-actions {
    /* Search box */
    /* Notification */
  }

  .page-header .navbar .container-fluid {
    padding-right: 6px;
    padding-left: 6px;
  }

  .page-header .topbar-actions .search-form {
    z-index: 9;
    margin-left: 0;
    background: #171B1E;
    width: 60px;
  }

  .page-header .topbar-actions .search-form.open {
    right: 0;
    left: 10px;
    display: block;
    position: absolute;
    width: 100% !important;
    margin-left: -10px !important;
  }

  .page-header .topbar-actions .search-form.open .input-group {
    width: 100%;
    display: table;
  }

  .page-header .topbar-actions .search-form.open .input-group .form-control {
    border-right: none;
    z-index: 9 !important;
    width: 100% !important;
  }

  .page-header .topbar-actions .search-form.open .input-group .btn.submit {
    display: table-cell;
  }

  .page-header .topbar-actions .search-form .form-control {
    width: 0 !important;
    padding-right: 44px !important;
  }

  .page-header .topbar-actions .search-form .input-group-btn .btn.submit {
    margin-left: -49px !important;
  }

  .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 {
    right: -85px;
  }

  .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2:before {
    right: 100px;
  }
}

/* Stylesheet design between 480px to 767px */

@media (min-width: 480px) and (max-width: 767px) {
  .page-header .topbar-actions {
    /* Search box */
  }

  .page-header .topbar-actions .search-form .input-group .form-control {
    width: 175px;
  }
}

/* Stylesheet design for max-width: 767px */

@media (max-width: 767px) {
  .page-header {
    /* Navbar */
  }

  .page-header .navbar {
    /* Container Fluid */
    /* Navbar Brand */
  }

  .container-fluid {
    padding-right: 6px;
    padding-left: 6px;
  }

  .page-header .navbar .navbar-brand {
    border-right: none;
  }
}

/* Stylesheet design for min-width: 768px */

@media (min-width: 768px) {

  /* 768px */
  .page-header {
    /* Search box */
  }

  .page-header .search-form .form-control {
    transition-duration: 300ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }

  .page-header .search-form.open .input-group .form-control {
    width: 330px;
    transition-duration: 300ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
}

/* Page header fixed for above 992px */

@media (max-width: 992px) {

  /* 992px */
  /* Page Header Fixed */
  .page-header-fixed .navbar-fixed-top {
    position: static;
  }
}

/*--------------------------------------------------
	[PAGE CONTENT]
----------------------------------------------------*/

/* Body */

body {
  /* background: #171B1E; */
  background: #141821;
}

html {
  height: 100%;
}

/* Go2top */

.go2top i,
.go2top i:focus {
  right: 20px;
  bottom: 25px;
  width: 38px;
  height: 38px;
  z-index: 999;
  line-height: 1;
  font-size: 35px;
  position: fixed;
  color: #384047;
}

/* Go2top under Page boxed */

/* Page Boxed */

/*--------------------------------------------------
    [Center Aligned Content]
----------------------------------------------------*/

/* Set displays an element as in inline-level flex container */

@media (min-width: 768px) {

  /* 768px */
  .center-wrap {
    top: 0;
    left: 0;
    right: 0;
    width: 700px;
    height: 100%;
    margin: 0 auto;
    display: block;
    overflow: hidden;
    position: absolute;
  }

  .center-align {
    width: 100%;
    height: 100%;
    display: table;
  }

  .center-body {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
  }
}

/*--------------------------------------------------
    [Webapp Button]
----------------------------------------------------*/

@media (max-width: 991px) {

  /* 991px */
  .sm-margin-bottom-30 {
    margin-bottom: 30px;
  }
}

/* Media Queries */

@media (max-width: 992px) {
  /* 992px */
}

/*--------------------------------------------------
    [Page Content Popup]
----------------------------------------------------*/

.page-content.page-content-popup {
  /* background: #fff;  */
}

/* Set space for content */

@media (max-width: 480px) {

  /* 480px */
  .page-content.page-content-popup {
    padding-bottom: 20px;
  }
}

@media (max-width: 767px) {
  /* 767px */
}

/*--------------------------------------------------
    [Page Content]
----------------------------------------------------*/

/* Page Content */

.page-content .page-content-fixed-header {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #efefef;
  /* Page Breadcrumb */
  /* Content Header Menu */
}

.page-content .page-content-fixed-header:before,
.page-content .page-content-fixed-header:after {
  display: table;
  content: " ";
}

.page-content .page-content-fixed-header:after {
  clear: both;
}

.page-content .page-content-fixed-header .content-header-menu {
  float: right;
  /* Menu Toggler */
  /* Dropdown Ajax Menu */
}

.page-content .page-content-fixed-header .content-header-menu .dropdown-ajax-menu {
  margin: 0;
  padding: 0;
  display: inline;
  position: relative;
  vertical-align: inherit;
}

.page-content .page-content-fixed-header .content-header-menu .dropdown-ajax-menu .btn {
  border-radius: 0;
  background: #fff;
  padding: 16px 30px 17px;
  border-left: 1px solid #efefef;
}

.page-content .page-content-fixed-header .content-header-menu .dropdown-ajax-menu .btn:hover i {
  color: #009dc7;
}

.page-content .page-content-fixed-header .content-header-menu .dropdown-ajax-menu .btn i {
  height: 8px;
  font-size: 5px;
  line-height: 2;
  display: block;
  color: #a6b2bd;
}

.page-content .page-content-fixed-header .content-header-menu .dropdown-ajax-menu .dropdown-menu-v2 {
  margin-top: 70px;
}

/* Material Design */

.page-md .content-header-menu .dropdown-ajax-menu .btn {
  box-shadow: none;
  border-left: 1px solid #efefef !important;
}

/* Stylesheet design for max-width: 991px */

@media (max-width: 991px) {

  /* 991px */
  /* Page content */
  .page-content .page-content-fixed-header {
    /* Content Header Menu */
  }

  .page-content .page-content-fixed-header .content-header-menu .dropdown-ajax-menu .btn {
    padding: 13px 21px;
  }

  .page-content .page-fixed-main-content {
    padding: 10px;
  }
}

/* Stylesheet design for under max-width: 479px */

@media (max-width: 479px) {

  /* 479px */
  /* Page Content */
  .page-content .page-fixed-main-content {
    padding: 6px;
  }
}

/*--------------------------------------------------
    [Page Fixed  Main Content]
----------------------------------------------------*/

/* Stylesheet design for min-width: 992px */

@media (min-width: 992px) {

  /* 992px */
  /* Page fixed main content */
  .page-content .page-fixed-main-content {
    overflow: hidden;
  }

  .page-content .page-fixed-main-content {
    padding: 20px;
  }
}

/***
Page sidebar
***/

/* IE8 fix */

.ie8 .page-sidebar {
  width: 235px;
  float: left;
  position: relative;
  margin-right: -100%;
}

/* Page Sidebar */

.page-sidebar,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  /* background: #fff; */
  /* Default sidebar menu */
}

.page-sidebar.navbar-collapse,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover.navbar-collapse {
  padding: 0;
  box-shadow: none;
}

.page-md .page-sidebar.navbar-collapse,
.page-md .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover.navbar-collapse {
  box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.03);
}

.page-sidebar .page-sidebar-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu {
  /* border: 1px solid #e7ecf1; */
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  list-style: none;
  margin-bottom: 0;
  padding: 0;
  /* 1st level links */
  /* all links */
}

.page-sidebar .page-sidebar-menu>li,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li {
  display: block;
  /* margin: 0; */
  padding: 0;
  border: 0px;
  font-size: 18px;
  color: white;
  /* margin: 8px; */
  margin-top: 5px;
}

.page-sidebar .page-sidebar-menu>li.heading,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.heading {
  /* height: 50px; */
  padding: 25px 15px 10px 15px;
}

.page-sidebar .page-sidebar-menu>li.heading>h3,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.heading>h3 {
  font-size: 13px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  font-weight: 600;
}

.page-sidebar .page-sidebar-menu>li>a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li>a {
  display: flex;
  color: rgba(255, 255, 255, 0.7);
  /* display: block; */
  align-items: center;
  position: relative;
  margin: 1px 0 0 0;
  border: 0px;
  padding: 12px 15px;
  text-decoration: none;
}

.page-sidebar .page-sidebar-menu>li>a>.title,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li>a>.title {
  font-size: 14px;
  font-weight: 400;
}

.page-sidebar .page-sidebar-menu>li>a>i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li>a>i {
  color: #94e8ff;
  font-size: 18px;
  text-shadow: none;
  font-weight: 300;
}

.page-sidebar .page-sidebar-menu>li>a>[class^="icon-"],
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li>a>[class^="icon-"] {
  top: -1px;
  margin-left: 1px;
  margin-right: 2px;
}

.page-sidebar-fixed .page-sidebar .page-sidebar-menu>li>a,
.page-sidebar-fixed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li>a {
  transition: all 0.2s ease;
}

.page-sidebar-reversed.page-sidebar-fixed .page-sidebar .page-sidebar-menu>li>a,
.page-sidebar-reversed.page-sidebar-fixed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li>a {
  transition: none;
}

.page-sidebar .page-sidebar-menu>li.open>a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.open>a {
  background: #f4f6f7;
  font-size: 14px;
}

.page-sidebar .page-sidebar-menu>li.open>a>.arrow:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.open>a>.arrow:before {
  color: #94e8ff;
}

.page-sidebar .page-sidebar-menu>li.open>a>i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.open>a>i {
  color: #94e8ff;
}

.page-sidebar .page-sidebar-menu>li.open:hover a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.open:hover a {
  color: inherit;
}

.page-sidebar .page-sidebar-menu>li.open:hover a>.arrow:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.open:hover a>.arrow:before {
  color: #94e8ff;
}

.page-sidebar .page-sidebar-menu>li.open:hover i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.open:hover i {
  color: #94e8ff;
}

.page-sidebar .page-sidebar-menu>li.active>a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.active>a {
  border: none;
  text-shadow: none;
  background: transparent;
  /* font-size: 14px; */
}

.page-sidebar .page-sidebar-menu>li.active>a>i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.active>a>i {
  color: #94e8ff;
}

.page-sidebar .page-sidebar-menu>li.active:hover a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.active:hover a {
  color: inherit;
}

.page-sidebar .page-sidebar-menu>li.active:hover a>.arrow:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.active:hover a>.arrow:before {
  color: #94e8ff;
}

.page-sidebar .page-sidebar-menu>li.active:hover i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.active:hover i {
  color: #94e8ff;
}

.page-sidebar .page-sidebar-menu li>a>.arrow:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li>a>.arrow:before {
  width: 10px;
  float: right;
  margin-right: 5px;
  margin-left: 5px;
  display: inline;
  color: #9ba6b0;
  font-size: 16px;
  font-family: FontAwesome;
  height: auto;
  content: "\f104";
  font-weight: 300;
  text-shadow: none;
}

.page-sidebar .page-sidebar-menu li>a>.arrow.open:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li>a>.arrow.open:before {
  content: "\f107";
  margin-right: 6px;
}

.page-sidebar .page-sidebar-menu li>a:hover>.arrow:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li>a:hover>.arrow:before {
  color: #94e8ff;
}

.page-sidebar .page-sidebar-menu li>a>.badge,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li>a>.badge {
  float: right;
  margin-top: 1px;
  margin-right: 0px;
}

.page-sidebar .page-sidebar-menu .sub-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu {
  list-style: none;
  display: none;
  padding: 0;
  margin: 8px 0px 8px 0px;
}

.page-sidebar .page-sidebar-menu .sub-menu li,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li {
  background: none;
  margin: 0px;
  padding: 0px;
  margin-top: 1px !important;
  /* 2nd level sub menu */
}

.page-sidebar .page-sidebar-menu .sub-menu li:hover>a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li:hover>a {
  background: #f4f6f7;
}

.page-sidebar .page-sidebar-menu .sub-menu li:hover>a>i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li:hover>a>i {
  color: #94e8ff;
}

.page-sidebar .page-sidebar-menu .sub-menu li>a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li>a {
  color: #171B1E;
  display: block;
  margin: 0;
  padding: 9px 14px 9px 30px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  background: none;
}

.page-sidebar .page-sidebar-menu .sub-menu li>a>i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li>a>i {
  color: #94e8ff;
  font-size: 16px;
  margin-right: 1px;
}

.page-sidebar .page-sidebar-menu .sub-menu li>a .arrow:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li>a .arrow:before {
  margin-top: -4px;
}

.page-sidebar .page-sidebar-menu .sub-menu li>a .arrow.open:before,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li>a .arrow.open:before {
  margin-top: -2px;
}

.page-sidebar .page-sidebar-menu .sub-menu li>.sub-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li>.sub-menu {
  margin: 0;
}

.page-sidebar .page-sidebar-menu .sub-menu li>.sub-menu>li,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li>.sub-menu>li {
  /* 3rd level sub menu */
}

.page-sidebar .page-sidebar-menu .sub-menu li>.sub-menu>li>a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li>.sub-menu>li>a {
  padding-left: 40px;
}

.page-sidebar .page-sidebar-menu .sub-menu li>.sub-menu>li>.sub-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li>.sub-menu>li>.sub-menu {
  margin: 0;
}

.page-sidebar .page-sidebar-menu .sub-menu li>.sub-menu>li>.sub-menu>li>a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li>.sub-menu>li>.sub-menu>li>a {
  padding-left: 50px;
}

.page-sidebar .page-sidebar-menu .sub-menu.always-open,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu.always-open {
  display: block;
}

.page-sidebar .page-sidebar-menu li.active>a,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active>a {
  /* background: #f4f6f7; */
}

.page-sidebar .page-sidebar-menu li.active>a>i,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active>a>i {
  color: #94e8ff;
}

.page-sidebar .page-sidebar-menu li.active>.sub-menu,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active>.sub-menu {
  display: block;
}

@media (min-width: 992px) {

  /* 992px */
  .page-sidebar {
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
    float: left;
    position: relative;
    margin: 6px 0% 6px 6px;
  }

  .page-full-width .page-sidebar {
    display: none !important;
  }

  .page-sidebar.navbar-collapse {
    max-height: none !important;
  }

  .page-sidebar .page-sidebar-menu {
    /* padding: 10px 0; */
  }

  .page-sidebar-reversed .page-sidebar {
    float: right;
    margin-right: 0;
    margin-left: -100%;
  }

  .page-sidebar-reversed.page-sidebar-fixed .page-sidebar {
    margin-left: -235px;
  }

  .page-sidebar-reversed.page-sidebar-fixed .page-sidebar-wrapper {
    position: relative;
    float: right;
  }

  .page-sidebar-fixed .page-sidebar {
    position: fixed !important;
    margin-left: 0;
    top: 95px;
  }

  .page-sidebar-fixed .page-sidebar-menu>li.last {
    margin-bottom: 15px !important;
  }

  .page-sidebar-fixed .page-sidebar-menu .sub-menu {
    height: auto !important;
  }

  /* Sidebar Closed */
  .page-sidebar-closed .page-sidebar {
    width: 54px !important;
  }

  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed {
    /* sidebar */
    width: 54px !important;
  }

  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li {
    /* hide opened sub menu */
  }

  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li.heading>h3 {
    display: none;
  }

  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li.open>.sub-menu,
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li>.sub-menu {
    display: none !important;
  }

  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:not(.heading):hover {
    width: 289px !important;
    position: relative !important;
    z-index: 10000;
    display: block !important;
  }

  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:not(.heading):hover>a {
    -webkit-border-radius: 0 4px 0 0;
    -moz-border-radius: 0 4px 0 0;
    -ms-border-radius: 0 4px 0 0;
    -o-border-radius: 0 4px 0 0;
    border-radius: 0 4px 0 0;
    text-align: left;
  }

  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:not(.heading):hover>a>i {
    display: inline-block;
    margin-right: 5px;
  }

  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:not(.heading):hover>a>.title {
    display: inline !important;
    padding-left: 20px;
  }

  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:not(.heading):hover>a>.badge {
    display: block !important;
  }

  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:not(.heading):hover>a>.selected {
    display: none;
  }

  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:not(.heading):hover>.sub-menu {
    width: 234px;
    position: absolute;
    z-index: 2000;
    left: 55px;
    margin-top: 0;
    top: 100%;
    display: block !important;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    -ms-border-radius: 0 0 4px 4px;
    -o-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
  }

  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:not(.heading):hover>.sub-menu>li>a {
    padding-left: 15px !important;
  }

  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:not(.heading):hover>.sub-menu>li>.sub-menu>li>a {
    padding-left: 30px !important;
  }

  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:not(.heading):hover>.sub-menu>li>.sub-menu>li>.sub-menu>li>a {
    padding-left: 45px !important;
  }

  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li>a {
    min-height: 46px;
  }

  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li>a>.badge,
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li>a>.title,
  .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li>a>.arrow {
    display: none !important;
  }

  .page-sidebar-closed.page-sidebar-reversed .page-sidebar {
    margin-left: -54px;
    width: 54px;
  }

  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed {
    /* sidebar */
  }

  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li>.sub-menu {
    left: auto;
    margin-left: -55px;
  }

  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:hover {
    margin-left: -235px;
  }

  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:hover>a {
    -webkit-border-radius: 4px 0 0 0;
    -moz-border-radius: 4px 0 0 0;
    -ms-border-radius: 4px 0 0 0;
    -o-border-radius: 4px 0 0 0;
    border-radius: 4px 0 0 0;
  }

  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:hover>a>.title {
    padding-left: 0;
    padding-right: 15px;
  }

  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:hover>a>i {
    margin-right: 0;
    margin-left: 2px;
  }

  .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-light.page-sidebar-menu-closed>li>a {
    padding-right: 7px;
    padding-left: 11px;
  }

  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover {
    width: 235px !important;
    display: block;
    z-index: 10000;
  }

  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu {
    width: 235px !important;
  }

  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .selected {
    display: none !important;
  }

  .page-sidebar-closed.page-sidebar-fixed.page-sidebar-reversed .page-sidebar:hover {
    width: 235px !important;
    display: block;
    z-index: 10000;
    margin-left: -235px !important;
  }

  .page-sidebar-closed.page-sidebar-fixed.page-sidebar-reversed .page-sidebar:hover .page-sidebar-menu {
    width: 235px !important;
  }

  .page-sidebar-closed.page-sidebar-hide .page-sidebar {
    display: none !important;
  }

  /* Default Sidebar Menu With Hoverable Submenu */
}

@media (max-width: 991px) {

  /* 991px */
  .page-sidebar {
    border-top: 0 !important;
    margin: 20px;
  }

  .page-sidebar .selected {
    display: none !important;
  }

  .page-sidebar.navbar-collapse {
    max-height: none;
    /* set some max height to have a scrollable menu on mobile devices */
  }

  .page-sidebar.navbar-collapse.collapse {
    display: none !important;
  }

  .page-sidebar.navbar-collapse.in {
    border-top: 0 !important;
    margin: 20px;
    position: relative;
    overflow: hidden !important;
    overflow-y: auto !important;
    display: block !important;
  }

  .page-sidebar.navbar-collapse.navbar-no-scroll {
    max-height: none !important;
  }

  .page-full-width .page-sidebar-menu {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  /* 768px & 991px */
  .page-sidebar .btn-navbar.collapsed .arrow {
    display: none;
  }

  .page-sidebar .btn-navbar .arrow {
    position: absolute;
    right: 25px;
    width: 0;
    height: 0;
    top: 50px;
    border-bottom: 15px solid #5f646b;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }
}

@media (max-width: 767px) {

  /* 767px */
  /* Page sidebar */
  .page-sidebar,
  .page-sidebar.in {
    /* margin: 10px 10px 10px 10px !important; */
  }

  .page-header-fixed.page-header-fixed-mobile .page-sidebar,
  .page-header-fixed.page-header-fixed-mobile .page-sidebar.in {
    /* margin-top: 10px !important; */
  }
}

/*--------------------------------------------------
    [Copyright]
----------------------------------------------------*/

.copyright {
  color: #55616b;
  margin: 20px 0 10px;
  text-align: center;
}

.copyright a {
  z-index: 1;
  position: relative;
}

@media (min-width: 480px) {

  /* 480px */
  .copyright {
    left: 0;
    right: 0;
    bottom: 5px;
    position: absolute;
  }
}

/*--------------------------------------------------
    [Copyright v2]
----------------------------------------------------*/

.copyright-v2 {
  color: #55616b;
  text-align: center;
  padding: 20px 0;
  margin-bottom: 0;
  background: #fff;
}

.copyright-v2 a {
  z-index: 1;
}

/******************
Page Quick Sidebar
******************/

/***
Quick Sidebar Layout
***/

/* Quick Sidebar Notification */

.quick-sidebar-notification {
  position: relative;
}

.quick-sidebar-notification .badge {
  top: -40px;
  left: -5px;
  position: absolute;
}

/* Quick sidebar toggler */

/* Page Portlet Fullscreen */

.page-portlet-fullscreen .page-quick-sidebar-wrapper {
  z-index: -1;
}

/* Quick sidebar toggler */

/* Quick sidebar wrapper */

.page-quick-sidebar-wrapper {
  transition: right 0.3s;
  z-index: 9999;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 320px;
  right: -320px;
  overflow: hidden;
  color: #99a8b5;
  background: #21282e;
}

.page-quick-sidebar-open .page-quick-sidebar-wrapper {
  transition: right 0.3s;
  right: 0;
}

.page-quick-sidebar-wrapper .page-quick-sidebar {
  background: #21282e;
  /* Quick sidebar tabs content */
  /* Quick sidebar general list heading */
  /* Quick sidebar general list-items */
  /* Inner content */
  /* Quick sidebar list */
  /* Quick sidebar list item */
  /* Quick sidebar list item shown */
  /* Quick sidebar chat */
  /* Quick sidebar alerts */
  /* Quick sidebar settings */
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs {
  margin: 0;
  padding: 0;
  border: 0;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li {
  display: table-cell !important;
  width: 1% !important;
  padding: 0;
  margin: 0;
  float: none;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li>a {
  position: relative;
  display: block;
  text-align: center;
  border: 0;
  height: auto;
  font-size: 14px;
  padding: 45px 15px 8px;
  text-transform: uppercase;
  background: none;
  margin-right: 0;
  color: #90a1af;
  border: 0;
  border-bottom: 3px solid rgba(243, 86, 93, 0.3);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  outline: none !important;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li>a>.badge {
  position: absolute;
  top: 45px;
  right: 3px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li.active>a,
.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li:hover>a {
  border: 0;
  border-bottom: 3px solid #f3565d;
  background: none;
  color: #fff;
  text-decoration: none;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li .dropdown-menu {
  border: 0;
  background: #36424c;
  box-shadow: 5px 5px rgba(97, 117, 135, 0.1);
  margin-top: 8px;
  margin-right: 20px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li .dropdown-menu:before {
  position: absolute;
  top: -7px;
  right: 19px;
  display: inline-block !important;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #36424c;
  content: '';
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li .dropdown-menu:after {
  position: absolute;
  top: -6px;
  right: 20px;
  display: inline-block !important;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  border-bottom: 7px solid #36424c;
  content: '';
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li .dropdown-menu>li>a {
  padding: 10px 15px;
  color: #99a8b5;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li .dropdown-menu>li>a>i {
  color: #93a3b1;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li .dropdown-menu>li:hover>a {
  background: #3d4a55;
  color: #99a8b5;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li .dropdown-menu>li:hover>a>i {
  color: #9babb8;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li .dropdown-menu>li.active>a {
  background: #38444f;
  color: #99a8b5;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li.open>a.dropdown-toggle {
  border-bottom: 3px solid #f3565d;
  background: none;
  text-decoration: none;
  color: #90a1af;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .tab-content {
  margin: 0;
  padding: 0;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .list-heading {
  font-size: 16px;
  margin: 10px 10px;
  color: #6c8296;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .list-items>li {
  margin: 0;
  padding: 15px;
  background: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #273037;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .list-items>li:hover {
  background: #273037;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .list-items>li:last-child {
  border-bottom: 0;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .list-items.borderless li {
  border: 0;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .inner-content {
  margin: 10px 10px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-list {
  position: absolute !important;
  width: 320px !important;
  transition: margin 0.3s;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item {
  width: 320px;
  position: absolute !important;
  width: 320px !important;
  transition: margin 0.3s;
  margin-left: 320px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item .page-quick-sidebar-nav {
  padding: 15px 10px 0px 10px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item .page-quick-sidebar-nav .page-quick-sidebar-back-to-list {
  vertical-align: middle;
  display: inline-block;
  font-size: 14px;
  color: #90a1af;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item .page-quick-sidebar-nav .page-quick-sidebar-back-to-list:hover {
  text-decoration: none;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item .page-quick-sidebar-nav .page-quick-sidebar-back-to-list>i {
  font-size: 17px;
  line-height: 17px;
  vertical-align: top;
  margin-right: 3px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-content-item-shown .page-quick-sidebar-list {
  transition: margin 0.3s;
  margin-left: -320px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-content-item-shown .page-quick-sidebar-item {
  transition: margin 0.3s;
  margin-left: 0;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users {
  padding: 10px 0;
  position: relative;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media {
  padding: 15px 15px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-object {
  border-radius: 50% !important;
  width: 45.71429px;
  opacity: 0.8;
  filter: alpha(opacity=80);
  float: left;
  margin-right: 10px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-object:before,
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-object:after {
  content: " ";
  display: table;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-object:after {
  clear: both;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media:hover {
  cursor: pointer;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media:hover .media-object {
  opacity: 1;
  filter: alpha(opacity=100);
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-body .media-heading {
  margin: 5px 0 0 0;
  font-size: 14px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-body .media-heading-sub {
  font-size: 11px;
  text-transform: uppercase;
  color: #657b8d;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-body .media-heading-small {
  font-size: 10px;
  color: #5d7081;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-status {
  margin-top: 10px;
  right: 10px;
  position: absolute;
  display: inline-block;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages {
  padding: 0px 10px;
  position: relative;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post {
  transition: display 0.3s;
  padding: 5px 0;
  margin: 10px auto;
  font-size: 13px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .body {
  color: #c3c3c3;
  display: block;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .name {
  font-size: 12px;
  font-weight: 300;
  color: #8496a7;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .datetime {
  font-size: 12px;
  font-weight: 300;
  color: #8496a7;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .message {
  display: block;
  padding: 5px;
  position: relative;
  color: #90a1af;
  background: #36424c;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.in .message {
  text-align: left;
  margin-left: 55px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.in .message .arrow {
  display: block;
  position: absolute;
  top: 9px;
  left: -6px;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right-width: 6px;
  border-right-style: solid;
  border-right-color: #36424c;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .message {
  margin-right: 55px;
  text-align: right;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .message .arrow {
  display: block;
  position: absolute;
  top: 9px;
  right: -6px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left-width: 6px;
  border-left-style: solid;
  border-left-color: #36424c;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .name,
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .datetime {
  text-align: right;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-form {
  padding: 20px 10px 15px 10px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-settings .page-quick-sidebar-settings-list {
  padding: 10px 0;
  position: relative;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-settings .page-quick-sidebar-settings-list .list-items li .bootstrap-switch {
  margin-top: -3px;
  float: right;
  border: 0;
  min-width: 59px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-settings .page-quick-sidebar-settings-list .list-items li .form-control {
  width: 75px !important;
  padding: 4px 4px !important;
  float: right;
  border: 0;
  margin-top: -4px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-settings .page-quick-sidebar-settings-list .list-items li select.form-control {
  padding: 4px 0px !important;
}

.quick-nav {
  position: fixed;
  z-index: 10103;
  top: 50%;
  right: 10px;
  margin-top: -230px;
  pointer-events: none;
}

.quick-nav .quick-nav-bg {
  /* this is the stretching navigation background */
  position: absolute;
  z-index: 10102;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  border-radius: 30px !important;
  background: #36C6D3;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  webkit-transition: height .2s, box-shadow .2s;
  -moz-transition: height .2s, box-shadow .2s;
  -ms-transition: height .2s, box-shadow .2s;
  -o-transition: height .2s, box-shadow .2s;
  transition: height .2s, box-shadow .2s;
}

.quick-nav.nav-is-visible {
  pointer-events: auto;
}

.quick-nav.nav-is-visible .quick-nav-bg {
  height: 100%;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.2);
}

.quick-nav-trigger {
  position: absolute;
  z-index: 10103;
  top: 0;
  right: 0;
  height: 60px;
  width: 60px;
  border-radius: 50% !important;
  overflow: hidden;
  white-space: nowrap;
  color: transparent;
  pointer-events: auto;
}

.quick-nav-trigger span,
.quick-nav-trigger span::after,
.quick-nav-trigger span::before {
  /* this is the hamburger icon */
  position: absolute;
  width: 16px;
  height: 2px;
  background-color: #ffffff;
}

.quick-nav-trigger span {
  /* middle line of the hamburger icon */
  webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  -ms-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  transition: background-color 0.2s;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.quick-nav-trigger span::after,
.quick-nav-trigger span::before {
  /* top and bottom lines of the hamburger icon */
  content: '';
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  webkit-transition: transform 0.2s;
  -moz-transition: transform 0.2s;
  -ms-transition: transform 0.2s;
  -o-transition: transform 0.2s;
  transition: transform 0.2s;
}

.quick-nav-trigger span::before {
  webkit-transform: translateY(-6px);
  -moz-transform: translateY(-6px);
  -ms-transform: translateY(-6px);
  -o-transform: translateY(-6px);
  transform: translateY(-6px);
}

.quick-nav-trigger span::after {
  webkit-transform: translateY(6px);
  -moz-transform: translateY(6px);
  -ms-transform: translateY(6px);
  -o-transform: translateY(6px);
  transform: translateY(6px);
}

.no-touch .quick-nav-trigger:hover~.quick-nav-bg {
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.2);
}

.nav-is-visible .quick-nav-trigger span {
  background-color: transparent;
}

.nav-is-visible .quick-nav-trigger span::before {
  webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.nav-is-visible .quick-nav-trigger span::after {
  webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.quick-nav ul {
  position: relative;
  z-index: 10103;
  padding: 60px 0 0;
  visibility: hidden;
  webkit-transition: visibility 0.3s;
  -moz-transition: visibility 0.3s;
  -ms-transition: visibility 0.3s;
  -o-transition: visibility 0.3s;
  transition: visibility 0.3s;
  text-align: right;
  list-style: none;
}

.quick-nav ul>li a {
  position: relative;
  display: block;
  height: 50px;
  line-height: 50px;
  padding: 0 calc(1em + 60px) 0 1em;
  font-size: 1.4rem;
  webkit-transition: color 0.2s;
  -moz-transition: color 0.2s;
  -ms-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
}

.quick-nav ul>li a:hover {
  text-decoration: none;
}

.quick-nav ul>li a:hover>span {
  text-decoration: none;
}

.quick-nav ul>li a>i {
  /* navigation item icons */
  content: '';
  position: absolute;
  height: 16px;
  width: 16px;
  font-size: 18px;
  right: 24px;
  top: 16px;
  color: #ebebeb;
}

.quick-nav ul>li a::before {
  /* line visible next to the active navigation item */
  content: '';
  position: absolute;
  width: 3px;
  height: 16px;
  top: 50%;
  right: 60px;
  webkit-transform: translateX(3px) translateY(-50%) scaleY(0);
  -moz-transform: translateX(3px) translateY(-50%) scaleY(0);
  -ms-transform: translateX(3px) translateY(-50%) scaleY(0);
  -o-transform: translateX(3px) translateY(-50%) scaleY(0);
  transform: translateX(3px) translateY(-50%) scaleY(0);
  background-color: #FF3F3F;
}

.quick-nav ul>li span {
  /* navigation item labels */
  color: #ebebeb;
  font-weight: 400;
  display: block;
  opacity: 0;
  webkit-transform: translateX(-25px);
  -moz-transform: translateX(-25px);
  -ms-transform: translateX(-25px);
  -o-transform: translateX(-25px);
  transform: translateX(-25px);
}

.quick-nav ul>li:last-child {
  padding-bottom: 10px;
}

.quick-nav.nav-is-visible ul {
  visibility: visible;
}

.quick-nav.nav-is-visible ul a::after {
  /* navigation item icons */
  webkit-transform: translateY(-50%) scale(1);
  -moz-transform: translateY(-50%) scale(1);
  -ms-transform: translateY(-50%) scale(1);
  -o-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
  -webkit-animation: scaleIn 0.15s backwards;
  -o-animation: scaleIn 0.15s backwards;
  animation: scaleIn 0.15s backwards;
  webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.quick-nav.nav-is-visible ul a:hover::after {
  opacity: 1;
}

.quick-nav.nav-is-visible ul a:hover::before {
  webkit-transform: translateX(3px) translateY(-50%) scaleY(2);
  -moz-transform: translateX(3px) translateY(-50%) scaleY(2);
  -ms-transform: translateX(3px) translateY(-50%) scaleY(2);
  -o-transform: translateX(3px) translateY(-50%) scaleY(2);
  transform: translateX(3px) translateY(-50%) scaleY(2);
  webkit-transition: transform 0.15s 0.3s;
  -moz-transition: transform 0.15s 0.3s;
  -ms-transition: transform 0.15s 0.3s;
  -o-transition: transform 0.15s 0.3s;
  transition: transform 0.15s 0.3s;
}

.quick-nav.nav-is-visible ul a:hover>span {
  color: white;
}

.quick-nav.nav-is-visible ul a:hover>i {
  color: #fafafa;
}

.quick-nav.nav-is-visible ul span {
  opacity: 1;
  webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-animation: slideIn 0.15s backwards;
  -o-animation: slideIn 0.15s backwards;
  animation: slideIn 0.15s backwards;
  webkit-transition: transform 0.2s;
  -moz-transition: transform 0.2s;
  -ms-transition: transform 0.2s;
  -o-transition: transform 0.2s;
  transition: transform 0.2s;
}

.no-touch .quick-nav.nav-is-visible ul a:hover::after {
  opacity: 1;
}

.no-touch .quick-nav.nav-is-visible ul a:hover span {
  webkit-transform: translateX(-5px);
  -moz-transform: translateX(-5px);
  -ms-transform: translateX(-5px);
  -o-transform: translateX(-5px);
  transform: translateX(-5px);
}

.quick-nav-overlay {
  display: none;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 10101;
  background: transparent;
}

.quick-nav.nav-is-visible+.quick-nav-overlay {
  background: rgba(0, 0, 0, 0.8);
  display: block;
  -webkit-transition: background .7s ease-out;
  -moz-transition: background .7s ease-out;
  -o-transition: background .7s ease-out;
  transition: background .7s ease-out;
}

@media (max-width: 991px) {

  /* 991px */
  .quick-nav {
    top: 120px;
    margin-top: 0;
  }
}

/* When the body has the loading class, we turn
   the scrollbar off with overflow:hidden */

.Countdown {
  margin: 10px auto;
  padding-bottom: 20px;
}

.Countdown-col {
  display: inline-block;
}

.Countdown-col-element {
  display: inline-block;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
}

.Countdown-col-element strong {
  font-size: 50px;
}

/* Position and sizing of burger button */

/* 
@media (min-width: 992px) {
    .bm-burger-button {
        position: fixed;
        float: right;
     
        height: 25px;
        margin-top: 2.2%
    }
}

@media (min-width: 481px) and (max-width: 991px) {
    .bm-burger-button {
        position: fixed;
        float: right;

        margin-top: 4%
    }
}

@media (max-width: 480px) {
    .bm-burger-button {
        position: fixed;
        float: right;

        margin-top: 10px;
    }
} */

/* Color/shape of burger icon bars */

/* Color/shape of burger icon bars on hover*/

/* Position and sizing of clickable cross button */

/* Color/shape of close button cross */

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/

/* General sidebar styles */

/* Morph shape necessary with bubble or elastic */

/* Wrapper for item list */

/* Individual item */

/* Styling of overlay */

.color-whitesmoke {
  color: whitesmoke;
}

/* Cubic Bezier Transition */

/***
Bootstrap Colorpicker
***/

.input-group.color .input-group-btn i {
  position: absolute;
  display: block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  right: 6px;
}

/* change z-index when opened in modal */

/***
Bootstrap Datepaginator
***/

#dp-calendar {
  right: 4px !important;
}

/***
Bootstrap Datepicker
***/

/* change z-index when opened in modal */

/***
Bootstrap Daterangepicker
***/

/***
Bootstrap  Datetimepicker
***/

/* change z-index when opened in modal */

/***
Bootstrap Editable
***/

.editable-input table,
.editable-input table th,
.editable-input table td,
.editable-input table tr {
  border: 0 !important;
}

/***
Jansy File Input plugin css changes
***/

/***
Bootstrap Markdown
***/

.md-input {
  padding: 5px !important;
  border-bottom: 0 !important;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  -o-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.md-editor {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.md-editor .btn-toolbar {
  margin-left: 0px;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  -o-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.md-editor .btn-toolbar .btn-group .btn-sm.btn {
  padding-bottom: 3px;
}

.md-editor .btn-toolbar .btn-group .btn-primary {
  padding-top: 5px;
  padding-bottom: 7px;
}

.md-editor.active {
  border: 1px solid #999999;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

/***
Error state for WYSIWYG Editors
***/

.has-error .md-editor {
  border: 1px solid #fbe1e3 !important;
}

.has-success .md-editor {
  border: 1px solid #abe7ed !important;
}

.has-warning .md-editor {
  border: 1px solid #f9e491 !important;
}

/***
Bootstrap Modal
***/

/* fix: content shifting to the right on modal open */

.modal-open.page-overflow .page-container,
.modal-open.page-overflow .page-container .navbar-fixed-top,
.modal-open.page-overflow .page-container .navbar-fixed-bottom,
.modal-open.page-overflow .modal-scrollable {
  overflow-y: auto !important;
}

.modal-scrollable {
  overflow: hidden !important;
}

/* BS 3.3.1 fix */

.modal-backdrop {
  position: fixed;
  bottom: 0;
}

.modal-scrollable {
  overflow: hidden !important;
  z-index: 10051;
  overflow-y: scroll !important;
}

/***
Bootstrap Select
***/

.bootstrap-select .btn {
  border-color: #c2cad8;
}

.has-error .bootstrap-select .btn {
  border-color: #fbe1e3;
}

.has-success .bootstrap-select .btn {
  border-color: #abe7ed;
}

.has-warning .bootstrap-select .btn {
  border-color: #f9e491;
}

.bootstrap-select.open .btn {
  border-color: #93a1bb;
}

.bootstrap-select .btn:focus {
  outline: none !important;
  outline-offset: 0;
}

.bootstrap-select.btn-group .dropdown-menu {
  margin-top: 1px;
}

.bootstrap-select.btn-group .dropdown-menu>li>dt>.text {
  font-weight: 600;
  font-family: 'Open Sans';
  font-size: 14px;
}

.bootstrap-select.btn-group .dropdown-menu .text-muted {
  color: #999 !important;
}

.bootstrap-select .caret {
  border: 0;
  width: auto;
  height: auto;
  margin-top: -10px !important;
}

.bootstrap-select .caret:before {
  content: "\f107";
  display: inline-block;
  border: 0;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
}

.bootstrap-select .selected i {
  color: #aaa;
}

.bootstrap-select .dropdown-menu {
  z-index: 1000 !important;
}

.modal-open .bootstrap-select .dropdown-menu {
  z-index: 10050 !important;
}

/***
Bootstrap Switch 
***/

.bootstrap-switch {
  border-color: #c2cad8;
}

.bootstrap-switch.bootstrap-switch-focused {
  box-shadow: none;
  border-color: #c2cad8;
}

/***
Color variants
***/

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary {
  color: #fff;
  background: #337ab7;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info {
  color: #fff;
  background: #659be0;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success {
  color: #fff;
  background: #36c6d3;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning {
  color: #fff;
  background: #F1C40F;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger {
  color: #fff;
  background: #ed6b75;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
  color: #444;
  background: #e8eaef;
}

/* Bootstrap switch on portlet headers */

.portlet:not(.light)>.portlet-title>.actions>.bootstrap-switch {
  border-color: transparent;
  margin-top: 0px;
}

/***
Bootstrap Time Picker
***/

/***
Toastr Notifications
***/

.toast {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.toast {
  background-color: #030303;
}

.toast-success {
  background-color: #51a351;
}

.toast-error {
  background-color: #bd362f;
}

.toast-info {
  background-color: #2f96b4;
}

.toast-warning {
  background-color: #f89406;
}

.toast .toast-close-button {
  display: inline-block;
  margin-top: 0px;
  margin-right: 0px;
  text-indent: -100000px;
  width: 11px;
  height: 16px;
  background-repeat: no-repeat !important;
}

.toast-top-center {
  top: 12px;
  margin: 0 auto;
  left: 50%;
  margin-left: -150px;
}

.toast-top-center .toast {
  margin: 0 !important;
}

.toast-bottom-center {
  bottom: 12px;
  margin: 0 auto;
  left: 50%;
  margin-left: -150px;
}

.toast-bottom-center .toast {
  margin: 0 !important;
}

/***
Bootstrap Wizard
***/

@media (min-width: 768px) and (max-width: 1200px) {
  /* 768px & 1200px */
}

@media (max-width: 768px) {
  /* 768px */
}

/*!
 * Tab drop for Bootstrap
 */

/***
wysihtml5
***/

/***
Error state for WYSIWYG Editors
***/

/***
Error state for WYSIWYG Editors
***/

.note-editor {
  border: 1px solid #c2cad8;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.note-editor .note-toolbar {
  border-bottom: 1px solid #c2cad8;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  -o-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.note-editor .note-statusbar {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  -o-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.note-editor.note-frame.fullscreen {
  z-index: 10050;
  width: 100% !important;
}

.note-editor .dropdown-menu:before {
  left: 9px;
  right: auto;
}

.note-editor .dropdown-menu:after {
  left: 10px;
  right: auto;
}

.note-link-dialog .checkbox {
  margin-left: 20px;
}

.has-error .note-editor {
  border: 1px solid #fbe1e3 !important;
}

.has-success .note-editor {
  border: 1px solid #abe7ed !important;
}

.has-warning .note-editor {
  border: 1px solid #f9e491 !important;
}

/***
Bootstrap GtreeTable
***/

/***
Modify bootstrap-tagsinput plugin's css
***/

.fixed-table-toolbar .columns label {
  margin: 10px;
  padding: 0 0 0 30px;
}

.fixed-table-header th.bs-checkbox {
  outline: none;
}

.fixed-table-header th.bs-checkbox .th-inner {
  overflow: visible;
}

/***
Bootbox
***/

/*--------------------------------------------------
    [CSS Animation Amchart]
----------------------------------------------------*/

@-webkit-keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -31px;
  }
}

@keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -31px;
  }
}

@-webkit-keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0px;
  }

  100% {
    stroke-opacity: 0;
    stroke-width: 50px;
  }
}

@keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0px;
  }

  100% {
    stroke-opacity: 0;
    stroke-width: 50px;
  }
}

@-webkit-keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }

  100% {
    stroke-dashoffset: 0px;
  }
}

@keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }

  100% {
    stroke-dashoffset: 0px;
  }
}

/*--------------------------------------------------
    [Mao Amchart]
----------------------------------------------------*/

/*--------------------------------------------------
    [Column Line]
----------------------------------------------------*/

@-webkit-keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -28px;
  }
}

@keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -28px;
  }
}

/*--------------------------------------------------
    [Animated Pie Chart]
----------------------------------------------------*/

/***
CKEditor css changes
***/

/***
Error state for WYSIWYG Editors
***/

/***
Clockface
***/

/***
Datatables general customization
***/

.table-checkable tr>th:first-child,
.table-checkable tr>td:first-child {
  text-align: center;
  max-width: 50px;
  min-width: 40px;
  padding-left: 0;
  padding-right: 0;
}

/***
Extended pagination
***/

.table-container .table-actions-wrapper {
  display: none;
}

/***
Scroller Extension
***/

/***
Datatables processing
***/

/***
Tool Buttons
***/

.dt-button-background {
  display: none !important;
}

div.dt-button-collection {
  border: 1px solid #eee;
  background: #ffffff;
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  padding: 0;
}

div.dt-button-collection>a.dt-button {
  background: none;
  padding: 8px 16px;
  font-weight: 300;
  margin: 1px 1px;
  box-shadow: none !important;
  border: 0 !important;
}

div.dt-button-collection>a.dt-button>span {
  font-size: 14px;
  color: #333;
}

div.dt-button-collection>a.dt-button.active {
  background: #f6f6f6 !important;
  box-shadow: none !important;
  border: 0 !important;
}

div.dt-button-collection>a.dt-button.active>span {
  color: #333;
}

button.dt-button,
div.dt-button,
a.dt-button {
  background-image: none !important;
  padding: 6px 12px !important;
}

button.dt-button:last-child,
div.dt-button:last-child,
a.dt-button:last-child {
  margin-right: 0;
}

/***
Rot Details
***/

/***
Sorting
***/

/***
Fixed Header
***/

.table-header-fixed thead>tr>th {
  border-top: 0;
}

.table>tfoot>tr>th,
.table>tfoot>tr>td {
  border-bottom: 0;
  padding: 10px 18px;
  border-top: 1px solid #e7ecf1;
  font-weight: 600;
}

/***
Responsive Datatables
***/

@-moz-document url-prefix() {
  .portlet-datatable .dataTables_wrapper .table-responsive {
    padding: 0 2px !important;
  }
}

@media (max-width: 767px) {
  /* 767px */
}

.table .btn-group .btn {
  margin-left: 0;
  margin-right: -1px;
}

/***
Dropzone css changes(new in v1.1.1)
***/

.dropzone {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

.dropzone-file-area {
  border: 2px dashed #028AF4;
  background: white;
  padding: 20px;
  margin: 0 auto;
  text-align: center;
}

@media (max-width: 768px) {

  /* 768px */
  .dropzone-file-area {
    width: auto;
  }
}

/***
Fancy box fix overlay fix
***/

/***
FuelUX Spinners
***/

.spinner-buttons.btn-group-vertical .btn {
  text-align: center;
  margin: 0;
  height: 17px;
  width: 22px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 0px;
}

.page-style-rounded .spinner-buttons.btn-group-vertical .btn.spinner-up {
  border-radius: 0;
  border-top-right-radius: 4px !important;
}

.page-style-rounded .spinner-buttons.btn-group-vertical .btn.spinner-down {
  border-radius: 0;
  border-bottom-right-radius: 4px;
}

/***
Fullcalendar
***/

/* External events */

.external-event {
  display: inline-block;
  cursor: move;
  margin-bottom: 5px;
  margin-left: 5px;
}

.portlet .event-form-title {
  font-size: 16px;
  margin-top: 4px;
  font-weight: 400;
  margin-bottom: 10px;
}

.portlet.calendar .fc-button {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  border: 0;
  height: 35px;
  padding: 5px 8px 8px 8px;
  margin-left: 2px;
  border-top-style: none;
  border-bottom-style: none;
  border-right-style: solid;
  border-left-style: solid;
  border-color: #ddd;
  background: transparent;
  color: #fff;
  top: -48px;
  outline: none !important;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  -o-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.portlet.calendar .fc-toolbar {
  margin-bottom: 0px;
}

.portlet.calendar .fc-toolbar h2 {
  margin-top: 3px;
  font-size: 17px;
}

.portlet.calendar .fc-header {
  margin-bottom: -21px;
}

.portlet.calendar .fc-button-prev {
  padding-right: 10px;
  padding-left: 8px;
}

.portlet.calendar .fc-button-next {
  padding-right: 8px;
  padding-left: 10px;
}

.portlet.calendar .fc-button .fc-icon {
  height: 20px;
  top: -5px;
}

.portlet.calendar .fc-button.fc-state-active,
.portlet.calendar .fc-button.fc-state-hover {
  color: #666;
  background-color: #fff;
}

.portlet.calendar .fc-button.fc-state-disabled {
  color: #ddd;
}

.portlet.calendar .fc-button .fc-icon-left-single-arrow {
  font-family: FontAwesome;
  display: inline-block;
  font-size: 16px;
}

.portlet.calendar .fc-button .fc-icon-left-single-arrow:after {
  content: "";
  margin: 0;
}

.portlet.calendar .fc-button .fc-icon-left-single-arrow:before {
  content: "\f104";
}

.portlet.calendar .fc-button .fc-icon-right-single-arrow {
  font-family: FontAwesome;
  display: inline-block;
  font-size: 16px;
}

.portlet.calendar .fc-button .fc-icon-right-single-arrow:after {
  margin: 0;
  content: "";
}

.portlet.calendar .fc-button .fc-icon-right-single-arrow:before {
  content: "\f105";
}

.portlet.calendar .fc-text-arrow {
  font-size: 22px;
  font-family: "Courier New", Courier, monospace;
  vertical-align: baseline;
}

.portlet.calendar .fc-event {
  border: 0px;
  background-color: #69a4e0;
  color: #fff;
}

.portlet.calendar .fc-event .fc-content {
  border: 0px;
  padding: 5px 7px;
}

.portlet.calendar .fc-event .fc-time {
  float: left;
  text-align: left;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  padding-right: 5px;
}

.portlet.calendar .fc-event .fc-title {
  text-align: left;
  float: left;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}

.portlet.calendar .fc-header-title h2 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #111;
}

.portlet.calendar .fc-widget-header {
  background-image: none;
  filter: none;
  background-color: #eee;
  text-transform: uppercase;
  font-weight: 300;
}

.portlet.calendar.light .fc-button {
  top: -60px;
  color: #666;
  text-transform: uppercase;
  font-size: 12px;
  padding-bottom: 35px;
}

.portlet.calendar.light .fc-button .fc-text-arrow {
  margin-top: -6px;
  display: inline-block;
}

.portlet.calendar.light .fc-button.fc-state-active,
.portlet.calendar.light .fc-button.fc-state-hover {
  color: #333;
  border-bottom: 2px solid #36c6d3;
}

.portlet.calendar.light .fc-button.fc-state-disabled {
  color: #aaa;
}

.portlet.calendar .mobile .fc-button {
  padding: 0px 6px 20px 6px;
  margin-left: 2px;
  border: 0;
  background-color: #ddd;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  color: #000;
  text-shadow: none;
  text-align: center;
}

.portlet.calendar .mobile .fc-button.fc-state-hover,
.portlet.calendar .mobile .fc-button.fc-state-active {
  background-color: #eee;
}

.portlet.calendar .mobile .fc-button.fc-state-disabled {
  color: #bbb;
}

.portlet.calendar .mobile .fc-button-prev {
  margin-right: 5px;
  margin-top: -2px;
}

.portlet.calendar .mobile .fc-button-next {
  margin-right: -0px;
  margin-top: -2px;
}

.portlet.calendar .mobile .fc-header-space {
  margin: 0px;
  padding: 0px;
  width: 0px;
}

.portlet.calendar .mobile .fc-header-left {
  position: absolute;
  z-index: 10;
}

.portlet.calendar .mobile .fc-header-left .fc-button {
  top: -2px;
}

.portlet.calendar .mobile .fc-header-right {
  position: relative;
  right: 0;
}

.portlet.calendar .mobile .fc-header-right .fc-button {
  top: 35px;
}

.portlet.calendar .mobile .fc-content {
  margin-top: 53px;
}

/***
Google Maps
***/

/***
Gritter notification modify
***/

/***
Dashboard Charts(new in v1.2.1)
***/

/***
jQuery Multi Select
***/

.ms-container .ms-list {
  border: 1px solid #e5e5e5;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.ms-container .ms-optgroup-label {
  font-size: 14px;
}

.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selection li.ms-elem-selection {
  font-size: 13px;
}

.ms-container .ms-list.ms-focus {
  border-color: #999999;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
  color: #333;
  background-color: #eee;
}

.ms-container .form-control {
  margin-bottom: 5px;
}

/***
jQuery Notific8 Plugin
***/

[class*="jquery-notific8"],
[class*="jquery-notific8"]:after,
[class*="jquery-notific8"]:before {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/***
Password Strength(in v1.4)
***/

.password-strength .progress {
  margin-top: 5px;
  margin-bottom: 0;
}

.password-strength .progress-bar {
  padding: 2px;
}

/***
jQuery Slimscroll
***/

/***
jQuery Sparkline
***/

/***
jQuery UI Sliders(new in v1.1.1)
***/

.slider {
  border: 0;
  padding: 0;
  display: block;
  margin: 12px 5px;
  min-height: 11px;
}

.ui-slider-vertical {
  width: 11px;
}

.ui-slider-horizontal .ui-slider-handle {
  top: -3px;
}

.ui-slider-vertical .ui-slider-handle {
  left: -3px;
}

.ui-slider-vertical,
.ui-slider-handle {
  filter: none !important;
  background-image: none !important;
}

/***
JQVMAP Plugin
***/

/***
Jstree
***/

/***
NoUIRangeSlider
***/

/***
Color variants
***/

/* Conect slider */

/* For this slider, disable the 'origin' size. */

/* Position the bar and color it. */

/* When the slider is moved by tap,   transition the connect bar like the handle. */

/**
noUI Tooltips
**/

/***
Google reCaptcha
***/

.form-recaptcha-img {
  margin-bottom: 10px;
  clear: both;
  border: 1px solid #e5e5e5;
  padding: 5px;
}

iframe[src="about:blank"] {
  display: none;
}

.select2-container--bootstrap .select2-search--dropdown .select2-search__field,
.select2-container--bootstrap .select2-selection,
.select2-container--bootstrap.select2-container--focus .select2-selection,
.select2-container--bootstrap.select2-container--open .select2-selection {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove {
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  margin-right: 3px;
}

.select2-container--bootstrap .select2-results__group {
  display: block;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
}

.select2-container--bootstrap .select2-selection {
  font-family: "Open Sans", sans-serif;
}

.modal-open .select2-dropdown {
  z-index: 10060;
}

.modal-open .select2-close-mask {
  z-index: 10055;
}

.modal-open .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
  width: auto !important;
}

.select2-result-repository {
  padding-top: 4px;
  padding-bottom: 3px;
}

.select2-result-repository__meta {
  margin-left: 70px;
}

.select2-result-repository__title {
  color: black;
  font-weight: bold;
  word-wrap: break-word;
  line-height: 1.1;
  margin-bottom: 4px;
}

.select2-result-repository__forks {
  margin-right: 1em;
}

.select2-result-repository__forks,
.select2-result-repository__watchers {
  display: inline-block;
  color: #aaa;
  font-size: 11px;
}

.select2-result-repository__description {
  font-size: 13px;
  color: #777;
  margin-top: 4px;
}

.select2-results__option--highlighted .select2-result-repository__title {
  color: white;
}

.select2-results__option--highlighted .select2-result-repository__forks,
.select2-results__option--highlighted .select2-result-repository__description,
.select2-results__option--highlighted .select2-result-repository__watchers {
  color: #c1d7e9;
}

.select2-selection__choice {
  background-color: #eee !important;
  border: 1px solid #eee !important;
}

.select2-selection__choice .select2-selection__choice__remove {
  font-weight: normal !important;
}

/***
Morris Charts
***/

/***
iCheck
***/

div[class*='icheckbox_'],
div[class*='iradio_'] {
  margin-right: 5px;
  top: -1px !important;
}

/***
AngularJS UI-select
***/

.ui-select-match-close {
  margin-top: 5px !important;
  margin-left: 5px !important;
}

/***
Codemirror Plugin
***/

/***
Prism Plugin
***/

pre[class*="language-"] {
  padding: 10px;
  background: none !important;
  border: 1px solid #f1f4f7 !important;
  box-shadow: none !important;
  margin: 0;
}

pre[class*="language-"] code {
  background: none !important;
  box-shadow: none !important;
  border: 0 !important;
}

/* When the body has the loading class, we turn
   the scrollbar off with overflow:hidden */

login.loading {
  overflow: hidden;
}

login.loading .modal {
  display: block;
}

/* Cubic Bezier Transition */

/***
Login page
***/

/* bg color */

.login {
  /*background-color: #364150 !important;*/
  padding-bottom: 10px;
}

.login .logo {
  margin: 0 auto;
  margin-top: 60px;
  padding: 15px;
  text-align: center;
}

.login .content {
  /* background-color: #13171F; */
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  border-radius: 7px;
  width: 420px;
  margin: 40px auto 10px auto;
  padding: 20px;
  padding-top: 10px;
  overflow: hidden;
  position: relative;
  padding-bottom: 0px;
  /* border: 1px solid #181D26;

  box-shadow: 0px 0px 5px 3px #1b2329;
  border-radius: 2px !important; */
  background: #13171F;
  border: 1px solid #433C3C;
  box-sizing: border-box;
  box-shadow: 3px 5px 5px 0px rgb(37 36 36 / 25%);
  border-radius: 5px;
}

.login .content2fa {
  background-color: #13171F;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  border-radius: 7px;
  width: 500px;
  margin: 40px auto 10px auto;
  padding: 20px;
  padding-top: 10px;
  overflow: hidden;
  position: relative;
  padding-bottom: 0px;
  border: 1px solid #433C3C33;
  box-shadow: 0 0px #433C3C;
  border-radius: 4px !important;
}

.login .content h3 {
  color: white;
  text-align: center;
  font-size: 28px;
  font-weight: 400 !important;
}

.login .content h4 {
  color: white;
}

.login .content .hint {
  color: #999;
  padding: 0;
  margin: 15px 0 7px 0;
}

.login .content .login-form,
.login .content .forget-form {
  padding: 0px;
  margin: 0px;
}

.login .content .form-control {
  border: none;
  background-color: #dde3ec;
  height: 43px;
  color: #8290a3;
  border: 1px solid #dde3ec;
}

.login .content .form-control:focus,
.login .content .form-control:active {
  border: 1px solid #c3ccda;
}

.login .content .form-control::-moz-placeholder {
  color: #8290a3;
  opacity: 1;
}

.login .content .form-control:-ms-input-placeholder {
  color: #8290a3;
}

.login .content .form-control::-webkit-input-placeholder {
  color: #8290a3;
}

.login .content select.form-control {
  padding-left: 9px;
  padding-right: 9px;
}

.login .content .forget-form {
  display: none;
}

.login .content .register-form {
  display: none;
}

.login .content .form-title {
  font-weight: 300;
  margin-bottom: 0px;
  margin-top: 10px;
}

.login .content .pg-title {
  font-weight: 400;
  margin-bottom: 15px;
  margin-top: 5px;
}

.your-indicator {
  /* display: flex; */
  justify-content: center;
  text-align: center;

  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  user-select: none;
}


.login .content .form-actions {
  clear: both;
  border: 0;
  border-top: 1px solid #433C3C !important;
  padding: 10px 30px 10px 30px;
  margin-left: -30px;
  margin-right: -30px;
}

.login .content .form-actions>.btn {
  margin-top: -2px;
}

.login-options {
  margin-top: 30px;
  margin-bottom: 30px;
  overflow: hidden;
}

.login-options h4 {
  float: left;
  font-weight: 600;
  font-size: 15px;
  color: #7d91aa !important;
}

.login-options .social-icons {
  float: right;
  padding-top: 3px;
}

.login-options .social-icons li a {
  border-radius: 15px 15px 15px 15px !important;
  -moz-border-radius: 15px 15px 15px 15px !important;
  -webkit-border-radius: 15px 15px 15px 15px !important;
}

.login .content .form-actions .checkbox {
  margin-left: 0;
  padding-left: 0;
}

.login .content .forget-form .form-actions {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.login .content .register-form .form-actions {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 0px;
}

.login .content .form-actions .btn {
  margin-top: 1px;
}

.login .content .form-actions .btn {
  font-weight: 600;
  padding: 10px 20px !important;
}

.login .content .form-actions .btn-default {
  font-weight: 600;
  padding: 10px 25px !important;
  color: #6c7a8d;
  background-color: #ffffff;
  border: none;
}

.login .content .form-actions .btn-default:hover {
  background-color: #fafaff;
  color: #45b6af;
}

.login .content .forget-password {
  font-size: 14px;
  float: right;
  display: inline-block;
  margin-top: 10px;
}

.login .content .check {
  color: #8290a3;
}

.create-acc {
  text-align: center;
  padding: 5px;
  color: white;
}

a {
  color: white;
}

.login .content .create-account>p {
  margin: 0;
}

.login .content .create-account p a {
  font-weight: 600;
  font-size: 14px;
  color: #c3cedd;
}

.login .content .create-account a {
  display: inline-block;
  margin-top: 5px;
}

/* footer copyright */

.login .copyright {
  text-align: center;
  margin: 0 auto 30px 0;
  padding: 10px;
  color: #7a8ca5;
  font-size: 13px;
}

@media (max-width: 440px) {

  /***
  Login page
  ***/
  .login .logo {
    margin-top: 10px;
  }

  .login .content {
    width: 95%;
    margin-top: 10px;
  }

  .login .content h3 {
    font-size: 22px;
  }

  .forget-password {
    display: inline-block;
    margin-top: 20px;
  }

  .login-options .social-icons {
    float: left;
    padding-top: 3px;
  }

  .login .checkbox {
    font-size: 13px;
  }
}

/* When the body has the loading class, we turn
   the scrollbar off with overflow:hidden */

verification.loading {
  overflow: hidden;
}

verification.loading .modal {
  display: block;
}

/* Cubic Bezier Transition */

/***
Login page
***/

/* bg color */

.verification {
  /*background-color: #364150 !important;*/
}

.verification .logo {
  margin: 0 auto;
  margin-top: 60px;
  padding: 15px;
  text-align: center;
}

.verification .content {
  background-color: white;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  border-radius: 7px;
  width: 74%;
  margin: 40px auto 10px auto;
  padding: 30px;
  padding-top: 10px;
  overflow: hidden;
  position: relative;
}

.verification .content h3 {
  color: #4db3a5;
  text-align: center;
  font-size: 28px;
  font-weight: 400 !important;
}

.verification .content h4 {
  color: #555;
}

.verification .content .hint {
  color: #999;
  padding: 0;
  margin: 15px 0 7px 0;
}

.verification .content .verification-form,
.verification .content .forget-form {
  padding: 0px;
  margin: 0px;
}

.verification .content .form-control {
  border: none;
  background-color: #dde3ec;
  height: 43px;
  color: #8290a3;
  border: 1px solid #dde3ec;
}

.verification .content .form-control:focus,
.verification .content .form-control:active {
  border: 1px solid #c3ccda;
}

.verification .content .form-control::-moz-placeholder {
  color: #8290a3;
  opacity: 1;
}

.verification .content .form-control:-ms-input-placeholder {
  color: #8290a3;
}

.verification .content .form-control::-webkit-input-placeholder {
  color: #8290a3;
}

.verification .content select.form-control {
  padding-left: 9px;
  padding-right: 9px;
}

.verification .content .forget-form {
  display: none;
}

.verification .content .register-form {
  display: none;
}

.verification .content .form-title {
  font-weight: 300;
  margin-bottom: 25px;
}

.verification .content .form-actions {
  clear: both;
  border: 0;
  border-bottom: 1px solid #eee;
  padding: 25px 30px 25px 30px;
  margin-left: -30px;
  margin-right: -30px;
}

.verification .content .form-actions>.btn {
  margin-top: -2px;
}

.verification-options {
  margin-top: 30px;
  margin-bottom: 30px;
  overflow: hidden;
}

.verification-options h4 {
  float: left;
  font-weight: 600;
  font-size: 15px;
  color: #7d91aa !important;
}

.verification-options .social-icons {
  float: right;
  padding-top: 3px;
}

.verification-options .social-icons li a {
  border-radius: 15px 15px 15px 15px !important;
  -moz-border-radius: 15px 15px 15px 15px !important;
  -webkit-border-radius: 15px 15px 15px 15px !important;
}

.verification .content .form-actions .checkbox {
  margin-left: 0;
  padding-left: 0;
}

.verification .content .forget-form .form-actions {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.verification .content .register-form .form-actions {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 0px;
}

.verification .content .form-actions .btn {
  margin-top: 1px;
}

.verification .content .form-actions .btn {
  font-weight: 600;
  padding: 10px 20px !important;
}

.verification .content .form-actions .btn-default {
  font-weight: 600;
  padding: 10px 25px !important;
  color: #6c7a8d;
  background-color: #ffffff;
  border: none;
}

.verification .content .form-actions .btn-default:hover {
  background-color: #fafaff;
  color: #45b6af;
}

.verification .content .forget-password {
  font-size: 14px;
  float: right;
  display: inline-block;
  margin-top: 10px;
}

.verification .content .check {
  color: #8290a3;
}

.verification .content .create-account {
  margin: 0 -40px -30px -40px;
  padding: 15px 0 17px 0;
  text-align: center;
  background-color: #6c7a8d;
  -webkit-border-radius: 0 0 7px 7px;
  -moz-border-radius: 0 0 7px 7px;
  -ms-border-radius: 0 0 7px 7px;
  -o-border-radius: 0 0 7px 7px;
  border-radius: 0 0 7px 7px;
}

.verification .content .create-account>p {
  margin: 0;
}

.verification .content .create-account p a {
  font-weight: 600;
  font-size: 14px;
  color: #c3cedd;
}

.verification .content .create-account a {
  display: inline-block;
  margin-top: 5px;
}

/* footer copyright */

.verification .copyright {
  text-align: center;
  margin: 0 auto 30px 0;
  padding: 10px;
  color: #7a8ca5;
  font-size: 13px;
}

@media (max-width: 440px) {

  /***
  Login page
  ***/
  .verification .logo {
    margin-top: 10px;
  }

  .verification .content {
    width: 280px;
    margin-top: 10px;
  }

  .verification .content h3 {
    font-size: 22px;
  }

  .forget-password {
    display: inline-block;
    margin-top: 20px;
  }

  .verification-options .social-icons {
    float: left;
    padding-top: 3px;
  }

  .verification .checkbox {
    font-size: 13px;
  }
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  /* border: 5px solid #2980b9; */
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 10px 0;
  background-color: #cf8900;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  color: #fff;
  font: 700 15px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/

.ribbon-top-left {
  top: -12px;
  left: -12px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}

.ribbon-top-left::before {
  top: 0;
  right: 0;
}

.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}

.ribbon-top-left span {
  right: -12px;
  top: 30px;
  transform: rotate(-45deg);
}

.border-radius-5-important {
  border-radius: 5px !important;
}

.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp-input-div {
  align-content: center;
  display: flex;
  flex-direction: column;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #14181e;
  position: absolute;
  border: 1px solid rgba(67, 60, 60, 0.2);
  border-radius: 4px;
  z-index: 10;
  overflow: hidden;
}

.blurred {
  filter: blur(3px);
}

.otp-input-div input {
  width: 66px !important;
  height: 100px !important;
  font-size: 20px;
}


.Demo__some-network {
  vertical-align: top;
  display: inline-block;
  /* margin-right: 10px; */
  text-align: center;
  width: 25%;
  background: gray;
  border-radius: 5px;
  margin: 5px;
  padding: 10px;
}


.Demo__some-network__share-button {
  cursor: pointer;
}

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75;
}

.Demo__some-network__custom-icon {
  width: 55px;
  height: 55px;
}

body>iframe[style*='2147483647'] {
  display: none;
}

.vertical-timeline-element--work {
  box-shadow: 0 0px 0 #ddd;
}

.marquee-container {
  height: 30px;
  overflow: hidden;
  line-height: 30px;
}

.marquee-container .marquee {
  /* top: 0; */
  left: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  animation: marquee 40s linear infinite;
}

.marquee-container .marquee2 {
  animation-delay: 20s;
}

.marquee-container b {
  padding-left: 10px;
}

@keyframes marquee {
  0% {
    left: 100%;
  }

  100% {
    left: -100%;
  }
}

.bf-dropdown-container {
  text-align: left;
  border: 1px solid #ccc;
  position: relative;
  border-radius: 5px;
}

.bf-ddropdown-input {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
}

.animated-border-box,
.animated-border-box-glow {
  /* max-height: 250px;
  max-width: 300px; */
  /* height: 100%; */
  width: calc(100% - 50px);
  position: absolute;
  overflow: hidden;
  z-index: 0;
  /* Border Radius */
  border-radius: 10px;
}

.animated-border-box-glow {
  overflow: hidden;
  /* Glow Blur */
  filter: blur(20px);
}

.animated-border-box:before,
.animated-border-box-glow:before {
  content: '';
  z-index: -2;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  position: absolute;
  width: 99999px;
  height: 99999px;
  background-repeat: no-repeat;
  background-position: 0 0;
  /*border color, change middle color*/
  background-image: conic-gradient(rgba(0, 0, 0, 0), #cf8900, rgba(0, 0, 0, 0) 25%);
  /* change speed here */
  animation: rotate 4s linear infinite;
}

.animated-border-box:after {
  content: '';
  position: absolute;
  z-index: -1;
  /* border width */
  left: 5px;
  top: 5px;
  /* double the px from the border width left */
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  /*bg color*/
  background: #292a2e;
  /*box border radius*/
  border-radius: 7px;
}

@keyframes rotate {
  100% {
    transform: translate(-50%, -50%) rotate(1turn);
  }
}